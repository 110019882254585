import React from 'react'

export const Logo = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_237_3869)">
        <path
          d="M0.0283527 20.35L8.18586 4.0282H11.3225L3.14005 20.35H0.0283527Z"
          fill="white"
        />
        <path
          d="M17.1507 17.512H23.9454V20.35H12.8955V4.0282H17.1507V17.512Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_237_3869">
          <rect
            width="24"
            height="16.3667"
            fill="white"
            transform="translate(0 4)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Logo
