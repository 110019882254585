import React, { Component, Fragment } from 'react'
import Button from '@liveramp/motif/core/Button'
import Alert from '@liveramp/motif/core/Alert'

export default class SimpleDemo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  handleOpen = () => this.setState({ open: true })

  handleClose = (event, reason) => {
    if (reason === 'clickaway') return
    this.setState({ open: false })
  }

  render() {
    return (
      <Fragment>
        <Button variant="secondary" onClick={this.handleOpen}>
          Open Me
        </Button>
        <Alert open={this.state.open} onClose={this.handleClose}>
          I am a basic Alert!
        </Alert>
      </Fragment>
    )
  }
}
