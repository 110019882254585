import React, { useState } from 'react'
import RichDataTable, {
  GridLinkOperator,
} from '@liveramp/motif/core/RichDataTable'
import ButtonFormGroup from '@liveramp/motif/core/ButtonFormGroup'
import Filter from '@liveramp/motif/core/Filter'
import Button from '@liveramp/motif/core/Button'
import makeStyles from '@liveramp/motif/styles/makeStyles'
import { caffeineOrder, rows } from '../../demos/data'

const columns = [
  { field: 'name', headerName: 'Name', width: 150, searchable: true },
  {
    field: 'caffeine',
    headerName: 'Caffeine',
    width: 120,
    sortComparator: (value1, value2) =>
      caffeineOrder.indexOf(value1) - caffeineOrder.indexOf(value2),
  },
  {
    field: 'price',
    headerName: 'Price',
    type: 'number',
    width: 120,
    valueFormatter: (params) => `$${params.value.toFixed(2)}`,
  },
  { field: 'desc', headerName: 'Description', width: 500 },
]

function ToolbarActions(
  { apiRef },
  { deleteSearches },
  filterValue,
  setFilterValue,
) {
  const classes = useStyles()

  const handleFilterChange = (e, newFilterValue) => {
    apiRef.current.applyFilterLinkOperator(GridLinkOperator.Or)

    let difference = filterValue
      .filter((x) => !newFilterValue.includes(x))
      .concat(newFilterValue.filter((x) => !filterValue.includes(x)))

    difference.forEach((diff) => {
      if (newFilterValue.includes(diff)) {
        apiRef.current.upsertFilter({
          id: `caffeine-filter-${diff}`,
          columnField: 'caffeine',
          operatorValue: 'contains',
          value: diff,
        })
      } else {
        apiRef.current.deleteFilter({
          id: `caffeine-filter-${diff}`,
          columnField: 'caffeine',
          operatorValue: 'contains',
          value: diff,
        })
      }
    })

    setFilterValue(newFilterValue)
  }

  const handleClearSearch = () => {
    const filterItems = apiRef.current.state.filter.items
    if (filterItems.length > 0) {
      filterItems.map((item) => apiRef.current.deleteFilter(item))
    }

    deleteSearches()
  }

  return (
    <ButtonFormGroup className={classes.bottomAlign}>
      <Filter
        id="filter-caffeine"
        label="Filter Caffeine"
        options={caffeineOrder}
        value={filterValue}
        onChange={handleFilterChange}
        getOptionLabel={(option) => option}
      />
      <Button onClick={handleClearSearch}>Clear Search</Button>
    </ButtonFormGroup>
  )
}

export default function SearchingToolbarDemo() {
  const [filterValue, setFilterValue] = useState([])

  return (
    <RichDataTable
      columns={columns}
      rows={rows}
      toolbarActions={(gridSlotComponentProps, searchDetails) =>
        ToolbarActions(
          gridSlotComponentProps,
          searchDetails,
          filterValue,
          setFilterValue,
        )
      }
    />
  )
}

let useStyles = makeStyles((theme) => ({
  bottomAlign: {
    height: '100%',
    alignItems: 'flex-end',
  },
}))
