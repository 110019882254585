/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: 'MuiOutlinedInput',
  description: '',
  displayName: 'OutlinedInput',
  methods: [],
  props: {
    autoComplete: {
      type: { name: 'string' },
      required: false,
      description:
        "This prop helps users to fill forms faster, especially on mobile devices.\r\nThe name can be confusing, as it's more like an autofill.\r\nYou can learn more about it [following the specification](https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill).",
    },
    autoFocus: {
      type: { name: 'bool' },
      required: false,
      description:
        'If `true`, the `input` element will be focused during the first mount.',
    },
    classes: {
      type: { name: 'object' },
      required: false,
      description:
        'Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details.',
    },
    color: {
      type: {
        name: 'enum',
        value: [
          { value: "'primary'", computed: false },
          { value: "'secondary'", computed: false },
        ],
      },
      required: false,
      description:
        'The color of the component. It supports those theme colors that make sense for this component.',
    },
    defaultValue: {
      type: { name: 'any' },
      required: false,
      description:
        'The default `input` element value. Use when the component is not controlled.',
    },
    disabled: {
      type: { name: 'bool' },
      required: false,
      description: 'If `true`, the `input` element will be disabled.',
    },
    endAdornment: {
      type: { name: 'node' },
      required: false,
      description: 'End `InputAdornment` for this component.',
    },
    error: {
      type: { name: 'bool' },
      required: false,
      description:
        'If `true`, the input will indicate an error. This is normally obtained via context from\r\nFormControl.',
    },
    fullWidth: {
      type: { name: 'bool' },
      required: false,
      description:
        'If `true`, the input will take up the full width of its container.',
      defaultValue: { value: 'false', computed: false },
    },
    id: {
      type: { name: 'string' },
      required: false,
      description: 'The id of the `input` element.',
    },
    inputComponent: {
      type: { name: 'elementType' },
      required: false,
      description:
        'The component used for the `input` element.\r\nEither a string to use a HTML element or a component.',
      defaultValue: { value: "'input'", computed: false },
    },
    inputProps: {
      type: { name: 'object' },
      required: false,
      description:
        '[Attributes](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Attributes) applied to the `input` element.',
    },
    inputRef: {
      type: { name: 'custom', raw: 'refType' },
      required: false,
      description: 'Pass a ref to the `input` element.',
    },
    label: {
      type: { name: 'node' },
      required: false,
      description:
        'The label of the input. It is only used for layout. The actual labelling\r\nis handled by `InputLabel`. If specified `labelWidth` is ignored.',
    },
    labelWidth: {
      type: { name: 'number' },
      required: false,
      description:
        'The width of the label. Is ignored if `label` is provided. Prefer `label`\r\nif the input label appears with a strike through.',
      defaultValue: { value: '0', computed: false },
    },
    margin: {
      type: {
        name: 'enum',
        value: [
          { value: "'dense'", computed: false },
          { value: "'none'", computed: false },
        ],
      },
      required: false,
      description:
        'If `dense`, will adjust vertical spacing. This is normally obtained via context from\r\nFormControl.',
    },
    maxRows: {
      type: { name: 'union', value: [{ name: 'number' }, { name: 'string' }] },
      required: false,
      description:
        'Maximum number of rows to display when multiline option is set to true.',
    },
    multiline: {
      type: { name: 'bool' },
      required: false,
      description: 'If `true`, a textarea element will be rendered.',
      defaultValue: { value: 'false', computed: false },
    },
    name: {
      type: { name: 'string' },
      required: false,
      description: 'Name attribute of the `input` element.',
    },
    notched: {
      type: { name: 'bool' },
      required: false,
      description:
        'If `true`, the outline is notched to accommodate the label.',
    },
    onChange: {
      type: { name: 'func' },
      required: false,
      description:
        'Callback fired when the value is changed.\r\n\r\n@param {object} event The event source of the callback.\r\nYou can pull out the new value by accessing `event.target.value` (string).',
    },
    placeholder: {
      type: { name: 'string' },
      required: false,
      description:
        'The short hint displayed in the input before the user enters a value.',
    },
    readOnly: {
      type: { name: 'bool' },
      required: false,
      description:
        'It prevents the user from changing the value of the field\r\n(not from interacting with the field).',
    },
    required: {
      type: { name: 'bool' },
      required: false,
      description: 'If `true`, the `input` element will be required.',
    },
    rows: {
      type: { name: 'union', value: [{ name: 'number' }, { name: 'string' }] },
      required: false,
      description:
        'Number of rows to display when multiline option is set to true.',
    },
    startAdornment: {
      type: { name: 'node' },
      required: false,
      description: 'Start `InputAdornment` for this component.',
    },
    type: {
      type: { name: 'string' },
      required: false,
      description:
        'Type of the `input` element. It should be [a valid HTML5 input type](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Form_%3Cinput%3E_types).',
      defaultValue: { value: "'text'", computed: false },
    },
    value: {
      type: { name: 'any' },
      required: false,
      description:
        'The value of the `input` element, required for a controlled component.',
    },
  },
  styles: {
    root: { description: 'Styles applied to the root element.' },
    colorSecondary: {
      description:
        'Styles applied to the root element if the color is secondary.',
    },
    focused: {
      description:
        'Styles applied to the root element if the component is focused.',
    },
    disabled: {
      description: 'Styles applied to the root element if `disabled={true}`.',
    },
    adornedStart: {
      description:
        'Styles applied to the root element if `startAdornment` is provided.',
    },
    adornedEnd: {
      description:
        'Styles applied to the root element if `endAdornment` is provided.',
    },
    error: {
      description:
        'Pseudo-class applied to the root element if `error={true}`.',
    },
    marginDense: {
      description: 'Styles applied to the `input` element if `margin="dense"`.',
    },
    multiline: {
      description: 'Styles applied to the root element if `multiline={true}`.',
    },
    notchedOutline: {
      description: 'Styles applied to the `NotchedOutline` element.',
    },
    input: { description: 'Styles applied to the `input` element.' },
    inputMarginDense: {
      description: 'Styles applied to the `input` element if `margin="dense"`.',
    },
    inputMultiline: {
      description:
        'Styles applied to the `input` element if `multiline={true}`.',
    },
    inputAdornedStart: {
      description:
        'Styles applied to the `input` element if `startAdornment` is provided.',
    },
    inputAdornedEnd: {
      description:
        'Styles applied to the `input` element if `endAdornment` is provided.',
    },
  },
}
export default generatedDocs
