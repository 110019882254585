import React from 'react'
import createSvgIcon from './utils/createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M12.203 4.482a1 1 0 011.321-.062l.093.084 2.792 2.883a2.6 2.6 0 01.724 1.607l.008.201v8.93a1.8 1.8 0 01-1.652 1.795l-.148.006h-.27a1 1 0 01-.116-1.993l.116-.007.07-.001v-8.73a.6.6 0 00-.11-.346l-.059-.071-2.792-2.882a1 1 0 01.023-1.414z" />
    <path d="M15.905 4.482a1 1 0 011.321-.062l.093.084 2.792 2.883a2.6 2.6 0 01.724 1.607l.008.201v8.93a1.8 1.8 0 01-1.652 1.795l-.148.006h-.4a1 1 0 01-.117-1.993l.117-.007.2-.001v-8.73a.6.6 0 00-.11-.346l-.059-.071-2.791-2.882a1 1 0 01.022-1.414z" />
    <path d="M8.239 4c.37 0 .73.129 1.012.364l.116.108 3.864 3.864c.266.259.428.604.464.97l.008.158V18.4c0 .831-.642 1.52-1.455 1.593l-.145.007H4.895a1.597 1.597 0 01-1.586-1.455l-.006-.145.008-12.8a1.6 1.6 0 011.447-1.593L4.903 4h3.336zm3.564 4.948l-3.5-3.5v2.5a1 1 0 001 1h2.5z" />
  </React.Fragment>,
  'CompactedFiles',
)
