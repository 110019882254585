import React from 'react'
import Truncate from '@liveramp/motif/core/Truncate'
import makeStyles from '@liveramp/motif/styles/makeStyles'

export default function PositionTooltip() {
  const classes = useStyles()
  const generateText = (position) => {
    let text = []
    while (text.length < 3) {
      text.push(`This tooltip shows up on the ${position}. `)
    }
    return text.join('')
  }
  return (
    <div className={classes.container}>
      <Truncate className={classes.top} tooltipPosition={'top'} wrapped>
        {generateText('top')}
      </Truncate>
      <Truncate className={classes.bottom} tooltipPosition={'bottom'} wrapped>
        {generateText('bottom')}
      </Truncate>
      <Truncate className={classes.left} tooltipPosition={'left'} wrapped>
        {generateText('left')}
      </Truncate>
      <Truncate className={classes.right} tooltipPosition={'right'} wrapped>
        {generateText('right')}
      </Truncate>
    </div>
  )
}

let useStyles = makeStyles({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: '1em 1em',
    gridTemplateAreas: `". top ."
    "left . right"
    ". bottom ."`,
  },
  top: {
    gridArea: 'top',
  },
  bottom: {
    gridArea: 'bottom',
  },
  left: {
    gridArea: 'left',
  },
  right: {
    gridArea: 'right',
  },
})
