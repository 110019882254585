import React from 'react'
import MuiStepConnector from '@material-ui/core/StepConnector'
import { makeStyles, mergeClasses } from '../../utils'
import { StepConnectorStyle } from '../../utils/styleKeys'

export default function StepConnector({ classes, ...props }) {
  const defaultClasses = useStyles()

  return (
    <MuiStepConnector
      classes={mergeClasses(defaultClasses, classes)}
      {...props}
    />
  )
}

const useStyles = makeStyles(
  (theme) => ({
    ...StepConnectorStyle,
    alternativeLabel: {
      top: '16px',
      left: 'calc(-50% + 24px)',
      right: 'calc(50% + 24px)',
    },
    vertical: {
      marginLeft: '14px',
      padding: '8px 0',
    },
    lineVertical: {
      minHeight: '48px',
    },
  }),
  { name: 'MuiStepConnector' },
)
