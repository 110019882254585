import React from 'react'
import MuiStepButton from '@material-ui/core/StepButton'
import StepIcon from '../StepIcon'
import { makeStyles, mergeClasses } from '../../utils'
import { StepButtonStyle } from '../../utils/styleKeys'

export default function StepButton({
  active,
  completed,
  error,
  icon,
  classes,
  children,
  hideStepNum,
  ...props
}) {
  const defaultClasses = useStyles()
  return (
    <MuiStepButton
      classes={mergeClasses(defaultClasses, classes)}
      icon={
        <StepIcon
          active={active}
          completed={completed}
          error={error}
          icon={icon}
          hideStepNum={hideStepNum}
        />
      }
      children={React.cloneElement(children, {
        active,
        completed,
        error,
        StepIconComponent: null, // the StepIcon is already handled by StepButton's icon prop
      })}
      {...props}
    />
  )
}

const useStyles = makeStyles(
  (theme) => ({
    ...StepButtonStyle,
    horizontal: {
      width: 'unset',
      padding: '24px',
      minWidth: '160px',
      minHeight: '124px',
      boxSizing: 'border-box',
      '&:hover': {
        backgroundColor: theme.palette.secondary.hover,
        borderRadius: '4px',
      },
    },
    vertical: {
      padding: '8px 16px',
      textAlign: 'left',
      minWidth: '192px',
      minHeight: '64px',
      margin: '-16px',
      '&:hover': {
        backgroundColor: theme.palette.secondary.hover,
        borderRadius: '4px',
      },
    },
  }),
  { name: 'MuiStepButton' },
)
