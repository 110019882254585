import React from 'react'
import clsx from 'clsx'
import FormGroup from '../../core-components/FormGroup'
import { makeStyles, mergeClasses } from '../../utils'
import { FormGroupStyle } from '../../utils/styleKeys'

export default function LogoCardGroup({
  children,
  classes,
  className,
  ...props
}) {
  const defaultClasses = useStyles()
  return (
    <FormGroup
      data-testid="LogoCardGroup"
      className={clsx('LogoCardGroup', 'LogoCardGroup-root', className)}
      classes={mergeClasses(defaultClasses, classes)}
      row
      {...props}
    >
      {React.Children.map(children, (card) =>
        React.cloneElement(card, { selectable: true }),
      )}
    </FormGroup>
  )
}

const useStyles = makeStyles((theme) => ({
  ...FormGroupStyle,
  root: {
    gap: '16px',
  },
}))
