import React from 'react'
import Button from '../../../core-components/Button'

const StatusButton = ({ onClick, text }) => {
  return (
    <Button size="small" variant="outlined" color="secondary" onClick={onClick}>
      {text}
    </Button>
  )
}

const FileStatusButton = ({
  isError,
  isSuccess,
  isRejected,
  retry,
  cancel,
  open,
}) => {
  if (isRejected) {
    return <StatusButton onClick={open} text={'Browse New File'} />
  }

  if (isError) {
    return <StatusButton onClick={retry} text={'Try Again'} />
  }

  if (isSuccess) {
    return null
  }

  return <StatusButton onClick={cancel} text={'Cancel'} />
}

export default FileStatusButton
