import React from 'react'
import {
  Page,
  Title,
  Subtitle,
  Supporting,
  Usage,
  NoticePanel,
  SectionTitle,
} from '../../resources/DemoPage'
import Demo, { generateDemos } from '../../resources/Demo'
import CodeChunk from '../../resources/CodeChunk'

import Simple from './demos/Simple'
import SwitchType from './demos/SwitchType'
import FormGroup from './demos/FormGroup'
import LabelPlacement from './demos/LabelPlacement'
import Size from './demos/Size'
import CustomDestructive from './demos/CustomDestructive'
import Custom from './demos/Custom'

let demoNames = [
  'Simple',
  'SwitchType',
  'FormGroup',
  'LabelPlacement',
  'Size',
  'CustomDestructive',
  'Custom',
]

export default function Switch() {
  let demos = generateDemos('core-component-demo/switch', demoNames)
  return (
    <Page
      components={[
        'Switch',
        'FormGroup',
        'FormControl',
        'FormLabel',
        'FormControlLabel',
        'FormHelperText',
      ]}
    >
      <Title>Switch</Title>
      <Subtitle>
        Switches toggle the state of a single setting on or off
      </Subtitle>
      <Supporting />
      <Usage>
        <ul>
          <li>For immediately activating or deactivating something</li>
          <li>
            Switches should be used instead of radio buttons if only one item
            can be selected from a list
          </li>
          <li>
            <a href="https://material.io/components/selection-controls/">
              Material Design documentation
            </a>{' '}
            (<i>Google</i>)
          </li>
        </ul>
      </Usage>

      <Demo demo={demos.Simple}>
        <Simple />
      </Demo>

      <SectionTitle>FormControlLabel</SectionTitle>
      <p>
        <code>FormControlLabel</code> has a <code>control</code> prop that
        accepts a control element to attach a label description to e.g.{' '}
        <code>Radio</code>,<code>Checkbox</code>, <code>Switch</code>
      </p>
      <Demo demo={demos.SwitchType}>
        <SwitchType />
      </Demo>

      <SectionTitle>FormGroup and FormHelperText</SectionTitle>
      <p>
        <code>FormGroup</code> is a wrapper used to group multiple selection
        control elements.
      </p>
      <p>
        <code>FormHelperText</code> appends text to your <code>FormGroup</code>,
        such as descriptions, hints and errors.
      </p>
      <Demo demo={demos.FormGroup}>
        <FormGroup />
      </Demo>

      <SectionTitle>Label Placement</SectionTitle>
      <Demo demo={demos.LabelPlacement}>
        <LabelPlacement />
      </Demo>

      <SectionTitle>Size</SectionTitle>
      <NoticePanel variant="info">
        <p>
          Remember to also use <code>size="small"</code> for the FormGroupLabel.
        </p>
      </NoticePanel>
      <Demo demo={demos.Size}>
        <Size />
      </Demo>

      <SectionTitle>Custom Destructive Switch</SectionTitle>
      <p>
        If you need to display a destructive option in a switch, you can use the
        <code>error</code> color from our palette to emphasize the destructive
        nature of <code>checked=false</code>. Also remember to handle click
        actions on the labels on either side of the switch.
      </p>
      <Demo demo={demos.CustomDestructive}>
        <CustomDestructive />
      </Demo>

      <SectionTitle>Custom Switches</SectionTitle>
      <Demo demo={demos.Custom}>
        <Custom />
      </Demo>

      <SectionTitle>Accessibility</SectionTitle>
      <p>
        All form controls should have labels, and this includes radio buttons,
        checkboxes, and switches. In most cases, this is done by using the{' '}
        <code>{`<label>`}</code> element (FormControlLabel).
      </p>
      <p>
        When a label can't be used, it's necessary to add an attribute directly
        to the input component. In this case, you can apply the additional
        attribute (e.g. <code>aria-label</code>, <code>aria-labelledby</code>,{' '}
        <code>title</code>) via the inputProps property.
      </p>
      <CodeChunk>
        {`<Switch value="checkedA" inputProps={{ 'aria-label': 'Switch A' }}/>`}
      </CodeChunk>
    </Page>
  )
}
