import React, { useState } from 'react'
import Tabs from '@liveramp/motif/core/Tabs'
import Tab from '@liveramp/motif/core/Tab'
import { makeStyles } from '@liveramp/motif/utils'

const useStyles = makeStyles({
  container: {
    maxWidth: 878,
    overflow: 'auto',
  },
})

export default function OverflowDemo() {
  const classes = useStyles()
  let [value, setValue] = useState(1)

  let handleChange = (event, value) => {
    setValue(value)
  }

  return (
    <div className={classes.container}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="Overflow tabs"
      >
        <Tab value={1} label={'TAB ONE'} {...a11yProps('1')} />
        <Tab value={2} label={'TAB TWO'} {...a11yProps('2')} />
        <Tab value={3} label={'TAB THREE'} {...a11yProps('3')} />
        <Tab value={4} label={'TAB FOUR'} {...a11yProps('4')} />
        <Tab value={5} label={'TAB FIVE'} {...a11yProps('5')} />
        <Tab value={6} label={'TAB SIX'} {...a11yProps('6')} />
        <Tab value={7} label={'TAB SEVEN'} {...a11yProps('7')} />
        <Tab value={8} label={'TAB EIGHT'} {...a11yProps('8')} />
        <Tab value={9} label={'TAB NINE'} {...a11yProps('9')} />
      </Tabs>
    </div>
  )
}

function a11yProps(tab) {
  return {
    id: `overflow-tab-${tab}`,
    'aria-controls': `overflow-tabpanel-${tab}`,
  }
}
