import React from 'react'
import Skeleton from '@liveramp/motif/core/Skeleton'
import Card from '@liveramp/motif/core/Card'
import CardContent from '@liveramp/motif/core/CardContent'
import css from './Skeleton.module.css'

export default function SkeletonVariantDemo() {
  return (
    <div className={css.grid}>
      <Card className={css.card}>
        <CardContent>
          <Skeleton variant="rect" className={css.media} />
          <Skeleton height={20} />
          <Skeleton height={20} />
          <Skeleton height={20} />
        </CardContent>
      </Card>
    </div>
  )
}
