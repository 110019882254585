import React from 'react'
import { Page, Title, Subtitle } from '../../../resources/DemoPage'
import { RdtNotices } from '../index'
import Demo, { generateDemos } from '../../../resources/Demo'
import PaginationDemo from './demos/Pagination'

let demoNames = ['Pagination']

export default function RichDataTable() {
  let demos = generateDemos(
    'composed-component-demo/rich-data-table/pagination',
    demoNames,
  )

  return (
    <Page>
      <Title>Rich Data Table - Pagination</Title>
      <Subtitle>Rich Data Table for React apps</Subtitle>
      <RdtNotices />

      <Demo demo={demos.Pagination}>
        <PaginationDemo />
      </Demo>
    </Page>
  )
}
