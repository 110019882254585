import React from 'react'
import {
  StylesProvider,
  jssPreset,
  createGenerateClassName,
} from '@material-ui/core/styles'
import { create } from 'jss'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import theme from '../../styles/theme'
import PropTypes from 'prop-types'

export default function Motif(props) {
  let jss = create({
    ...jssPreset(),
    insertionPoint: document.getElementById('jss-insertion-point'),
  })

  const { productionPrefix, seed, children, ...otherProps } = props

  const generateClassName = createGenerateClassName({
    seed: seed || '',
    productionPrefix: productionPrefix || 'motif-',
  })

  return (
    <StylesProvider jss={jss} generateClassName={generateClassName}>
      <MuiThemeProvider theme={theme} {...otherProps} children={children} />
    </StylesProvider>
  )
}

Motif.propTypes = {
  children: PropTypes.node.isRequired,
}
