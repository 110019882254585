import React from 'react'
import {
  Page,
  Title,
  Subtitle,
  Supporting,
  Usage,
  NoticePanel,
} from '../../resources/DemoPage'
import Demo, { generateDemos } from '../../resources/Demo'

import Simple from './demos/Simple'

let demoNames = ['Simple']

export default function VerticalNav() {
  let demos = generateDemos('composed-component-demo/vertical-nav', demoNames)

  return (
    <Page components={['VerticalNav', 'VerticalNavItem', 'VerticalNavDivider']}>
      <Title>Vertical Nav</Title>
      <Subtitle>
        The Vertical Nav bar displays links to application pages and content
      </Subtitle>
      <Supporting>
        Each nav item is represented by an icon and/or text label. They may be
        attached to a corresponding menu of links related to the nav item.
      </Supporting>
      <NoticePanel variant="danger">
        <p>
          This component is being deprecated. Please refer to the{' '}
          <a href="/composed/global-navigation">Global Navigation</a> instead.
        </p>
      </NoticePanel>
      <Usage>
        <ul>
          <li>
            You can pass in an <code>icon</code> and/or <code>text</code> to
            each nav <code>Item</code>.
          </li>
          <li>
            To help clarify the user's current location within your app, use the{' '}
            <code>active</code> prop.
          </li>
          <li>
            To make the nav <code>Item</code> a direct link without relying on a
            menu, use <code>onClick</code>.
          </li>
          <li>
            If your page has background color other than white, you may need to
            pass in a <code>caretColor</code> (the caret is pure CSS!{' '}
            <span role="img" aria-label="yay">
              {' '}
              🎉
            </span>
            ).
          </li>
        </ul>
      </Usage>

      <Demo demo={demos.Simple}>
        <Simple />
      </Demo>
    </Page>
  )
}
