let overrideDocs = {
  props: {
    light: {
      type: { name: 'bool' },
      required: false,
      description:
        'If `true`, the divider will have a lighter color so that it can be used on dark backgrounds.',
      defaultValue: { value: 'false', computed: false },
    },
  },
}

export default overrideDocs
