/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: 'MuiBadge',
  description: '',
  displayName: 'Badge',
  methods: [],
  props: {
    anchorOrigin: {
      type: {
        name: 'shape',
        value: {
          horizontal: {
            name: 'enum',
            value: [
              { value: "'left'", computed: false },
              { value: "'right'", computed: false },
            ],
            required: true,
          },
          vertical: {
            name: 'enum',
            value: [
              { value: "'bottom'", computed: false },
              { value: "'top'", computed: false },
            ],
            required: true,
          },
        },
      },
      required: false,
      description: 'The anchor of the badge.',
      defaultValue: {
        value: "{\n  vertical: 'top',\n  horizontal: 'right'\n}",
        computed: false,
      },
    },
    badgeContent: {
      type: { name: 'node' },
      required: false,
      description: 'The content rendered within the badge.',
    },
    children: {
      type: { name: 'node' },
      required: false,
      description: 'The badge will be added relative to this node.',
    },
    classes: {
      type: {
        name: 'custom',
        raw: "chainPropTypes(PropTypes.object, props => {\n  const {\n    classes\n  } = props;\n\n  if (classes == null) {\n    return null;\n  }\n\n  [['anchorOriginTopRightRectangle', 'anchorOriginTopRightRectangular'], ['anchorOriginBottomRightRectangle', 'anchorOriginBottomRightRectangular'], ['anchorOriginTopLeftRectangle', 'anchorOriginTopLeftRectangular'], ['anchorOriginBottomLeftRectangle', 'anchorOriginBottomLeftRectangular'], ['anchorOriginTopRightCircle', 'anchorOriginTopRightCircular'], ['anchorOriginBottomRightCircle', 'anchorOriginBottomRightCircular'], ['anchorOriginTopLeftCircle', 'anchorOriginTopLeftCircular']].forEach(([deprecatedClassKey, newClassKey]) => {\n    if (classes[deprecatedClassKey] != null && // 2 classnames? one from withStyles the other must be custom\n    classes[deprecatedClassKey].split(' ').length > 1) {\n      throw new Error(`Material-UI: The \\`${deprecatedClassKey}\\` class was deprecated. Use \\`${newClassKey}\\` instead.`);\n    }\n  });\n  return null;\n})",
      },
      required: false,
      description:
        'Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details.',
    },
    className: {
      type: { name: 'string' },
      required: false,
      description: '@ignore',
    },
    color: {
      type: {
        name: 'enum',
        value: [
          { value: "'default'", computed: false },
          { value: "'error'", computed: false },
          { value: "'primary'", computed: false },
          { value: "'secondary'", computed: false },
        ],
      },
      required: false,
      description:
        'The color of the component. It supports those theme colors that make sense for this component.',
      defaultValue: { value: "'default'", computed: false },
    },
    component: {
      type: { name: 'elementType' },
      required: false,
      description:
        'The component used for the root node.\r\nEither a string to use a HTML element or a component.',
      defaultValue: { value: "'span'", computed: false },
    },
    invisible: {
      type: { name: 'bool' },
      required: false,
      description: 'If `true`, the badge will be invisible.',
    },
    max: {
      type: { name: 'number' },
      required: false,
      description: 'Max count to show.',
      defaultValue: { value: '99', computed: false },
    },
    overlap: {
      type: {
        name: 'custom',
        raw: "chainPropTypes(PropTypes.oneOf(['circle', 'rectangle', 'circular', 'rectangular']), props => {\n  const {\n    overlap\n  } = props;\n\n  if (overlap === 'rectangle') {\n    throw new Error('Material-UI: `overlap=\"rectangle\"` was deprecated. Use `overlap=\"rectangular\"` instead.');\n  }\n\n  if (overlap === 'circle') {\n    throw new Error('Material-UI: `overlap=\"circle\"` was deprecated. Use `overlap=\"circular\"` instead.');\n  }\n\n  return null;\n})",
      },
      required: false,
      description: 'Wrapped shape the badge should overlap.',
      defaultValue: { value: "'rectangle'", computed: false },
    },
    showZero: {
      type: { name: 'bool' },
      required: false,
      description:
        'Controls whether the badge is hidden when `badgeContent` is zero.',
      defaultValue: { value: 'false', computed: false },
    },
    variant: {
      type: {
        name: 'enum',
        value: [
          { value: "'dot'", computed: false },
          { value: "'standard'", computed: false },
        ],
      },
      required: false,
      description: 'The variant to use.',
      defaultValue: { value: "'standard'", computed: false },
    },
  },
  styles: {
    root: { description: 'Styles applied to the root element.' },
    badge: { description: 'Styles applied to the badge `span` element.' },
    colorPrimary: {
      description: 'Styles applied to the root element if `color="primary"`.',
    },
    colorSecondary: {
      description: 'Styles applied to the root element if `color="secondary"`.',
    },
    colorError: {
      description: 'Styles applied to the root element if `color="error"`.',
    },
    dot: {
      description: 'Styles applied to the root element if `variant="dot"`.',
    },
    anchorOriginTopRightRectangle: {
      description:
        "Styles applied to the root element if `anchorOrigin={{ 'top', 'right' }} overlap=\"rectangle\"`.",
    },
    anchorOriginTopRightRectangular: {
      description:
        "Styles applied to the root element if `anchorOrigin={{ 'top', 'right' }} overlap=\"rectangular\"`.",
    },
    anchorOriginBottomRightRectangle: {
      description:
        "Styles applied to the root element if `anchorOrigin={{ 'bottom', 'right' }} overlap=\"rectangle\"`.",
    },
    anchorOriginBottomRightRectangular: {
      description:
        "Styles applied to the root element if `anchorOrigin={{ 'bottom', 'right' }} overlap=\"rectangular\"`.",
    },
    anchorOriginTopLeftRectangle: {
      description:
        "Styles applied to the root element if `anchorOrigin={{ 'top', 'left' }} overlap=\"rectangle\"`.",
    },
    anchorOriginTopLeftRectangular: {
      description:
        "Styles applied to the root element if `anchorOrigin={{ 'top', 'left' }} overlap=\"rectangular\"`.",
    },
    anchorOriginBottomLeftRectangle: {
      description:
        "Styles applied to the root element if `anchorOrigin={{ 'bottom', 'left' }} overlap=\"rectangle\"`.",
    },
    anchorOriginBottomLeftRectangular: {
      description:
        "Styles applied to the root element if `anchorOrigin={{ 'bottom', 'left' }} overlap=\"rectangular\"`.",
    },
    anchorOriginTopRightCircle: {
      description:
        "Styles applied to the root element if `anchorOrigin={{ 'top', 'right' }} overlap=\"circle\"`.",
    },
    anchorOriginTopRightCircular: {
      description:
        "Styles applied to the root element if `anchorOrigin={{ 'top', 'right' }} overlap=\"circular\"`.",
    },
    anchorOriginBottomRightCircle: {
      description:
        "Styles applied to the root element if `anchorOrigin={{ 'bottom', 'right' }} overlap=\"circle\"`.",
    },
    anchorOriginBottomRightCircular: {
      description:
        "Styles applied to the root element if `anchorOrigin={{ 'bottom', 'right' }} overlap=\"circular\"`.",
    },
    anchorOriginTopLeftCircle: {
      description:
        "Styles applied to the root element if `anchorOrigin={{ 'top', 'left' }} overlap=\"circle\"`.",
    },
    anchorOriginTopLeftCircular: {
      description:
        "Styles applied to the root element if `anchorOrigin={{ 'top', 'left' }} overlap=\"circular\"`.",
    },
    anchorOriginBottomLeftCircle: {
      description:
        "Styles applied to the root element if `anchorOrigin={{ 'bottom', 'left' }} overlap=\"circle\"`.",
    },
    anchorOriginBottomLeftCircular: {
      description:
        "Styles applied to the root element if `anchorOrigin={{ 'bottom', 'left' }} overlap=\"circular\"`.",
    },
    invisible: {
      description:
        'Pseudo-class to the badge `span` element if `invisible={true}`.',
    },
  },
}
export default generatedDocs
