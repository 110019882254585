import React, { useCallback, useRef, useState } from 'react'
import clsx from 'clsx'
import Button from '../../core-components/Button'
import Popper from '../../core-components/Popper'
import MultiFilterAutocomplete from './MultiFilterAutocomplete'
import { flattenOptions, restoreOriginalOptions } from './utils'

import makeStyles from '../../styles/makeStyles'
import FilterIcon from '../../icons/Filter'
import ArrowDropDownIcon from '../../icons/ArrowDropDown'

const FADE_DURATION = 750
const TOGGLE_INPUT_REASON = 'toggleInput'

export default function MultiFilter({
  id,
  label,
  buttonProps,
  popperProps,

  // autocomplete props
  options,
  onChange,
  inputValue: inputValueProp = '',
  value: valueProp,
  ...props
}) {
  const timeoutRef = useRef()
  const menuRef = useRef(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [temporaryFade, setTemporaryFade] = useState(false)

  const flattenedValue = flattenOptions(valueProp)
  const [inputValue, setInputValue] = useState(inputValueProp)
  const [value, setValue] = useState(flattenedValue)
  const [initialValue, setInitialValue] = useState(flattenedValue)

  useStyles()

  const setListeners = useCallback((node) => {
    if (node) {
      node.addEventListener('keydown', handleKeyDown, {
        capture: true,
      })
    }
    menuRef.current = node
  }, [])

  const handleKeyDown = (e) => {
    switch (e.key) {
      case 'Escape':
        // currently the escape key is being hooked into an internal
        // useAutocomplete handler which closes the autocomplete but our open
        // state is being handled with anchorEl
        setAnchorEl(null)
        clearTimeout(timeoutRef.current) // Also clear any leftover timeout
        e.stopPropagation()
        break
      default:
    }
  }

  const handleChange = (event, value) => {
    setValue(value)

    if (onChange) {
      const restoredValues = restoreOriginalOptions(options, value)
      onChange(event, restoredValues)
    }
  }

  const handleClose = (event, reason) => {
    if (reason === TOGGLE_INPUT_REASON) {
      return
    }
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    setAnchorEl(null)
    setTemporaryFade(false)
  }

  const open = Boolean(anchorEl)

  return (
    <div data-testid="MultiFilter" className="MultiFilter" id={id}>
      <Button
        className="MultiFilter__button MultiFilter-button"
        onClick={(event) =>
          anchorEl ? setAnchorEl(null) : setAnchorEl(event.currentTarget)
        }
        startIcon={<FilterIcon />}
        endIcon={
          <ArrowDropDownIcon
            className={clsx('MultiFilter__caret', {
              ['MultiFilter__caret--isOpen']: open,
            })}
          />
        }
        variant={value?.length > 0 ? 'contained' : 'outlined'}
        color={value?.length > 0 ? 'primary' : 'secondary'}
        {...buttonProps}
      >
        {label ? label : 'Advanced Filters'}
      </Button>
      <Popper
        anchorEl={anchorEl}
        ref={setListeners}
        className={clsx('MultiFilter__popper', 'MultiFilter-popper', {
          ['MultiFilter__temporary-fade']: temporaryFade,
        })}
        open={open}
        placement="bottom-start"
        keepMounted
        {...popperProps}
      >
        <MultiFilterAutocomplete
          onChange={handleChange}
          onClose={handleClose}
          options={options}
          initialValue={initialValue}
          setInitialValue={setInitialValue}
          value={value}
          setValue={setValue}
          inputValue={inputValue}
          setInputValue={setInputValue}
          title={label ? label : 'Advanced Filters'}
          {...props}
        />
      </Popper>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.MultiFilter': {
      // .MultiFilter____caret
      '&__caret': {
        // .MultiFilter__caret--isOpen
        '&&--isOpen': {
          transform: 'rotate(180deg)',
        },
      },
      // .MultiFilter__popper
      '&__popper': {
        zIndex: theme.zIndex.dialog,
      },
    },
    '@keyframes fadeOut': {
      from: { opacity: 0.75 },
      to: { opacity: 0 },
    },
    // .MultiFilter__temporary-fade
    '&__temporary-fade': {
      opacity: 0.75,
      animation: `$fadeOut ${FADE_DURATION}ms ease-in-out`,
    },
  },
}))
