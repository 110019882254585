import React from 'react'
import {
  Page,
  Title,
  Subtitle,
  Supporting,
  Usage,
  SectionTitle,
} from '../../resources/DemoPage'
import Demo, { generateDemos } from '../../resources/Demo'

import Simple from './demos/Simple'
import Sticky from './demos/Sticky'
import CellTooltips from './demos/CellTooltips'
import Sorting from './demos/Sorting'

let demoNames = ['Simple', 'Sticky', 'CellTooltips', 'Sorting']

export default function Table() {
  let demos = generateDemos('core-component-demo/table', demoNames)

  return (
    <Page
      components={[
        'Table',
        'TableBody',
        'TableCell',
        'TableFooter',
        'TableHeader',
        'TableRow',
        'TableSortLabel',
      ]}
    >
      <Title>Table</Title>
      <Subtitle>Tables display sets of data</Subtitle>
      <Supporting>
        Tables display information in a way that’s easy to scan, so that users
        can look for patterns and insights. They can be embedded in primary
        content, such as cards. Tables should work intuitively, allowing users
        to focus on content, not structure.
      </Supporting>
      <Usage>
        <ul>
          <li>
            A data table contains a header row at the top that lists column
            names, followed by rows for data.
          </li>
          <li>
            Checkboxes should accompany each row if the user needs to select or
            manipulate data.
          </li>
          <li>
            Tables can include tools to query and manipulate data, navigation or
            a corresponding visualization.
          </li>
          <li>
            When including tools, they should be placed directly above or below
            the table.
          </li>
        </ul>
      </Usage>

      <SectionTitle>Simple Table</SectionTitle>
      <Demo demo={demos.Simple}>
        <Simple />
      </Demo>

      <SectionTitle>Sticky Table Header and Footer</SectionTitle>
      <p>
        We provide a helper TableWrapper component to provide the styling
        required to enable sticky headers and footers. It requires a height
        prop, but will default to 632px.
      </p>
      <p>
        To calculate the table height you will need, the default row heights are
        as follows:
      </p>
      <ul>
        <li>
          <code>size="medium"</code> (default)
          <ul>
            <li>
              Header & Footer: <code>56px</code>
            </li>
            <li>
              Rows: <code>52px</code>
            </li>
          </ul>
        </li>
        <li>
          <code>size="small"</code>
          <ul>
            <li>
              Header & Footer: <code>40px</code>
            </li>
            <li>
              Rows: <code>36px</code>
            </li>
          </ul>
        </li>
      </ul>
      <Demo demo={demos.Sticky}>
        <Sticky />
      </Demo>

      <SectionTitle>Cell Tooltips</SectionTitle>
      <p>
        If tooltips are required, the header and relevant body cell must be
        given a <code>max-width</code> as well as the standard overflow rules;{' '}
        <code>text-overflow: ellipsis</code>, <code>overflow: hidden</code> and{' '}
        <code>whitespace: nowrap</code> to work properly.
      </p>
      <Demo demo={demos.CellTooltips}>
        <CellTooltips />
      </Demo>

      <SectionTitle>Sorting</SectionTitle>
      <p>
        We provide a TableSortLabel component to enable the sort feature of
        Table
      </p>
      <Demo demo={demos.Sorting}>
        <Sorting />
      </Demo>
    </Page>
  )
}
