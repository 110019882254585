import React from 'react'

const WorkflowCreateIntegrationConnection = (props) => (
  <svg width="104px" height="28px" viewBox="0 0 104 28" {...props}>
    <g fillRule="evenodd" fill="none" strokeWidth="1" stroke="none">
      <g
        id="Group"
        transform="translate(4.125670, 2.941154)"
        stroke="currentColor"
        strokeWidth="1.5"
      >
        <circle id="Oval" cx="10.75" cy="10.75" r="10.75"></circle>
        <circle id="Oval-Copy" cx="47.8743305" cy="10.75" r="10.75"></circle>
        <circle id="Oval-Copy-2" cx="84.9986609" cy="10.75" r="10.75"></circle>
        <line
          x1="10.75"
          y1="11.0798791"
          x2="37.1243305"
          y2="11.0798791"
          id="Line"
          strokeLinecap="round"
        ></line>
        <line
          x1="43.0511918"
          y1="11.0798791"
          x2="52.5409338"
          y2="11.0798791"
          id="Line-Copy-2"
          strokeLinecap="round"
        ></line>
        <line
          x1="42.9982174"
          y1="11.3792444"
          x2="52.4879594"
          y2="11.3792444"
          id="Line-Copy-3"
          strokeLinecap="round"
          transform="translate(47.796063, 11.358211) rotate(90.000000) translate(-47.796063, -11.358211) "
        ></line>
        <line
          x1="58.5217332"
          y1="11.0798791"
          x2="84.8960636"
          y2="11.0798791"
          id="Line-Copy"
          strokeLinecap="round"
        ></line>
      </g>
    </g>
  </svg>
)

export default WorkflowCreateIntegrationConnection
