/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: 'MuiAutocomplete',
  description: '',
  displayName: 'Autocomplete',
  methods: [],
  props: {
    autoComplete: {
      type: { name: 'bool' },
      required: false,
      description:
        'If `true`, the portion of the selected suggestion that has not been typed by the user,\r\nknown as the completion string, appears inline after the input cursor in the textbox.\r\nThe inline completion string is visually highlighted and has a selected state.',
      defaultValue: { value: 'false', computed: false },
    },
    autoHighlight: {
      type: { name: 'bool' },
      required: false,
      description: 'If `true`, the first option is automatically highlighted.',
      defaultValue: { value: 'false', computed: false },
    },
    autoSelect: {
      type: { name: 'bool' },
      required: false,
      description:
        'If `true`, the selected option becomes the value of the input\r\nwhen the Autocomplete loses focus unless the user chooses\r\na different option or changes the character string in the input.',
      defaultValue: { value: 'false', computed: false },
    },
    blurOnSelect: {
      type: {
        name: 'union',
        value: [
          {
            name: 'enum',
            value: [
              { value: "'mouse'", computed: false },
              { value: "'touch'", computed: false },
            ],
          },
          { name: 'bool' },
        ],
      },
      required: false,
      description:
        'Control if the input should be blurred when an option is selected:\r\n\r\n- `false` the input is not blurred.\r\n- `true` the input is always blurred.\r\n- `touch` the input is blurred after a touch event.\r\n- `mouse` the input is blurred after a mouse event.',
      defaultValue: { value: 'false', computed: false },
    },
    ChipProps: {
      type: { name: 'object' },
      required: false,
      description: 'Props applied to the [`Chip`](/api/chip/) element.',
    },
    classes: {
      type: { name: 'object' },
      required: false,
      description:
        'Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details.',
    },
    className: {
      type: { name: 'string' },
      required: false,
      description: '@ignore',
    },
    clearOnBlur: {
      type: { name: 'bool' },
      required: false,
      description:
        "If `true`, the input's text will be cleared on blur if no value is selected.\r\n\r\nSet to `true` if you want to help the user enter a new value.\r\nSet to `false` if you want to help the user resume his search.",
      defaultValue: { value: '!props.freeSolo', computed: false },
    },
    clearOnEscape: {
      type: { name: 'bool' },
      required: false,
      description:
        'If `true`, clear all values when the user presses escape and the popup is closed.',
      defaultValue: { value: 'false', computed: false },
    },
    clearText: {
      type: { name: 'string' },
      required: false,
      description:
        'Override the default text for the *clear* icon button.\r\n\r\nFor localization purposes, you can use the provided [translations](/guides/localization/).',
      defaultValue: { value: "'Clear'", computed: false },
    },
    closeIcon: {
      type: { name: 'node' },
      required: false,
      description: 'The icon to display in place of the default close icon.',
      defaultValue: {
        value: 'React.createElement(CloseIcon, {\n  fontSize: "small"\n})',
        computed: true,
      },
    },
    closeText: {
      type: { name: 'string' },
      required: false,
      description:
        'Override the default text for the *close popup* icon button.\r\n\r\nFor localization purposes, you can use the provided [translations](/guides/localization/).',
      defaultValue: { value: "'Close'", computed: false },
    },
    debug: {
      type: { name: 'bool' },
      required: false,
      description:
        'If `true`, the popup will ignore the blur event if the input is filled.\r\nYou can inspect the popup markup with your browser tools.\r\nConsider this option when you need to customize the component.',
      defaultValue: { value: 'false', computed: false },
    },
    defaultValue: {
      type: { name: 'any' },
      required: false,
      description:
        'The default input value. Use when the component is not controlled.',
      defaultValue: { value: 'props.multiple ? [] : null', computed: false },
    },
    disableClearable: {
      type: { name: 'bool' },
      required: false,
      description: "If `true`, the input can't be cleared.",
      defaultValue: { value: 'false', computed: false },
    },
    disableCloseOnSelect: {
      type: { name: 'bool' },
      required: false,
      description: "If `true`, the popup won't close when a value is selected.",
      defaultValue: { value: 'false', computed: false },
    },
    disabled: {
      type: { name: 'bool' },
      required: false,
      description: 'If `true`, the input will be disabled.',
      defaultValue: { value: 'false', computed: false },
    },
    disabledItemsFocusable: {
      type: { name: 'bool' },
      required: false,
      description: 'If `true`, will allow focus on disabled items.',
      defaultValue: { value: 'false', computed: false },
    },
    disableListWrap: {
      type: { name: 'bool' },
      required: false,
      description: 'If `true`, the list box in the popup will not wrap focus.',
      defaultValue: { value: 'false', computed: false },
    },
    disablePortal: {
      type: { name: 'bool' },
      required: false,
      description:
        "Disable the portal behavior.\r\nThe children stay within it's parent DOM hierarchy.",
      defaultValue: { value: 'false', computed: false },
    },
    filterOptions: {
      type: { name: 'func' },
      required: false,
      description:
        'A filter function that determines the options that are eligible.\r\n\r\n@param {T[]} options The options to render.\r\n@param {object} state The state of the component.\r\n@returns {T[]}',
    },
    filterSelectedOptions: {
      type: { name: 'bool' },
      required: false,
      description: 'If `true`, hide the selected options from the list box.',
      defaultValue: { value: 'false', computed: false },
    },
    forcePopupIcon: {
      type: {
        name: 'union',
        value: [
          { name: 'enum', value: [{ value: "'auto'", computed: false }] },
          { name: 'bool' },
        ],
      },
      required: false,
      description: 'Force the visibility display of the popup icon.',
      defaultValue: { value: "'auto'", computed: false },
    },
    freeSolo: {
      type: { name: 'bool' },
      required: false,
      description:
        'If `true`, the Autocomplete is free solo, meaning that the user input is not bound to provided options.',
      defaultValue: { value: 'false', computed: false },
    },
    fullWidth: {
      type: { name: 'bool' },
      required: false,
      description:
        'If `true`, the input will take up the full width of its container.',
      defaultValue: { value: 'false', computed: false },
    },
    getLimitTagsText: {
      type: { name: 'func' },
      required: false,
      description:
        'The label to display when the tags are truncated (`limitTags`).\r\n\r\n@param {number} more The number of truncated tags.\r\n@returns {ReactNode}',
      defaultValue: { value: 'more => `+${more}`', computed: false },
    },
    getOptionDisabled: {
      type: { name: 'func' },
      required: false,
      description:
        'Used to determine the disabled state for a given option.\r\n\r\n@param {T} option The option to test.\r\n@returns {boolean}',
    },
    getOptionLabel: {
      type: { name: 'func' },
      required: false,
      description:
        "Used to determine the string value for a given option.\r\nIt's used to fill the input (and the list box options if `renderOption` is not provided).\r\n\r\n@param {T} option\r\n@returns {string}",
      defaultValue: { value: 'x => x', computed: false },
    },
    getOptionSelected: {
      type: { name: 'func' },
      required: false,
      description:
        'Used to determine if an option is selected, considering the current value.\r\nUses strict equality by default.\r\n\r\n@param {T} option The option to test.\r\n@param {T} value The value to test against.\r\n@returns {boolean}',
    },
    groupBy: {
      type: { name: 'func' },
      required: false,
      description:
        'If provided, the options will be grouped under the returned string.\r\nThe groupBy value is also used as the text for group headings when `renderGroup` is not provided.\r\n\r\n@param {T} options The options to group.\r\n@returns {string}',
    },
    handleHomeEndKeys: {
      type: { name: 'bool' },
      required: false,
      description:
        'If `true`, the component handles the "Home" and "End" keys when the popup is open.\r\nIt should move focus to the first option and last option, respectively.',
      defaultValue: { value: '!props.freeSolo', computed: false },
    },
    id: {
      type: { name: 'string' },
      required: false,
      description:
        "This prop is used to help implement the accessibility logic.\r\nIf you don't provide this prop. It falls back to a randomly generated id.",
    },
    includeInputInList: {
      type: { name: 'bool' },
      required: false,
      description: 'If `true`, the highlight can move to the input.',
      defaultValue: { value: 'false', computed: false },
    },
    inputValue: {
      type: { name: 'string' },
      required: false,
      description: 'The input value.',
    },
    limitTags: {
      type: { name: 'number' },
      required: false,
      description:
        'The maximum number of tags that will be visible when not focused.\r\nSet `-1` to disable the limit.',
      defaultValue: { value: '-1', computed: false },
    },
    ListboxComponent: {
      type: { name: 'elementType' },
      required: false,
      description: 'The component used to render the listbox.',
      defaultValue: { value: "'ul'", computed: false },
    },
    ListboxProps: {
      type: { name: 'object' },
      required: false,
      description: 'Props applied to the Listbox element.',
    },
    loading: {
      type: { name: 'bool' },
      required: false,
      description: 'If `true`, the component is in a loading state.',
      defaultValue: { value: 'false', computed: false },
    },
    loadingText: {
      type: { name: 'node' },
      required: false,
      description:
        'Text to display when in a loading state.\r\n\r\nFor localization purposes, you can use the provided [translations](/guides/localization/).',
      defaultValue: { value: "'Loading…'", computed: false },
    },
    multiple: {
      type: { name: 'bool' },
      required: false,
      description:
        'If `true`, `value` must be an array and the menu will support multiple selections.',
      defaultValue: { value: 'false', computed: false },
    },
    noOptionsText: {
      type: { name: 'node' },
      required: false,
      description:
        'Text to display when there are no options.\r\n\r\nFor localization purposes, you can use the provided [translations](/guides/localization/).',
      defaultValue: { value: "'No options'", computed: false },
    },
    onChange: {
      type: { name: 'func' },
      required: false,
      description:
        'Callback fired when the value changes.\r\n\r\n@param {object} event The event source of the callback.\r\n@param {T|T[]} value The new value of the component.\r\n@param {string} reason One of "create-option", "select-option", "remove-option", "blur" or "clear".',
    },
    onClose: {
      type: { name: 'func' },
      required: false,
      description:
        'Callback fired when the popup requests to be closed.\r\nUse in controlled mode (see open).\r\n\r\n@param {object} event The event source of the callback.\r\n@param {string} reason Can be: `"toggleInput"`, `"escape"`, `"select-option"`, `"blur"`.',
    },
    onHighlightChange: {
      type: { name: 'func' },
      required: false,
      description:
        'Callback fired when the highlight option changes.\r\n\r\n@param {object} event The event source of the callback.\r\n@param {T} option The highlighted option.\r\n@param {string} reason Can be: `"keyboard"`, `"auto"`, `"mouse"`.',
    },
    onInputChange: {
      type: { name: 'func' },
      required: false,
      description:
        'Callback fired when the input value changes.\r\n\r\n@param {object} event The event source of the callback.\r\n@param {string} value The new value of the text input.\r\n@param {string} reason Can be: `"input"` (user input), `"reset"` (programmatic change), `"clear"`.',
    },
    onOpen: {
      type: { name: 'func' },
      required: false,
      description:
        'Callback fired when the popup requests to be opened.\r\nUse in controlled mode (see open).\r\n\r\n@param {object} event The event source of the callback.',
    },
    open: {
      type: { name: 'bool' },
      required: false,
      description: 'Control the popup` open state.',
    },
    openOnFocus: {
      type: { name: 'bool' },
      required: false,
      description: 'If `true`, the popup will open on input focus.',
      defaultValue: { value: 'false', computed: false },
    },
    openText: {
      type: { name: 'string' },
      required: false,
      description:
        'Override the default text for the *open popup* icon button.\r\n\r\nFor localization purposes, you can use the provided [translations](/guides/localization/).',
      defaultValue: { value: "'Open'", computed: false },
    },
    options: {
      type: { name: 'array' },
      required: true,
      description: 'Array of options.',
    },
    PaperComponent: {
      type: { name: 'elementType' },
      required: false,
      description: 'The component used to render the body of the popup.',
      defaultValue: { value: 'Paper', computed: true },
    },
    PopperComponent: {
      type: { name: 'elementType' },
      required: false,
      description: 'The component used to position the popup.',
      defaultValue: { value: 'Popper', computed: true },
    },
    popupIcon: {
      type: { name: 'node' },
      required: false,
      description: 'The icon to display in place of the default popup icon.',
      defaultValue: {
        value: 'React.createElement(ArrowDropDownIcon, null)',
        computed: true,
      },
    },
    renderGroup: {
      type: { name: 'func' },
      required: false,
      description:
        'Render the group.\r\n\r\n@param {any} option The group to render.\r\n@returns {ReactNode}',
    },
    renderInput: {
      type: { name: 'func' },
      required: true,
      description:
        'Render the input.\r\n\r\n@param {object} params\r\n@returns {ReactNode}',
    },
    renderOption: {
      type: { name: 'func' },
      required: false,
      description:
        'Render the option, use `getOptionLabel` by default.\r\n\r\n@param {T} option The option to render.\r\n@param {object} state The state of the component.\r\n@returns {ReactNode}',
    },
    renderTags: {
      type: { name: 'func' },
      required: false,
      description:
        'Render the selected value.\r\n\r\n@param {T[]} value The `value` provided to the component.\r\n@param {function} getTagProps A tag props getter.\r\n@returns {ReactNode}',
    },
    selectOnFocus: {
      type: { name: 'bool' },
      required: false,
      description:
        "If `true`, the input's text will be selected on focus.\r\nIt helps the user clear the selected value.",
      defaultValue: { value: '!props.freeSolo', computed: false },
    },
    size: {
      type: {
        name: 'enum',
        value: [
          { value: "'medium'", computed: false },
          { value: "'small'", computed: false },
        ],
      },
      required: false,
      description: 'The size of the autocomplete.',
      defaultValue: { value: "'medium'", computed: false },
    },
    value: {
      type: { name: 'any' },
      required: false,
      description:
        'The value of the autocomplete.\r\n\r\nThe value must have reference equality with the option in order to be selected.\r\nYou can customize the equality behavior with the `getOptionSelected` prop.',
    },
  },
  styles: {
    root: { description: 'Styles applied to the root element.' },
    '': { description: 'Avoid double tap issue on iOS' },
    fullWidth: {
      description: 'Styles applied to the root element if `fullWidth={true}`.',
    },
    focused: {
      description: 'Pseudo-class applied to the root element if focused.',
    },
    tag: { description: 'Styles applied to the tag elements, e.g. the chips.' },
    tagSizeSmall: {
      description:
        'Styles applied to the tag elements, e.g. the chips if `size="small"`.',
    },
    hasPopupIcon: {
      description: 'Styles applied when the popup icon is rendered.',
    },
    hasClearIcon: {
      description: 'Styles applied when the clear icon is rendered.',
    },
    inputRoot: { description: 'Styles applied to the Input element.' },
    input: { description: 'Styles applied to the input element.' },
    inputFocused: {
      description: 'Styles applied to the input element if tag focused.',
    },
    endAdornment: {
      description: 'Styles applied to the endAdornment element.',
    },
    clearIndicator: { description: 'Styles applied to the clear indicator.' },
    clearIndicatorDirty: {
      description:
        'Styles applied to the clear indicator if the input is dirty.',
    },
    popupIndicator: { description: 'Styles applied to the popup indicator.' },
    popupIndicatorOpen: {
      description:
        'Styles applied to the popup indicator if the popup is open.',
    },
    popper: { description: 'Styles applied to the popper element.' },
    popperDisablePortal: {
      description:
        'Styles applied to the popper element if `disablePortal={true}`.',
    },
    paper: { description: 'Styles applied to the `Paper` component.' },
    listbox: { description: 'Styles applied to the `listbox` component.' },
    loading: { description: 'Styles applied to the loading wrapper.' },
    noOptions: { description: 'Styles applied to the no option wrapper.' },
    option: { description: 'Styles applied to the option elements.' },
    groupLabel: {
      description: "Styles applied to the group's label elements.",
    },
    groupUl: { description: "Styles applied to the group's ul elements." },
  },
}
export default generatedDocs
