import React from 'react'
import List from '../../core-components/List'
import Error from '../../icons/Error'
import Loader from '../../core-components/Loader'
import Button from '../../core-components/Button'
import CloseButton from '../../core-components/CloseButton'
import NotificationItem from './NotificationItem'
import makeStyles from '../../styles/makeStyles'
import NotificationsEmpty from '../../icons/NotificationsEmpty'

/**
 *
 * @param notifications[]
 * @param onMarkReadClick @deprecated use onMarkAllReadClick
 * @param onMarkAllReadClick (notifications: Notification[]) => void
 * @param onSeeAllClick function
 * @param onClose function
 * @param height
 * @param error
 * @param loading boolean
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
export default function NotificationContent({
  notifications,
  onMarkReadClick,
  onMarkAllReadClick,
  onSeeAllClick,
  onClose,
  height,
  error = false,
  loading = false,
}) {
  useStyles()

  const handleMarkRead = () => {
    if (onMarkReadClick) {
      notifications.forEach((notification) => onMarkReadClick(notification))
    } else if (onMarkAllReadClick) {
      onMarkAllReadClick(notifications)
    }
    onClose && onClose()
  }

  let contentView
  if (error) {
    contentView = (
      <div className="NotificationContent__error-view NotificationContent__center-view">
        <Error />
        Failed to load notifications
      </div>
    )
  } else if (loading) {
    contentView = (
      <div className="NotificationContent__center-view">
        <Loader />
      </div>
    )
  } else if (notifications.length === 0) {
    contentView = (
      <div className="NotificationContent__center-view">
        <NotificationsEmpty />
        <div>There are no recent notifications</div>
      </div>
    )
  } else {
    contentView = (
      <List
        className="NotificationContent__list NotificationContent-list"
        disablePadding
      >
        {notifications.map((notification) => (
          <NotificationItem
            key={notification.id}
            onMarkReadClick={onMarkReadClick}
            {...notification}
          />
        ))}
      </List>
    )
  }

  return (
    <div
      data-testid="NotificationContent"
      className="NotificationContent"
      style={height && { height }}
    >
      <div className="NotificationContent__header">
        <h6>Notifications</h6>
        <a className="NotificationContent__mark-read" onClick={handleMarkRead}>
          Mark All as Read
        </a>
        {onClose && <CloseButton onClick={onClose} />}
      </div>
      {contentView}
      <Button
        className="NotificationContent-button"
        variant="text"
        color="primary"
        onClick={onSeeAllClick}
        fullWidth
      >
        SEE ALL
      </Button>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.NotificationContent': {
      display: 'flex',
      flexDirection: 'column',
      // height: props => (props?.height ? props.height : "100%"),
      width: '100%',
      overflowY: 'hidden',

      // .NotificationContent__header
      '&__header': {
        display: 'flex',
        alignItems: 'center',
        padding: '8px 16px',
        gap: '8px',

        '& > h6': {
          flex: 1,
          margin: 0,
        },
      },

      // .NotificationContent__mark-read
      '&__mark-read': {
        ...theme.typography.caption,
        color: theme.palette.text.primary,
        textDecoration: 'underline',
        fontStyle: 'italic',
        cursor: 'pointer',
      },

      // .NotificationContent__list
      '&__list': {
        flex: 1,
        overflowY: 'auto',
        paddingBottom: '2px', // this allows for the last item in the list to have a drop shadow on hover
      },

      // .NotificationContent__error-view
      '&__error-view': {
        color: theme.palette.danger.main,
      },

      // .NotificationContent__center-view
      '&__center-view': {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

        '& > svg': {
          fontSize: '5rem',
        },
      },
    },
  },
}))
