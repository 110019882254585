// this export is icon files w/o the js extension; include icon names in this export if an icon should be rendered in the display page under the Navigation category

let icons = [
  'ArrowBack',
  'ArrowDown',
  'ArrowDropDown',
  'ArrowDropUp',
  'ArrowForward',
  'ArrowUp',
  'CaretDown',
  'CaretLeft',
  'CaretRight',
  'CaretUp',
  'Fullscreen',
  'FullscreenExit',
  'GlobalAction',
  'Grip',
  'List',
  'Map',
  'Menu',
  'MoreHorizontal',
  'MoreVertical',
  'Settings',
]

export default icons
