import React from 'react'

const WorkflowSellData = (props) => (
  <svg width={82} height={82} viewBox="0 0 85 85" {...props}>
    <g fillRule="nonzero">
      <path d="M61.789 11.091v30.55c0 2.137-1.791 4.209-5.162 5.834-.271.134-.556.261-.957.43a1.086 1.086 0 00-.58 1.433c.24.554.89.81 1.451.573.437-.184.758-.327 1.065-.48C61.65 47.484 64 44.763 64 41.642v-30.55C64 10.49 63.505 10 62.894 10c-.61 0-1.105.489-1.105 1.091zM21 38.929V11.07C21 10.48 20.552 10 20 10s-1 .48-1 1.071V38.93c0 .591.448 1.071 1 1.071s1-.48 1-1.071z" />
      <path d="M26.659 19.78C30.447 21.258 35.389 22 41 22c12.204 0 22-4.882 22-11.268C63 4.43 53.423 0 41 0S19 4.43 19 10.732a1.073 1.073 0 002.146 0c0-4.665 8.476-8.586 19.854-8.586 11.378 0 19.854 3.92 19.854 8.586 0 4.794-8.683 9.122-19.854 9.122-5.364 0-10.05-.704-13.561-2.073a1.073 1.073 0 10-.78 2zM26.682 34.096A38.245 38.245 0 0041.007 37C53.211 37 63 31.858 63 25.13c0-.624-.48-1.13-1.072-1.13-.593 0-1.073.506-1.073 1.13 0 5.05-8.677 9.61-19.84 9.61a36.188 36.188 0 01-13.551-2.75c-.552-.227-1.174.06-1.39.641-.216.582.056 1.238.608 1.465zM20.617 71.07L.637 79.91c-.532.235-.78.874-.553 1.427.225.553.84.811 1.37.576l20.91-9.25c.517-.229.768-.844.567-1.39l-9.41-25.574c-.203-.554-.797-.836-1.333-.633l-11.5 4.353c-.542.205-.822.83-.625 1.394.198.565.797.856 1.34.651l10.534-3.987 8.68 23.594zM59.392 58.035a1.145 1.145 0 00-.163 1.55c.36.477 1.019.554 1.47.173l13.068-11.033c.035-.03.068-.062.1-.096a4.288 4.288 0 013.167-1.422c1.195 0 2.339.513 3.167 1.422.4.439 1.063.452 1.479.03a1.147 1.147 0 00.028-1.56C80.485 45.757 78.798 45 77.034 45c-1.74 0-3.405.737-4.624 2.045l-13.018 10.99z" />
      <path d="M29.45 62.726a1.121 1.121 0 00-1.014-.068l-8.778 3.748a1.061 1.061 0 00-.566 1.41c.243.542.89.79 1.445.552l8.254-3.524 16.52 9.882.086.046c4.111 1.99 9.023 1.534 12.675-1.178L80.59 55.905c.472-.37.546-1.045.166-1.505a1.116 1.116 0 00-1.542-.163l-22.496 17.67c-2.952 2.192-6.949 2.572-10.303.967L29.45 62.726z" />
      <path d="M57.76 57.485c2.132 1.4 2.615 3.043 1.352 4.814-1.29 1.808-4.057 2.14-6.142.771l-.047-.03-14.244-8.354c-.515-.302-1.19-.149-1.507.342-.317.492-.157 1.135.359 1.437l14.22 8.34c3.038 1.978 7.17 1.477 9.173-1.332 1.994-2.795 1.14-5.699-1.928-7.713L40.362 43.746a1.11 1.11 0 00-.136-.075l-.508-.235a48.506 48.506 0 00-.554-.252c-.884-.395-1.615-.677-2.339-.879-.711-.198-1.376-.305-2.007-.305-1.865.01-3.701.398-5.39 1.14L14.651 49.4c-.553.234-.802.851-.556 1.378.245.527.893.765 1.446.53l14.791-6.265a11.288 11.288 0 014.491-.954c.397 0 .861.074 1.386.22.597.167 1.234.413 2.026.766l.529.24.435.203 18.562 11.967zM8.93 68.572L2.05 50.677a1.062 1.062 0 00-1.368-.607 1.05 1.05 0 00-.612 1.358l6.88 17.895c.208.543.82.815 1.368.607a1.05 1.05 0 00.612-1.358z" />
    </g>
  </svg>
)

export default WorkflowSellData
