/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: 'MuiButtonBase',
  description:
    '`ButtonBase` contains as few styles as possible.\r\nIt aims to be a simple building block for creating a button.\r\nIt contains a load of style reset and some focus/ripple logic.',
  displayName: 'ButtonBase',
  methods: [],
  props: {
    action: {
      type: { name: 'custom', raw: 'refType' },
      required: false,
      description:
        'A ref for imperative actions.\r\nIt currently only supports `focusVisible()` action.',
    },
    buttonRef: {
      type: {
        name: 'custom',
        raw: "deprecatedPropType(refType, 'Use `ref` instead.')",
      },
      required: false,
      description:
        '@ignore\r\n\r\nUse that prop to pass a ref to the native button component.\r\n@deprecated Use `ref` instead.',
    },
    centerRipple: {
      type: { name: 'bool' },
      required: false,
      description:
        "If `true`, the ripples will be centered.\r\nThey won't start at the cursor interaction position.",
      defaultValue: { value: 'false', computed: false },
    },
    children: {
      type: { name: 'node' },
      required: false,
      description: 'The content of the component.',
    },
    classes: {
      type: { name: 'object' },
      required: false,
      description:
        'Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details.',
    },
    className: {
      type: { name: 'string' },
      required: false,
      description: '@ignore',
    },
    component: {
      type: { name: 'custom', raw: 'elementTypeAcceptingRef' },
      required: false,
      description:
        'The component used for the root node.\r\nEither a string to use a HTML element or a component.',
      defaultValue: { value: "'button'", computed: false },
    },
    disabled: {
      type: { name: 'bool' },
      required: false,
      description: 'If `true`, the base button will be disabled.',
      defaultValue: { value: 'false', computed: false },
    },
    disableRipple: {
      type: { name: 'bool' },
      required: false,
      description:
        'If `true`, the ripple effect will be disabled.\r\n\r\n⚠️ Without a ripple there is no styling for :focus-visible by default. Be sure\r\nto highlight the element by applying separate styles with the `focusVisibleClassName`.',
      defaultValue: { value: 'false', computed: false },
    },
    disableTouchRipple: {
      type: { name: 'bool' },
      required: false,
      description: 'If `true`, the touch ripple effect will be disabled.',
      defaultValue: { value: 'false', computed: false },
    },
    focusRipple: {
      type: { name: 'bool' },
      required: false,
      description:
        'If `true`, the base button will have a keyboard focus ripple.',
      defaultValue: { value: 'false', computed: false },
    },
    focusVisibleClassName: {
      type: { name: 'string' },
      required: false,
      description:
        "This prop can help identify which element has keyboard focus.\r\nThe class name will be applied when the element gains the focus through keyboard interaction.\r\nIt's a polyfill for the [CSS :focus-visible selector](https://drafts.csswg.org/selectors-4/#the-focus-visible-pseudo).\r\nThe rationale for using this feature [is explained here](https://github.com/WICG/focus-visible/blob/master/explainer.md).\r\nA [polyfill can be used](https://github.com/WICG/focus-visible) to apply a `focus-visible` class to other components\r\nif needed.",
    },
    href: { type: { name: 'string' }, required: false, description: '@ignore' },
    onBlur: { type: { name: 'func' }, required: false, description: '@ignore' },
    onClick: {
      type: { name: 'func' },
      required: false,
      description: '@ignore',
    },
    onDragLeave: {
      type: { name: 'func' },
      required: false,
      description: '@ignore',
    },
    onFocus: {
      type: { name: 'func' },
      required: false,
      description: '@ignore',
    },
    onFocusVisible: {
      type: { name: 'func' },
      required: false,
      description:
        'Callback fired when the component is focused with a keyboard.\r\nWe trigger a `onFocus` callback too.',
    },
    onKeyDown: {
      type: { name: 'func' },
      required: false,
      description: '@ignore',
    },
    onKeyUp: {
      type: { name: 'func' },
      required: false,
      description: '@ignore',
    },
    onMouseDown: {
      type: { name: 'func' },
      required: false,
      description: '@ignore',
    },
    onMouseLeave: {
      type: { name: 'func' },
      required: false,
      description: '@ignore',
    },
    onMouseUp: {
      type: { name: 'func' },
      required: false,
      description: '@ignore',
    },
    onTouchEnd: {
      type: { name: 'func' },
      required: false,
      description: '@ignore',
    },
    onTouchMove: {
      type: { name: 'func' },
      required: false,
      description: '@ignore',
    },
    onTouchStart: {
      type: { name: 'func' },
      required: false,
      description: '@ignore',
    },
    tabIndex: {
      type: { name: 'union', value: [{ name: 'number' }, { name: 'string' }] },
      required: false,
      description: '@ignore',
      defaultValue: { value: '0', computed: false },
    },
    TouchRippleProps: {
      type: { name: 'object' },
      required: false,
      description: 'Props applied to the `TouchRipple` element.',
    },
    type: {
      type: {
        name: 'union',
        value: [
          {
            name: 'enum',
            value: [
              { value: "'button'", computed: false },
              { value: "'reset'", computed: false },
              { value: "'submit'", computed: false },
            ],
          },
          { name: 'string' },
        ],
      },
      required: false,
      description: '@ignore',
      defaultValue: { value: "'button'", computed: false },
    },
  },
  styles: {
    root: { description: 'Styles applied to the root element.' },
    disabled: {
      description:
        'Pseudo-class applied to the root element if `disabled={true}`.',
    },
    focusVisible: {
      description:
        'Pseudo-class applied to the root element if keyboard focused.',
    },
  },
}
export default generatedDocs
