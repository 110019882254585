import React from 'react'
import clsx from 'clsx'
import CardActions from '../../core-components/CardActions'
import { makeStyles, mergeClasses } from '../../utils'
import { CardActionsStyle } from '../../utils/styleKeys'

export default function LogoCardActions({
  children,
  classes,
  className,
  disabled,
  renderOnHover = null,
  ...props
}) {
  const defaultClasses = useStyles()
  useCustomStyles()
  return (
    <CardActions
      data-testid="LogoCardActions"
      className={clsx('LogoCardActions', 'LogoCardActions-root', className)}
      classes={mergeClasses(defaultClasses, classes)}
      disabled={disabled}
      data-hover={Boolean(renderOnHover)}
      {...props}
    >
      <div className="LogoCardActions-default">{children}</div>
      {renderOnHover && (
        <div className="LogoCardActions-onHover">{renderOnHover}</div>
      )}
    </CardActions>
  )
}

const useStyles = makeStyles((theme) => ({
  ...CardActionsStyle,
  root: {
    padding: '14px', // -2px LR for border
    minHeight: '64px',
  },
}))

const useCustomStyles = makeStyles((theme) => ({
  '@global': {
    '.LogoCardActions-default': {
      width: '100%',
    },
    '.LogoCardActions-onHover': {
      width: '100%',
    },
  },
}))
