import React, { useContext, useReducer } from 'react'
import UploadContext from './UploadContext'

export const STATUS_ERROR = 'ERROR'
export const STATUS_SUCCESS = 'SUCCESS'
export const STATUS_QUEUED = 'QUEUED'
export const STATUS_UPLOADING = 'UPLOADING'
export const STATUS_REJECTED = 'REJECTED'

export const STATUS_DETAIL_CANCELED = 'File Upload Canceled!'

const defaultFileState = {
  progress: 0,
  status: STATUS_QUEUED,
  isError: false,
  isComplete: false,
  isSuccess: false,
  isRejected: false,
}
export const useFileListReducer = () => {
  const reducer = (state, { type, payload }) => {
    const updateState = (state, type, payload) => {
      switch (type) {
        case 'RECORD_FILE_PROGRESS': {
          const { progress, motifId, file } = payload
          const previous = state.files[motifId]
          const newFile = file
            ? { ...previous, ...file, progress }
            : { ...previous, progress }
          return {
            ...state,
            files: {
              ...state.files,
              [motifId]: newFile,
            },
          }
        }
        case 'FILE_UPLOAD_ERROR': {
          const { errorMessage, motifId } = payload
          return {
            ...state,
            files: {
              ...state.files,
              [motifId]: {
                ...state.files[motifId],
                statusDetail: errorMessage,
                isError: true,
                status: STATUS_ERROR,
              },
            },
          }
        }
        case 'FILE_UPLOAD_CANCELLED': {
          const { motifId } = payload
          return {
            ...state,
            files: {
              ...state.files,
              [motifId]: {
                ...state.files[motifId],
                statusDetail: STATUS_DETAIL_CANCELED,
                isError: true,
                isComplete: false,
                progress: state.files[motifId].progress || 100,
                status: STATUS_ERROR,
              },
            },
          }
        }
        case 'FILE_UPLOAD_QUEUED': {
          const { motifId, file } = payload
          return {
            ...state,
            count: state.count + 1,
            files: {
              ...state.files,
              [motifId]: {
                ...file,
                ...defaultFileState,
                motifId,
              },
            },
            mostRecentFileId: motifId,
          }
        }
        case 'FILE_UPLOAD_BEGIN': {
          const { motifId, file } = payload
          return {
            ...state,
            files: {
              ...state.files,
              [motifId]: {
                ...state.files[motifId],
                ...file,
                isError: false,
                status: STATUS_UPLOADING,
              },
            },
          }
        }
        case 'FILE_REJECTED': {
          const { motifId, errorMessage } = payload
          return {
            ...state,
            files: {
              ...state.files,
              [motifId]: {
                ...state.files[motifId],
                isError: false,
                statusDetail: errorMessage,
                status: STATUS_REJECTED,
                isRejected: true,
                progress: 100,
                isComplete: true,
              },
            },
          }
        }
        case 'REMOVE_FILE': {
          const { motifId } = payload
          delete state.files[motifId]
          return {
            ...state,
            count: state.count - 1,
            mostRecentFileId: null,
            mostRecentFile: null,
          }
        }
        case 'FILE_UPLOAD_COMPLETED': {
          const { motifId, responseDetail = null, file = {} } = payload
          return {
            ...state,
            files: {
              ...state.files,
              [motifId]: {
                ...state.files[motifId],
                ...file,
                progress: 100,
                isComplete: true,
                isError: false,
                isSuccess: true,
                status: STATUS_SUCCESS,
                responseDetail,
              },
            },
          }
        }
        default:
          return state
      }
    }
    const { mostRecentFileId, files, ...updatedState } = updateState(
      state,
      type,
      payload,
    )
    if (mostRecentFileId && mostRecentFileId in files) {
      return {
        ...updatedState,
        files,
        mostRecentFileId,
        mostRecentFile: files[mostRecentFileId],
      }
    }

    return { ...updatedState, files, mostRecentFileId }
  }

  const initialState = {
    files: {},
    count: 0,
    mostRecentFileId: null,
    mostRecentFile: null,
  }
  const [state, dispatch] = useReducer(reducer, initialState)

  const actions = (motifId) => ({
    recordFileProgress: (percentage, file = null) =>
      dispatch({
        type: 'RECORD_FILE_PROGRESS',
        payload: {
          motifId,
          progress: Number(percentage),
          file,
        },
      }),
    fileUploadCancelled: () =>
      dispatch({
        type: 'FILE_UPLOAD_CANCELLED',
        payload: { motifId },
      }),
    fileUploadCompleted: (responseDetail, file) =>
      dispatch({
        type: 'FILE_UPLOAD_COMPLETED',
        payload: { motifId, responseDetail, file },
      }),
    fileUploadError: (errorMessage) =>
      dispatch({
        type: 'FILE_UPLOAD_ERROR',
        payload: { motifId, errorMessage },
      }),
    fileRejected: (errorMessage) =>
      dispatch({
        type: 'FILE_REJECTED',
        payload: { motifId, errorMessage },
      }),
    removeFile: () => {
      return dispatch({
        type: 'REMOVE_FILE',
        payload: { motifId },
      })
    },
    addFile: (file) =>
      dispatch({
        type: 'FILE_UPLOAD_QUEUED',
        payload: {
          motifId,
          file,
        },
      }),
    startUpload: (file) =>
      dispatch({
        type: 'FILE_UPLOAD_BEGIN',
        payload: {
          motifId,
          file,
        },
      }),
  })
  return [state, actions]
}

const useFileList = () => useContext(UploadContext)

export default useFileList
