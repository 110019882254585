import React, { useContext } from 'react'
import clsx from 'clsx'
import ListItem from '../../core-components/ListItem'
import L3BusinessObjectSelector from './L3BusinessObjectSelector'
import {
  GlobalNavigationContext,
  handleRoute,
  isActive,
} from './GlobalNavigation'
import makeStyles from '../../styles/makeStyles'

export default function L3Item({
  businessObjects = null,
  className,
  count = null,
  icon: Icon = null,
  label,
  onNavigate,
  parent = null,
  route,
}) {
  const { setCurrentL3 } = useContext(GlobalNavigationContext)
  useStyles()

  const handleClick = (route, onNavigate) => {
    if (!route) return
    handleRoute(route, onNavigate)
    if (parent?.level === 2) setCurrentL3(Object.assign({}, parent))
  }

  return (
    <>
      <div className="GNL3Item">
        <ListItem
          className={clsx(
            'GNL3Item-item',
            { ['GNL3Item-item--isActive']: isActive(route) },
            className,
          )}
          onClick={() => {
            handleClick(route, onNavigate)
          }}
        >
          {Icon && <Icon className="GNL3Item-icon" />}
          <div className="GNL3Item-label">
            <span>{label}</span>
            {count !== null && count !== undefined && <span>({count})</span>}
          </div>
        </ListItem>
      </div>
      {businessObjects && (
        <L3BusinessObjectSelector
          className="GNL3Item-businessObjectSelector"
          onClick={handleClick}
          label={label}
          {...businessObjects}
        />
      )}
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.GNL3Item': {
      // .GNL3Item-item
      '&-item': {
        display: 'grid',
        gridTemplateColumns: '24px 1fr',
        columnGap: '6px',
        padding: '6px',
        fontSize: '14px',
        fontWeight: '300',
        cursor: 'pointer',
        gridTemplateAreas: `
          "icon label"
          "bos  bos"`,

        // .GNL3Item-item--isActive
        '&--isActive': {
          backgroundColor: theme.palette.primary.active,
          borderRight: `6px solid ${theme.palette.primary.main}`,
        },
      },
      // .GNL3Item-icon
      '&-icon': {
        gridArea: 'icon',
        fill: theme.palette.grey[600],
      },

      // .GNL3Item-label
      '&-label': {
        gridArea: 'label',
        display: 'flex',
        justifyContent: 'space-between',
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
      },
    },
  },
}))
