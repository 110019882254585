/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: 'RadioGroup',
  description: '',
  displayName: 'RadioGroup',
  methods: [],
  props: {
    children: {
      type: { name: 'node' },
      required: false,
      description: 'The content of the component.',
    },
    defaultValue: {
      type: {
        name: 'union',
        value: [
          { name: 'arrayOf', value: { name: 'string' } },
          { name: 'number' },
          { name: 'string' },
        ],
      },
      required: false,
      description:
        'The default `input` element value. Use when the component is not controlled.',
    },
    name: {
      type: { name: 'string' },
      required: false,
      description:
        "The name used to reference the value of the control.\r\nIf you don't provide this prop, it falls back to a randomly generated name.",
    },
    onChange: {
      type: { name: 'func' },
      required: false,
      description:
        'Callback fired when a radio button is selected.\r\n\r\n@param {object} event The event source of the callback.\r\nYou can pull out the new value by accessing `event.target.value` (string).',
    },
    value: {
      type: { name: 'any' },
      required: false,
      description:
        'Value of the selected radio button. The DOM API casts this to a string.',
    },
  },
  styles: {},
}
export default generatedDocs
