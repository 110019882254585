import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import withStyles from '../../styles/withStyles'

const variants = {
  active: 'active',
  error: 'error',
  pending: 'pending',
  scheduled: 'scheduled',
  revoked: 'revoked',
  expired: 'expired',

  //deprecated, but supported for backwards compat
  success: 'active',
  running: 'scheduled',
  cancelled: 'expired',
}

function Status({ variant: variantName, customLabel, classes }) {
  const variant = variants[variantName]
  const textClasses = clsx(classes.defaultText, {
    [classes.errorText]: variant === 'error',
  })

  return (
    <div>
      <svg width="16" height="8" viewBox="0 0 16 8">
        <circle
          className={classes[variant]}
          stroke="none"
          cx="4"
          cy="4"
          r="4"
        />
      </svg>
      <label className={textClasses}>{customLabel || variantName}</label>
    </div>
  )
}

let styles = (theme) => ({
  active: {
    fill: theme.palette.primary.main,
  },
  pending: {
    fill: theme.palette.warning.main,
  },
  scheduled: {
    fill: theme.palette.info.main,
  },
  expired: {
    fill: theme.palette.disabled.main,
  },
  error: {
    fill: theme.palette.danger.dark,
  },
  revoked: {
    fill: theme.palette.grey[600],
  },
  defaultText: {
    ...theme.typography.body3,
    textTransform: 'capitalize',
    fontWeight: 400,
  },
  errorText: {
    color: theme.palette.danger.dark,
  },
})

Status.propTypes = {
  variant: PropTypes.oneOf(Object.values(variants)).isRequired,
}

export default withStyles(styles)(Status)
