import React from 'react'
import clsx from 'clsx'
import ColorSquare from './ColorSquare'
import css from './ColorPalette.module.css'

export default function ColorPalette({
  className,
  variant = 'main',
  color,
  ...props
}) {
  let getRange = () => {
    if (variant === 'main') {
      return ['light', 'main', 'dark']
    } else if (variant === 'XLight') {
      return ['x-light', 'light', 'main', 'dark']
    } else if (variant === 'XDark') {
      return ['light', 'main', 'dark', 'x-dark']
    } else if (variant === 'full') {
      return [50, 100, 200, 300, 400, 500, 600, 700, 800, 900]
    } else if (variant === 'common') {
      return ['black', 'white']
    }
  }
  let palette = getRange().map((x) => (
    <ColorSquare key={`${color}${x}`} hue={color} shade={x} {...props} />
  ))

  return <div className={clsx(css.palette, className)}>{palette}</div>
}
