import React, { useState } from 'react'
import FormControl from '@liveramp/motif/core/FormControl'
import FormGroup from '@liveramp/motif/core/FormGroup'
import FormControlLabel from '@liveramp/motif/core/FormControlLabel'
import Radio from '@liveramp/motif/core/Radio'

export default function LabelPlacementDemo() {
  let [radio, setRadio] = useState('a')
  let handleChange = (event) => setRadio(event.target.value)

  return (
    <FormControl>
      <FormGroup row>
        <FormControlLabel
          value="start"
          control={
            <Radio value="a" checked={radio === 'a'} onChange={handleChange} />
          }
          label="Start"
          labelPlacement="start"
        />
        <FormControlLabel
          value="top"
          control={
            <Radio value="b" checked={radio === 'b'} onChange={handleChange} />
          }
          label="Top"
          labelPlacement="top"
        />
        <FormControlLabel
          value="bottom"
          control={
            <Radio value="c" checked={radio === 'c'} onChange={handleChange} />
          }
          label="Bottom"
          labelPlacement="bottom"
        />
        <FormControlLabel
          value="end"
          control={
            <Radio value="d" checked={radio === 'd'} onChange={handleChange} />
          }
          label="End"
          labelPlacement="end"
        />
      </FormGroup>
    </FormControl>
  )
}
