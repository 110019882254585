import React, { useState } from 'react'
import Button from '@liveramp/motif/core/Button'
import IconButton from '@liveramp/motif/core/IconButton'
import LogoCardGroup from '@liveramp/motif/core/LogoCardGroup'
import LogoCard from '@liveramp/motif/core/LogoCard'
import LogoCardActions from '@liveramp/motif/core/LogoCardActions'
import LogoCardContent from '@liveramp/motif/core/LogoCardContent'
import LogoCardHeader from '@liveramp/motif/core/LogoCardHeader'
import LogoCardMedia from '@liveramp/motif/core/LogoCardMedia'
import Tooltip from '@liveramp/motif/core/Tooltip'
import Collapse from '@liveramp/motif/core/Collapse'

import makeStyles from '@liveramp/motif/styles/makeStyles'
import StatusSuccess from '@liveramp/motif/icons/StatusSuccess'
import StatusPending from '@liveramp/motif/icons/StatusPending'
import StatusStopped from '@liveramp/motif/icons/StatusStopped'
import Facebook from '@liveramp/motif/icons/Facebook'
import Twitter from '@liveramp/motif/icons/Twitter'
import Snapchat from '@liveramp/motif/icons/Snapchat'
import LinkedIn from '@liveramp/motif/icons/LinkedIn'
import Microsoft from '@liveramp/motif/icons/Microsoft'
import CaretDown from '@liveramp/motif/icons/CaretDown'
import Edit from '@liveramp/motif/icons/Edit'

export default function FormGroupLargeDemo() {
  let classes = useStyles()
  let [openFacebook, setFacebookOpen] = useState(false)
  let [openTwitter, setTwitterOpen] = useState(false)
  let [openSnapchat, setSnapchatOpen] = useState(false)
  let [openMicrosoft, setMicrosoftOpen] = useState(false)
  let [openLinkedIn, setLinkedInOpen] = useState(false)

  let collapsibleSection = (open) => (
    <Collapse in={open}>
      <LogoCardContent>
        <p className={classes.p}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </p>
      </LogoCardContent>
      <LogoCardActions renderOnHover={false}>
        <IconButton aria-label="edit">
          <Edit />
        </IconButton>
      </LogoCardActions>
    </Collapse>
  )

  return (
    <LogoCardGroup className={classes.root}>
      <LogoCard key="facebookLg" id="facebookLg" size="large" defaultChecked>
        <LogoCardHeader
          title="Facebook - DefaultChecked"
          status={
            <Tooltip title="Success">
              <StatusSuccess />
            </Tooltip>
          }
        />
        <LogoCardMedia>
          <Facebook alt="Facebook Logo" title="Facebook Logo" />
        </LogoCardMedia>
        <LogoCardActions
          renderOnHover={
            <div className={classes.actions}>
              <Button variant="outlined" color="secondary">
                CTA
              </Button>
              <IconButton
                className={
                  useIconButtonStyles({ open: openFacebook })?.iconButton
                }
                onClick={() => setFacebookOpen(!openFacebook)}
                aria-expanded={openFacebook}
                aria-label="Show content"
              >
                <CaretDown />
              </IconButton>
            </div>
          }
        >
          <div className={classes.expiration}>Expiration: 10/12/2020</div>
        </LogoCardActions>
        {collapsibleSection(openFacebook)}
      </LogoCard>
      <LogoCard key="twitterLg" id="twitterLg" size="large" checked>
        <LogoCardHeader
          title="Twitter - Checked"
          status={
            <Tooltip title="Success">
              <StatusSuccess />
            </Tooltip>
          }
        />
        <LogoCardMedia>
          <Twitter alt="Twitter Logo" title="Twitter Logo" />
        </LogoCardMedia>
        <LogoCardActions
          renderOnHover={
            <div className={classes.actions}>
              <Button variant="outlined" color="secondary">
                CTA
              </Button>
              <IconButton
                className={
                  useIconButtonStyles({ open: openTwitter })?.iconButton
                }
                onClick={() => setTwitterOpen(!openTwitter)}
                aria-expanded={openTwitter}
                aria-label="Show content"
              >
                <CaretDown />
              </IconButton>
            </div>
          }
        />
        {collapsibleSection(openTwitter)}
      </LogoCard>
      <LogoCard key="snapchatLg" id="snapchatLg" size="large">
        <LogoCardHeader
          title="Snapchat - Unchecked"
          status={
            <Tooltip title="Pending">
              <StatusPending />
            </Tooltip>
          }
        />
        <LogoCardMedia>
          <Snapchat alt="Snapchat Logo" title="Snapchat Logo" />
        </LogoCardMedia>
        <LogoCardActions
          renderOnHover={
            <div className={classes.actions}>
              <Button variant="outlined" color="secondary">
                CTA
              </Button>
              <IconButton
                className={
                  useIconButtonStyles({ open: openSnapchat })?.iconButton
                }
                onClick={() => setSnapchatOpen(!openSnapchat)}
                aria-expanded={openSnapchat}
                aria-label="Show content"
              >
                <CaretDown />
              </IconButton>
            </div>
          }
        />
        {collapsibleSection(openSnapchat)}
      </LogoCard>
      <LogoCard
        key="microsoftLg"
        id="microsoftLg"
        size="large"
        checked
        disabled
      >
        <LogoCardHeader
          title="Microsoft - Disabled Checked"
          status={
            <Tooltip title="Success">
              <StatusSuccess />
            </Tooltip>
          }
        />
        <LogoCardMedia>
          <Microsoft alt="Microsoft Logo" title="Microsoft Logo" />
        </LogoCardMedia>
        <LogoCardActions
          renderOnHover={
            <div className={classes.actions}>
              <Button variant="outlined" color="secondary">
                CTA
              </Button>
              <IconButton
                className={
                  useIconButtonStyles({ open: openMicrosoft })?.iconButton
                }
                onClick={() => setMicrosoftOpen(!openMicrosoft)}
                aria-expanded={openMicrosoft}
                aria-label="Show content"
              >
                <CaretDown />
              </IconButton>
            </div>
          }
        />
        {collapsibleSection(openMicrosoft)}
      </LogoCard>
      <LogoCard key="linkedinLg" id="linkedinLg" size="large" disabled>
        <LogoCardHeader
          title="LinkedIn - Disabled Unchecked"
          status={
            <Tooltip title="Stopped">
              <StatusStopped />
            </Tooltip>
          }
        />
        <LogoCardMedia>
          <LinkedIn alt="LinkedIn Logo" title="LinkedIn Logo" />
        </LogoCardMedia>
        <LogoCardActions
          renderOnHover={
            <div className={classes.actions}>
              <Button variant="outlined" color="secondary">
                CTA
              </Button>
              <IconButton
                className={
                  useIconButtonStyles({ open: openLinkedIn })?.iconButton
                }
                onClick={() => setLinkedInOpen(!openLinkedIn)}
                aria-expanded={openLinkedIn}
                aria-label="Show content"
              >
                <CaretDown />
              </IconButton>
            </div>
          }
        />
        {collapsibleSection(openLinkedIn)}
      </LogoCard>
    </LogoCardGroup>
  )
}

let useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    justifyContent: 'center',
    gridTemplateColumns: '1fr 1fr',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  p: { margin: 0 },
  expiration: {
    ...theme.typography.subtitle2,
    color: theme.palette.error.main,
    display: 'flex',
    alignItems: 'center',
  },
}))

let useIconButtonStyles = makeStyles((theme) => ({
  iconButton: {
    transition: theme.transitions.common.short,
    transform: (props) => (props.open ? 'rotate(180deg)' : 'rotate(0deg)'),
  },
}))
