let yellow = {
  50: '#fffae1',
  100: '#fef3b3',
  200: '#feec86',
  300: '#ffe763',
  400: '#ffe137',
  500: '#fdd703',
  600: '#f8ce02',
  700: '#f5c100',
  800: '#f1b808',
  900: '#ecb203',
}

export default yellow
