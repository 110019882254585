import React from 'react'
import createSvgIcon from './utils/createSvgIcon'

export default createSvgIcon(
  <g fill="none" fillRule="evenodd">
    <path
      d="M4.153 4.3c.154.001.28.106.295.239l.002.031-.15 14.722h15.33c.147 0 .267.134.267.3 0 .154-.104.28-.237.298l-.03.002H4.101c-.127 0-.234-.099-.261-.232a.26.26 0 01-.14-.196l-.001-.031.15-14.868c.001-.148.137-.266.303-.265zm9.35 10.441c.155 0 .282.103.299.236l.002.032v3.212c0 .148-.134.268-.3.268-.154 0-.28-.103-.298-.237l-.002-.03v-3.213c0-.148.134-.268.3-.268zm-2.409-1.07c.154 0 .281.102.298.236l.002.03v4.284c0 .148-.134.268-.3.268-.153 0-.28-.103-.298-.237l-.002-.03v-4.284c0-.148.135-.268.3-.268zm-2.123 1.07c.154 0 .281.103.298.236l.002.032v3.212c0 .148-.134.268-.3.268-.153 0-.28-.103-.298-.237l-.002-.03v-3.213c0-.148.135-.268.3-.268zm-2.141 1.07c.154 0 .28.104.298.237l.002.032v2.141c0 .148-.135.268-.3.268-.154 0-.281-.103-.298-.237l-.002-.03V16.08c0-.148.134-.268.3-.268zM17.52 4.3c.154 0 .28.103.298.237l.002.03v3.749c0 .025-.004.05-.012.074a3.776 3.776 0 011.752 3.19 3.778 3.778 0 01-1.83 3.24c.048.04.08.096.088.157l.002.032v3.212c0 .148-.135.268-.3.268-.154 0-.281-.103-.298-.237l-.002-.03v-3.146c-.444.183-.93.284-1.44.284l-.103-.003v2.864c0 .148-.133.268-.3.268-.153 0-.28-.103-.297-.237l-.002-.03-.001-2.927a3.781 3.781 0 01.017-7.433.168.168 0 01-.014-.05l-.002-.032V5.906c0-.148.134-.267.3-.267.154 0 .28.103.298.236l.002.031V7.78l-.003.021.105-.001c.51 0 .996.1 1.44.284V4.568c0-.148.134-.268.3-.268zm1.713 10.213l.028.019 1.339 1.07a.268.268 0 01-.307.438l-.028-.02-1.339-1.07a.268.268 0 01.307-.437zM15.78 8.34a3.24 3.24 0 100 6.48 3.24 3.24 0 000-6.48z"
      fill="#22303B"
      fillRule="nonzero"
    />
    <path
      d="M17.53 9.882a.269.269 0 00-.38 0l-1.32 1.317-1.32-1.32a.269.269 0 10-.381.38l1.32 1.321-1.32 1.32a.269.269 0 10.38.381l1.32-1.32 1.321 1.32a.269.269 0 10.381-.38l-1.32-1.321 1.32-1.32a.27.27 0 000-.378z"
      fill="#EB989E"
    />
  </g>,
  'DashboardCalculating',
)
