import createTheme from '@material-ui/core/styles/createTheme'
import chroma from 'chroma-js'
import blue from './colors/blue'
import green from './colors/green'
import grey from './colors/grey'
import orange from './colors/orange'
import red from './colors/red'
import yellow from './colors/yellow'
import purple from './colors/purple'

const baseSize = 16
const liverampsans = "'Liveramp Sans', sans-serif"
const opensans = "'Open Sans', sans-serif"
const IBMPlexMono = '"IBM Plex Mono", monospace'

// https://material-ui.com/customization/default-theme/
// https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/styles/createPalette.js
let theme = createTheme({
  palette: {
    common: {
      black: grey[900],
      white: '#fff',
      primary: green[500],
      success: green[500],
      secondary: grey[500],
      error: red[500],
      danger: red[500],
      info: blue[500],
      pending: orange[500],
      warning: yellow[500],
      privacy: purple[500],
    },
    primary: {
      hue: green,
      light: green[100],
      main: green[500],
      dark: green[600],
      hover: chroma(green[100]).alpha(0.24).css(),
      active: chroma(green[500]).alpha(0.28).css(),
    },
    // same as primary
    success: {
      hue: green,
      'x-light': green[50],
      light: green[100],
      main: green[500],
      dark: green[600],
      hover: chroma(green[100]).alpha(0.24).css(),
      active: chroma(green[500]).alpha(0.28).css(),
    },
    secondary: {
      hue: grey,
      light: grey[200],
      main: grey[500],
      dark: grey[700],
      hover: chroma(grey[500]).alpha(0.15).css(),
      active: chroma(grey[500]).alpha(0.28).css(),
    },
    // same as danger
    error: {
      hue: red,
      'x-light': red[50],
      light: red[100],
      main: red[500],
      dark: red[700],
      hover: chroma(red[500]).alpha(0.15).css(),
      active: chroma(red[500]).alpha(0.28).css(),
    },
    // same as error
    danger: {
      hue: red,
      'x-light': red[50],
      light: red[100],
      main: red[500],
      dark: red[700],
      hover: chroma(red[500]).alpha(0.15).css(),
      active: chroma(red[500]).alpha(0.28).css(),
    },
    info: {
      hue: blue,
      'x-light': blue[50],
      light: blue[100],
      main: blue[500],
      dark: blue[600],
      'x-dark': blue[800],
      hover: chroma(blue[500]).alpha(0.1).css(),
      active: chroma(blue[500]).alpha(0.28).css(),
    },
    pending: {
      hue: orange,
      light: orange[100],
      main: orange[500],
      dark: orange[600],
      hover: chroma(orange[500]).alpha(0.15).css(),
      active: chroma(orange[500]).alpha(0.28).css(),
    },
    warning: {
      hue: yellow,
      light: yellow[200],
      main: yellow[400],
      dark: yellow[600],
      hover: chroma(yellow[500]).alpha(0.15).css(),
      active: chroma(yellow[500]).alpha(0.28).css(),
    },
    privacy: {
      hue: purple,
      'x-light': purple[50],
      light: purple[100],
      main: purple[500],
      dark: purple[700],
      hover: chroma(purple[500]).alpha(0.15).css(),
      active: chroma(purple[500]).alpha(0.28).css(),
    },
    action: {
      activeOpacity: 0.54,
      disabledOpacity: 0.26,
      disabledBackgroundOpacity: 0.12,
      hoverOpacity: 0.08,
      selectedOpacity: 0.14,
      loadingOpacity: 0.32,
    },
    disabled: {
      hue: grey,
      light: grey[100],
      main: grey[400],
      dark: grey[600],
    },
    text: {
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
      primary: grey[800],
      secondary: grey[500],
      link: blue[700],
      hoverLink: blue[800],
      visitedLink: purple[500],
    },
  },
  transitions: {
    common: {
      enteringScreen: '225ms cubic-bezier(0.4, 0, 0.2, 1)',
      short: '250ms cubic-bezier(0.4, 0, 0.2, 1)',
      leavingScreen: '195ms cubic-bezier(0.0, 0, 0.2, 1)',
    },
  },
  // Changes in the typography object should also be reflected in
  // docs/src/index.module.css
  typography: {
    htmlFontSize: baseSize,
    fontFamily: opensans,
    fontFamilyHeader: liverampsans,
    fontSize: baseSize,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 550,
    fontWeightBold: 600,
    h1: {
      fontFamily: liverampsans,
      fontSize: 36,
      fontWeight: 400,
      lineHeight: 1.4,
    },
    jumbo: {
      fontSize: 32,
      fontWeight: 400,
      lineHeight: 1.4,
    },
    h2: {
      fontSize: 24,
      fontWeight: 400,
      lineHeight: 1.4,
    },
    h2Branding: {
      fontFamily: liverampsans,
      fontSize: 24,
      fontWeight: 400,
      lineHeight: 1.4,
    },
    h3: {
      fontSize: 20,
      fontWeight: 400,
      lineHeight: 1.4,
    },
    h3Branding: {
      fontFamily: liverampsans,
      fontSize: 20,
      fontWeight: 400,
      lineHeight: 1.4,
    },
    h4: {
      fontFamily: opensans,
      fontSize: 18,
      fontWeight: 400,
      lineHeight: 1.4,
    },
    h4Branding: {
      fontFamily: liverampsans,
      fontSize: 18,
      fontWeight: 400,
      lineHeight: 1.4,
    },
    h5: {
      fontFamily: opensans,
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 1.4,
    },
    h6: {
      fontFamily: opensans,
      fontSize: 14,
      fontWeight: 600,
      lineHeight: 1.4,
    },
    subtitle1: {
      fontFamily: opensans,
      fontSize: baseSize,
      fontWeight: 600,
      lineHeight: 1.4,
    },
    subtitle2: {
      fontFamily: opensans,
      fontSize: 14,
      fontWeight: 600,
      lineHeight: 1.4,
    },
    subtitle3: {
      fontFamily: opensans,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.4,
    },
    body1: {
      fontFamily: opensans,
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.4,
    },
    body2: {
      fontFamily: opensans,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.4,
    },
    body3: {
      fontFamily: opensans,
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1.4,
    },
    body4: {
      fontFamily: opensans,
      fontSize: 10,
      fontWeight: 400,
      lineHeight: 1.4,
    },
    button: { fontSize: 14 },
    caption: {
      fontFamily: opensans,
      fontSize: 10,
      lineHeight: 1.4,
    },
    code1: {
      fontFamily: IBMPlexMono,
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1.4,
    },
    code2: {
      fontFamily: IBMPlexMono,
      fontSize: 10,
      fontWeight: 400,
      lineHeight: 1.4,
    },
    overline: { fontFamily: opensans, fontSize: 12 },
    pxToRem: (size) => `${size / baseSize}rem`,
  },
  overrides: {
    MuiSvgIcon: {
      fontSizeSmall: {
        fontSize: '20px',
      },
      fontSizeLarge: {
        fontSize: '32px',
      },
    },
    MuiTouchRipple: {
      child: {
        transform: 'scale(0.95)',
        opacity: 0.35,
      },
      rippleVisible: {
        opacity: 0.2,
      },
    },
  },
  props: {
    MuiTypography: {
      variantMapping: {
        h1: 'h1',
        h2: 'h2',
        h3: 'h3',
        h4: 'h4',
        h5: 'h5',
        h6: 'h6',
        subtitle1: 'span',
        subtitle2: 'span',
        body1: 'p',
        body2: 'p',
        caption: 'span',
      },
    },
  },
  zIndex: {
    dialog: 1300,
    alert: 1400,
  },
})

export default theme
