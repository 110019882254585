import React from 'react'
import TextField from '@liveramp/motif/core/TextField'
import css from './States.module.css'

export default function UnderlinedStatesDemo() {
  return (
    <div className={css.grid}>
      <TextField
        id="underlined-helper-input"
        variant="underlined"
        label="label, helperText"
        helperText="This is your helper text"
      />
      <TextField
        id="underlined-default-input"
        variant="underlined"
        label="defaultValue"
        defaultValue="Hello World"
        helperText="This input has default text"
      />
      <TextField
        id="underlined-placeholder-input"
        variant="underlined"
        label="placeholder"
        placeholder="Hello World"
        helperText="This input has a placeholder"
      />
      <TextField
        id="underlined-required-input"
        variant="underlined"
        label="Label"
        helperText="This input is marked as required"
        required
      />
      <TextField
        id="underlined-error-input"
        variant="underlined"
        label="error"
        helperText="This input has an error"
        error
      />
      <TextField
        id="underlined-disabled-input"
        variant="underlined"
        label="disabled"
        helperText="This input is disabled"
        disabled
      />
      <TextField
        id="underlined-readonly-input"
        variant="underlined"
        label="readOnly"
        defaultValue="Read-Only"
        helperText="This input is read-only"
        readOnly
      />
      <TextField
        id="underlined-password-input"
        variant="underlined"
        defaultValue="password"
        label='type="password"'
        helperText="This input is a password"
        type="password"
      />
      <TextField
        id="underlined-number-input"
        variant="underlined"
        label='type="number"'
        helperText="This input can only be a number"
        type="number"
      />
      <TextField
        id="underlined-search-input"
        variant="underlined"
        defaultValue="Hover to see clear icon"
        label='type="search"'
        helperText="This input looks like a search"
        type="search"
      />
      {/* We do not recommend the following, but they are available to use */}
      {/* <TextField
          id="underlined-multiline-input"
          variant="underlined"
          label="Label"
          defaultValue="Type to expand"
          helperText="This input is multiline with max rows"
          multiline
          maxRows={4}
        />
        <TextField
          id="underlined-multiline-default-input"
          variant="underlined"
          label="Label"
          defaultValue={`Fully expanded`}
          helperText="This input is multiline with starting rows"
          multiline
          rows={4}
        /> */}
      <TextField
        id="underlined-fullwidth-input"
        variant="underlined"
        label="fullWidth"
        helperText="This is your helper text"
        fullWidth
      />
    </div>
  )
}
