import React from 'react'
import MuiTextField from '@material-ui/core/TextField'
import InputAdornment from '../InputAdornment'
import { mergeClasses } from '../../utils'
import makeStyles from '../../styles/makeStyles'
import { TextFieldStyle } from '../../utils/styleKeys'
import { styles as outlinedStyles } from '../OutlinedInput'
import { styles as underlinedStyles } from '../UnderlinedInput'
import { styles as inputLabelStyles } from '../InputLabel'
import { styles as formHelperTextStyles } from '../FormHelperText'

export default function TextField({
  select, // ignore - we don't want to render this type of select
  SelectProps, // ignore - we don't want to render this type of select
  readOnly = false,
  variant: variantProp = 'outlined',
  InputProps = {},
  InputLabelProps = {},
  FormHelperTextProps = {},
  ...props
}) {
  let variant =
    variantProp === 'underlined' || variantProp === 'standard'
      ? 'standard'
      : 'outlined'
  let classes = useStyles()
  let outlinedClasses = useOutlinedStyles()
  let underlinedClasses = useUnderlinedStyles()
  let inputLabelClasses = useInputLabelStyles()
  let formHelperTextClasses = useFormHelperTextStyles()

  // adornment styling
  if (InputProps) {
    if (isStringOrSvg(InputProps.startAdornment)) {
      InputProps.startAdornment = (
        <InputAdornment position="start">
          {InputProps.startAdornment}
        </InputAdornment>
      )
    }

    if (isStringOrSvg(InputProps.endAdornment)) {
      InputProps.endAdornment = (
        <InputAdornment position="end">
          {InputProps.endAdornment}
        </InputAdornment>
      )
    }
  }

  return (
    <MuiTextField
      variant={variant}
      classes={classes}
      InputProps={{
        ...(variant === 'outlined' ? { notched: false } : {}),
        ...InputProps,
        readOnly,
        classes: mergeClasses(
          variant === 'standard' ? underlinedClasses : outlinedClasses,
          InputProps.classes,
        ),
      }}
      InputLabelProps={{
        ...InputLabelProps,
        classes: mergeClasses(inputLabelClasses, InputLabelProps.classes),
      }}
      FormHelperTextProps={{
        ...FormHelperTextProps,
        classes: mergeClasses(
          variant === 'outlined' ? formHelperTextClasses : {},
          FormHelperTextProps.classes,
        ),
      }}
      {...props}
    />
  )
}

// This helper function ensures only strings and svgs are wrapped in the
// InputAdornment component to ensure consistent styling throughout our app
function isStringOrSvg(value) {
  return (
    !!value &&
    (typeof value === 'string' ||
      (value.type &&
        (value.type === 'svg' || value.type.muiName === 'SvgIcon')))
  )
}

let useStyles = makeStyles(
  (theme) => ({
    ...TextFieldStyle,
    root: {
      "&:not([class*='MuiFormControl-fullWidth'])": {
        width: '250px',
      },
    },
  }),
  { name: 'MuiTextField' },
)
let useOutlinedStyles = makeStyles((theme) => outlinedStyles(theme), {
  name: 'MuiOutlinedStyles',
})
let useUnderlinedStyles = makeStyles((theme) => underlinedStyles(theme), {
  name: 'MuiInput',
})
let useInputLabelStyles = makeStyles((theme) => inputLabelStyles(theme), {
  name: 'MuiInputLabel',
})
let useFormHelperTextStyles = makeStyles(
  (theme) => formHelperTextStyles(theme),
  {
    name: 'MuiFormHelperText',
  },
)
