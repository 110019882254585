import React from 'react'
import Badge from '../../core-components/Badge'
import GlobalAdminButton from '../GlobalAdminButton'
import NotificationContent from '../NotificationContent'

import makeStyles from '../../styles/makeStyles'
import Notifications from '../../icons/Notifications'

const MenuContent = React.forwardRef((props, ref) => (
  <div {...props} ref={ref}>
    {props.children}
  </div>
))

export default function GlobalAdminNotificationsButton({
  onMarkReadClick,
  notifications,
  onSeeAllClick,
  loading,
  error,
}) {
  useStyles()

  const unreadCount = notifications.reduce((acc, notification) => {
    return acc + (notification.read ? 0 : 1)
  }, 0)

  return (
    <Badge
      data-testid="GlobalAdminNotificationsButton"
      className="GlobalAdminNotificationsButton GlobalAdminNotificationButton-badge"
      badgeContent={unreadCount}
      max={99}
      color="error"
    >
      <GlobalAdminButton
        label="Notifications"
        menu={(handleClose) => (
          <MenuContent className="GlobalAdminNotificationsButton__MenuContent">
            <NotificationContent
              handleClose={handleClose}
              notifications={notifications}
              onMarkReadClick={onMarkReadClick}
              onSeeAllClick={onSeeAllClick}
              loading={loading}
              error={error}
              height="360px"
            />
          </MenuContent>
        )}
        menuProps={{
          MenuListProps: { disablePadding: true },
        }}
      >
        <Notifications />
      </GlobalAdminButton>
    </Badge>
  )
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.GlobalAdminNotificationsButton__MenuContent': {
      width: '248px',
    },
    '.GlobalAdminNotificationsButton': {
      '& .MuiBadge-badge': {
        right: 4,
        top: 4,
        height: 16,
        minWidth: 16,
        fontSize: '8px',
        fontWeight: 'bold',
        padding: '0 5px',
      },
    },
  },
}))
