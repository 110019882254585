import React, { useState } from 'react'
import RichDataTable from '@liveramp/motif/core/RichDataTable'
import { columns, generateRows } from '../../demos/data'

export default function FetchRowsDemo() {
  const [rowsUpdated, setRowsUpdated] = useState(1)
  const [rows, setRows] = useState(generateRows(10, rowsUpdated))
  const [loading, setLoading] = useState(false)

  const handleRowsScrollEnd = () => {
    setLoading(true)
    setTimeout(() => {
      const newRowsUpdated = rowsUpdated + 1
      setRows(rows.concat(generateRows(10, newRowsUpdated)))
      setRowsUpdated(newRowsUpdated)
      setLoading(false)
    }, 500)
  }

  return (
    <RichDataTable
      columns={columns}
      rows={rows}
      loading={loading}
      onRowsScrollEnd={handleRowsScrollEnd}
      autoHeight={false}
      height={290}
    />
  )
}
