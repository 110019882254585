import React from 'react'

const Activate = (props) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_22_781)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.29219 -0.550049C9.59594 -0.550049 9.84219 -0.303805 9.84219 -4.88162e-05V3.10338C9.84219 3.40714 9.59594 3.65338 9.29219 3.65338C8.98843 3.65338 8.74219 3.40714 8.74219 3.10338V-4.88162e-05C8.74219 -0.303805 8.98843 -0.550049 9.29219 -0.550049Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.2322 0.999124C14.4594 1.20068 14.4803 1.5483 14.2787 1.77555L12.2769 4.03259C12.0754 4.25984 11.7277 4.28067 11.5005 4.07912C11.2732 3.87756 11.2524 3.52995 11.454 3.30269L13.4558 1.04566C13.6573 0.818402 14.0049 0.79757 14.2322 0.999124Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.35108 0.999124C4.57833 0.79757 4.92594 0.818402 5.1275 1.04566L7.12931 3.30269C7.33086 3.52995 7.31003 3.87756 7.08278 4.07912C6.85552 4.28067 6.50791 4.25984 6.30635 4.03259L4.30454 1.77555C4.10299 1.5483 4.12382 1.20068 4.35108 0.999124Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.42026 6.61565C4.18583 6.61137 3.96122 6.70147 3.79772 6.86277C3.6345 7.02379 3.54613 7.24173 3.55034 7.46607L3.55053 7.47639L3.55044 9.59236C3.55044 10.1665 4.00137 10.5941 4.4303 10.5941H8.00495C8.30871 10.5941 8.55495 10.8403 8.55495 11.1441C8.55495 11.4478 8.30871 11.6941 8.00495 11.6941H4.4303C3.3064 11.6941 2.45044 10.6827 2.45044 9.59236V7.48111C2.4421 6.95503 2.65034 6.44951 3.02519 6.07971C3.39979 5.71013 3.90858 5.50758 4.43491 5.51574H19.2963C19.8226 5.50758 20.3314 5.71013 20.706 6.07971C21.0808 6.44953 21.2891 6.95508 21.2807 7.4812V9.59236C21.2807 10.6827 20.4248 11.6941 19.3009 11.6941H10.5787C10.2749 11.6941 10.0287 11.4478 10.0287 11.1441C10.0287 10.8403 10.2749 10.5941 10.5787 10.5941H19.3009C19.7298 10.5941 20.1807 10.1665 20.1807 9.59236V7.46607C20.1849 7.24173 20.0967 7.02379 19.9334 6.86277C19.7699 6.70147 19.5453 6.61137 19.3109 6.61565L19.3009 6.61583L4.42026 6.61565Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.3001 13.2743C18.6038 13.2743 18.8501 13.5205 18.8501 13.8243V22.8524L18.8501 22.8552C18.8446 23.9412 17.9557 24.8078 16.8729 24.8131L16.8702 24.8131H8.00508C7.70132 24.8131 7.45508 24.5669 7.45508 24.2631C7.45508 23.9593 7.70132 23.7131 8.00508 23.7131H16.8687C17.3611 23.71 17.7471 23.3189 17.7501 22.8508V13.8243C17.7501 13.5205 17.9963 13.2743 18.3001 13.2743Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.43086 17.7883C5.73462 17.7883 5.98086 18.0346 5.98086 18.3383V24.2631C5.98086 24.5668 5.73462 24.8131 5.43086 24.8131C5.1271 24.8131 4.88086 24.5668 4.88086 24.2631V18.3383C4.88086 18.0346 5.1271 17.7883 5.43086 17.7883Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.7258 13.2743C16.0295 13.2743 16.2758 13.5205 16.2758 13.8243V18.6205C16.2758 18.9243 16.0295 19.1705 15.7258 19.1705C15.422 19.1705 15.1758 18.9243 15.1758 18.6205V13.8243C15.1758 13.5205 15.422 13.2743 15.7258 13.2743Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.1525 13.2743C13.4563 13.2743 13.7025 13.5205 13.7025 13.8243V18.6205C13.7025 18.9243 13.4563 19.1705 13.1525 19.1705C12.8488 19.1705 12.6025 18.9243 12.6025 18.6205V13.8243C12.6025 13.5205 12.8488 13.2743 13.1525 13.2743Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.29195 8.44961C8.92809 8.44961 8.55508 8.78435 8.55508 9.31026V21.4418C8.55508 21.7456 8.30883 21.9918 8.00508 21.9918C7.70132 21.9918 7.45508 21.7456 7.45508 21.4418V9.31026C7.45508 8.27599 8.22595 7.34961 9.29195 7.34961C10.358 7.34961 11.1288 8.27599 11.1288 9.31026V18.6205C11.1288 18.9243 10.8826 19.1705 10.5788 19.1705C10.2751 19.1705 10.0288 18.9243 10.0288 18.6205V9.31026C10.0288 8.78435 9.65581 8.44961 9.29195 8.44961Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_22_781">
          <rect width="24" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Activate
