import React from 'react'
import MuiCheckbox from '@material-ui/core/Checkbox'
import CheckboxUnchecked from '../../icons/CheckboxUnchecked'
import IndeterminateCheckBox from '../../icons/IndeterminateCheckBox'
import withStyles from '../../styles/withStyles'
import { CheckboxStyle } from '../../utils/styleKeys'
import { pxToRem as rem } from '../../utils'

function Checkbox(props) {
  return (
    <MuiCheckbox
      color="default"
      // When a checkbox is indeterminate but NOT checked, use an empty checkbox
      // instead of a greyed indeterminate icon
      indeterminateIcon={
        props.checked ? <IndeterminateCheckBox /> : <CheckboxUnchecked />
      }
      {...props}
    />
  )
}

let styles = (theme) => ({
  ...CheckboxStyle,
  root: {
    padding: `${rem(6)}`,
    color: theme.palette.secondary.main,
    transition: theme.transitions.common.enteringScreen,
    '&:hover': {
      backgroundColor: theme.palette.primary.hue[50],
    },
    [`&.Mui-checked, &.Mui-checked.MuiCheckbox-indeterminate`]: {
      color: theme.palette.primary.dark,
    },
    '&.Mui-disabled': {
      color: theme.palette.disabled.main,
    },
  },
})

export default withStyles(styles, { name: 'MuiCheckbox' })(Checkbox)
