let overrideDocs = {
  props: {
    action: {
      description: '@ignore',
    },
    anchorOrigin: {
      description: '@ignore',
    },
    autoHideDuration: {
      type: { name: 'number' },
      required: false,
      description:
        'The number of milliseconds to wait before automatically calling the\n`onClose` function. `onClose` should then set the state of the `open`\nprop to hide the Alert. This behavior is disabled by default with\nthe `null` value.',
      defaultValue: {
        value: 6000,
      },
    },
    children: {
      type: { name: 'element' },
      required: true,
      description: 'The content of the Alert.',
    },
    ContentProps: {
      type: { name: 'object' },
      required: false,
      description: 'Props applied to the content element.',
    },
    icon: {
      type: { name: 'bool' },
      required: false,
      description:
        'If true, an icon will precede the message passed into the alert.',
      defaultValue: {
        value: 'true',
      },
    },
    onClickButton: {
      type: { name: 'func' },
      required: false,
      description:
        'Callback fired when the button in the Alert is clicked. Required if using onClickButtonLabel.',
    },
    onClickButtonLabel: {
      type: { name: 'string' },
      required: false,
      description:
        'Label on the button in the Alert. Required if using onClickButtonLabel.',
    },
    message: {
      description: '@ignore',
    },
    TransitionComponent: {
      description: '@ignore',
    },
    transitionDuration: {
      description: '@ignore',
    },
    TransitionProps: {
      description: '@ignore',
    },
    variant: {
      type: {
        name: 'enum',
        value: [
          { value: "'info'" },
          { value: "'success'" },
          { value: "'warning'" },
          { value: "'danger'" },
        ],
      },
      required: false,
      description: 'The variant to use.',
      defaultValue: { value: "'info'", computed: false },
    },
  },
}

export default overrideDocs
