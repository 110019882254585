import React, { useCallback, useEffect, useRef } from 'react'
import clsx from 'clsx'
import { useFormControl } from '@material-ui/core'
import CloseButton from '../../core-components/CloseButton'
import useAutocomplete from '../../core-components/useAutocomplete'
import { createFilterOptions } from '../../core-components/Autocomplete'
import Button from '../../core-components/Button'
import ButtonFormGroup from '../../core-components/ButtonFormGroup'
import Checkbox from '../../core-components/Checkbox'
import Divider from '../../core-components/Divider'
import FormControlLabel from '../../core-components/FormControlLabel'
import List from '../../core-components/List'
import ListItem from '../../core-components/ListItem'
import MultiFilterOptionGroup from './MultiFilterOptionGroup'
import Paper from '../../core-components/Paper'
import TextField from '../../core-components/TextField'
import Typography from '../../core-components/Typography'
import {
  handleGetOptionLabel,
  handleOptionSelected,
  flattenOptions,
  regroupOptions,
  restoreOriginalOptions,
} from './utils'

import makeStyles from '../../styles/makeStyles'
import SearchIcon from '../../icons/Search'

function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const CANCEL_REASON = 'cancel'
const APPLY_REASON = 'apply'

export default function MultiFilterAutocomplete({
  // autocomplete props
  defaultValue: defaultValueProp,
  getOptionLabel,
  getOptionSelected: getOptionSelectedProp,
  onApply,
  onChange,
  onClose: handleClose,
  onFocus,
  options: optionsProp,
  initialValue,
  setInitialValue,
  value: valueProp,
  setValue,
  setInputValue,
  title = 'Advanced Filters',
  ...props
}) {
  const muiFormControl = useFormControl()
  const flattenedOptions = flattenOptions(optionsProp)
  const flattenedDefaultValue = flattenOptions(defaultValueProp)
  useStyles()

  if (muiFormControl) {
    if (typeof disabled === 'undefined') {
      disabled = muiFormControl.disabled
    }
  }

  const handleFocus = (event) => {
    if (onFocus) {
      onFocus(event)
    }

    if (muiFormControl?.onFocus) {
      muiFormControl.onFocus(event)
    }
  }

  // Test to determine if two options are the same given just an id, or an id
  // and a _mfGroupId
  const isSameId = (newOpt, val) => {
    const idMatches = newOpt.option.id === val.id
    if (val._mfGroupId) {
      return newOpt.option?._mfGroupId === val._mfGroupId && idMatches
    }
    return idMatches
  }

  const handleApply = (event, value) => {
    setInitialValue(value)

    if (onApply) {
      const restoredValues = restoreOriginalOptions(optionsProp, value)
      onApply(event, restoredValues)
    }
  }

  const filterOptions = createFilterOptions({
    ignoreCase: true,
    trim: true,
    stringify: (option) => {
      const label = handleGetOptionLabel(option, getOptionLabel)

      if (option._mfGroup) {
        const groupLabel = handleGetOptionLabel(option._mfGroup, getOptionLabel)

        return `${groupLabel} ${label}`
      }

      return label
    },
  })

  const handleChange = (event, value) => {
    onChange(event, value)
    handleInputChange(event, inputValue)
  }

  const handleInputChange = (event, value) => {
    setInputValue(value)
  }

  const {
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    getRootProps,
    groupedOptions,
    id,
    inputValue,
    value,
  } = useAutocomplete({
    defaultValue: flattenedDefaultValue,
    filterOptions: filterOptions,
    getOptionLabel: handleGetOptionLabel,
    getOptionSelected: getOptionSelectedProp
      ? getOptionSelectedProp
      : handleOptionSelected,
    onChange: handleChange,
    onFocus: handleFocus,
    onInputChange: handleInputChange,
    options: flattenedOptions,
    value: valueProp,
    disableCloseOnSelect: true,
    multiple: true,
    open: true,
    ...props,
  })

  const handleMultiChange = (event, newOptions, selectAll) => {
    const newValue = [...value]

    newOptions.forEach((newOpt) => {
      const spliceIndex = newValue.findIndex((val) => isSameId(newOpt, val))
      if (spliceIndex >= 0) newValue.splice(spliceIndex, 1)
    })

    if (selectAll) {
      newOptions.forEach((newOpt) => {
        const newObj = groupedOptions.find((val) => isSameId(newOpt, val))
        if (newObj) newValue.push(newObj)
      })
    }

    handleChange(event, newValue)
  }

  const setTextFieldListener = useCallback((node) => {
    if (node) {
      node.addEventListener('keydown', handleTextFieldKeyDown, {
        capture: true,
      })
    }
  }, [])

  const handleTextFieldKeyDown = (e) => {
    switch (e.key) {
      case 'Backspace':
        // currently the backspace key is being hooked into an internal
        // useAutocomplete handler which clears the currently selected values
        // if the inputValue is empty; we do not want this to happen
        if (inputValue === '') {
          e.stopPropagation()
        }
        break
      default:
    }
  }

  // Currently the inputValue is cleared on option selection. To work around this
  // while updates are not being applied to v4 of MUI, we apply a useEffect that
  // occurs after a change is detected in the value.
  const prevValue = usePrevious(value)
  useEffect(() => {
    if (inputValue && prevValue.length !== value.length) {
      handleInputChange(null, inputValue)
    }
  }, [value, prevValue])

  const regroupedOptions = regroupOptions(groupedOptions, getOptionProps)

  const handleClosePopup = async (event, reason = CANCEL_REASON) => {
    reason !== APPLY_REASON && handleChange(event, initialValue)
    handleInputChange(event, '')
    handleClose(event, reason)
  }

  return (
    <Paper
      data-testid="MultiFilterAutocomplete"
      className="MultiFilterAutocomplete MultiFilter-paper"
      id={id}
      {...getRootProps()}
    >
      <div className="MultiFilterAutocomplete__header MultiFilter-header">
        <Typography
          component="span"
          className="MultiFilterAutocomplete__header-title"
        >
          <span>{title}</span>
          <CloseButton onClick={handleClosePopup} />
        </Typography>
        <TextField
          variant="outlined"
          className="MultiFilterAutocomplete__searchField MultiFilter-searchField"
          helperText={
            inputValue !== ''
              ? `Showing ${groupedOptions.length} of ${flattenedOptions.length} filters`
              : ''
          }
          InputLabelProps={getInputLabelProps()}
          InputProps={{ endAdornment: <SearchIcon /> }}
          // Explicitly setting onBlur to null is mandatory to override MUIs
          // default onBlur action from useAutocomplete
          inputProps={{ ...getInputProps(), onBlur: null }}
          inputRef={setTextFieldListener}
          placeholder="Search"
          autoFocus
          fullWidth
        />
        <FormControlLabel
          className="MultiFilterAutocomplete__checkbox-all MultiFilter-checkboxAll"
          control={
            <Checkbox
              checked={value.length > 0}
              indeterminate={value.length !== flattenedOptions.length}
              onChange={(event) =>
                value.length > 0
                  ? handleChange(event, [])
                  : handleChange(event, groupedOptions)
              }
            />
          }
          label="Filter"
        />
      </div>
      <Divider />
      {regroupedOptions.length > 0 && (
        <List
          className="MultiFilterAutocomplete__list MultiFilter-list"
          disablePadding
          {...getListboxProps()}
        >
          {regroupedOptions.map((groupedOption) => {
            if (groupedOption._mfGroupId) {
              return (
                <MultiFilterOptionGroup
                  key={handleGetOptionLabel(groupedOption, getOptionLabel)}
                  getOptionLabel={getOptionLabel}
                  optionGroupLabel={handleGetOptionLabel(
                    groupedOption,
                    getOptionLabel,
                  )}
                  onCheckboxClick={handleMultiChange}
                  searching={inputValue !== ''}
                  {...groupedOption}
                />
              )
            } else {
              const { option, optionProps } = groupedOption
              return (
                <ListItem
                  key={handleGetOptionLabel(option, getOptionLabel)}
                  className="MultiFilterAutocomplete__list-option MultiFilter-option"
                >
                  <Checkbox
                    checked={optionProps['aria-selected']}
                    {...optionProps}
                  />
                  <span className={clsx('MultiFilter-optionLabel')}>
                    {handleGetOptionLabel(option, getOptionLabel)}
                  </span>
                </ListItem>
              )
            }
          })}
        </List>
      )}
      <ButtonFormGroup className="MultiFilterAutocomplete__actions MultiFilter-actions">
        <Button variant="text" color="secondary" onClick={handleClosePopup}>
          Cancel
        </Button>
        <Button
          onClick={(event) => {
            handleApply(event, value)
            handleClosePopup(event, APPLY_REASON)
          }}
        >
          Apply
        </Button>
      </ButtonFormGroup>
    </Paper>
  )
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.MultiFilterAutocomplete': {
      // .MultiFilterAutocomplete__header-title
      '&__header-title': {
        ...theme.typography.h4Branding,
        display: 'grid',
        gridAutoFlow: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '12px 16px',
      },

      // .MultiFilterAutocomplete__searchField
      '&__searchField': {
        padding: '8px 16px',
      },

      // .MultiFilterAutocomplete__checkbox-all
      '&__checkbox-all': {
        padding: '8px 16px 7px', // -1px for hr
      },

      // .MultiFilterAutocomplete__list
      '&__list': {
        maxHeight: '312px', // 6 rows * 52px height
        overflowY: 'auto',

        // .MultiFilterAutocomplete__list-option
        '&-option': {
          gap: '8px',
          padding: '8px 6px',
          cursor: 'pointer',
        },
      },

      // .MultiFilterAutocomplete__actions
      '&__actions': {
        padding: '4px 6px',
        justifyContent: 'flex-end',
      },
    },
  },
}))
