import React from 'react'
import Uploader from '@liveramp/motif/core/Uploader'

const useComplicatedUpload = (file, actions) => {
  alert('You uploaded a file!')
  actions.fileUploadCompleted()
  // You are free to handle the file upload however you like,
  // including chaining several requests.
  // You may pass the `fileActions` in part, or in total to any
  // functions that need to manipulate fileState.
}

const FilesAddedCallbackDemo = () => {
  return (
    <Uploader
      id="files-added-callback-uploader"
      onFilesAdded={useComplicatedUpload}
    />
  )
}
export default FilesAddedCallbackDemo
