import React, { useState } from 'react'
import clsx from 'clsx'
import Button from '@liveramp/motif/core/Button'
import SidePanel from '@liveramp/motif/core/SidePanel'
import SidePanelHeader from '@liveramp/motif/core/SidePanelHeader'
import SidePanelContent from '@liveramp/motif/core/SidePanelContent'
import SidePanelSubsection from '@liveramp/motif/core/SidePanelSubsection'
import SidePanelImageSection from '@liveramp/motif/core/SidePanelImageSection'
import SidePanelFieldValueRow from '@liveramp/motif/core/SidePanelFieldValueRow'
import SidePanelFieldValue from '@liveramp/motif/core/SidePanelFieldValue'
import SidePanelList from '@liveramp/motif/core/SidePanelList'
import SidePanelListItem from '@liveramp/motif/core/SidePanelListItem'
import Table, { TableWrapper } from '@liveramp/motif/core/Table'
import TableHeader from '@liveramp/motif/core/TableHeader'
import TableBody from '@liveramp/motif/core/TableBody'
import TableRow from '@liveramp/motif/core/TableRow'
import TableCell from '@liveramp/motif/core/TableCell'
import TableFooter from '@liveramp/motif/core/TableFooter'
import Tabs from '@liveramp/motif/core/Tabs'
import Tab from '@liveramp/motif/core/Tab'
import Typography from '@liveramp/motif/core/Typography'
import Chip from '@liveramp/motif/core/Chip'
import InfoIcon from '@liveramp/motif/icons/Info'
import css from './Simple.module.css'

export default function SidePanelDemo() {
  const [open, setOpen] = useState(false)
  const [tab, setTab] = useState(0)
  let data = [
    { name: 'Cappuccino', caffeine: true },
    { name: 'Latte', caffeine: true },
    { name: 'Cortado', caffeine: true },
    { name: 'Flat White', caffeine: true },
    { name: 'Affogato', caffeine: false },
    { name: 'Cold Brew', caffeine: true },
    { name: 'Decaf Latte', caffeine: false },
  ]
  data = [...data, ...data, ...data, ...data, ...data]

  return (
    <>
      <Button variant="outlined" onClick={() => setOpen(!open)}>
        Toggle SidePanel
      </Button>
      <SidePanel open={open} onClose={() => setOpen(false)} width="500px">
        <SidePanelHeader
          style={{ justifyContent: 'space-between' }}
          onClose={() => setOpen(false)}
        >
          Super Long Side Panel Header That Overflows Into A Tooltip Super Long
          Side Panel Header That Overflows Into A Tooltip
        </SidePanelHeader>
        <Tabs
          value={tab}
          onChange={(event, newTab) => setTab(newTab)}
          aria-label="side panel tabs"
        >
          <Tab
            label="Field Values"
            aria-label="Field Values"
            {...a11yProps(0)}
          />
          <Tab label="Subsections" aria-label="Subsections" {...a11yProps(1)} />
          <Tab label="Lists" aria-label="Lists" {...a11yProps(2)} />
          <Tab label="Tables" aria-label="Tables" {...a11yProps(3)} />
        </Tabs>
        <SidePanelContent id={0} className={clsx({ [css.hidden]: tab !== 0 })}>
          <Typography variant="body2" paragraph={false}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </Typography>
          <SidePanelImageSection image="https://storage.googleapis.com/motif-docs-images/side-panel/facebook-logo.png">
            <SidePanelFieldValueRow
              editAction={() => alert('Edit action triggered')}
            >
              {fvPair}
              {fvPair}
            </SidePanelFieldValueRow>
            <SidePanelFieldValueRow>
              {fvPair}
              {fvPair}
            </SidePanelFieldValueRow>
          </SidePanelImageSection>
          <SidePanelSubsection title="Subsection">
            <div className={css.fvPairSection}>{fvPair}</div>
            <br />
            <div className={css.fvPairSection}>
              {fvPair}
              {fvPair}
              {fvPair}
            </div>
          </SidePanelSubsection>
        </SidePanelContent>
        <SidePanelContent id={1} className={clsx({ [css.hidden]: tab !== 1 })}>
          <SidePanelSubsection title="Subsection">
            <Chip
              avatar={<InfoIcon className={css.chipIcon} />}
              label="Subsection is open by default"
              className={css.chip}
            />
            <Typography variant="body2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </Typography>
          </SidePanelSubsection>
          <SidePanelSubsection title="Subsection" open={false}>
            <Chip
              avatar={<InfoIcon className={css.chipIcon} />}
              label="But can be closed by default with open={false}"
              className={css.chip}
            />
            <Typography variant="body2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </Typography>
          </SidePanelSubsection>
        </SidePanelContent>
        <SidePanelContent id={2} className={clsx({ [css.hidden]: tab !== 2 })}>
          <SidePanelSubsection title="Subsection - Lists">
            <SidePanelList>
              <SidePanelListItem label="Normal Field">
                Normal Value
              </SidePanelListItem>
              <SidePanelListItem label="Short Field">
                Value with a really long child text that should show a tooltip
              </SidePanelListItem>
              <SidePanelListItem label="Super duper long Field with a really long title that wraps to another line">
                Value
              </SidePanelListItem>
              <SidePanelListItem label="Field with a really long title that wraps to another line">
                Value with a really long child text that should show a tooltip
              </SidePanelListItem>
            </SidePanelList>
          </SidePanelSubsection>
        </SidePanelContent>
        <SidePanelContent id={3} className={clsx({ [css.hidden]: tab !== 3 })}>
          <SidePanelSubsection title="Subsection - Tables">
            {/* 7 rows of 36px, header + footer */}
            <TableWrapper height={`${7 * 36 + 40 + 40}px`}>
              <Table stickyHeader size="small">
                <TableHeader>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Caffeine</TableCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {data.map((row, i) => (
                    <TableRow key={i} className={css.row}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.caffeine ? 'High' : 'Low'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={2}>
                      Showing rows <b>1</b> - <b>{data.length}</b>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableWrapper>
          </SidePanelSubsection>
        </SidePanelContent>
      </SidePanel>
    </>
  )
}

function a11yProps(index) {
  return {
    id: `side-panel-tab-${index}`,
    'aria-controls': `side-panel-tabpanel-${index}`,
  }
}

const fvPair = <SidePanelFieldValue label="Field">Value</SidePanelFieldValue>
