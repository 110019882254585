import React from 'react'
import {
  Page,
  Title,
  Subtitle,
  Supporting,
  Usage,
} from '../../resources/DemoPage'
import Demo, { generateDemos } from '../../resources/Demo'
import Simple from './demos/Simple'

let demoNames = ['Simple']

export default function RichDataTable() {
  let demos = generateDemos('core-component-demo/data-grid', demoNames)
  return (
    <Page components={['TextField']}>
      <Title>DataGrid</Title>
      <Subtitle>
        A fast and extendable data table and data grid for React
      </Subtitle>
      <Supporting>
        Data tables display information in a grid-like format of rows and
        columns. They organize information in a way that’s easy to scan so that
        users can look for patterns and insights. The <code>RichDataTable</code>{' '}
        (RDT) is a composed and extended version of the <code>DataGrid</code>{' '}
        component, providing support for common functionality such as searching
        and footer actions. UX recommends using the <code>RichDataTable</code>{' '}
        component over the <code>DataGrid</code> component when possible to
        ensure consistent experiences across LiveRamp applications.
      </Supporting>
      <Usage>
        <ul>
          <li>
            Docs for the full DataGrid API can be found under{' '}
            <a href="https://material-ui.com/api/x-grid/">
              Material UIs official DataGrid documentation
            </a>
          </li>
          <li>
            Because the DataGrid has been modified to suit LiveRamp's needs,
            documentation there may conflict with ours here; assume that Motif
            takes precedence.
          </li>
        </ul>
      </Usage>
      <p>
        If the functionality provided by the Rich Data Table is not what you
        need, the DataGrid is also available to use with some limited styling.
        Please don't hesitate to contact AX first before resulting to this
        solution!
      </p>
      <Demo demo={demos.Simple}>
        <Simple />
      </Demo>
    </Page>
  )
}
