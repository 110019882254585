import React, { useEffect, useState } from 'react'
import {
  GridPagination,
  GridSelectedRowCount,
  GridFooterContainer,
  useGridSlotComponentProps,
} from '../../core-components/DataGrid'
import Toolbar from '../../core-components/Toolbar'
import makeStyles from '../../styles/makeStyles'
import grey from '../../styles/colors/grey'

export default function Footer({
  actions = null,
  pagination,
  deselectAllHandler = null,
  selectAllHandler = null,
  selectAllCount = null,
  selectAllClicked,
  setSelectAllClicked,
  ...props
}) {
  const [customAllRowCount, setCustomAllRowCount] = useState(0)

  const gridSlotComponentProps = useGridSlotComponentProps()
  const { apiRef, state } = gridSlotComponentProps
  useStyles()
  const selectedRowCount = Object.keys(state.selection).length
  const rowCount = apiRef.current.getAllRowIds().length
  const allRowsSelected = rowCount === selectedRowCount
  const showActions = actions && selectedRowCount > 0
  const showSelectAllRows =
    selectAllHandler &&
    selectAllCount !== null &&
    allRowsSelected &&
    !selectAllClicked
  const deselectAll = () => apiRef.current.selectRows([], true, true)

  const handleSelectAllClick = () => {
    setSelectAllClicked(true)
    selectAllHandler()
  }

  useEffect(() => {
    if (!selectAllHandler) return
    if (allRowsSelected) return
    if (!selectAllClicked) return
    if (selectedRowCount) return
    setSelectAllClicked(false)
    deselectAllHandler && deselectAllHandler()
  }, [selectAllHandler, allRowsSelected, selectAllClicked, selectedRowCount])

  useEffect(() => {
    if (!selectAllHandler) return
    setCustomAllRowCount(selectAllCount - rowCount + selectedRowCount)
  }, [selectAllHandler, selectedRowCount, rowCount, selectAllCount])

  // Using the open prop in the GridFooterContainer still renders the footer,
  // despite being an empty container
  return pagination || showActions ? (
    <GridFooterContainer
      data-testid="RichDataTableFooter"
      className="RichDataTableFooter"
      {...props}
    >
      {pagination && (
        <div className="MuiDataGrid-footerData">
          <GridPagination />
        </div>
      )}
      {showActions && (
        <Toolbar className="MuiDataGrid-footerActions">
          {selectedRowCount > 0 && (
            <div className="RichDataTableFooter__deselect">
              <GridSelectedRowCount
                selectedRowCount={
                  selectAllClicked ? customAllRowCount : selectedRowCount
                }
              />
              <a
                onClick={deselectAll}
                className="RichDataTableFooter__deselect-all"
              >
                Deselect All
              </a>
              {showSelectAllRows && (
                <a
                  onClick={handleSelectAllClick}
                  className="RichDataTableFooter__select-all"
                >
                  Select all {customAllRowCount} rows in this table
                </a>
              )}
            </div>
          )}
          <div>
            <p className="RichDataTableFooter__actions">
              Actions available for all selected objects
            </p>
            {actions(gridSlotComponentProps)}
          </div>
        </Toolbar>
      )}
    </GridFooterContainer>
  ) : null
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.RichDataTableFooter': {
      flexDirection: 'column',
      borderTop: `1px solid ${grey[200]}`,

      '& > div': {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
      },

      '& .MuiToolbar-root': {
        minHeight: '52px',
        '& a': { cursor: 'pointer' },
      },

      '& .MuiTablePagination-root': {
        flex: 1,
      },

      '& .MuiDataGrid-footerActions': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        gap: '16px',
      },

      '& .MuiDataGrid-selectedRowCount': {
        display: 'flex',
        margin: '0',
      },

      // .RichDataTableFooter__select-all
      '&__select-all': {
        color: theme.palette.success.main,
        textDecoration: 'underline',
      },

      // .RichDataTableFooter__deselect
      '&__deselect': {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '4px',

        // .RichDataTableFooter__deselect-all
        '&-all': {
          marginRight: '16px',
        },
      },

      // .RichDataTableFooter__actions
      '&__actions': {
        ...theme.typography.subtitle3,
        marginBottom: '8px',
        textAlign: 'right',
      },
    },
  },
}))
