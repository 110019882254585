/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: 'MuiCheckbox',
  description: '',
  displayName: 'Checkbox',
  methods: [],
  props: {
    checked: {
      type: { name: 'bool' },
      required: false,
      description: 'If `true`, the component is checked.',
    },
    checkedIcon: {
      type: { name: 'node' },
      required: false,
      description: 'The icon to display when the component is checked.',
      defaultValue: {
        value: 'React.createElement(CheckBoxIcon, null)',
        computed: true,
      },
    },
    classes: {
      type: { name: 'object' },
      required: false,
      description:
        'Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details.',
    },
    color: {
      type: {
        name: 'enum',
        value: [
          { value: "'default'", computed: false },
          { value: "'primary'", computed: false },
          { value: "'secondary'", computed: false },
        ],
      },
      required: false,
      description:
        'The color of the component. It supports those theme colors that make sense for this component.',
      defaultValue: { value: "'secondary'", computed: false },
    },
    disabled: {
      type: { name: 'bool' },
      required: false,
      description: 'If `true`, the checkbox will be disabled.',
    },
    disableRipple: {
      type: { name: 'bool' },
      required: false,
      description: 'If `true`, the ripple effect will be disabled.',
    },
    icon: {
      type: { name: 'node' },
      required: false,
      description: 'The icon to display when the component is unchecked.',
      defaultValue: {
        value: 'React.createElement(CheckBoxOutlineBlankIcon, null)',
        computed: true,
      },
    },
    id: {
      type: { name: 'string' },
      required: false,
      description: 'The id of the `input` element.',
    },
    indeterminate: {
      type: { name: 'bool' },
      required: false,
      description:
        'If `true`, the component appears indeterminate.\r\nThis does not set the native input element to indeterminate due\r\nto inconsistent behavior across browsers.\r\nHowever, we set a `data-indeterminate` attribute on the input.',
      defaultValue: { value: 'false', computed: false },
    },
    indeterminateIcon: {
      type: { name: 'node' },
      required: false,
      description: 'The icon to display when the component is indeterminate.',
      defaultValue: {
        value: 'React.createElement(IndeterminateCheckBoxIcon, null)',
        computed: true,
      },
    },
    inputProps: {
      type: { name: 'object' },
      required: false,
      description:
        '[Attributes](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Attributes) applied to the `input` element.',
    },
    inputRef: {
      type: { name: 'custom', raw: 'refType' },
      required: false,
      description: 'Pass a ref to the `input` element.',
    },
    onChange: {
      type: { name: 'func' },
      required: false,
      description:
        'Callback fired when the state is changed.\r\n\r\n@param {object} event The event source of the callback.\r\nYou can pull out the new checked state by accessing `event.target.checked` (boolean).',
    },
    required: {
      type: { name: 'bool' },
      required: false,
      description: 'If `true`, the `input` element will be required.',
    },
    size: {
      type: {
        name: 'enum',
        value: [
          { value: "'medium'", computed: false },
          { value: "'small'", computed: false },
        ],
      },
      required: false,
      description:
        'The size of the checkbox.\r\n`small` is equivalent to the dense checkbox styling.',
      defaultValue: { value: "'medium'", computed: false },
    },
    value: {
      type: { name: 'any' },
      required: false,
      description:
        'The value of the component. The DOM API casts this to a string.\r\nThe browser uses "on" as the default value.',
    },
  },
  styles: {
    root: { description: 'Styles applied to the root element.' },
    checked: {
      description:
        'Pseudo-class applied to the root element if `checked={true}`.',
    },
    disabled: {
      description:
        'Pseudo-class applied to the root element if `disabled={true}`.',
    },
    indeterminate: {
      description:
        'Pseudo-class applied to the root element if `indeterminate={true}`.',
    },
    colorPrimary: {
      description: 'Styles applied to the root element if `color="primary"`.',
    },
    colorSecondary: {
      description: 'Styles applied to the root element if `color="secondary"`.',
    },
  },
}
export default generatedDocs
