import InputLabel from '@material-ui/core/InputLabel'
import { InputLabelStyle } from '../../utils/styleKeys'
import { pxToRem as rem } from '../../utils'
export default InputLabel

export let styles = (theme) => ({
  ...InputLabelStyle,
  root: {
    fontSize: rem(14),
    fontWeight: 400,
  },
  formControl: {
    transform: 'translate(0, 26px)',
  },
  outlined: {
    transform: 'translate(8px, 30px)',
    '&.MuiInputLabel-shrink': {
      transform: 'translate(8px, 0)',
    },
  },
  shrink: {
    transform: 'translate(0, 0)',
  },
  focused: {
    '.MuiInputLabel-root&': {
      color: theme.palette.text.secondary,
    },
  },
  error: {
    '.MuiInputLabel-root&': {
      color: theme.palette.error.dark,
    },
  },
})
