import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import SidePanel from '@liveramp/motif/core/SidePanel'
import SidePanelHeader from '@liveramp/motif/core/SidePanelHeader'
import SidePanelContent from '@liveramp/motif/core/SidePanelContent'
import SidePanelSubsection from '@liveramp/motif/core/SidePanelSubsection'
import SidePanelImageSection from '@liveramp/motif/core/SidePanelImageSection'
import SidePanelFieldValueRow from '@liveramp/motif/core/SidePanelFieldValueRow'
import SidePanelList from '@liveramp/motif/core/SidePanelList'
import SidePanelListItem from '@liveramp/motif/core/SidePanelListItem'
import Tabs from '@liveramp/motif/core/Tabs'
import Tab from '@liveramp/motif/core/Tab'
import Typography from '@liveramp/motif/core/Typography'
import makeStyles from '@liveramp/motif/styles/makeStyles'

export default function RdtSidePanel({
  open,
  setOpen,
  content,
  token,
  ...props
}) {
  const [tab, setTab] = useState(0)
  const [artists, setArtists] = useState(null)
  const classes = useStyles()

  // Get artist data
  useEffect(() => {
    if (content) {
      let promises = []
      content.row.artists.map((artist) =>
        promises.push(
          fetch(artist.href, {
            method: 'GET',
            headers: {
              Authorization: `${token.token_type} ${token.access_token}`,
            },
          }),
        ),
      )

      Promise.all(promises)
        .then((responses) =>
          Promise.all(responses.map((response) => response.json())),
        )
        .then((data) => setArtists(data))
    }
  }, [token, content])

  const renderSidePanelContent = () => {
    if (content) {
      const { row } = content

      const ms = row.duration_ms
      const minutes = Math.floor(ms / 60000)
      const seconds = ((ms % 60000) / 1000).toFixed(0)
      const duration = `${minutes}:${String(seconds).padStart(2, '0')}`

      return (
        <>
          <SidePanelHeader onClose={() => setOpen(false)}>
            {row.name}
          </SidePanelHeader>
          <Tabs value={tab} onChange={(event, newTab) => setTab(newTab)}>
            <Tab label="General" aria-label="General" />
            <Tab label="Artists" aria-label="Artists" />
            <Tab label="Album" aria-label="Album" />
          </Tabs>

          {/* General Tab */}
          <SidePanelContent
            index={0}
            className={clsx({ [classes.hidden]: tab !== 0 })}
          >
            <SidePanelImageSection image={row.album.images[0]?.url}>
              <SidePanelFieldValueRow>
                <div>
                  <Typography
                    variant="body3"
                    color="secondary"
                    paragraph={false}
                  >
                    Artist(s)
                  </Typography>
                  <Typography variant="body2" paragraph={false}>
                    {row.artists.map((artist) => artist.name).join(', ')}
                  </Typography>
                </div>
              </SidePanelFieldValueRow>
              <SidePanelFieldValueRow>
                <div>
                  <Typography
                    variant="body3"
                    color="secondary"
                    paragraph={false}
                  >
                    Album
                  </Typography>
                  <Typography variant="body2" paragraph={false}>
                    {row.album.name}
                  </Typography>
                </div>
              </SidePanelFieldValueRow>
            </SidePanelImageSection>
            {row.preview_url && (
              <audio className={classes.audio} controls>
                <source src={row.preview_url} />
              </audio>
            )}
            <SidePanelSubsection title="Song Details">
              <div className={classes.fvPairSection}>
                <div>
                  <Typography
                    variant="body3"
                    color="secondary"
                    paragraph={false}
                  >
                    Disc Number
                  </Typography>
                  <Typography variant="body2" paragraph={false}>
                    {row.disc_number}
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant="body3"
                    color="secondary"
                    paragraph={false}
                  >
                    Track Number
                  </Typography>
                  <Typography variant="body2" paragraph={false}>
                    {row.track_number}
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant="body3"
                    color="secondary"
                    paragraph={false}
                  >
                    Duration
                  </Typography>
                  <Typography variant="body2" paragraph={false}>
                    {duration}
                  </Typography>
                </div>
              </div>
            </SidePanelSubsection>
            <SidePanelSubsection title="Other" open={false}>
              <div>
                <Typography variant="body3" color="secondary" paragraph={false}>
                  ID
                </Typography>
                <Typography variant="body2" paragraph={false}>
                  {row.id}
                </Typography>
              </div>
              <br />
              <div className={classes.fvPairSection}>
                <div>
                  <Typography
                    variant="body3"
                    color="secondary"
                    paragraph={false}
                  >
                    Popularity
                  </Typography>
                  <Typography variant="body2" paragraph={false}>
                    {row.popularity} / 100
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant="body3"
                    color="secondary"
                    paragraph={false}
                  >
                    Available Markets
                  </Typography>
                  <Typography variant="body2" paragraph={false}>
                    {row.available_markets.length}
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant="body3"
                    color="secondary"
                    paragraph={false}
                  >
                    Explicit
                  </Typography>
                  <Typography variant="body2" paragraph={false}>
                    {row.explicit.toString()}
                  </Typography>
                </div>
              </div>
            </SidePanelSubsection>
            <SidePanelSubsection title="External IDs" open={false}>
              <SidePanelList>
                {Object.keys(row.external_ids).map((key) => (
                  <SidePanelListItem key={key} label={key}>
                    {row.external_ids[key]}
                  </SidePanelListItem>
                ))}
              </SidePanelList>
            </SidePanelSubsection>
            <div>
              <h5>Listen to {row.name} at:</h5>
              <SidePanelList>
                {Object.keys(row.external_urls).map((key) => (
                  <SidePanelListItem
                    key={key}
                    label={<a href={row.external_urls[key]}>{key}</a>}
                  />
                ))}
                <SidePanelListItem className={classes.listItem} label="URI">
                  {row.uri}
                </SidePanelListItem>
              </SidePanelList>
            </div>
          </SidePanelContent>

          {/* Arist Tab */}
          <SidePanelContent
            index={1}
            className={clsx({ [classes.hidden]: tab !== 1 })}
          >
            {artists &&
              artists.map((artist) => (
                <SidePanelSubsection title={artist.name} key={artist.name}>
                  <SidePanelImageSection
                    key={artist.name}
                    image={artist.images[0]?.url}
                  >
                    <SidePanelFieldValueRow>
                      <div>
                        <Typography
                          variant="body3"
                          color="secondary"
                          paragraph={false}
                        >
                          Followers
                        </Typography>
                        <Typography variant="body2" paragraph={false}>
                          {artist.followers.total}
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          variant="body3"
                          color="secondary"
                          paragraph={false}
                        >
                          Popularity
                        </Typography>
                        <Typography variant="body2" paragraph={false}>
                          {artist.popularity} / 100
                        </Typography>
                      </div>
                    </SidePanelFieldValueRow>
                    <SidePanelFieldValueRow>
                      <div>
                        <Typography
                          variant="body3"
                          color="secondary"
                          paragraph={false}
                        >
                          Genres
                        </Typography>
                        <Typography variant="body2" paragraph={false}>
                          {artist.genres.join(', ')}
                        </Typography>
                      </div>
                    </SidePanelFieldValueRow>
                  </SidePanelImageSection>
                  <div>
                    <h5>Discover {artist.name} at:</h5>
                    <SidePanelList>
                      {Object.keys(artist.external_urls).map((key) => (
                        <SidePanelListItem
                          key={key}
                          label={<a href={row.external_urls[key]}>{key}</a>}
                        />
                      ))}
                      <SidePanelListItem
                        className={classes.listItem}
                        label="URI"
                      >
                        {row.uri}
                      </SidePanelListItem>
                    </SidePanelList>
                  </div>
                </SidePanelSubsection>
              ))}
          </SidePanelContent>

          {/* Album Tab */}
          <SidePanelContent
            index={2}
            className={clsx({ [classes.hidden]: tab !== 2 })}
          >
            <div>
              <img
                src={row.album.images[0]?.url}
                width="100%"
                alt="album art cover"
              />
            </div>
            <div className={classes.albumPrimary}>
              <SidePanelFieldValueRow>
                <div>
                  <Typography
                    variant="body3"
                    color="secondary"
                    paragraph={false}
                  >
                    Album Title
                  </Typography>
                  <Typography variant="body2" paragraph={false}>
                    {row.album.name}
                  </Typography>
                </div>
              </SidePanelFieldValueRow>
              <SidePanelFieldValueRow>
                <div>
                  <Typography
                    variant="body3"
                    color="secondary"
                    paragraph={false}
                  >
                    Album Artist(s)
                  </Typography>
                  <Typography variant="body2" paragraph={false}>
                    {row.album.artists.map((artist) => artist.name).join(', ')}
                  </Typography>
                </div>
              </SidePanelFieldValueRow>
            </div>
            <SidePanelSubsection title="Album Details">
              <div className={classes.fvPairSection}>
                <div>
                  <Typography
                    variant="body3"
                    color="secondary"
                    paragraph={false}
                  >
                    Total Tracks
                  </Typography>
                  <Typography variant="body2" paragraph={false}>
                    {row.album.total_tracks}
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant="body3"
                    color="secondary"
                    paragraph={false}
                  >
                    Album Type
                  </Typography>
                  <Typography variant="body2" paragraph={false}>
                    {row.album.album_type}
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant="body3"
                    color="secondary"
                    paragraph={false}
                  >
                    Release Date
                  </Typography>
                  <Typography variant="body2" paragraph={false}>
                    {row.album.release_date}
                  </Typography>
                </div>
              </div>
            </SidePanelSubsection>
          </SidePanelContent>
        </>
      )
    }

    return null
  }

  return (
    <SidePanel open={open} onClose={() => setOpen(false)} {...props}>
      {renderSidePanelContent()}
    </SidePanel>
  )
}

const useStyles = makeStyles((theme) => ({
  hidden: { display: 'none' },
  fvPairSection: {
    display: 'grid',
    gap: '16px',
    gridAutoFlow: 'column',
    '& .MuiTypography-body2:first-letter': {
      textTransform: 'capitalize',
    },
  },
  albumPrimary: {
    display: 'grid',
    gap: '1px',
  },
  audio: {
    width: '100%',
  },
  listItem: {
    gridTemplateColumns: '1fr auto',
  },
}))
