import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { bytesToSize } from '../../../../utils'
import useFileList from '../useFileList'

const useUploadFile = (
  url,
  onFilesAdded,
  onFileCancelled = null,
  onFileRemoved = null,
) => {
  const [fileQueue, setFileQueue] = useState({})
  const [currentFile, setCurrentFile] = useState(null)

  const addFileToQueue = (file) => {
    setCurrentFile(file.motifId)
    setFileQueue({ ...fileQueue, [file.motifId]: file })
  }
  const removeFileFromQueue = (idToDiscard) => {
    setFileQueue((prev) => {
      const { idToDiscard, ...newQueue } = prev
      return newQueue
    })
    if (currentFile === idToDiscard) {
      setCurrentFile(null)
    }
  }

  const [_, actionsFactory] = useFileList()

  function doUpload(xhr, actions, file) {
    actions.startUpload(file)
    xhr.upload.onprogress = (event) => {
      const percentage = (event.loaded / event.total) * 100
      actions.recordFileProgress(percentage, file)
    }
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) {
        return
      }
      if (xhr.status === 200) {
        actions.fileUploadCompleted(file.motifId)
      } else {
        actions.fileUploadError(file.motifId, xhr.responseText)
      }
      removeFileFromQueue(file.motifId)
    }

    const formData = new FormData()
    formData.append('file', file.data)
    xhr.open('POST', url, true)
    xhr.send(formData)
  }

  useEffect(() => {
    if (!currentFile || !(currentFile in fileQueue)) {
      return
    }
    const { xhr, ...file } = fileQueue[currentFile]
    const actions = actionsFactory(file.motifId)
    doUpload(xhr, actions, file)

    return () => file.status === 'UPLOADING' && file.cancelUpload()
  }, [currentFile, fileQueue])

  return (rawFile) => {
    const motifId = rawFile.id ?? uuidv4()
    const actions = actionsFactory(motifId)

    const cancelFileUpload = (motifId) => {
      actions.fileUploadCancelled(motifId)
      if (currentFile === motifId) {
        removeFileFromQueue(motifId)
      }
    }
    const file = {
      motifId,
      name: rawFile.name,
      data: rawFile,
      size: bytesToSize(rawFile.size || 0),
      removeFile: () => {
        onFileRemoved && onFileRemoved(motifId, file)
        actions.removeFile()
      },
    }
    if (onFilesAdded) {
      file.cancelUpload = () => {
        onFileCancelled && onFileCancelled(motifId, file)
        cancelFileUpload(motifId)
      }
      file.retryUpload = () => {
        actions.addFile(file)
        onFilesAdded(file, actions)
      }
      actions.addFile(file)
      return onFilesAdded(file, actions)
    }

    const xhr = new XMLHttpRequest()
    file.cancelUpload = () => {
      xhr.abort()
      onFileCancelled && onFileCancelled(motifId, file)
      cancelFileUpload(motifId)
    }
    file.retryUpload = () => {
      actions.addFile(file)
      doUpload(xhr, actions, file)
    }
    file.xhr = xhr
    actions.addFile(file)
    return addFileToQueue(file)
  }
}

export default useUploadFile
