import React from 'react'
import Button from '@liveramp/motif/core/Button'
import ButtonFormGroup from '@liveramp/motif/core/ButtonFormGroup'
import chroma from 'chroma-js'
import withStyles from '@liveramp/motif/styles/withStyles'
import css from './Custom.module.css'

function CustomDemo({ classes }) {
  return (
    <ButtonFormGroup>
      <Button className={css.custom}>CSS Modules</Button>
      <Button classes={classes}>JSS withStyles</Button>
    </ButtonFormGroup>
  )
}

let styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.info.main,
    borderColor: theme.palette.info.main,
    '&:hover': {
      backgroundColor: `${chroma.mix(
        theme.palette.info.main,
        theme.palette.info.dark,
      )}`,
      borderColor: `${chroma.mix(
        theme.palette.info.main,
        theme.palette.info.dark,
      )}`,
    },
    '&:active': {
      backgroundColor: theme.palette.info.dark,
      borderColor: theme.palette.info.dark,
    },
    '&.Mui-active': {
      backgroundColor: theme.palette.info.dark,
      borderColor: theme.palette.info.dark,
    },
  },
})

export default withStyles(styles)(CustomDemo)
