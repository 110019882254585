import React from 'react'
import { Subtitle, SectionTitle } from '../../resources/Page'
import CodeChunk from '../../resources/CodeChunk'

export default function Style() {
  return (
    <div>
      <h1>Style</h1>
      <Subtitle>
        Create outside-of-the-box, unique designs that still encompass the
        LiveRamp brand
      </Subtitle>
      <SectionTitle>Basic Overrides</SectionTitle>
      <p>
        Due to the way the underlying Material UI library is built, their
        built-in CSS has the highest level of specificity because it injects its
        JSS styles at the bottom of the <code>{'<head>'}</code> (inline styling
        still takes precedence as per usual). If you don't want to mark style
        attributes with <code>!important</code> or use inline styling, you need
        to change the CSS injection order.
      </p>
      <p>
        In your <code>index.html</code> file, add the following
        <code>noscript</code> tag in your <code>{'<head>'}</code> to specify
        where the Material UI styles should be inserted.
      </p>
      <CodeChunk>{`<noscript id="jss-insertion-point"></noscript>`}</CodeChunk>
      <br />
      <p>
        Next, start understanding how to style Motif components in{' '}
        <a href="/style/basics">basics</a>
      </p>
    </div>
  )
}
