import React from 'react'
import Table from '@liveramp/motif/core/Table'
import TableHeader from '@liveramp/motif/core/TableHeader'
import TableBody from '@liveramp/motif/core/TableBody'
import TableRow from '@liveramp/motif/core/TableRow'
import TableCell from '@liveramp/motif/core/TableCell'
import Switch from '@liveramp/motif/core/Switch'
import Typography from '@liveramp/motif/core/Typography'

export default function SimpleDemo() {
  let data = [
    { name: 'Client A' },
    { name: 'Client B' },
    { name: 'Client C' },
    { name: 'Client D' },
    { name: 'Client E' },
  ]

  return (
    <>
      <Typography variant="h4">Selected Segments</Typography>
      <Table>
        <TableHeader>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Always On</TableCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data.map((row, i) => (
            <TableRow key={i}>
              <TableCell>{row.name}</TableCell>
              <TableCell align="left">
                <Switch />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}
