import CardContent from '@material-ui/core/CardContent'
import withStyles from '../../styles/withStyles'
import { CardContentStyle } from '../../utils/styleKeys'
import { pxToRem as rem } from '../../utils'

let styles = (theme) => ({
  ...CardContentStyle,
  root: {
    width: '100%',
    ...theme.typography.body2,
    display: 'flex',
    flexDirection: 'column',
    alighItems: 'center',
    marginTop: '24px',
    lineHeight: rem(20),
    '&, &:last-child': {
      padding: `0 ${rem(16)}`,
    },
  },
})

export default withStyles(styles, { name: 'MuiCardContent' })(CardContent)
