/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: 'MuiList',
  description: '',
  displayName: 'List',
  methods: [],
  props: {
    children: {
      type: { name: 'node' },
      required: false,
      description: 'The content of the component.',
    },
    classes: {
      type: { name: 'object' },
      required: true,
      description:
        'Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details.',
    },
    className: {
      type: { name: 'string' },
      required: false,
      description: '@ignore',
    },
    component: {
      type: { name: 'elementType' },
      required: false,
      description:
        'The component used for the root node.\r\nEither a string to use a HTML element or a component.',
      defaultValue: { value: "'ul'", computed: false },
    },
    dense: {
      type: { name: 'bool' },
      required: false,
      description:
        'If `true`, compact vertical padding designed for keyboard and mouse input will be used for\r\nthe list and list items.\r\nThe prop is available to descendant components as the `dense` context.',
      defaultValue: { value: 'false', computed: false },
    },
    disablePadding: {
      type: { name: 'bool' },
      required: false,
      description: 'If `true`, vertical padding will be removed from the list.',
      defaultValue: { value: 'false', computed: false },
    },
    subheader: {
      type: { name: 'node' },
      required: false,
      description: 'The content of the subheader, normally `ListSubheader`.',
    },
  },
  styles: {
    root: { description: 'Styles applied to the root element.' },
    padding: {
      description:
        'Styles applied to the root element if `disablePadding={false}`.',
    },
    dense: { description: 'Styles applied to the root element if dense.' },
    subheader: {
      description:
        'Styles applied to the root element if a `subheader` is provided.',
    },
  },
}
export default generatedDocs
