import React from 'react'
import createSvgIcon from './utils/createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path
      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zM6.835 11a.835.835 0 00-.835.835v1.33c0 .461.374.835.835.835h10.33a.835.835 0 00.835-.835v-1.33a.835.835 0 00-.835-.835H6.835z"
      fill="#BDBDBD"
      fillRule="evenodd"
    />
  </React.Fragment>,
  'StatusCancelled',
)
