import React, { useEffect, useContext, useState, createRef } from 'react'
import clsx from 'clsx'
import ButtonBase from '../../core-components/ButtonBase'
import Collapse from '../../core-components/Collapse'
import Autocomplete from '../../core-components/Autocomplete'
import List from '../../core-components/List'
import Skeleton from '../../core-components/Skeleton'
import Tooltip from '../../core-components/Tooltip'
import SearchField from './SearchField'
import { GlobalNavigationContext } from './GlobalNavigation'
import CaretDown from '../../icons/CaretDown'
import CaretUp from '../../icons/CaretUp'
import Info from '../../icons/Info'
import makeStyles from '../../styles/makeStyles'
import light from '../../styles/colors/light'

function AccountSwitcher({ lightColor = false, ...props }) {
  const { accountSwitcherProps, customisation } = useContext(
    GlobalNavigationContext,
  )
  useStyles({ lightColor, customisation })
  const autocompleteClasses = useAutocompleteStyles({ lightColor })

  const {
    accountSwitcherOpen,
    getOptionLabel,
    getOptionLink,
    loading,
    onChange,
    options,
    setAccountSwitcherOpen,
    value,
  } = accountSwitcherProps
  const [acInputValue, setAcInputValue] = useState('')
  const [showTooltip, setShowTooltip] = useState(false)
  const [openTooltip, setOpenTooltip] = useState(false)
  const accountNameRef = createRef()
  useEffect(() => {
    if (
      accountNameRef?.current.scrollWidth > accountNameRef?.current.clientWidth
    ) {
      setShowTooltip(true)
    } else {
      setShowTooltip(false)
    }
  }, [accountNameRef])

  const determineShowSwitcher = ({
    loading,
    options,
    value,
    getOptionLabel,
  }) => {
    if (!loading && options.length === 1) {
      // show if the single account available is not the same as the current account
      return getOptionLabel(options[0]) !== getOptionLabel(value)
    }
    // show the switcher as it may contain more information regardless of the
    // accounts available
    return true
  }

  const [showSwitcher, setShowSwitcher] = useState(
    determineShowSwitcher(accountSwitcherProps),
  )
  useEffect(() => {
    setShowSwitcher(determineShowSwitcher(accountSwitcherProps))
  }, [accountSwitcherProps])

  const Caret = accountSwitcherOpen ? CaretUp : CaretDown

  return (
    <div
      className="GNAccountSwitcher"
      onBlur={(e) => setAccountSwitcherOpen(false)}
    >
      <ButtonBase
        className="GNAccountSwitcher-button"
        onClick={() => setAccountSwitcherOpen(!accountSwitcherOpen)}
        disabled={!showSwitcher}
      >
        <div className="GNAccountSwitcher-label-wrapper">
          <Tooltip
            title={getOptionLabel(value)}
            placement="right"
            open={showTooltip && openTooltip}
            onOpen={() => setOpenTooltip(true)}
            onClose={() => setOpenTooltip(false)}
          >
            <div ref={accountNameRef} className="GNAccountSwitcher-label">
              {getOptionLabel(value)}
            </div>
          </Tooltip>
          {getOptionLink(value) && (
            <a
              className="GNAccountSwitcher-adminLink"
              href={getOptionLink(value)}
            >
              Open in Admin
            </a>
          )}
        </div>
        {showSwitcher && <Caret className="GNAccountSwitcher-caret" />}
      </ButtonBase>
      <Collapse
        classes={{
          root: 'GNAccountSwitcher-collapse',
        }}
        in={accountSwitcherOpen}
      >
        {options.length > 0 ? (
          <Autocomplete
            className="GNAccountSwitcher-autocomplete"
            classes={autocompleteClasses}
            getOptionLabel={getOptionLabel}
            inputValue={
              acInputValue === getOptionLabel(value) ? '' : acInputValue
            }
            ListboxComponent={List}
            ListboxProps={{
              className: clsx('GNAccountSwitcher-listbox', {
                ['GN-lightColor']: lightColor,
                ['GNAccountSwitcher-scroll']: lightColor,
              }),
              disablePadding: true,
            }}
            loading={loading}
            loadingText={<Skeleton />}
            onChange={(event, option) => {
              setAccountSwitcherOpen(false)
              onChange(event, option)
            }}
            onInputChange={(event, value) => setAcInputValue(value)}
            options={options}
            noOptionsText="No account names match your search"
            PopperComponent={null}
            renderInput={(props) => (
              <SearchField
                renderInputProps={props}
                onFocus={() => setAccountSwitcherOpen(true)}
                lightColor={lightColor}
              />
            )}
            value={value}
            autoHighlight
            disableClearable
            disablePortal
            open
          />
        ) : (
          <div
            className={clsx(
              'GNAccountSwitcher-noOptions',
              'GNAutocomplete-noOptions',
            )}
          >
            <Info className="GNAccountSwitcher-infoIcon" />
            You only have access to the selected account. Contact your LiveRamp
            Account Manager with questions.
          </div>
        )}
      </Collapse>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.GNAccountSwitcher': {
      // .GNAccountSwitcher-button
      '&-button': {
        minHeight: '60px',
        display: 'grid',
        gridTemplateColumns: '156px 32px',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 10px',
        fontFamily: 'inherit',
        textAlign: 'left',

        '& *': {
          color: ({ lightColor }) =>
            lightColor
              ? theme.palette.common.white
              : theme.palette.common.black,
        },

        '&:hover, &:active': {
          backgroundColor: ({ lightColor }) =>
            lightColor ? theme.palette.secondary.hover : 'transparent',
        },
      },

      // .GNAccountSwitcher-label
      '&-label': {
        fontSize: theme.typography.body2.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
        maxHeight: '2.5em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },

      // .GNAccountSwitcher-adminLink
      '&-adminLink': {
        fontSize: '12px',
        fontWeight: '300',
        textDecoration: 'underline',
      },

      // .GNAccountSwitcher-caret
      '&-caret': {
        fontSize: '32px',
        color: ({ lightColor }) =>
          lightColor ? theme.palette.common.white : theme.palette.common.black,
        transition: theme.transitions.common.short,
      },

      // .GNAccountSwitcher-collapse
      '&-collapse': {
        flex: '0 0 auto',
        maxHeight: ({ lightColor }) => (lightColor ? '536px' : '336px'),
        overflowY: 'hidden',
        transition: theme.transitions.common.leavingScreen,
      },

      // .GNAccountSwitcher-listbox
      '&-listbox': {
        maxHeight: '300px',
        overflow: 'auto',
      },

      // .GNAccountSwitcher-scroll
      '&-scroll': {
        // This class is duplicated in the L1L2 component
        // Any changes will need to be reflected in both places
        '&::-webkit-scrollbar': {
          backgroundColor: ({ customisation }) =>
            customisation?.backgroundColor
              ? customisation.backgroundColor
              : theme.palette.grey[900],
          width: '14px',
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: ({ customisation }) =>
            customisation?.backgroundColor
              ? customisation.backgroundColor
              : theme.palette.grey[900],
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          borderRadius: '14px',
          border: ({ customisation }) =>
            `4px solid ${
              customisation?.backgroundColor
                ? customisation.backgroundColor
                : theme.palette.grey[900]
            }`,
        },
      },

      '&-infoIcon': {
        color: '#01a9e0',
        fontSize: '16px',
      },
    },
  },
}))

const useAutocompleteStyles = makeStyles((theme) => ({
  '@global': {
    // duplicate of noOptions below
    '.GNAutocomplete-noOptions': {
      display: 'flex',
      alignItems: 'flex-start',
      color: ({ lightColor }) =>
        lightColor ? theme.palette.common.white : theme.palette.common.black,
      fontSize: theme.typography.caption.fontSize,
      fontStyle: 'italic',
      padding: '5px 5px 4px 10px',

      '&.Mui-disabled': {
        opacity: 1,
      },

      '& > .MuiSvgIcon-root': {
        fontSize: '0.75rem',
        paddingRight: '4px',
      },
    },
  },
  popperDisablePortal: {
    position: 'unset',
  },
  paper: {
    backgroundColor: 'unset',
    margin: 0,
    width: '208px',
  },
  loading: {
    padding: '13px 10px',
    '& .MuiSkeleton-root': {
      width: '100%',
    },
  },
  option: {
    color: ({ lightColor }) =>
      lightColor ? theme.palette.common.white : theme.palette.common.black,
    ...theme.typography.body2,
    minHeight: '36px',
    padding: '6px 10px',
    "&:hover, &[data-focus='true']": {
      backgroundColor: ({ lightColor }) =>
        lightColor ? light.action.hover : theme.palette.action.hover,
    },
    "&[aria-selected='true']": {
      backgroundColor: ({ lightColor }) =>
        lightColor ? light.action.selected : theme.palette.action.selected,
    },
  },
  noOptions: {
    display: 'flex',
    alignItems: 'flex-start',
    color: ({ lightColor }) =>
      lightColor ? theme.palette.common.white : theme.palette.common.black,
    fontSize: theme.typography.caption.fontSize,
    fontStyle: 'italic',
    padding: '5px 5px 4px 10px',

    '&.Mui-disabled': {
      opacity: 1,
    },

    '& > .MuiSvgIcon-root': {
      fontSize: '0.75rem',
      paddingRight: '4px',
    },
  },
}))

export default AccountSwitcher
