import React from 'react'
import Truncate from '@liveramp/motif/core/Truncate'
import makeStyles from '@liveramp/motif/styles/makeStyles'

export default function Wrapped() {
  const classes = useStyles()
  const children =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ac purus a diam dapibus sollicitudin. Phasellus eget cursus orci. Quisque eleifend justo non sapien dignissim, eget sollicitudin lacus vestibulum.'
  return (
    <div className={classes.container}>
      <Truncate wrapped>{children}</Truncate>
    </div>
  )
}

let useStyles = makeStyles({
  container: {
    display: 'grid',
    maxWidth: '800px',
  },
})
