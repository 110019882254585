import React from 'react'
import {
  Page,
  Title,
  Subtitle,
  SectionTitle,
} from '../../../resources/DemoPage'
import { RdtNotices } from '../index'
import Demo, { generateDemos } from '../../../resources/Demo'
import UpdatingDeletingRowsDemo from './demos/UpdatingDeletingRows'
import FetchRowsDemo from './demos/FetchRows'
import ServerSideDemo from './demos/ServerSide'

let demoNames = ['UpdatingDeletingRows', 'FetchRows', 'ServerSide']

export default function RichDataTable() {
  let demos = generateDemos(
    'composed-component-demo/rich-data-table/rows',
    demoNames,
  )

  return (
    <Page>
      <Title>Rich Data Table - Rows</Title>
      <Subtitle>Rich Data Table for React apps</Subtitle>
      <RdtNotices />

      <p>
        MUI provides an imperative API that allows you to access and update
        various properties of the table. The demo shows how well the RDT handles
        updates being made every 500ms.
      </p>
      <ul>
        <li>
          The default behavior of updateRows API is to upsert rows; so if a row
          has an id that is not in the current list of rows, it will be added to
          the grid.
        </li>
        <li>
          To delete a row, add <code>_action: "delete"</code> property in the
          update object.
        </li>
        <li>
          The full api for apiRef can be found in MUIs{' '}
          <a href="https://material-ui.com/api/data-grid/grid-api/">
            Grid Api Reference
          </a>
          .
        </li>
      </ul>
      <Demo demo={demos.UpdatingDeletingRows}>
        <UpdatingDeletingRowsDemo />
      </Demo>

      <SectionTitle>Dynamic Loading</SectionTitle>
      <p>
        If rows need to be loaded dynamically, <code>onRowsScrollEnd</code> can
        be used to fetch more rows.
      </p>
      <Demo demo={demos.FetchRows}>
        <FetchRowsDemo />
      </Demo>

      <SectionTitle>Server Side</SectionTitle>
      <Demo demo={demos.ServerSide}>
        <ServerSideDemo />
      </Demo>
    </Page>
  )
}
