import React from 'react'
import createSvgIcon from './utils/createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path
      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-3.333-3.877a.5.5 0 00.883.32l2.45-2.93 2.45 2.93a.5.5 0 00.883-.32V5.348C14.223 4.783 13.111 4.5 12 4.5c-1.111 0-2.222.283-3.333.848v12.775z"
      fill="#00A9E0"
      fillRule="evenodd"
    />
  </React.Fragment>,
  'StatusNew',
)
