import React from 'react'
import createSvgIcon from './utils/createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path
      d="M11.573 1.977c5.536 0 10.023 4.488 10.023 10.023 0 5.535-4.487 10.023-10.023 10.023-5.535 0-10.022-4.488-10.022-10.023 0-5.535 4.487-10.023 10.022-10.023zM7.305 7.941h-.902l-.081.007a.453.453 0 00-.37.444v7.216l.007.081c.038.21.223.37.444.37h.902l.08-.007a.453.453 0 00.37-.444V8.392L7.75 8.31a.453.453 0 00-.444-.37zm2.93 0h-.901l-.081.007a.453.453 0 00-.37.444v7.216l.007.081c.038.21.223.37.444.37h.902l.08-.007a.453.453 0 00.37-.444V8.392l-.006-.081a.453.453 0 00-.444-.37zm2.804.094a.799.799 0 00-1.174.704v6.519l.007.105c.074.583.752.9 1.258.551l4.621-3.259.09-.074a.801.801 0 00-.09-1.231l-4.62-3.26-.092-.055z"
      fill="#26B6E5"
    />
  </React.Fragment>,
  'StatusQueued',
)
