import React, { useState } from 'react'
import Select from '@liveramp/motif/core/Select'
import { films } from './data'

export default function FixedValuesDemo() {
  let fixedValues = [films[79], films[94]]
  let [value, setValue] = useState([...fixedValues, films[96]])

  return (
    <Select
      id="fixed-values"
      options={films}
      getOptionLabel={(film) => film.title}
      fixedValues={fixedValues}
      value={value}
      onChange={(e, val) => setValue(val)}
      multiple
    />
  )
}
