/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: 'MuiFormControl',
  description:
    'Provides context such as filled/focused/error/required for form inputs.\r\nRelying on the context provides high flexibility and ensures that the state always stays\r\nconsistent across the children of the `FormControl`.\r\nThis context is used by the following components:\r\n\r\n - FormLabel\r\n - FormHelperText\r\n - Input\r\n - InputLabel\r\n\r\nYou can find one composition example below and more going to [the demos](/components/text-fields/#components).\r\n\r\n```jsx\r\n<FormControl>\r\n  <InputLabel htmlFor="my-input">Email address</InputLabel>\r\n  <Input id="my-input" aria-describedby="my-helper-text" />\r\n  <FormHelperText id="my-helper-text">We\'ll never share your email.</FormHelperText>\r\n</FormControl>\r\n```\r\n\r\n⚠️Only one input can be used within a FormControl.',
  displayName: 'FormControl',
  methods: [],
  props: {
    children: {
      type: { name: 'node' },
      required: false,
      description: 'The contents of the form control.',
    },
    classes: {
      type: { name: 'object' },
      required: false,
      description:
        'Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details.',
    },
    className: {
      type: { name: 'string' },
      required: false,
      description: '@ignore',
    },
    color: {
      type: {
        name: 'enum',
        value: [
          { value: "'primary'", computed: false },
          { value: "'secondary'", computed: false },
        ],
      },
      required: false,
      description:
        'The color of the component. It supports those theme colors that make sense for this component.',
      defaultValue: { value: "'primary'", computed: false },
    },
    component: {
      type: { name: 'elementType' },
      required: false,
      description:
        'The component used for the root node.\r\nEither a string to use a HTML element or a component.',
      defaultValue: { value: "'div'", computed: false },
    },
    disabled: {
      type: { name: 'bool' },
      required: false,
      description:
        'If `true`, the label, input and helper text should be displayed in a disabled state.',
      defaultValue: { value: 'false', computed: false },
    },
    error: {
      type: { name: 'bool' },
      required: false,
      description:
        'If `true`, the label should be displayed in an error state.',
      defaultValue: { value: 'false', computed: false },
    },
    focused: {
      type: { name: 'bool' },
      required: false,
      description:
        'If `true`, the component will be displayed in focused state.',
    },
    fullWidth: {
      type: { name: 'bool' },
      required: false,
      description:
        'If `true`, the component will take up the full width of its container.',
      defaultValue: { value: 'false', computed: false },
    },
    hiddenLabel: {
      type: { name: 'bool' },
      required: false,
      description:
        'If `true`, the label will be hidden.\r\nThis is used to increase density for a `FilledInput`.\r\nBe sure to add `aria-label` to the `input` element.',
      defaultValue: { value: 'false', computed: false },
    },
    margin: {
      type: {
        name: 'enum',
        value: [
          { value: "'dense'", computed: false },
          { value: "'none'", computed: false },
          { value: "'normal'", computed: false },
        ],
      },
      required: false,
      description:
        'If `dense` or `normal`, will adjust vertical spacing of this and contained components.',
      defaultValue: { value: "'none'", computed: false },
    },
    required: {
      type: { name: 'bool' },
      required: false,
      description:
        'If `true`, the label will indicate that the input is required.',
      defaultValue: { value: 'false', computed: false },
    },
    size: {
      type: {
        name: 'enum',
        value: [
          { value: "'medium'", computed: false },
          { value: "'small'", computed: false },
        ],
      },
      required: false,
      description: 'The size of the text field.',
    },
    variant: {
      type: {
        name: 'enum',
        value: [
          { value: "'filled'", computed: false },
          { value: "'outlined'", computed: false },
          { value: "'standard'", computed: false },
        ],
      },
      required: false,
      description: 'The variant to use.',
      defaultValue: { value: "'standard'", computed: false },
    },
  },
  styles: {
    root: { description: 'Styles applied to the root element.' },
    marginNormal: {
      description: 'Styles applied to the root element if `margin="normal"`.',
    },
    marginDense: {
      description: 'Styles applied to the root element if `margin="dense"`.',
    },
    fullWidth: {
      description: 'Styles applied to the root element if `fullWidth={true}`.',
    },
  },
}
export default generatedDocs
