import React, { useEffect, useLayoutEffect, useState } from 'react'
import clsx from 'clsx'
import Checkbox from '../../core-components/Checkbox'
import Divider from '../../core-components/Divider'
import FormControl from '../../core-components/FormControl'
import FormControlLabel from '../../core-components/FormControlLabel'
import FormGroup from '../../core-components/FormGroup'
import FormLabel from '../../core-components/FormLabel'
import IconButton from '../../core-components/IconButton'
import Menu from '../../core-components/Menu'
import MenuItem from '../../core-components/MenuItem'
import Radio from '../../core-components/Radio'
import RadioGroup from '../../core-components/RadioGroup'
import makeStyles from '../../styles/makeStyles'
import Settings from '../../icons/Settings'

const densityOptions = {
  standard: 'Default View',
  compact: 'Compact View',
  comfortable: 'Comfortable View',
}

export default function SettingsMenu({
  apiRef,
  toolbarRef,
  disableDensity = false,
  hideableColumns: hideableColumnsProp = true,
  additionalControls = null,
  ...props
}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [density, setDensity] = useState('standard')
  const [hideableColumns, setHideableColumns] = useState([])
  const [toolbarHeight, setToolbarHeight] = useState(0)
  useStyles({ toolbarHeight })
  const menuOptions = []

  const handleSettingsMenuPlacement = () => {
    if (toolbarRef?.current) {
      setToolbarHeight(toolbarRef.current.clientHeight)
    }
  }

  useLayoutEffect(() => {
    handleSettingsMenuPlacement()
    window.addEventListener('resize', handleSettingsMenuPlacement)

    return () =>
      window.removeEventListener('resize', handleSettingsMenuPlacement)
  }, [toolbarRef])

  // Table Density
  // By default, table density settings are enabled and set to default view.
  // To disable this functionality, you can pass in disableDensity = true
  useEffect(() => {
    setDensity(apiRef.current.state.density.value)
  }, [apiRef.current])

  if (!disableDensity) {
    menuOptions.push(
      <FormControl component="fieldset">
        <FormLabel component="legend">Table Density</FormLabel>
        <RadioGroup
          value={density}
          onChange={(event) => {
            setDensity(event.target.value)
            apiRef.current.setDensity(event.target.value)
          }}
        >
          {Object.keys(densityOptions).map((option) => (
            <MenuItem key={option}>
              <FormControlLabel
                control={<Radio />}
                label={densityOptions[option]}
                value={option}
              />
            </MenuItem>
          ))}
        </RadioGroup>
      </FormControl>,
    )
  }

  // Hideable Columns
  // By default, it will show all columns with the first disabled.
  // To disable this functionality, you can pass in hideableColumns = false
  if (hideableColumnsProp) {
    // The state of the hideableColumns checkboxes needs to be saved somewhere for
    // React to know when to re-render, so we just save the whole column data
    useEffect(() => {
      if (hideableColumnsProp.length > 0) {
        setHideableColumns(
          hideableColumnsProp.map((column) =>
            apiRef.current.getColumn(column?.field),
          ),
        )
      } else {
        // Filter out columns used for table functionality e.g. checkbox, row menus etc.
        const dataColumns = Object.values(
          apiRef.current.state.columns.lookup,
        ).filter((col) => !col?.field?.startsWith('__'))
        const hideDataColumns = dataColumns.map((col) => ({
          field: col.field,
          headerName: col.headerName,
          hide: col.hide,
        }))
        setHideableColumns(hideDataColumns)
      }
    }, [apiRef.current?.state.columns.lookup])
  }

  if (hideableColumns.length > 0) {
    // disable the first column option if all columns are able to be hidden
    const disableFirstOption =
      apiRef.current.state.columns.all.filter((col) => !col?.startsWith('__'))
        .length === hideableColumns.length

    const handleItemClick = (event, column) => {
      apiRef.current.updateColumn({
        ...column,
        hide: !column?.hide,
      })
      setHideableColumns(
        hideableColumns.map((column) =>
          apiRef.current.getColumn(column?.field),
        ),
      )
    }

    menuOptions.push(
      <FormControl component="fieldset">
        <FormLabel component="legend">Manage Columns</FormLabel>
        <FormGroup>
          {hideableColumns.map((column, i) => (
            <MenuItem
              key={column?.field}
              disabled={disableFirstOption && i === 0}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!column?.hide}
                    value={column?.field}
                    disabled={disableFirstOption && i === 0}
                    onChange={(event) => handleItemClick(event, column)}
                  />
                }
                label={column?.headerName}
              />
            </MenuItem>
          ))}
        </FormGroup>
      </FormControl>,
    )
  }

  if (additionalControls) {
    menuOptions.push(additionalControls(apiRef))
  }

  return (
    menuOptions.length > 0 && (
      <div
        className={clsx('RichDataTable__SettingsMenu', {
          ['RichDataTable__SettingsMenu--default']: density === 'standard',
          ['RichDataTable__SettingsMenu--compact']: density === 'compact',
          ['RichDataTable__SettingsMenu--comfortable']:
            density === 'comfortable',
        })}
        {...props}
        style={{ top: toolbarHeight }}
      >
        <IconButton
          className="RichDataTable__SettingsMenu__icon"
          onClick={(event) => setAnchorEl(event.currentTarget)}
        >
          <Settings />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          MenuListProps={{
            className: 'RichDataTable__SettingsMenu__menu-list',
          }}
        >
          {menuOptions
            .map((option, i) => React.cloneElement(option, { key: i }))
            .reduce((prev, curr, i) => [
              prev,
              <Divider key={`${i}-divider`} variant="middle" />,
              curr,
            ])}
        </Menu>
      </div>
    )
  )
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.RichDataTable': {
      // .RichDataTable__SettingsMenu
      '&__SettingsMenu': {
        position: 'absolute',
        right: 0,
        zIndex: 1,
        /*
          Padding to fake center align the settings menu icon button to the table header
        */
        // .RichDataTable__SettingsMenu--default
        '&--default': {
          paddingTop: '10px',
        },
        // .RichDataTable__SettingsMenu--compact
        '&--compact': {
          paddingTop: '1.5px',
        },
        // .RichDataTable__SettingsMenu--comfortable
        '&--comfortable': {
          paddingTop: '18px',
        },

        // .RichDataTable__SettingsMenu__icon
        '&__icon': {
          backgroundColor: theme.palette.common.white,
          '&:hover': {
            background: `linear-gradient(${theme.palette.action.hover}, ${theme.palette.action.hover}), ${theme.palette.common.white}`,
          },
        },

        // .RichDataTable__SettingsMenu__menu-list
        '&__menu-list': {
          '& .MuiFormControl-root': {
            width: '100%',

            '& .MuiFormLabel-root': {
              padding: '16px',
              paddingBottom: '8px',
            },
          },
        },
      },
    },
  },
}))
