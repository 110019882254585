import React from 'react'
import Button from '@liveramp/motif/core/Button'
import ButtonFormGroup from '@liveramp/motif/core/ButtonFormGroup'
import Tooltip from '@liveramp/motif/core/Tooltip'

export default function InteractiveDemo() {
  return (
    <ButtonFormGroup>
      <Tooltip
        title={
          <>
            You <b>CANNOT</b> hover over me to click this{' '}
            <a href="http://shib.es" target="_blank" rel="noopener noreferrer">
              link
            </a>
          </>
        }
      >
        <Button variant="secondary">Non Interactive</Button>
      </Tooltip>
      <Tooltip
        title={
          <>
            You <b>CAN</b> hover over me to click this{' '}
            <a href="http://shib.es" target="_blank" rel="noopener noreferrer">
              link
            </a>
          </>
        }
        interactive
      >
        <Button variant="secondary">Interactive</Button>
      </Tooltip>
    </ButtonFormGroup>
  )
}
