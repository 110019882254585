import React, { useState } from 'react'
import Paper from '@liveramp/motif/core/Paper'
import Tabs from '@liveramp/motif/core/Tabs'
import Tab from '@liveramp/motif/core/Tab'
import TabPanel from './Panel'
import { Star, StarOutline } from '@liveramp/motif/icons'
import withStyles from '@liveramp/motif/styles/withStyles'
import makeStyles from '@liveramp/motif/styles/makeStyles'
import css from '../../../resources/Page.module.css'
import democss from './Custom.module.css'

function CustomDemo({ classes: withStylesClasses }) {
  let [value, setValue] = useState(1)
  let handleChange = (event, value) => {
    setValue(value)
  }

  let tabWithStylesClasses = {
    root: withStylesClasses.tab,
    selected: withStylesClasses.selected,
  }

  let makeStylesTabClasses = useTabStyles()
  let makeStylesTabsClasses = useTabsStyles()

  return (
    <div className={css.grid}>
      <Paper>
        <Tabs
          value={value}
          onChange={handleChange}
          className={democss.tabs}
          aria-label="CSS Modules"
          centered
          withBorder
        >
          <Tab
            value={1}
            label={'CSS'}
            className={democss.tab}
            {...a11yProps('1')}
          />
          <Tab
            value={2}
            label={'Modules'}
            className={democss.tab}
            {...a11yProps('2')}
          />
        </Tabs>
        <TabPanel id={'1'} selected={value === 1} bgIcon={<StarOutline />} />
        <TabPanel id={'2'} selected={value === 2} bgIcon={<Star />} />
      </Paper>
      <Paper>
        <Tabs
          value={value}
          onChange={handleChange}
          classes={{
            root: withStylesClasses.tabs,
            indicator: withStylesClasses.indicator,
          }}
          aria-label="JSS withStyles"
          centered
          withBorder
        >
          <Tab
            value={1}
            label={'JSS'}
            classes={tabWithStylesClasses}
            {...a11yProps('1')}
          />
          <Tab
            value={2}
            label={'withStyles'}
            classes={tabWithStylesClasses}
            {...a11yProps('2')}
          />
        </Tabs>
        <TabPanel id={'1'} selected={value === 1} bgIcon={<StarOutline />} />
        <TabPanel id={'2'} selected={value === 2} bgIcon={<Star />} />
      </Paper>
      <Paper>
        <Tabs
          value={value}
          onChange={handleChange}
          classes={makeStylesTabsClasses}
          aria-label="JSS makeStyles"
          centered
          withBorder
        >
          <Tab
            value={1}
            label={'JSS'}
            classes={makeStylesTabClasses}
            {...a11yProps('1')}
          />
          <Tab
            value={2}
            label={'makeStyles'}
            classes={makeStylesTabClasses}
            {...a11yProps('2')}
          />
        </Tabs>
        <TabPanel id={'1'} selected={value === 1} bgIcon={<StarOutline />} />
        <TabPanel id={'2'} selected={value === 2} bgIcon={<Star />} />
      </Paper>
    </div>
  )
}

let withStylesStyles = (theme) => ({
  tabs: {
    borderRadius: '0.25rem 0.25rem 0 0',
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
  tab: {
    color: theme.palette.primary.main,
    '&:hover, &.Mui-focus, &.Mui-focusVisible': {
      backgroundColor: theme.palette.primary.hover,
    },
    '&:active, &.Mui-active': {
      color: theme.palette.primary.main,
    },
  },
  selected: {
    color: theme.palette.primary.main,
  },
})

let useTabsStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '0.25rem 0.25rem 0 0',
  },
  indicator: { backgroundColor: theme.palette.primary.main },
}))

let useTabStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    '&:hover, &.Mui-focus, &.Mui-focusVisible': {
      backgroundColor: theme.palette.primary.hover,
    },
    '&:active, &.Mui-active': {
      color: theme.palette.primary.main,
    },
  },
  selected: {
    color: theme.palette.primary.main,
  },
}))

export default withStyles(withStylesStyles)(CustomDemo)

function a11yProps(tab) {
  return {
    id: `simple-tab-${tab}`,
    'aria-controls': `simple-tabpanel-${tab}`,
  }
}
