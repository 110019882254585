import React from 'react'
import { Subtitle, SectionTitle, NoticePanel } from '../../resources/Page'
import Demo, { generateDemos } from '../../resources/Demo'
import CodeChunk from '../../resources/CodeChunk'

import Hook from './demos/Hook'
import HigherOrderComponent from './demos/HigherOrderComponent'
import NestingSelectors from './demos/NestingSelectors'
import AdaptingWithProps from './demos/AdaptingWithProps'
import CSSModules from './demos/CSSModules'
import CSSModulesMuiSelector from './demos/CSSModulesMuiSelector'
import DynamicClassName from './demos/DynamicClassName'
import ClassTable from './demos/ClassTable'

let demoNames = [
  'Hook',
  'HigherOrderComponent',
  'NestingSelectors',
  'AdaptingWithProps',
  'CSSModules',
  'CSSModulesMuiSelector',
  'DynamicClassName',
]

export default function Style() {
  let demos = generateDemos('style/basics', demoNames)

  return (
    <div>
      <h1>Style</h1>
      <Subtitle>
        Overview of the ways Motif component styling can be overridden
      </Subtitle>
      <ul>
        <li>
          <span role="img" aria-label="puzzle">
            🧩 {''}
          </span>
          Utilize Motif's out of the box styling solution built with{' '}
          <a
            href="https://github.com/cssinjs/jss"
            target="_blank"
            rel="noreferrer"
          >
            JSS {''}
          </a>
          - a high performance JavaScript to CSS compiler which works at runtime
          and server-side
        </li>
        <li>
          <span role="img" aria-label="nails">
            💅 {''}
          </span>
          <a href="/style/basics#css-modules">CSS Modules</a> can be used to
          override default styling
        </li>
      </ul>
      <SectionTitle>Getting Started</SectionTitle>
      <NoticePanel variant="info">
        <p>
          Style overrides should be used in accordance with UX/Design; use
          overrides sparingly and with purpose when required to make something
          custom beyond the defaults provided by Motif.
        </p>
      </NoticePanel>
      <p>
        There are several APIs you can use to generate and apply styles, but
        they all share the same underlying logic. CSS can also be used to style.
      </p>
      <SectionTitle>Hook API - MakeStyles</SectionTitle>
      <p>
        The hook API is the preferred and current default stying solution in
        Motif. It is the recommended method for projects consuming Motif. This
        option can be used with functional components, and injects styles
        directly into the component.
      </p>
      <p>
        <code>makeStyles</code> expects an object with keys matching the
        component's custom classes prop; these can be found on the corresponding
        component API page under CSS classes.
      </p>
      <Demo demo={demos.Hook}>
        <Hook />
      </Demo>
      <SectionTitle>Higher-order component API - withStyles</SectionTitle>
      <p>
        Motif's original styling solution utilized the higher-order component
        API and is still available to use if needed. Use <code>withStyles</code>{' '}
        when you develop apps with a React version that don't support hooks yet,
        or when you style a class-based component.
      </p>
      <Demo demo={demos.HigherOrderComponent}>
        <HigherOrderComponent />
      </Demo>
      <SectionTitle>Class names</SectionTitle>
      <p>
        The makeStyles (hook generator) and withStyles (HOC) APIs allow the
        creation of multiple style rules per style sheet. Each style rule has
        its own class name. The class names are provided to the component with
        the classes variable. This is particularly useful when styling nested
        elements in a component.
      </p>
      <CodeChunk>
        {`function Nested(props) {
            const classes = useStyles();
            return (
              <button className={classes.root}> // 'jss1'
                <span className={classes.label}> // 'jss2'
                  nested
                </span>
              </button>
            );
          }

          function Parent() {
            return <Nested />
          }

          const useStyles = makeStyles({
            root: {}, // a style rule
            label: {}, // a nested style rule
          });
          `}
      </CodeChunk>
      <p>
        By default, the class names generated by{' '}
        <code>@material-ui/core/styles</code> are non-deterministic; you can't
        rely on them to stay the same. Let's take the following style as an
        example:
      </p>
      <CodeChunk>
        {`const useStyles = makeStyles({
            root: {
              opacity: 1,
            },
          });`}
      </CodeChunk>
      <p>
        This will generate a class name such as <code>makeStyles-root-123</code>
        . The non-deterministic nature of the class names enables style
        isolation. You can utilize the classNames generated by MUI and override
        them (note each component's API page lists these available class names,
        i.e. <a href="/api/button">Button API under Classes:MuiButton</a>).
      </p>
      <CodeChunk>
        {`.MuiButton-root { /* … */ }
          .MuiButton-label { /* … */ }
          .MuiButton-outlined { /* … */ }
          .MuiButton-outlined.Mui-disabled { /* … */ }
          .MuiButton-outlinedPrimary: { /* … */ }
          .MuiButton-outlinedPrimary:hover { /* … */ }`}
      </CodeChunk>
      <SectionTitle>Psuedo Classes</SectionTitle>
      <p>
        The component's special states, like hover, focus, disabled and
        selected, are styled with a higher CSS specificity.{' '}
        <a href="https://developer.mozilla.org/en-US/docs/Web/CSS/Specificity">
          Specificity is a weight
        </a>{' '}
        that is applied to a given CSS declaration.
      </p>
      <p>
        In order to override the components special states, you need to increase
        specificity. For consistency, Material-UI increases the specificity of
        its custom pseudo-classes. This has one important advantage, it allows
        you to cherry-pick the state you want to customize. that is applied to a
        given CSS declaration. You can rely on the global class names generated
        by Material-UI. It implements all these custom pseudo-classes:
      </p>
      <ClassTable />
      <CodeChunk>
        {`"&.MuiListItem-root:Mui-selected": {
            backgroundColor: theme.palette.grey[100]
          },`}
      </CodeChunk>
      <SectionTitle>Nesting Selectors</SectionTitle>
      <p>
        You can nest selectors to target elements inside the current class or
        component. The following example uses the Hook API, but it works the
        same way with the other APIs.
      </p>
      <CodeChunk>
        {`const useStyles = makeStyles({
            root: {
              color: 'blue',
              '& p': {
                color: 'green',
                '& span': {
                  color: 'yellow'
                }
              }
            },
          });`}
      </CodeChunk>
      <Demo demo={demos.NestingSelectors}>
        <NestingSelectors />
      </Demo>
      <SectionTitle>Adapting with props</SectionTitle>
      <p>
        You can pass a function to makeStyles ("interpolation") in order to
        adapt the generated value based on the component's props. The function
        can be provided at the style rule level, or at the CSS property level:
      </p>
      <Demo demo={demos.AdaptingWithProps}>
        <AdaptingWithProps />
      </Demo>
      <SectionTitle>CSS Modules</SectionTitle>
      <p>
        CSS Modules can be used, along with color and typography variables from
        the <a href="/theme">theme</a>. Components using the base Popover
        element will not work with CSS Modules (e.g. tooltips and menus), as
        these elements are added to and removed from the DOM as they appear and
        disappear. Use the <code>makeStyles</code> hook for these components.
      </p>
      <Demo demo={demos.CSSModules}>
        <CSSModules />
      </Demo>
      <p>
        .Mui generated class names can target classes for override with CSS
        modules.
      </p>
      <Demo demo={demos.CSSModulesMuiSelector}>
        <CSSModulesMuiSelector />
      </Demo>
      <SectionTitle>Dynamic CSS</SectionTitle>
      Here's an example of dynamically changing styling with
      <code>makeStyles</code>.
      <Demo demo={demos.DynamicClassName}>
        <DynamicClassName />
      </Demo>
    </div>
  )
}
