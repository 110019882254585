// this export is icon files w/o the js extension; include icon names in this export if an icon should be rendered in the display page under the Workflow category

let icons = [
  'WorkflowBuildAudience',
  'WorkflowBuildB2bAudience',
  'WorkflowBuyData',
  'WorkflowCreateSegment',
  'WorkflowConfigureSidecar',
  'WorkflowDataScience',
  'WorkflowDataStore',
  'WorkflowDistribute',
  'WorkflowEditDataStore',
  'WorkflowFileStatus',
  'WorkflowForecastingReport',
  'WorkflowManageAudience',
  'WorkflowManageDataSet',
  'WorkflowReports',
  'WorkflowSellData',
  'WorkflowSupport',
  'WorkflowUpload',
  'WorkflowUploadData',
  'WorkflowSQLEditor',
  'WorkflowDownloadActivityReports',
  'WorkflowCreateIntegrationConnection',
]

export default icons
