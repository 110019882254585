import React from 'react'
import Button from '@liveramp/motif/core/Button'
import ButtonFormGroup from '@liveramp/motif/core/ButtonFormGroup'

export default function OutlinedButtonDemo() {
  return (
    <ButtonFormGroup>
      <Button variant="outlined" color="primary">
        Button
      </Button>
      {/* <Button variant="outlined" color="secondary">
        Secondary
      </Button> */}
      <Button variant="outlined" disabled>
        Disabled
      </Button>
    </ButtonFormGroup>
  )
}
