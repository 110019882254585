import React from 'react'
import clsx from 'clsx'
import CardContent from '../../core-components/CardContent'
import { makeStyles, mergeClasses } from '../../utils'
import { CardContentStyle } from '../../utils/styleKeys'

export default function LogoCardContent({ classes, className, ...props }) {
  const defaultClasses = useStyles()
  return (
    <CardContent
      data-testid="LogoCardContent"
      className={clsx('LogoCardContent', 'LogoCardContent-root', className)}
      classes={mergeClasses(defaultClasses, classes)}
      {...props}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  ...CardContentStyle,
  root: {
    padding: '16px 14px', // -2px LR for border
  },
}))
