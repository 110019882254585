import OutlinedInput from '@material-ui/core/OutlinedInput'
import { OutlinedInputStyle } from '../../utils/styleKeys'
import { pxToRem as rem } from '../../utils'
export default OutlinedInput

export let styles = (theme) => ({
  ...OutlinedInputStyle,
  root: {
    padding: '8px',
    boxShadow: 'inset 0 0 0 1px #808080',

    '&.MuiOutlinedInput-adornedStart, &.MuiOutlinedInput-adornedEnd': {
      padding: '6px 8px',
      '&:not(.MuiOutlinedInput-adornedEnd) > .MuiSvgIcon-root': {
        color: theme.palette.text.secondary,
        paddingRight: '4px',
      },
      '&:not(.MuiOutlinedInput-adornedStart) > .MuiSvgIcon-root': {
        color: theme.palette.text.secondary,
        paddingLeft: '4px',
      },
    },
    '&.MuiAutocomplete-inputRoot': {
      padding: '9px 32px 8px 8px',
      '&:hover, &.Mui-focused': {
        paddingRight: '60px',
      },
    },
    'label + &': {
      marginTop: '18px',
    },
    '&:not(.Mui-error):not(.Mui-disabled):hover .MuiOutlinedInput-notchedOutline':
      {
        borderColor: theme.palette.primary.main,
      },
    '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderStyle: 'dotted',
    },
  },
  input: {
    '&, .MuiAutocomplete-inputRoot &.MuiAutocomplete-input, .MuiAutocomplete-inputRoot &.MuiAutocomplete-input:first-child':
      {
        padding: 'unset',
        minHeight: '20px',
      },
  },
  multiline: {
    // hack to nicely display the scroll bar,
    // and still prevent text from hitting the border
    padding: '8px',
    paddingRight: '2px',
  },
  notchedOutline: {
    '.MuiOutlinedInput-root.Mui-focused &': {
      borderWidth: rem(1),
    },
    '.MuiOutlinedInput-root.Mui-error &': {
      borderColor: theme.palette.error.dark,
    },
  },
})
