/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: 'MuiTreeItem',
  description: '',
  displayName: 'TreeItem',
  methods: [],
  props: {
    children: {
      type: { name: 'node' },
      required: false,
      description: 'The content of the component.',
    },
    classes: {
      type: { name: 'object' },
      required: false,
      description:
        'Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details.',
    },
    className: {
      type: { name: 'string' },
      required: false,
      description: '@ignore',
    },
    collapseIcon: {
      type: { name: 'node' },
      required: false,
      description: 'The icon used to collapse the node.',
    },
    endIcon: {
      type: { name: 'node' },
      required: false,
      description: 'The icon displayed next to a end node.',
    },
    expandIcon: {
      type: { name: 'node' },
      required: false,
      description: 'The icon used to expand the node.',
    },
    icon: {
      type: { name: 'node' },
      required: false,
      description: "The icon to display next to the tree node's label.",
    },
    label: {
      type: { name: 'node' },
      required: false,
      description: 'The tree node label.',
    },
    nodeId: {
      type: { name: 'string' },
      required: true,
      description: 'The id of the node.',
    },
    onClick: {
      type: { name: 'func' },
      required: false,
      description: '@ignore',
    },
    onFocus: {
      type: { name: 'func' },
      required: false,
      description: '@ignore',
    },
    onIconClick: {
      type: { name: 'func' },
      required: false,
      description:
        '`onClick` handler for the icon container. Call `event.preventDefault()` to prevent `onNodeToggle` from being called.',
    },
    onKeyDown: {
      type: { name: 'func' },
      required: false,
      description: '@ignore',
    },
    onLabelClick: {
      type: { name: 'func' },
      required: false,
      description:
        '`onClick` handler for the label container. Call `event.preventDefault()` to prevent `onNodeToggle` from being called.',
    },
    onMouseDown: {
      type: { name: 'func' },
      required: false,
      description: '@ignore',
    },
    TransitionComponent: {
      type: { name: 'elementType' },
      required: false,
      description:
        'The component used for the transition.\r\n[Follow this guide](/components/transitions/#transitioncomponent-prop) to learn more about the requirements for this component.',
      defaultValue: { value: 'Collapse', computed: true },
    },
    TransitionProps: {
      type: { name: 'object' },
      required: false,
      description:
        'Props applied to the [`Transition`](http://reactcommunity.org/react-transition-group/transition#Transition-props) element.',
    },
  },
  styles: {
    root: { description: 'Styles applied to the root element.' },
    expanded: {
      description: 'Pseudo-class applied to the root element when expanded.',
    },
    selected: {
      description: 'Pseudo-class applied to the root element when selected.',
    },
    group: { description: 'Styles applied to the `role="group"` element.' },
    content: { description: 'Styles applied to the tree node content.' },
    iconContainer: {
      description:
        'Styles applied to the tree node icon and collapse/expand icon.',
    },
    label: { description: 'Styles applied to the label element.' },
  },
}
export default generatedDocs
