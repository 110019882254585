import React, { useState } from 'react'
import List from '../../core-components/List'
import Collapse from '../../core-components/Collapse'
import ArrowDropDown from '../../icons/ArrowDropDown'
import ArrowDropUp from '../../icons/ArrowDropUp'
import withStyles from '../../styles/withStyles'

function L3Collapse({ classes, collapsed = false, children, label }) {
  const [collapse, setCollapse] = useState(!collapsed)

  const Arrow = collapse ? ArrowDropDown : ArrowDropUp
  return (
    <li className="GNL3Collapse">
      <div
        className="GNL3Collapse-label"
        onClick={() => setCollapse(!collapse)}
      >
        {label}
        <Arrow className="GNL3Collapse-caret GNL3Collapse-collapse" />
      </div>
      <Collapse className="GNL3Collapse-collapse" in={collapse} unmountOnExit>
        <List className="GNL3Collapse-list" disablePadding>
          {children}
        </List>
      </Collapse>
    </li>
  )
}

const styles = (theme) => ({
  '@global': {
    '.GNL3Collapse': {
      // .GNL3Collapse-label
      '&-label': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '6px 12px',
        fontSize: '14px',
        fontWeight: 600,
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
      },

      // .GNL3Collapse-collapse
      '&-collapse': {
        transition: theme.transitions.common.short,
      },
    },
  },
})

export default withStyles(styles)(L3Collapse)
