import React from 'react'
import {
  Page,
  Title,
  Subtitle,
  Supporting,
  SectionTitle,
} from '../../resources/DemoPage'
import Demo, { generateDemos } from '../../resources/Demo'
import CodeChunk from '../../resources/CodeChunk'

import Fonts from './demos/Fonts'
import Typography from './demos/Typography'
import Display from './demos/Display'

let demoNames = ['Fonts', 'Typography', 'Display']

export default function TypographyPage() {
  let demos = generateDemos('core-component-demo/typography', demoNames)

  return (
    <Page components="Typography">
      <Title>Typography</Title>
      <Subtitle>
        Use LiveRamp typography to present content as clearly and efficiently as
        possible
      </Subtitle>
      <Supporting>
        Too many type sizes and styles at once can spoil any layout. A
        typographic scale has a limited set of type sizes that work well
        together along with the layout grid. LiveRamp utilizes two typefaces for
        a variety of user interfaces.
      </Supporting>

      <SectionTitle>Supported Fonts</SectionTitle>
      <ul>
        <li>
          Liveramp sans is reserved for page titles and headers: <code>h1</code>
          , branded <code>h2</code>, branded <code>h3</code> and branded{' '}
          <code>h4</code>.
        </li>
        <li>
          Open sans is used for default Headers (aside from <code>h1</code>s),
          body text, subtitles and captions: <code>h2</code>,<code>h3</code>,
          <code>h4</code>,<code>h5</code>,<code>h6</code>,<code>p</code> and
          etc.
        </li>
        <li>IBM Plex Mono is used for code</li>
      </ul>
      <p>
        The base font size for LiveRamp web applications is 16px. This should be
        the default type size for most strings of text which are not headings or
        titles.
      </p>
      <CodeChunk>
        {`<link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700" rel="stylesheet" />
          <link href="https://cdn-motif.liveramp.com/fonts/liverampsans.css" rel="stylesheet" type="text/css" />
          <link href="https://cdn-motif.liveramp.com/fonts/IBMPlexMono.css" rel="stylesheet" type="text/css" />`}
      </CodeChunk>
      <br />
      <h2>How to consume type styles</h2>
      <p>
        There are a few ways of utilizing Motif's default typography which are
        covered below.
      </p>
      {/* TODO: when Motif styling docs page is updated to show newest ways to style, link there */}

      <SectionTitle>Hook API</SectionTitle>
      <p>
        First, we recommend using the <a href="/style/theme">theme object</a> to
        access typography variants for usage in <code>jsx</code> files. This is
        the <a href="https://material-ui.com/styles/basics/">CSS-in-JS </a>
        solution that is leveraging MUI's styling{' '}
        <a href="https://material-ui.com/styles/basics/#getting-started">
          hook API
        </a>{' '}
        that unlocks many great features (theme nesting, dynamic styles, etc).
        Motif components are by default styled this way.
      </p>
      <CodeChunk>
        {`import React from "react";
          import Component from "@liveramp/motif/core/Component";
          import makeStyles from "@liveramp/motif/styles/makeStyles";

          export default function Demo() {
            const classes = useStyles();

            return (
              <Component>
                <div className={classes.label}>
                  Label to be styled
                </div>
                <div className={classes.body}>
                  Body to be styled
                </div>
              </Component>
            );
          }

          let useStyles = makeStyles(theme => ({
            body: {
              ...theme.typography.body2,
            },
            label: {
              ...theme.typography.subtitle2,
            }
          }));`}
      </CodeChunk>
      <Demo demo={demos.Fonts}>
        <Fonts />
      </Demo>

      <SectionTitle>CSS and CSS modules</SectionTitle>
      <p>
        If you'd prefer to use CSS or are unable to utilize the above method,
        Motif has CSS and CSS module files that can be used to apply all of the
        typography variables.
      </p>
      <CodeChunk css>
        {`@value fontFamilyHeader, subtitle1FontWeight from "~@liveramp/motif/typography/typography.module.css";
       
       .root {
          font-family: fontFamilyHeader;
        }

        .label {
          font-weight: subtitle1FontWeight;
        } 
        `}
      </CodeChunk>
      <br />

      <SectionTitle>Global Stylesheet</SectionTitle>
      <p>
        A{' '}
        <a href="https://storage.googleapis.com/motif-global-style/global.module.css">
          global stylesheet
        </a>{' '}
        is available for use in any application with the same class names for
        each variant.
      </p>

      <SectionTitle>Typography Component</SectionTitle>
      <p>
        Additionally, you can use the Typography component to use the type
        styles defined in Motif's theme, along with being able to set display
        properties, alignment, color etc.
      </p>
      <Demo demo={demos.Typography}>
        <Typography />
      </Demo>
      <Demo demo={demos.Display}>
        <Display />
      </Demo>

      <SectionTitle>Accessibility</SectionTitle>
      <ul>
        <li>
          Color - Provide enough contrast between text and its background, check
          out the{' '}
          <a
            href="https://webaim.org/resources/contrastchecker/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Web Aim contrast checker
          </a>
          .
        </li>
        <li>
          Font size - Ideally use relative units (rem) to accommodate the user's
          browser settings.
        </li>
        <li>Heading hierarchy - Avoid using heading levels out of order.</li>
      </ul>
    </Page>
  )
}
