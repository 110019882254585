import React from 'react'
import Truncate from '@liveramp/motif/core/Truncate'
import makeStyles from '@liveramp/motif/styles/makeStyles'

export default function Styling() {
  const classes = useStyles()
  const children = 'Hello, I am styled with both Typography and className.'
  return (
    <div className={classes.container}>
      <Truncate variant="h2Branding">{children}</Truncate>
    </div>
  )
}

let useStyles = makeStyles({
  container: {
    display: 'grid',
    maxWidth: '600px',
  },
})
