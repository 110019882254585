import React from 'react'
import createSvgIcon from './utils/createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <g fillRule="evenodd">
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        d="M12.83 6.203c-1.235 0-3.497.513-4.24 1.537.04.142.066.29.08.44l.01.229v1.328h1.328a2.657 2.657 0 01.198 5.306l-.198.007h-.714c.179.13.382.266.61.405.021 3.137.226 7.196.615 7.684.372.248.924.334 1.529.36a.138.138 0 00.034.005h.083l.33.006h.335c.224.002.447.001.666-.006h.083a.138.138 0 00.034-.004c.604-.027 1.156-.113 1.529-.36.389-.489.593-4.548.614-7.685 1.038-.633 1.557-1.172 1.557-1.674V8.444c0-1.492-2.99-2.241-4.483-2.241zm6.725 1.224c-.32 0-.721.041-1.142.125.083.207.137.426.16.657l.01.235v5.337c0 .853-.462 1.533-1.336 2.199l-.12.087c.044 2.474.211 5.252.501 5.62.31.209.77.281 1.274.304l.015.002.083.001.276.006h.279c.186 0 .372 0 .554-.006h.07l.028-.003c.504-.023.964-.095 1.274-.304.324-.411.495-3.829.511-6.47.866-.533 1.298-.987 1.298-1.41V9.315c0-1.256-2.492-1.887-3.735-1.887zM6.023 7.08c-.734 0-1.329.595-1.329 1.329v2.656H2.038a1.328 1.328 0 100 2.657h2.658l-.002 2.656a1.328 1.328 0 102.657 0l.001-2.656h2.656a1.328 1.328 0 100-2.657H7.35V8.41c0-.734-.595-1.329-1.328-1.329zm13.531-4.506a1.87 1.87 0 00-1.861 1.887 1.87 1.87 0 001.861 1.887 1.875 1.875 0 001.868-1.887 1.875 1.875 0 00-1.868-1.887zM12.83.439a2.232 2.232 0 00-2.234 2.241 2.238 2.238 0 104.476 0A2.238 2.238 0 0012.83.44z"
        fillRule="nonzero"
      />
    </g>
  </React.Fragment>,
  'CreateAudience',
)
