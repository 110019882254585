import React from 'react'
import Status from '@liveramp/motif/core/Status'

export default function SimpleDemo() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        height: '150px',
        columnGap: '10px',
      }}
    >
      <Status variant="active" customLabel={'Distributing'} />
      <Status variant="error" customLabel={'Distribution Rejected'} />
      <Status variant="pending" customLabel={'Review'} />
      <Status variant="scheduled" customLabel={'Distribution Active'} />
    </div>
  )
}
