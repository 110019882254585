import React, { Fragment } from 'react'
import doctrine from 'doctrine'
import css from '../resources/Page.module.css'

// Create the value for the Name column
export function GenerateName({ required = false, children }) {
  let formattedName = <span className={css.blackCode}>{children}</span>
  if (children !== 'classes' && required) {
    formattedName = <Fragment>{formattedName}*</Fragment>
  }
  return formattedName
}

// Styles the description appropriately if it includes a function signature
export function generateDesc(desc) {
  let ast = doctrine.parse(desc)

  if (ast.tags.length <= 0) {
    return desc
  } else {
    let params = ast.tags.filter((t) => t.title === 'param')
    let paramsStr = []
    let paramsDetails = []
    params.forEach((t) => {
      let type = t.type.name ? t.type.name : t.type.type
      if (type === 'AllLiteral') type = '*'
      paramsStr.push(`${t.name}: ${type}`)
      paramsDetails.push(`- \`${t.name}<${type}>\`: ${t.description}`)
    })

    let returns = ast.tags.filter((t) => t.title === 'returns')
    let returnsStr = 'void'
    let returnsDetails = ''
    if (returns.length > 0) {
      // there shouldn't be more than one returning object
      // but throw warning just in case
      if (returns.length > 1) {
        console.log('This function has more than one return statement?!')
      }
      returnsStr = returns[0].type.name
      returnsDetails = `- Returns \`<${returnsStr}>\``
      if (returns[0].description) {
        returnsDetails += `: ${returns[0].description}`
      }
    }

    let source = [
      ast.description,
      '',
      '**Signature:**',
      '',
      `\`function(${paramsStr.join(', ')}) => ${returnsStr}\``,
      '',
      paramsDetails.join('\n'),
    ].join('\n')
    if (returnsDetails) {
      source += `\n${returnsDetails}`
    }

    return source
  }
}
