import React from 'react'

let NavDataStore = (props) => (
  <svg width={26} height={25} viewBox="0 0 26 26" {...props}>
    <g fillRule="nonzero">
      <path d="M9.646 19.949c0 1.5-1.089 2.751-2.484 2.751s-2.484-1.252-2.484-2.751c0-1.5 1.089-2.752 2.484-2.752s2.484 1.252 2.484 2.752zm-1.4 0c0-.767-.509-1.352-1.084-1.352-.575 0-1.084.585-1.084 1.352 0 .766.51 1.351 1.084 1.351.575 0 1.084-.585 1.084-1.351zM23.268 19.949c0 1.5-1.089 2.751-2.484 2.751S18.3 21.448 18.3 19.949c0-1.5 1.089-2.752 2.484-2.752s2.484 1.252 2.484 2.752zm-1.4 0c0-.767-.51-1.352-1.084-1.352-.575 0-1.084.585-1.084 1.352 0 .766.509 1.351 1.084 1.351.575 0 1.084-.585 1.084-1.351zM24.216 4.16l-1.138 10.054H6.166L4.923 3.182C4.733 1.467 3.01.214 1 .214a.7.7 0 1 0 0 1.4c1.349 0 2.427.784 2.532 1.724l1.313 11.654a.7.7 0 0 0 .696.622h18.162a.7.7 0 0 0 .695-.621l1.298-11.455A.7.7 0 0 0 25 2.759H7.162a.7.7 0 1 0 0 1.4h17.054z" />
    </g>
  </svg>
)

export default NavDataStore
