import React from 'react'
import createSvgIcon from './utils/createSvgIcon'

export default createSvgIcon(
  <g fill="none" fillRule="evenodd">
    <path
      d="M20.307 17.136c-.026.409-.36.719-.731.695h-4.581l-.003.048c-.091 1.307-1.31 2.329-2.784 2.329-1.493 0-2.723-1.048-2.787-2.378H4.76c-.346-.028-.628-.32-.653-.713v-1.71a.3.3 0 01.023-.115c.332-.793.748-1.556 1.324-2.478l.123-.196.863-1.35.171-.277.102-.17.093-.16.085-.152.083-.154v-.074c.026-2.859.983-4.704 2.576-5.702l.083-.05a5.217 5.217 0 011.856-.67c.224-.036.427-.055.596-.063l.12-.004h.104l.067.003.087-.002c.222-.003.508.018.836.082.55.106 1.093.31 1.603.632 1.58 1.001 2.52 2.93 2.515 5.983v-.075l.039.072.038.07.082.144.09.153.1.164.11.175.185.29.728 1.11.24.373c.552.872.948 1.592 1.255 2.326a.3.3 0 01.023.115zm-.54-.006v-1.365H6.482c-.15 0-.27-.134-.27-.3 0-.153.104-.28.238-.297l.032-.002h13.142a16.672 16.672 0 00-1.048-1.88l-.097-.153-.99-1.525-.119-.19-.108-.177-.021-.034a.247.247 0 01-.075.022l-.032.002h-4.352c-.15 0-.27-.134-.27-.3 0-.154.104-.28.238-.298l.032-.002h4.137a.28.28 0 01-.033-.096l-.004-.048c.005-2.872-.842-4.62-2.25-5.518a4.076 4.076 0 00-1.42-.563c-.318-.062-.588-.079-.782-.072l-.056.001-.034-.002-.08-.001-.095.002-.11.005-.12.01-.133.014a4.675 4.675 0 00-1.884.635c-1.495.9-2.389 2.623-2.383 5.429a.302.302 0 01-.033.137l-.079.152-.085.157-.093.163-.1.172-.11.183-.121.195-.132.21-.808 1.27-.15.242c-.506.828-.877 1.524-1.175 2.24l.023-.054v-.001 1.637a.17.17 0 00.16.16l14.764.002c.09.005.167-.067.173-.162zm-5.313.701l-4.491-.002v.03c.082 1.004 1.05 1.809 2.245 1.809 1.206 0 2.18-.82 2.246-1.837z"
      fill="#222"
      fillRule="nonzero"
    />
    <path
      d="M7.78 6.06a3.78 3.78 0 100 7.56 3.78 3.78 0 000-7.56z"
      fill="#22303B"
      fillRule="nonzero"
    />
    <path
      d="M7.78 6.6a3.24 3.24 0 110 6.48 3.24 3.24 0 010-6.48z"
      fill="#FFF"
      fillRule="nonzero"
    />
    <path
      d="M9.531 8.142a.269.269 0 00-.38 0L7.83 9.459 6.51 8.14a.269.269 0 10-.381.38l1.32 1.32-1.32 1.321a.269.269 0 10.38.38l1.321-1.32 1.32 1.32a.269.269 0 10.381-.38L8.21 9.84l1.32-1.32a.27.27 0 000-.378z"
      fill="#EB989E"
    />
  </g>,
  'NotificationsEmpty',
)
