import React, { useState } from 'react'
import Paper from '@liveramp/motif/core/Paper'
import Tabs from '@liveramp/motif/core/Tabs'
import Tab from '@liveramp/motif/core/Tab'
import TabPanel from './Panel'
import { Price, Settings, User, Permission } from '@liveramp/motif/icons'
import css from './TabsPanel.module.css'

let tabs = {
  users: 'users',
  billing: 'billing',
  settings: 'settings',
  admin: 'admin',
}

export default function TabsPanelDemo() {
  let [value, setValue] = useState(tabs.users)

  let handleChange = (event, value) => {
    setValue(value)
  }

  return (
    <Paper className={css.demo}>
      <Tabs
        withBorder
        value={value}
        onChange={handleChange}
        aria-label="Simple tabs"
      >
        <Tab value={tabs.users} label="Users" {...a11yProps('users')} />
        <Tab value={tabs.billing} label="Billing" {...a11yProps('billing')} />
        <Tab
          value={tabs.settings}
          label="Settings"
          {...a11yProps('settings')}
        />
        <Tab
          value={tabs.admin}
          label="Admin"
          {...a11yProps('admin')}
          disabled
        />
      </Tabs>
      <TabPanel
        id={'users'}
        selected={value === tabs.users}
        bgIcon={<User />}
      />
      <TabPanel
        id={'billing'}
        selected={value === tabs.billing}
        bgIcon={<Price />}
      />
      <TabPanel
        id={'settings'}
        selected={value === tabs.settings}
        bgIcon={<Settings />}
      />
      <TabPanel
        id={'admin'}
        selected={value === tabs.admin}
        bgIcon={<Permission />}
      />
    </Paper>
  )
}

function a11yProps(tab) {
  return {
    id: `simple-tab-${tab}`,
    'aria-controls': `simple-tabpanel-${tab}`,
  }
}
