import React from 'react'
import {
  Page,
  Title,
  Subtitle,
  Usage,
  NoticePanel,
  SectionTitle,
} from '../../resources/DemoPage'
import Demo, { generateDemos } from '../../resources/Demo'

import Simple from './demos/Simple'
import Selected from './demos/Selected'
import RightAligned from './demos/RightAligned'
import MaxHeightMenu from './demos/MaxHeightMenu'
import StaticWidthMenu from './demos/StaticWidthMenu'

let demoNames = [
  'Simple',
  'Selected',
  'RightAligned',
  'MaxHeightMenu',
  'StaticWidthMenu',
]

export default function Dialog() {
  let demos = generateDemos('core-component-demo/menu', demoNames)

  return (
    <Page components={['Menu', 'MenuList', 'MenuItem']}>
      <Title>Menu</Title>
      <Subtitle>Menus display a list of choices on temporary surfaces</Subtitle>
      <br />
      <Usage>
        <ul>
          <li>
            They typically appear when a user interacts with a button, or other
            similar control.
          </li>
        </ul>
      </Usage>

      <SectionTitle>Simple Menu</SectionTitle>
      <p>
        Simple menus open below the anchor element by default (this option can
        be changed via props <code>anchorOrigin</code> and{' '}
        <code>transformOrigin</code>). When close to a screen edge, simple menus
        vertically realign to make sure that all menu items are completely
        visible. Choosing an option should immediately ideally commit the option
        and close the menu.
      </p>
      <Demo demo={demos.Simple}>
        <Simple />
      </Demo>

      <SectionTitle>Selected Menu</SectionTitle>
      <p>
        If you want to use a Menu to display a selected item instead of
        immediately applying an action with a click or close, you can use the
        <code>selected</code> prop. When using the <code>selected</code> prop,
        when opened, the currently selected menu item will be in initial
        focus/highlighted in the Menu's list. The currently selected menu item
        is set using the <code>selected</code> property (from List/Item).
      </p>
      <Demo demo={demos.Selected}>
        <Selected />
      </Demo>

      <p>
        If the height of a menu prevents all menu items from being displayed,
        the menu can scroll internally.
      </p>
      <Demo demo={demos.MaxHeightMenu}>
        <MaxHeightMenu />
      </Demo>

      <SectionTitle>Staticly Set Menu Width</SectionTitle>
      <p>
        If you need to set your menu width statically, please be aware that
        there is a flexbox bug that prevents{' '}
        <code>text-overflow: ellipsis;</code> from working in a flexbox layout.
        You can use code in the example to truncate overflow text.
      </p>
      <NoticePanel variant="warning">
        If you truncate text, use a tooltip or label to show the full piece of
        text.
      </NoticePanel>
      <Demo demo={demos.StaticWidthMenu}>
        <StaticWidthMenu />
      </Demo>

      <SectionTitle subtitle>Right aligned Menu</SectionTitle>
      <p>
        For pages with Menu anchors that are aligned on the right side of the
        page or content area, align your Menu to the right to allow for adequate
        spacing and alignment.
      </p>
      <Demo demo={demos.RightAligned}>
        <RightAligned />
      </Demo>
    </Page>
  )
}
