/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: '',
  description:
    'Poppers rely on the 3rd party library [Popper.js](https://popper.js.org/docs/v1/) for positioning.',
  displayName: 'Popper',
  methods: [],
  props: {
    anchorEl: {
      type: {
        name: 'custom',
        raw: "chainPropTypes(PropTypes.oneOfType([HTMLElementType, PropTypes.object, PropTypes.func]), props => {\n  if (props.open) {\n    const resolvedAnchorEl = getAnchorEl(props.anchorEl);\n\n    if (resolvedAnchorEl && resolvedAnchorEl.nodeType === 1) {\n      const box = resolvedAnchorEl.getBoundingClientRect();\n\n      if (process.env.NODE_ENV !== 'test' && box.top === 0 && box.left === 0 && box.right === 0 && box.bottom === 0) {\n        return new Error(['Material-UI: The `anchorEl` prop provided to the component is invalid.', 'The anchor element should be part of the document layout.', \"Make sure the element is present in the document or that it's not display none.\"].join('\\n'));\n      }\n    } else if (!resolvedAnchorEl || typeof resolvedAnchorEl.clientWidth !== 'number' || typeof resolvedAnchorEl.clientHeight !== 'number' || typeof resolvedAnchorEl.getBoundingClientRect !== 'function') {\n      return new Error(['Material-UI: The `anchorEl` prop provided to the component is invalid.', 'It should be an HTML element instance or a referenceObject ', '(https://popper.js.org/docs/v1/#referenceObject).'].join('\\n'));\n    }\n  }\n\n  return null;\n})",
      },
      required: false,
      description:
        "A HTML element, [referenceObject](https://popper.js.org/docs/v1/#referenceObject),\r\nor a function that returns either.\r\nIt's used to set the position of the popper.\r\nThe return value will passed as the reference object of the Popper instance.",
    },
    children: {
      type: { name: 'union', value: [{ name: 'node' }, { name: 'func' }] },
      required: true,
      description: 'Popper render function or node.',
    },
    container: {
      type: {
        name: 'union',
        value: [
          { name: 'custom', raw: 'HTMLElementType' },
          { name: 'instanceOf', value: 'React.Component' },
          { name: 'func' },
        ],
      },
      required: false,
      description:
        "A HTML element, component instance, or function that returns either.\r\nThe `container` will have the portal children appended to it.\r\n\r\nBy default, it uses the body of the top-level document object,\r\nso it's simply `document.body` most of the time.",
    },
    disablePortal: {
      type: { name: 'bool' },
      required: false,
      description:
        "Disable the portal behavior.\r\nThe children stay within it's parent DOM hierarchy.",
      defaultValue: { value: 'false', computed: false },
    },
    keepMounted: {
      type: { name: 'bool' },
      required: false,
      description:
        'Always keep the children in the DOM.\r\nThis prop can be useful in SEO situation or\r\nwhen you want to maximize the responsiveness of the Popper.',
      defaultValue: { value: 'false', computed: false },
    },
    modifiers: {
      type: { name: 'object' },
      required: false,
      description:
        'Popper.js is based on a "plugin-like" architecture,\r\nmost of its features are fully encapsulated "modifiers".\r\n\r\nA modifier is a function that is called each time Popper.js needs to\r\ncompute the position of the popper.\r\nFor this reason, modifiers should be very performant to avoid bottlenecks.\r\nTo learn how to create a modifier, [read the modifiers documentation](https://popper.js.org/docs/v1/#modifiers).',
    },
    open: {
      type: { name: 'bool' },
      required: true,
      description: 'If `true`, the popper is visible.',
    },
    placement: {
      type: {
        name: 'enum',
        value: [
          { value: "'bottom-end'", computed: false },
          { value: "'bottom-start'", computed: false },
          { value: "'bottom'", computed: false },
          { value: "'left-end'", computed: false },
          { value: "'left-start'", computed: false },
          { value: "'left'", computed: false },
          { value: "'right-end'", computed: false },
          { value: "'right-start'", computed: false },
          { value: "'right'", computed: false },
          { value: "'top-end'", computed: false },
          { value: "'top-start'", computed: false },
          { value: "'top'", computed: false },
        ],
      },
      required: false,
      description: 'Popper placement.',
      defaultValue: { value: "'bottom'", computed: false },
    },
    popperOptions: {
      type: { name: 'object' },
      required: false,
      description:
        'Options provided to the [`popper.js`](https://popper.js.org/docs/v1/) instance.',
      defaultValue: { value: '{}', computed: false },
    },
    popperRef: {
      type: { name: 'custom', raw: 'refType' },
      required: false,
      description: 'A ref that points to the used popper instance.',
    },
    style: {
      type: { name: 'object' },
      required: false,
      description: '@ignore',
    },
    transition: {
      type: { name: 'bool' },
      required: false,
      description:
        'Help supporting a react-transition-group/Transition component.',
      defaultValue: { value: 'false', computed: false },
    },
  },
  styles: {},
}
export default generatedDocs
