/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: 'MuiListItemText',
  description: '',
  displayName: 'ListItemText',
  methods: [],
  props: {
    children: {
      type: { name: 'node' },
      required: false,
      description: 'Alias for the `primary` prop.',
    },
    classes: {
      type: { name: 'object' },
      required: false,
      description:
        'Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details.',
    },
    className: {
      type: { name: 'string' },
      required: false,
      description: '@ignore',
    },
    disableTypography: {
      type: { name: 'bool' },
      required: false,
      description:
        "If `true`, the children won't be wrapped by a Typography component.\r\nThis can be useful to render an alternative Typography variant by wrapping\r\nthe `children` (or `primary`) text, and optional `secondary` text\r\nwith the Typography component.",
      defaultValue: { value: 'false', computed: false },
    },
    inset: {
      type: { name: 'bool' },
      required: false,
      description:
        'If `true`, the children will be indented.\r\nThis should be used if there is no left avatar or left icon.',
      defaultValue: { value: 'false', computed: false },
    },
    primary: {
      type: { name: 'node' },
      required: false,
      description: 'The main content element.',
    },
    primaryTypographyProps: {
      type: { name: 'object' },
      required: false,
      description:
        'These props will be forwarded to the primary typography component\r\n(as long as disableTypography is not `true`).',
    },
    secondary: {
      type: { name: 'node' },
      required: false,
      description: 'The secondary content element.',
    },
    secondaryTypographyProps: {
      type: { name: 'object' },
      required: false,
      description:
        'These props will be forwarded to the secondary typography component\r\n(as long as disableTypography is not `true`).',
    },
  },
  styles: {
    root: { description: 'Styles applied to the root element.' },
    multiline: {
      description:
        'Styles applied to the `Typography` components if primary and secondary are set.',
    },
    dense: {
      description: 'Styles applied to the `Typography` components if dense.',
    },
    inset: {
      description: 'Styles applied to the root element if `inset={true}`.',
    },
    primary: {
      description: 'Styles applied to the primary `Typography` component.',
    },
    secondary: {
      description: 'Styles applied to the secondary `Typography` component.',
    },
  },
}
export default generatedDocs
