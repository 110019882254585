/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: 'MuiMenuItem',
  description: '',
  displayName: 'MenuItem',
  methods: [],
  props: {
    children: {
      type: { name: 'node' },
      required: false,
      description: 'Menu item contents.',
    },
    classes: {
      type: { name: 'object' },
      required: true,
      description:
        'Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details.',
    },
    className: {
      type: { name: 'string' },
      required: false,
      description: '@ignore',
    },
    component: {
      type: { name: 'elementType' },
      required: false,
      description:
        'The component used for the root node.\r\nEither a string to use a HTML element or a component.',
      defaultValue: { value: "'li'", computed: false },
    },
    dense: {
      type: { name: 'bool' },
      required: false,
      description:
        'If `true`, compact vertical padding designed for keyboard and mouse input will be used.',
    },
    disabled: {
      type: { name: 'bool' },
      required: false,
      description: '@ignore',
    },
    disableGutters: {
      type: { name: 'bool' },
      required: false,
      description: 'If `true`, the left and right padding is removed.',
      defaultValue: { value: 'false', computed: false },
    },
    ListItemClasses: {
      type: { name: 'object' },
      required: false,
      description:
        '`classes` prop applied to the [`ListItem`](/api/list-item/) element.',
    },
    role: {
      type: { name: 'string' },
      required: false,
      description: '@ignore',
      defaultValue: { value: "'menuitem'", computed: false },
    },
    selected: {
      type: { name: 'bool' },
      required: false,
      description: '@ignore',
    },
    tabIndex: {
      type: { name: 'number' },
      required: false,
      description: '@ignore',
    },
  },
  styles: {
    root: { description: 'Styles applied to the root element.' },
    gutters: {
      description:
        'Styles applied to the root element if `disableGutters={false}`.',
    },
    selected: {
      description: 'Styles applied to the root element if `selected={true}`.',
    },
    dense: { description: 'Styles applied to the root element if dense.' },
  },
}
export default generatedDocs
