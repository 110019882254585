import React from 'react'
import Skeleton from '@liveramp/motif/core/Skeleton'
import css from './Skeleton.module.css'

export default function SkeletonDemo() {
  return (
    <div className={css.root}>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </div>
  )
}
