import React, { Component } from 'react'
import Button from '@liveramp/motif/core/Button'
import ButtonFormGroup from '@liveramp/motif/core/ButtonFormGroup'
import Alert from '@liveramp/motif/core/Alert'

export default class CustomDemo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      info: false,
      success: false,
      warning: false,
      danger: false,
      privacy: false,

      icon: false,
    }
  }

  handleOpen = (type) => () => this.setState({ [type]: true })

  handleClose = (type) => (event, reason) => {
    if (reason === 'clickaway') return
    this.setState({ [type]: false })
  }

  handleToggleIcon = () => this.setState({ icon: !this.state.icon })

  render() {
    return (
      <ButtonFormGroup>
        <Button variant="secondary" onClick={this.handleOpen('info')}>
          Info
        </Button>
        <Alert
          open={this.state.info}
          onClickButton={this.handleClose('info')}
          onClickButtonLabel="Close Me"
          onClose={this.handleClose('info')}
        >
          My two primary function props are onClickButton and onClose
        </Alert>
        <Button variant="secondary" onClick={this.handleOpen('success')}>
          Success
        </Button>
        <Alert
          variant="success"
          open={this.state.success}
          onClickButton={() => alert('Hello World!')}
          onClickButtonLabel="Alert"
          onClose={this.handleClose('success')}
          style={{ top: '65px' }}
        >
          I do not have to close on onClickButton
        </Alert>
        <Button variant="secondary" onClick={this.handleOpen('warning')}>
          Warning
        </Button>
        <Alert
          variant="warning"
          open={this.state.warning}
          onClickButton={this.handleToggleIcon}
          onClickButtonLabel="Toggle Icon"
          onClose={this.handleClose('warning')}
          icon={this.state.icon}
          style={{ top: '130px' }}
        >
          I optionally come with icons by default for each color
        </Alert>
        <Button variant="secondary" onClick={this.handleOpen('danger')}>
          Danger
        </Button>
        <Alert
          variant="danger"
          open={this.state.danger}
          onClose={this.handleClose('danger')}
          style={{ top: '195px' }}
        >
          My default timeout is set to 6 seconds
        </Alert>
        <Button variant="secondary" onClick={this.handleOpen('privacy')}>
          Privacy
        </Button>
        <Alert
          variant="privacy"
          open={this.state.privacy}
          onClickButton={this.handleClose('privacy')}
          onClickButtonLabel="Close Me"
          onClose={this.handleClose('privacy')}
        >
          My two primary function props are onClickButton and onClose
        </Alert>
      </ButtonFormGroup>
    )
  }
}
