/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: 'MuiDialog',
  description:
    'Dialogs are overlaid modal paper based components with a backdrop.',
  displayName: 'Dialog',
  methods: [],
  props: {
    'aria-describedby': {
      type: { name: 'string' },
      required: false,
      description: 'The id(s) of the element(s) that describe the dialog.',
    },
    'aria-labelledby': {
      type: { name: 'string' },
      required: false,
      description: 'The id(s) of the element(s) that label the dialog.',
    },
    BackdropProps: {
      type: { name: 'object' },
      required: false,
      description: '@ignore',
    },
    children: {
      type: { name: 'node' },
      required: false,
      description: 'Dialog children, usually the included sub-components.',
    },
    classes: {
      type: { name: 'object' },
      required: false,
      description:
        'Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details.',
    },
    className: {
      type: { name: 'string' },
      required: false,
      description: '@ignore',
    },
    disableBackdropClick: {
      type: {
        name: 'custom',
        raw: "deprecatedPropType(PropTypes.bool, 'Use the onClose prop with the `reason` argument to filter the `backdropClick` events.')",
      },
      required: false,
      description:
        'If `true`, clicking the backdrop will not fire the `onClose` callback.\r\n@deprecated Use the onClose prop with the `reason` argument to filter the `backdropClick` events.',
      defaultValue: { value: 'false', computed: false },
    },
    disableEscapeKeyDown: {
      type: { name: 'bool' },
      required: false,
      description:
        'If `true`, hitting escape will not fire the `onClose` callback.',
      defaultValue: { value: 'false', computed: false },
    },
    fullScreen: {
      type: { name: 'bool' },
      required: false,
      description: 'If `true`, the dialog will be full-screen',
      defaultValue: { value: 'false', computed: false },
    },
    fullWidth: {
      type: { name: 'bool' },
      required: false,
      description:
        'If `true`, the dialog stretches to `maxWidth`.\r\n\r\nNotice that the dialog width grow is limited by the default margin.',
      defaultValue: { value: 'false', computed: false },
    },
    maxWidth: {
      type: {
        name: 'enum',
        value: [
          { value: "'lg'", computed: false },
          { value: "'md'", computed: false },
          { value: "'sm'", computed: false },
          { value: "'xl'", computed: false },
          { value: "'xs'", computed: false },
          { value: 'false', computed: false },
        ],
      },
      required: false,
      description:
        'Determine the max-width of the dialog.\r\nThe dialog width grows with the size of the screen.\r\nSet to `false` to disable `maxWidth`.',
      defaultValue: { value: "'sm'", computed: false },
    },
    onBackdropClick: {
      type: {
        name: 'custom',
        raw: "deprecatedPropType(PropTypes.func, 'Use the onClose prop with the `reason` argument to handle the `backdropClick` events.')",
      },
      required: false,
      description:
        'Callback fired when the backdrop is clicked.\r\n@deprecated Use the onClose prop with the `reason` argument to handle the `backdropClick` events.',
    },
    onClose: {
      type: { name: 'func' },
      required: false,
      description:
        'Callback fired when the component requests to be closed.\r\n\r\n@param {object} event The event source of the callback.\r\n@param {string} reason Can be: `"escapeKeyDown"`, `"backdropClick"`.',
    },
    onEnter: {
      type: {
        name: 'custom',
        raw: "deprecatedPropType(PropTypes.func, 'Use the `TransitionProps` prop instead.')",
      },
      required: false,
      description:
        'Callback fired before the dialog enters.\r\n@deprecated Use the `TransitionProps` prop instead.',
    },
    onEntered: {
      type: {
        name: 'custom',
        raw: "deprecatedPropType(PropTypes.func, 'Use the `TransitionProps` prop instead.')",
      },
      required: false,
      description:
        'Callback fired when the dialog has entered.\r\n@deprecated Use the `TransitionProps` prop instead.',
    },
    onEntering: {
      type: {
        name: 'custom',
        raw: "deprecatedPropType(PropTypes.func, 'Use the `TransitionProps` prop instead.')",
      },
      required: false,
      description:
        'Callback fired when the dialog is entering.\r\n@deprecated Use the `TransitionProps` prop instead.',
    },
    onEscapeKeyDown: {
      type: {
        name: 'custom',
        raw: "deprecatedPropType(PropTypes.func, 'Use the onClose prop with the `reason` argument to handle the `escapeKeyDown` events.')",
      },
      required: false,
      description:
        'Callback fired when the escape key is pressed,\r\n`disableKeyboard` is false and the modal is in focus.\r\n@deprecated Use the onClose prop with the `reason` argument to handle the `escapeKeyDown` events.',
    },
    onExit: {
      type: {
        name: 'custom',
        raw: "deprecatedPropType(PropTypes.func, 'Use the `TransitionProps` prop instead.')",
      },
      required: false,
      description:
        'Callback fired before the dialog exits.\r\n@deprecated Use the `TransitionProps` prop instead.',
    },
    onExited: {
      type: {
        name: 'custom',
        raw: "deprecatedPropType(PropTypes.func, 'Use the `TransitionProps` prop instead.')",
      },
      required: false,
      description:
        'Callback fired when the dialog has exited.\r\n@deprecated Use the `TransitionProps` prop instead.',
    },
    onExiting: {
      type: {
        name: 'custom',
        raw: "deprecatedPropType(PropTypes.func, 'Use the `TransitionProps` prop instead.')",
      },
      required: false,
      description:
        'Callback fired when the dialog is exiting.\r\n@deprecated Use the `TransitionProps` prop instead.',
    },
    open: {
      type: { name: 'bool' },
      required: true,
      description: 'If `true`, the Dialog is open.',
    },
    PaperComponent: {
      type: { name: 'elementType' },
      required: false,
      description: 'The component used to render the body of the dialog.',
      defaultValue: { value: 'Paper', computed: true },
    },
    PaperProps: {
      type: { name: 'object' },
      required: false,
      description: 'Props applied to the [`Paper`](/api/paper/) element.',
      defaultValue: { value: '{}', computed: false },
    },
    scroll: {
      type: {
        name: 'enum',
        value: [
          { value: "'body'", computed: false },
          { value: "'paper'", computed: false },
        ],
      },
      required: false,
      description: 'Determine the container for scrolling the dialog.',
      defaultValue: { value: "'paper'", computed: false },
    },
    TransitionComponent: {
      type: { name: 'elementType' },
      required: false,
      description:
        'The component used for the transition.\r\n[Follow this guide](/components/transitions/#transitioncomponent-prop) to learn more about the requirements for this component.',
      defaultValue: { value: 'Fade', computed: true },
    },
    transitionDuration: {
      type: {
        name: 'union',
        value: [
          { name: 'number' },
          {
            name: 'shape',
            value: {
              appear: { name: 'number', required: false },
              enter: { name: 'number', required: false },
              exit: { name: 'number', required: false },
            },
          },
        ],
      },
      required: false,
      description:
        'The duration for the transition, in milliseconds.\r\nYou may specify a single timeout for all transitions, or individually with an object.',
      defaultValue: {
        value:
          '{\n  enter: duration.enteringScreen,\n  exit: duration.leavingScreen\n}',
        computed: false,
      },
    },
    TransitionProps: {
      type: { name: 'object' },
      required: false,
      description:
        'Props applied to the [`Transition`](http://reactcommunity.org/react-transition-group/transition#Transition-props) element.',
    },
  },
  styles: {
    root: { description: 'Styles applied to the root element.' },
    scrollPaper: {
      description:
        'Styles applied to the container element if `scroll="paper"`.',
    },
    scrollBody: {
      description:
        'Styles applied to the container element if `scroll="body"`.',
    },
    container: { description: 'Styles applied to the container element.' },
    paper: { description: 'Styles applied to the `Paper` component.' },
    paperScrollPaper: {
      description:
        'Styles applied to the `Paper` component if `scroll="paper"`.',
    },
    paperScrollBody: {
      description:
        'Styles applied to the `Paper` component if `scroll="body"`.',
    },
    paperWidthFalse: {
      description:
        'Styles applied to the `Paper` component if `maxWidth=false`.',
    },
    paperWidthXs: {
      description:
        'Styles applied to the `Paper` component if `maxWidth="xs"`.',
    },
    paperWidthSm: {
      description:
        'Styles applied to the `Paper` component if `maxWidth="sm"`.',
    },
    paperWidthMd: {
      description:
        'Styles applied to the `Paper` component if `maxWidth="md"`.',
    },
    paperWidthLg: {
      description:
        'Styles applied to the `Paper` component if `maxWidth="lg"`.',
    },
    paperWidthXl: {
      description:
        'Styles applied to the `Paper` component if `maxWidth="xl"`.',
    },
    paperFullWidth: {
      description:
        'Styles applied to the `Paper` component if `fullWidth={true}`.',
    },
    paperFullScreen: {
      description:
        'Styles applied to the `Paper` component if `fullScreen={true}`.',
    },
  },
}
export default generatedDocs
