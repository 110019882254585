import React from 'react'
import clsx from 'clsx'
import Dialog from '../../core-components/Dialog'

export default function FullScreenDialog({ className, ...props }) {
  return (
    <Dialog
      className={clsx('FullScreenDialog-root', className)}
      fullScreen
      {...props}
    />
  )
}
