/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: 'MuiInputAdornment',
  description: '',
  displayName: 'InputAdornment',
  methods: [],
  props: {
    children: {
      type: { name: 'node' },
      required: true,
      description:
        'The content of the component, normally an `IconButton` or string.',
    },
    classes: {
      type: { name: 'object' },
      required: true,
      description:
        'Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details.',
    },
    className: {
      type: { name: 'string' },
      required: false,
      description: '@ignore',
    },
    component: {
      type: { name: 'elementType' },
      required: false,
      description:
        'The component used for the root node.\r\nEither a string to use a HTML element or a component.',
      defaultValue: { value: "'div'", computed: false },
    },
    disablePointerEvents: {
      type: { name: 'bool' },
      required: false,
      description:
        'Disable pointer events on the root.\r\nThis allows for the content of the adornment to focus the input on click.',
      defaultValue: { value: 'false', computed: false },
    },
    disableTypography: {
      type: { name: 'bool' },
      required: false,
      description:
        'If children is a string then disable wrapping in a Typography component.',
      defaultValue: { value: 'false', computed: false },
    },
    muiFormControl: {
      type: { name: 'object' },
      required: false,
      description: '@ignore',
    },
    position: {
      type: {
        name: 'enum',
        value: [
          { value: "'start'", computed: false },
          { value: "'end'", computed: false },
        ],
      },
      required: true,
      description:
        'The position this adornment should appear relative to the `Input`.',
    },
    variant: {
      type: {
        name: 'enum',
        value: [
          { value: "'standard'", computed: false },
          { value: "'outlined'", computed: false },
          { value: "'filled'", computed: false },
        ],
      },
      required: false,
      description:
        'The variant to use.\r\nNote: If you are using the `TextField` component or the `FormControl` component\r\nyou do not have to set this manually.',
    },
  },
  styles: {
    root: { description: 'Styles applied to the root element.' },
    filled: {
      description: 'Styles applied to the root element if `variant="filled"`.',
    },
    positionStart: {
      description: 'Styles applied to the root element if `position="start"`.',
    },
    positionEnd: {
      description: 'Styles applied to the root element if `position="end"`.',
    },
    disablePointerEvents: {
      description:
        'Styles applied to the root element if `disablePointerEvents=true`.',
    },
    hiddenLabel: {
      description:
        'Styles applied if the adornment is used inside <FormControl hiddenLabel />.',
    },
    marginDense: {
      description:
        'Styles applied if the adornment is used inside <FormControl margin="dense" />.',
    },
  },
}
export default generatedDocs
