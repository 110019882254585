import React from 'react'
import IconButton from '../IconButton'
import ClearIcon from '../../icons/Clear'

export default function CloseButton(props) {
  return (
    <IconButton size="small" {...props}>
      <ClearIcon />
    </IconButton>
  )
}
