import ToggleButton from '@material-ui/lab/ToggleButton'
import withStyles from '../../styles/withStyles'
import { ToggleButtonStyle } from '../../utils/styleKeys'
import { pxToRem as rem } from '../../utils'

let styles = (theme) => ({
  ...ToggleButtonStyle,
  root: {
    ...theme.typography.subtitle2,
    textTransform: 'capitalize',
    minHeight: rem(36),
    padding: `${rem(5)} ${rem(16)} ${rem(4)}`,
    // specifically only targetting the background-color prevents FUOC
    transition: theme.transitions.common.short,
    transitionProperty: 'background-color, border-color, color',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
    '&:active': {
      backgroundColor: theme.palette.secondary.active,
    },
    '&, &.Mui-selected': {
      color: theme.palette.text.primary,
      borderColor: theme.palette.common.black,
    },
    '&.Mui-disabled': {
      color: theme.palette.disabled.main,
      borderColor: theme.palette.disabled.light,
    },
    '&.MuiToggleButtonGroup-grouped.Mui-selected': {
      backgroundColor: theme.palette.primary.light,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
      '&:active': {
        backgroundColor: theme.palette.primary.active,
      },
    },
  },
})

export default withStyles(styles, { name: 'MuiToggleButton' })(ToggleButton)

import MotifToggleButton from './ToggleButton'
import ToggleButtonGroup from './ToggleButtonGroup'

export { MotifToggleButton as ToggleButton, ToggleButtonGroup }
