/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: 'MuiListSubheader',
  description: '',
  displayName: 'ListSubheader',
  methods: [],
  props: {
    children: {
      type: { name: 'node' },
      required: false,
      description: 'The content of the component.',
    },
    classes: {
      type: { name: 'object' },
      required: true,
      description:
        'Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details.',
    },
    className: {
      type: { name: 'string' },
      required: false,
      description: '@ignore',
    },
    color: {
      type: {
        name: 'enum',
        value: [
          { value: "'default'", computed: false },
          { value: "'primary'", computed: false },
          { value: "'inherit'", computed: false },
        ],
      },
      required: false,
      description:
        'The color of the component. It supports those theme colors that make sense for this component.',
      defaultValue: { value: "'default'", computed: false },
    },
    component: {
      type: { name: 'elementType' },
      required: false,
      description:
        'The component used for the root node.\r\nEither a string to use a HTML element or a component.',
      defaultValue: { value: "'li'", computed: false },
    },
    disableGutters: {
      type: { name: 'bool' },
      required: false,
      description: 'If `true`, the List Subheader will not have gutters.',
      defaultValue: { value: 'false', computed: false },
    },
    disableSticky: {
      type: { name: 'bool' },
      required: false,
      description:
        'If `true`, the List Subheader will not stick to the top during scroll.',
      defaultValue: { value: 'false', computed: false },
    },
    inset: {
      type: { name: 'bool' },
      required: false,
      description: 'If `true`, the List Subheader will be indented.',
      defaultValue: { value: 'false', computed: false },
    },
  },
  styles: {
    root: { description: 'Styles applied to the root element.' },
    colorPrimary: {
      description: 'Styles applied to the root element if `color="primary"`.',
    },
    colorInherit: {
      description: 'Styles applied to the root element if `color="inherit"`.',
    },
    gutters: {
      description:
        'Styles applied to the inner `component` element if `disableGutters={false}`.',
    },
    inset: {
      description: 'Styles applied to the root element if `inset={true}`.',
    },
    sticky: {
      description:
        'Styles applied to the root element if `disableSticky={false}`.',
    },
  },
}
export default generatedDocs
