import React, { useEffect, useState } from 'react'
import Header from '@liveramp/motif/core/Header'
import RichDataTable, {
  useGridApiRef,
} from '@liveramp/motif/core/RichDataTable'
import { columns, rows } from '../../demos/data'

export default function HeaderDemo() {
  const [visibleRows, setVisibleRows] = useState(null)
  const [totalRows, setTotalRows] = useState(null)
  const [pageSize, setPageSize] = useState(8)

  const apiRef = useGridApiRef()

  const handlePageChange = (page, pageCount, pageSize, rowCount) => {
    if (rowCount % pageSize === 0 || page !== pageCount - 1) {
      setVisibleRows(pageSize)
    } else {
      setVisibleRows(rowCount % pageSize)
    }
  }

  useEffect(
    () => {
      // Set initial values
      setVisibleRows(apiRef.current.state.pagination.pageSize)
      setTotalRows(apiRef.current.state.rows.totalRowCount)

      // On any page changes, update the total rows shown.
      // The timeout is to take into account an internal DataGrid debounce time
      // of 60ms; the api parameter will not contain up-to-date data otherwise.
      apiRef.current.subscribeEvent('pageChange', (page, event, { api }) => {
        setTimeout(() => {
          const { pageCount, pageSize, rowCount } = api.state.pagination
          handlePageChange(page, pageCount, pageSize, rowCount)
        }, 100)
      })
      apiRef.current.subscribeEvent(
        'pageSizeChange',
        (pageSize, event, { api }) => {
          setTimeout(() => {
            const { page, pageCount, rowCount } = api.state.pagination
            handlePageChange(page, pageCount, pageSize, rowCount)
          }, 100)
        },
      )
      apiRef.current.subscribeEvent(
        'filterModelChange',
        (params, event, { api }) => {
          setTimeout(() => {
            const { page, pageCount, pageSize, rowCount } = api.state.pagination
            handlePageChange(page, pageCount, pageSize, rowCount)
          }, 100)
        },
      )
    },
    // Ensure there is data to render before running this effect.
    // Because the data in this demo is static, eslint sees the rows dependency
    // as unnecessary, but this MUST be included to ensure the effect runs on
    // initial load if the data needs to be fetched.
    // eslint-disable-next-line
    [rows, apiRef],
  )

  return (
    <>
      <Header
        subtitle={
          visibleRows && totalRows
            ? `Showing ${visibleRows} out of ${totalRows} rows`
            : ''
        }
      >
        Boba Guys Milk Tea Menu
      </Header>
      <RichDataTable
        apiRef={apiRef}
        columns={columns}
        rows={rows}
        pageSize={pageSize}
        onPageSizeChange={(pageSize) => setPageSize(pageSize)}
        rowsPerPageOptions={[8, 10]}
        pagination
      />
    </>
  )
}
