import React from 'react'
import MuiTableRow from '@material-ui/core/TableRow'
import withStyles from '../../styles/withStyles'
import { TableRowStyle } from '../../utils/styleKeys'

function TableRow(props) {
  return <MuiTableRow hover {...props} />
}

let styles = (theme) => ({
  ...TableRowStyle,
  hover: {
    '&.MuiTableRow-root:hover': {
      backgroundColor: 'transparent',
    },
  },
})

export default withStyles(styles, { name: 'MuiTableRow' })(TableRow)
