import React from 'react'
import ColorPalette from '../../../style/colors/ColorPalette'
import theme from '@liveramp/motif/theme'
import css from './Color.module.css'

export default function ColorDemo() {
  let colorGrids = [
    theme.palette.primary,
    theme.palette.info,
    theme.palette.danger,
    theme.palette.pending,
    theme.palette.warning,
  ].map((palette) => (
    <ColorPalette
      key={palette.main}
      className={css.palette}
      color={palette}
      hideHue
    />
  ))

  return <div className={css.grid}>{colorGrids}</div>
}
