import React from 'react'
import createSvgIcon from './utils/createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <g fillRule="evenodd">
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        d="M11.17 3.58v9.21h9.17c0 5.087-4.106 9.21-9.17 9.21C6.105 22 2 17.877 2 12.79c0-5.086 4.105-9.21 9.17-9.21zM13.289 2C18.099 2 22 5.917 22 10.75h-8.711V2z"
        fillRule="nonzero"
      />
    </g>
  </React.Fragment>,
  'Segment',
)
