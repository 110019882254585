import React from 'react'
import PropTypes from 'prop-types'
import AlertContent from '../Alert/Content'

function AlertInline(props) {
  if (props.open === false) {
    return null
  }
  return <AlertContent showCloseIcon={false} icon {...props} />
}

AlertInline.propTypes = {
  onClickButton: PropTypes.func,
  onClickButtonLabel: PropTypes.string,
  variant: PropTypes.string,
  children: PropTypes.node,
}

AlertInline.defaultProps = {
  onClickButton: undefined,
  onClickButtonLabel: undefined,
  variant: 'default',
}

export default AlertInline
