import React, { Component } from 'react'
import IconButton from '@liveramp/motif/core/IconButton'
import ButtonFormGroup from '@liveramp/motif/core/ButtonFormGroup'
import css from './Simple.module.css'

import {
  SuperUniverse,
  Campaign,
  Delete,
  DeleteOutline,
  Star,
  StarHalf,
  StarOutline,
  ZoomIn,
  ZoomOut,
} from '@liveramp/motif/icons'

export default class SimpleDemo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      trash: true,
      star: 0,
    }

    this.starComponent = {
      0: <StarOutline />,
      1: <StarHalf />,
      2: <Star />,
    }
  }

  toggleTrash = () => this.setState({ trash: !this.state.trash })

  toggleStar = () => {
    if (this.state.star === 2) {
      this.setState({ star: 0 })
    } else {
      this.setState({ star: this.state.star + 1 })
    }
  }

  render({ trash, star } = this.state) {
    return (
      <ButtonFormGroup>
        <IconButton>
          <SuperUniverse />
        </IconButton>
        <IconButton size="small">
          <Campaign />
        </IconButton>
        <IconButton onClick={this.toggleTrash}>
          {trash ? <Delete /> : <DeleteOutline />}
        </IconButton>
        <IconButton onClick={this.toggleStar}>
          {this.starComponent[star]}
        </IconButton>
        <div className={css.coloredBg}>
          <IconButton light>
            <ZoomIn />
          </IconButton>
          <IconButton light>
            <ZoomOut />
          </IconButton>
        </div>
        <IconButton green>
          <ZoomIn />
        </IconButton>
        <IconButton green>
          <ZoomOut />
        </IconButton>
      </ButtonFormGroup>
    )
  }
}
