import React from 'react'
import { Page, Title, Subtitle, Supporting } from '../../resources/DemoPage'
import Demo, { generateDemos } from '../../resources/Demo'
import Simple from './demos/Simple'

let demoNames = ['Simple']

export default function ButtonMenu() {
  let demos = generateDemos('core-component-demo/button-menu', demoNames)

  return (
    <Page components={['Button', 'Menu', 'MenuList', 'MenuItem']}>
      <Title>Button Menu</Title>
      <Subtitle>
        A composed component that handles the placement and toggling of
        contextual menus containing related actions.
      </Subtitle>
      <Supporting>
        By default, the ButtonMenu appears as an outlined button with a right
        aligned menu, but can be customized as required.
      </Supporting>

      <Demo demo={demos.Simple}>
        <Simple />
      </Demo>
    </Page>
  )
}
