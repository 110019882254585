import React from 'react'
import PropTypes from 'prop-types'
import { Motif2DeprecationWarning } from '../../utils/internal'
import withStyles from '../../styles/withStyles'

function VerticalNav({ className, classes, ...props }) {
  Motif2DeprecationWarning('VerticalNav', 'GlobalNavigation')
  return (
    <div
      data-testid="VerticalNav"
      data-motif="VerticalNav"
      className={`VerticalNav ${className} VerticalNav-root`}
      {...props}
    />
  )
}

const styles = (theme) => ({
  '@global': {
    '.VerticalNav': {
      background: theme.palette.grey[900],
      display: 'flex',
      flexWrap: 'nowrap',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      width: '60px',
      padding: '10px 0',
      zIndex: '1100',
      position: 'fixed',
    },
  },
})

export default withStyles(styles, { name: 'MuiVerticalNav' })(VerticalNav)

VerticalNav.propTypes = {
  className: PropTypes.string,
}

VerticalNav.defaultProps = {
  className: '',
}

import MotifVerticalNav from './VerticalNav'
import Item from './Item'
import Divider from './Divider'

export { MotifVerticalNav as VerticalNav, Item, Divider }
