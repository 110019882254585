import React from 'react'
import Typography from '../../../core-components/Typography'
import Divider from '../../../core-components/Divider'
import Button from '../../../core-components/Button'
import { makeStyles } from '../../../utils'
import UploadIcon from '../../../icons/Upload'

const Dropzone = ({ inputProps, disabled, multiple }) => {
  useStyles()

  return (
    <div data-testid="UploaderDropzone" className="UploaderDropzone">
      <Typography variant="subtitle2" align="center">
        Drop file{`${multiple ? '(s)' : ''}`} here to upload
      </Typography>
      <div className="UploaderDropzone__divider-container">
        <Divider className="UploaderDropzone__divider" />
        <Typography
          variant="subtitle2"
          className="UploaderDropzone__divider-text"
        >
          or
        </Typography>
        <Divider className="UploaderDropzone__divider" />
      </div>
      <Button
        className="uploader-button"
        variant="outlined"
        disabled={disabled}
        size="small"
      >
        <UploadIcon />
        Browse File{`${multiple ? 's' : ''}`}
      </Button>
      <input {...inputProps} />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.UploaderDropzone': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      flex: 1,

      '& .uploader-button': {
        justifyContent: 'space-around',
      },

      // .UploaderDropzone__divider-container
      '&__divider-container': {
        display: 'flex',
        alignItems: 'center',
      },

      // .UploaderDropzone__divider
      '&__divider': {
        flex: 1,

        // .UploaderDropzone__divider-text
        '&__divider-text': {
          color: theme.palette.secondary.main,
          padding: '0 16px',
        },
      },
    },
  },
}))

export default Dropzone
