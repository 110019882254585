import React from 'react'
import createSvgIcon from './utils/createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path
      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-4.823-5h9.72c.942 0 1.687-.991 1.216-1.773L12.947 6.05c-.47-.782-1.449-.782-1.92 0l-4.923 9.176c-.471.782.13 1.773 1.073 1.773zM12 13.09c-.642 0-.933-.43-.933-.955v-2.21c0-.525.318-1.002.96-1.002.641 0 .96.477.96 1.002v2.21c0 .525-.345.955-.987.955zm.917 1.945h-1.804v-1.176h1.804v1.176z"
      fill="#FFD54F"
      fillRule="evenodd"
    />
  </React.Fragment>,
  'StatusWarning',
)
