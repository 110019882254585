import React from 'react'
import alpha from '@liveramp/motif/styles/alpha'
import lighten from '@liveramp/motif/styles/lighten'
import darken from '@liveramp/motif/styles/darken'
import makeStyles from '@liveramp/motif/styles/makeStyles'
import theme from '@liveramp/motif/theme'

export default function ColorManipulation() {
  const classes = useStyles()

  return (
    <div className={classes.grid}>
      <div className={classes.alpha}>alpha</div>
      <div className={classes.lighten}>lighten</div>
      <div className={classes.darken}>darken</div>
    </div>
  )
}

const useStyles = makeStyles({
  grid: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: '115px',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100px',
    },
  },
  alpha: {
    backgroundColor: alpha(theme.palette.info.main, 0.25),
  },
  lighten: {
    backgroundColor: lighten(theme.palette.info.main, 0.45),
  },
  darken: {
    backgroundColor: darken(theme.palette.info.main, 0.05),
  },
})
