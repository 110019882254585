import React from 'react'
import Paper from '@liveramp/motif/core/Paper'
import Header from '@liveramp/motif/core/Header'
import Chip from '@liveramp/motif/core/Chip'
import {
  CheckCircle,
  RadioButtonUnchecked,
  Universe,
} from '@liveramp/motif/icons'
import TextField from '@liveramp/motif/core/TextField'
import Button from '@liveramp/motif/core/Button'
import ButtonFormGroup from '@liveramp/motif/core/ButtonFormGroup'
import css from './Form.module.css'

export default function FormDemo() {
  return (
    <Paper className={css.body}>
      <Header>Share Universe with Partner</Header>
      <div>
        <Chip className={css.chip} icon={<Universe />}>
          Hello World!
        </Chip>
        {/* TODO: replace this with the vertical stepper! */}
        <div className={css.step}>
          <CheckCircle className={css.completed} />
          PARTNER
          <div className={css.stepSubText}>Nexus</div>
        </div>
        <div className={`${css.step} ${css.current}`}>
          <RadioButtonUnchecked />
          CONTACT INFO
        </div>
        <div className={css.step}>
          <RadioButtonUnchecked />
          THRESHOLDS
        </div>
        <div className={css.step}>
          <RadioButtonUnchecked />
          FILTER ATTRIBUTES
        </div>
      </div>
      <div className={css.form}>
        <TextField
          id="rep"
          label="Representative"
          helperText="Contact for the partner"
        />
        <TextField
          id="email"
          label="Email"
          helperText="Representative's email address"
        />
        <TextField
          id="phone"
          label="Phone"
          helperText="Representative's phone number"
        />
        <br />
        <ButtonFormGroup end>
          <Button variant="outlined">Cancel</Button>
          <Button>Save</Button>
        </ButtonFormGroup>
      </div>
    </Paper>
  )
}
