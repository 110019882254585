let orange = {
  50: '#ffece5',
  100: '#ffdAcb',
  200: '#ffb192',
  300: '#ff9166',
  400: '#ff7a45',
  500: '#ff6224',
  600: '#ed5227',
  700: '#da2b09',
  800: '#c82000',
  900: '#b81e00',
}

export default orange
