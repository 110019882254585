import React, { Component } from 'react'
import Card from '@liveramp/motif/core/Card'
import CardContent from '@liveramp/motif/core/CardContent'
import CardHeader from '@liveramp/motif/core/CardHeader'
import CardMedia from '@liveramp/motif/core/CardMedia'
import css from './Media.module.css'

class MediaDemo extends Component {
  render() {
    return (
      <Card className={css.card}>
        <CardHeader title="Card with Media" />
        <CardMedia
          title="wallpaper"
          alt="wallpaper"
          image={
            'https://storage.googleapis.com/liveramp-public-assets/img/landing-page/connect-illustration-cropped-reverse.svg'
          }
          className={css.media}
        />
        <CardContent>Welcome to Connect!</CardContent>
      </Card>
    )
  }
}

export default MediaDemo
