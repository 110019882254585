import InputAdornment from '@material-ui/core/InputAdornment'
import withStyles from '../../styles/withStyles'
import { InputAdornmentStyle } from '../../utils/styleKeys'

let styles = (theme) => ({
  ...InputAdornmentStyle,
  root: {
    height: '12px',
  },
  positionStart: {
    margin: '6px 4px 6px 0',
  },
  positionEnd: {
    margin: '6px 0 7px 4px',
  },
})

export default withStyles(styles, { name: 'MuiInputAdornment' })(InputAdornment)
