import React from 'react'
import MuiLink from '@material-ui/core/Link'
import withStyles from '../../styles/withStyles'

const Link = React.forwardRef(function Link(props, ref) {
  return <MuiLink ref={ref} underline="always" {...props} />
})

const styles = (theme) => ({
  root: {
    color: theme.palette.text.link,
    '&:hover': {
      color: theme.palette.text.hoverLink,
    },
    '&:visited': {
      color: theme.palette.text.visitedLink,
    },
  },
})

export default withStyles(styles, { name: 'MuiLink' })(Link)
