import React from 'react'
import Tooltip from '../../../core-components/Tooltip'
import Typography from '../../../core-components/Typography'

const FileName = ({ name = '', maxChar }) => {
  if (name.length <= maxChar) {
    return <span>{name}</span>
  }
  const firstChunk = name.slice(0, Math.floor(maxChar * 0.625))
  const secondChunk = name.slice(-Math.floor(maxChar * 0.25))

  return (
    <Typography variant={'subtitle2'}>
      <Tooltip title={name}>{`${firstChunk}...${secondChunk}`}</Tooltip>
    </Typography>
  )
}

export default FileName
