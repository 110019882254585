import React from 'react'
import Button from '@liveramp/motif/core/Button'
import Tooltip from '@liveramp/motif/core/Tooltip'

export default function ShowingHidingDemo() {
  return (
    <Tooltip title="I'm a delayed tooltip" enterDelay={500} leaveDelay={500}>
      <Button variant="secondary">500ms Show Delay, 500ms Hide Delay</Button>
    </Tooltip>
  )
}
