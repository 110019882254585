import React, { useState } from 'react'
import css from './StickyFilterBar.module.css'

//for the filters
import Filter from '@liveramp/motif/core/Filter'
import Dialog from '@liveramp/motif/core/Dialog'
import Button from '@liveramp/motif/core/Button'

//for the logo cards
import LogoCardGroup from '@liveramp/motif/core/LogoCardGroup'
import LogoCard from '@liveramp/motif/core/LogoCard'
import LogoCardHeader from '@liveramp/motif/core/LogoCardHeader'
import LogoCardMedia from '@liveramp/motif/core/LogoCardMedia'
import Tooltip from '@liveramp/motif/core/Tooltip'

import StatusSuccess from '@liveramp/motif/icons/StatusSuccess'
import StatusPending from '@liveramp/motif/icons/StatusPending'
import StatusStopped from '@liveramp/motif/icons/StatusStopped'
import Facebook from '@liveramp/motif/icons/Facebook'
import Twitter from '@liveramp/motif/icons/Twitter'
import Snapchat from '@liveramp/motif/icons/Snapchat'
import LinkedIn from '@liveramp/motif/icons/LinkedIn'
import Microsoft from '@liveramp/motif/icons/Microsoft'

let options = []
for (let i = 1; i <= 20; i++) {
  options.push({
    label: `Option ${i}`,
    optCount: Math.floor(Math.random() * 100) + 1,
  })
}

const logoCards = (
  <LogoCardGroup>
    <LogoCard key="facebookSm" id="facebookSm" defaultChecked>
      <LogoCardHeader
        title="Facebook - DefaultChecked"
        status={
          <Tooltip title="Success">
            <StatusSuccess />
          </Tooltip>
        }
      />
      <LogoCardMedia>
        <Facebook alt="Facebook Logo" title="Facebook Logo" />
      </LogoCardMedia>
    </LogoCard>
    <LogoCard key="twitterSm" id="twitterSm" checked>
      <LogoCardHeader
        title="Twitter - Checked"
        status={
          <Tooltip title="Success">
            <StatusSuccess />
          </Tooltip>
        }
      />
      <LogoCardMedia>
        <Twitter alt="Twitter Logo" title="Twitter Logo" />
      </LogoCardMedia>
    </LogoCard>
    <LogoCard key="snapchatSm" id="snapchatSm">
      <LogoCardHeader
        title="Snapchat - Unchecked"
        status={
          <Tooltip title="Pending">
            <StatusPending />
          </Tooltip>
        }
      />
      <LogoCardMedia>
        <Snapchat alt="Snapchat Logo" title="Snapchat Logo" />
      </LogoCardMedia>
    </LogoCard>
    <LogoCard key="microsoftSm" id="microsoftSm" checked disabled>
      <LogoCardHeader
        title="Microsoft - Disabled Checked"
        status={
          <Tooltip title="Success">
            <StatusSuccess />
          </Tooltip>
        }
      />
      <LogoCardMedia>
        <Microsoft alt="Microsoft Logo" title="Microsoft Logo" />
      </LogoCardMedia>
    </LogoCard>
    <LogoCard key="linkedinSm" id="linkedinSm" disabled>
      <LogoCardHeader
        title="LinkedIn - Disabled Unchecked"
        status={
          <Tooltip title="Stopped">
            <StatusStopped />
          </Tooltip>
        }
      />
      <LogoCardMedia>
        <LinkedIn alt="LinkedIn Logo" title="LinkedIn Logo" />
      </LogoCardMedia>
    </LogoCard>
  </LogoCardGroup>
)

export default function StickyFilterBar() {
  let [value, setValue] = useState([options[0], options[1]])
  let [open, setOpen] = useState(false)

  return (
    <div className={css.containDemo}>
      <h2>Logo Card Collection</h2>
      <div className={css.stickyFilter}>
        <Filter
          id="filter-uncontrolled-demo"
          label="Uncontrolled"
          options={options}
          defaultValue={value}
          getOptionLabel={(option) => option.label}
        />
        <Filter
          id="filter-controlled-demo"
          label="Controlled"
          options={options}
          value={value}
          onChange={(e, v) => setValue(v)}
          getOptionLabel={(option) => option.label}
        />
        <Button
          variant="secondary"
          color="primary"
          onClick={() => setOpen(true)}
        >
          Dialog
        </Button>
        <Dialog open={open} onClose={() => setOpen(false)} size="small">
          <Filter
            id="filter-uncontrolled-demo"
            label="Uncontrolled"
            options={options}
            defaultValue={value}
            getOptionLabel={(option) => option.label}
          />
          <br />
          <Filter
            id="filter-controlled-demo"
            label="Controlled"
            options={options}
            value={value}
            onChange={(e, v) => setValue(v)}
            getOptionLabel={(option) => option.label}
          />
        </Dialog>
        <Button variant="primary" color="primary">
          Action 1
        </Button>
        <Button variant="primary" color="secondary">
          Action 2
        </Button>
      </div>
      <div>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </p>
        {logoCards}
        <br></br>
        {logoCards}
        <br></br>
        {logoCards}
        <br></br>
        {logoCards}
        <br></br>
        {logoCards}
      </div>
    </div>
  )
}
