import React from 'react'
import Drawer from '@liveramp/motif/core/Drawer'
import Button from '@liveramp/motif/core/Button'
import Settings from '@liveramp/motif/icons/Settings'
import User from '@liveramp/motif/icons/User'
import css from './Simple.module.css'
import Favorite from '@liveramp/motif/icons/Favorite'

export default function Simple() {
  const [open, setOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setOpen(!open)
  }

  return (
    <div>
      <Button variant="text" onClick={handleDrawerToggle}>
        Open Drawer
      </Button>
      <Drawer anchor={'bottom'} open={open} onClick={handleDrawerToggle}>
        <div className={css.content}>
          <User />
          <Settings />
          <Favorite />
        </div>
      </Drawer>
    </div>
  )
}
