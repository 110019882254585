import React, { useState } from 'react'
import clsx from 'clsx'
import Table from '@liveramp/motif/core/Table'
import TableHeader from '@liveramp/motif/core/TableHeader'
import TableBody from '@liveramp/motif/core/TableBody'
import TableRow from '@liveramp/motif/core/TableRow'
import TableCell from '@liveramp/motif/core/TableCell'
import TableFooter from '@liveramp/motif/core/TableFooter'
import Checkbox from '@liveramp/motif/core/Checkbox'
import Tooltip from '@liveramp/motif/core/Tooltip'
import { Check, Clear } from '@liveramp/motif/icons'
import makeStyles from '@liveramp/motif/styles/makeStyles'

export default function CellTooltipsDemo() {
  let classes = useStyles()
  let [checkedItems, setCheckedItems] = useState([])
  let data = [
    {
      name: 'Cappuccino',
      temp: 'Hot',
      caffeine: true,
      desc: 'A cappuccino is an espresso-based coffee drink that originated in Italy, and is traditionally prepared with steamed milk foam (microfoam).',
    },
    {
      name: 'Latte',
      temp: 'Hot',
      caffeine: true,
      desc: 'Caffe latte is a coffee drink made with espresso and steamed milk. The term comes from the Italian caffè e latte, caffelatte or caffellatte, which means "coffee and milk".',
    },
    {
      name: 'Cortado',
      temp: 'Hot',
      caffeine: true,
      desc: 'A cortado is a beverage consisting of espresso mixed with a roughly equal amount of warm milk to reduce the acidity. The milk in a cortado is steamed, but not frothy and "texturized" as in many Italian coffee drinks.',
    },
    {
      name: 'Flat White',
      temp: 'Hot',
      caffeine: true,
      desc: 'A flat white is a coffee drink consisting of espresso with microfoam (steamed milk with small, fine bubbles and a glossy or velvety consistency). It is comparable to a latte, but smaller in volume and with less microfoam, therefore having a higher proportion of coffee to milk, and milk that is more velvety in consistency – allowing the espresso to dominate the flavour, while being supported by the milk.',
    },
    {
      name: 'Affogato',
      temp: 'Cold',
      caffeine: false,
      desc: 'An affogato or more traditionally known as "affogato al caffe" (Italian for "drowned") is an Italian coffee-based dessert. It usually takes the form of a scoop of fior di latte or vanilla gelato or ice cream topped or "drowned" with a shot of hot espresso. Some variations also include a shot of amaretto, Bicerin, Kahlua, or other liqueur.',
    },
    {
      name: 'Cold Brew',
      temp: 'Cold',
      caffeine: true,
      desc: 'Cold brewing, also called cold water extraction or cold pressing, is the process of steeping coffee grounds in water at cool temperatures for an extended period. Coarse-ground beans are soaked in water for about 12 to 24 hours.',
    },
    {
      name: 'Decaf Latte',
      temp: 'Hot',
      caffeine: false,
      desc: 'The caffeine content in coffee beans may be reduced via one of several decaffeination processes to produce decaffeinated coffee, also known as "decaf" coffee, which may be served as regular, espresso or instant coffee.',
    },
  ]

  let handleToggleAll = () => {
    if (checkedItems.length !== data.length) {
      setCheckedItems(data.map((item) => item.name))
    } else {
      setCheckedItems([])
    }
  }

  let toggleItem = (name) => {
    if (checkedItems.includes(name)) {
      setCheckedItems(checkedItems.filter((item) => item !== name))
    } else {
      setCheckedItems([...checkedItems, name])
    }
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              checked={checkedItems.length > 0}
              indeterminate={checkedItems.length < data.length}
              onChange={handleToggleAll}
            />
          </TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Caffeine</TableCell>
          <TableCell>Temperature</TableCell>
          <TableCell align="right">Price</TableCell>
          <TableCell align="center">Available</TableCell>
          <TableCell className={classes.descWidth}>Description</TableCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        {data.map((row, i) => (
          <TableRow key={i}>
            <TableCell padding="checkbox">
              <Checkbox
                checked={checkedItems.includes(row.name)}
                onChange={() => toggleItem(row.name)}
              />
            </TableCell>
            <TableCell>{row.name}</TableCell>
            <TableCell>{row.caffeine ? 'High' : 'Low'}</TableCell>
            <TableCell>{row.temp}</TableCell>
            <TableCell align="right">
              ${(Math.random() * 6 + 1).toFixed(2)}
            </TableCell>
            <TableCell align="center">
              {Math.random() < 0.5 ? (
                <Check className={classes.check} />
              ) : (
                <Clear className={classes.clear} />
              )}
            </TableCell>
            <TableCell>
              <Tooltip title={row.desc}>
                <div className={clsx(classes.descWidth, classes.cellOverflow)}>
                  {row.desc}
                </div>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCell />
          <TableCell colSpan={6}>
            Showing rows <b>1</b> - <b>{data.length}</b>
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  )
}

let useStyles = makeStyles((theme) => ({
  descWidth: {
    maxWidth: '450px',
  },
  cellOverflow: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  check: {
    color: theme.palette.primary.main,
  },
  clear: {
    color: theme.palette.error.main,
  },
}))
