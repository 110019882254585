import React, { useState } from 'react'
import Select from '@liveramp/motif/core/Select'
import { films } from './data'
import css from './States.module.css'

export default function SingleStatesDemo() {
  let [controlled, setControlled] = useState(films[62])
  return (
    <div
      className={css.grid}
      style={{
        gridTemplateColumns: 'repeat(auto-fit, 250px)',
      }}
    >
      <Select
        id="single-controlled-input"
        label="value"
        options={films}
        getOptionLabel={(film) => film.title}
        helperText="This input is controlled"
        value={controlled}
        onChange={(event, value) => setControlled(value)}
      />
      <Select
        id="single-helper-input"
        label="label, helperText"
        options={films}
        getOptionLabel={(film) => film.title}
        helperText="This is your helper text"
      />
      <Select
        id="single-default-value-input"
        label="defaultValue"
        options={films}
        getOptionLabel={(film) => film.title}
        defaultValue={films[30]}
        helperText="This input is uncontrolled and has a default value"
      />
      <Select
        id="single-placeholder-input"
        label="placeholder"
        options={films}
        getOptionLabel={(film) => film.title}
        placeholder="Hello World"
        helperText="This input has a placeholder"
      />
      <Select
        id="single-required-input"
        label="required"
        options={films}
        getOptionLabel={(film) => film.title}
        helperText="This input is marked as required"
        required
      />
      <Select
        id="single-error-input"
        label="error"
        options={films}
        getOptionLabel={(film) => film.title}
        helperText="This input has an error"
        error
      />
      <Select
        id="single-disabled-input"
        label="disabled"
        options={films}
        getOptionLabel={(film) => film.title}
        helperText="This input is disabled"
        disabled
      />
      {/* TODO: this does not work */}
      {/* <Select
        id="single-readonly-input"
        label="readOnly"
        options={films}
        getOptionLabel={film => film.title}
        defaultValue={films[49]}
        helperText="This input is read-only"
        readOnly
      /> */}
      <Select
        id="single-multiline-input"
        label="multiline, maxRows"
        options={films}
        getOptionLabel={(film) => film.title}
        helperText="This input is multiline with max rows. Select a long title to see it expand"
        multiline
        maxRows="4"
      />
      <Select
        id="single-fullwidth-input"
        label="fullWidth"
        options={films}
        getOptionLabel={(film) => film.title}
        helperText="This input takes up the full width of its container"
        fullWidth
      />
    </div>
  )
}
