import * as React from 'react'
import createSvgIcon from './utils/createSvgIcon'

export default createSvgIcon(
  <g fill="none" fillRule="evenodd">
    <path
      d="M18.08 11.2a3.78 3.78 0 110 7.56 3.78 3.78 0 010-7.56zm.02.5a3.3 3.3 0 100 6.6 3.3 3.3 0 000-6.6z"
      fill="#222"
      fillRule="nonzero"
    />
    <path
      d="M17.535 16.086l-.829-.883a.228.228 0 00-.336 0 .265.265 0 000 .359l.998 1.064c.093.099.243.099.336 0l2.526-2.693a.265.265 0 000-.359.228.228 0 00-.336 0l-2.36 2.512z"
      fill="#222"
    />
    <path
      d="M18.383 18.362c0 .352-.273.64-.619.663l-.043.001H8.657a.23.23 0 01-.03-.459l.03-.002h9.064c.067 0 .288-.068.662-.203zM12.591 5c1.622 0 2.808 1.293 2.808 3.052v1.411c0 1.108-.47 2.03-1.224 2.562v1.035l.611.086c.214.354.214.354-.158.443l-.716-.1a.23.23 0 01-.197-.2l-.002-.028.001-.975c-.34.148-.718.23-1.123.23-.462 0-.888-.105-1.262-.296v1.041a.23.23 0 01-.172.223l-.028.006-3.209.43c-.537.118-.905.521-.93 1.022l-.002.056v3.798a.23.23 0 01-.46.029l-.001-.03v-3.797c0-.73.502-1.325 1.245-1.515l.068-.016 3.028-.408v-1.046a.23.23 0 01.015-.082c-.681-.541-1.1-1.422-1.1-2.468v-1.41C9.783 6.292 10.968 5 12.59 5zm-4.599.544c.363-.015.723.069 1.045.243a.23.23 0 11-.218.406 1.57 1.57 0 00-.73-.189l-.087.001c-1.034 0-1.796.8-1.827 1.954V9.214c0 1.193.771 2.026 1.831 2.026.181.003.361-.028.531-.093a.23.23 0 11.164.431 1.867 1.867 0 01-.7.123c-.37 0-.712-.083-1.013-.233v.737a.23.23 0 01-.173.223l-.028.006-2.626.331a.893.893 0 00-.699.84v2.845c0 .11.079.2.181.214l.029.002h1.543a.23.23 0 01.028.46l-.028.001H3.672a.674.674 0 01-.67-.633L3 16.45v-2.79a1.358 1.358 0 011.023-1.335l.06-.014 2.444-.31V11.2l.004-.038c-.492-.43-.798-1.09-.817-1.87V8.03c0-1.414.935-2.45 2.209-2.486h.07zm4.599-.083c-1.359 0-2.347 1.078-2.347 2.591v1.411c0 1.513.988 2.59 2.347 2.59 1.358 0 2.346-1.077 2.346-2.59v-1.41c0-1.514-.988-2.592-2.346-2.592zm4.229.081l.088.002c1.295 0 2.254 1.01 2.288 2.41v1.26c0 1.44-.97 2.487-2.283 2.487a1.874 1.874 0 01-.704-.123.23.23 0 01.164-.43c.17.064.35.095.535.092 1.034 0 1.796-.8 1.827-1.954V8.031c0-1.169-.74-1.992-1.772-2.025H16.9a1.563 1.563 0 00-.808.187.23.23 0 01-.219-.406c.296-.16.623-.243.948-.245z"
      fill="#222"
      fillRule="nonzero"
    />
  </g>,
  'DashboardActiveRecords',
)
