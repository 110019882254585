import React from 'react'

const ExploreDatastoreTile = (props) => (
  <svg width={82} height={82} viewBox="0 0 82 82" {...props}>
    <g fillRule="nonzero">
      <path d="M73.846 25.482V68.03c0 .595-.482 1.078-1.077 1.078H56.077a1.077 1.077 0 000 2.154h16.692A3.231 3.231 0 0076 68.03V25.482a1.077 1.077 0 10-2.154 0zM19.854 17.394c0 4.852-3.963 8.786-8.852 8.786-4.89 0-8.853-3.934-8.853-8.786a8.608 8.608 0 011.433-5.005 1.06 1.06 0 00-.306-1.475 1.078 1.078 0 00-1.486.303 10.702 10.702 0 00-1.786 6.205c0 6 4.923 10.888 10.998 10.888C17.076 28.31 22 23.422 22 17.394c0-.588-.48-1.065-1.073-1.065s-1.073.477-1.073 1.065z" />
      <path d="M60 17.394c0 6.028 4.924 10.916 10.998 10.916 6.074 0 10.998-4.888 10.998-10.916a10.677 10.677 0 00-1.786-6.177 1.078 1.078 0 00-1.487-.303 1.06 1.06 0 00-.305 1.475 8.584 8.584 0 011.433 4.977c0 4.88-3.964 8.814-8.853 8.814-4.889 0-8.852-3.934-8.852-8.786 0-.588-.48-1.065-1.073-1.065S60 16.806 60 17.394zM39.854 17.637c0 4.744-3.964 8.59-8.854 8.59-4.89 0-8.854-3.846-8.854-8.59 0-.576-.48-1.042-1.073-1.042S20 17.061 20 17.637c0 5.894 4.925 10.673 11 10.673s11-4.779 11-10.673c0-.576-.48-1.042-1.073-1.042s-1.073.466-1.073 1.042z" />
      <path d="M59.854 17.637c0 4.744-3.964 8.59-8.854 8.59-4.89 0-8.854-3.846-8.854-8.59 0-.576-.48-1.042-1.073-1.042S40 17.061 40 17.637c0 5.894 4.925 10.673 11 10.673s11-4.779 11-10.673c0-.576-.48-1.042-1.073-1.042s-1.073.466-1.073 1.042zM46.854 41.1v30.137c0 4.679-8.476 8.61-19.854 8.61-11.378 0-19.854-3.931-19.854-8.61V41.1c0-.594-.48-1.076-1.073-1.076S5 40.506 5 41.1v30.137C5 77.557 14.577 82 27 82s22-4.443 22-10.763V41.1c0-.594-.48-1.076-1.073-1.076s-1.073.482-1.073 1.076z" />
      <path d="M12.659 49.571c3.788 1.443 8.73 2.167 14.341 2.167 12.204 0 22-4.766 22-11 0-6.152-9.577-10.476-22-10.476S5 34.586 5 40.738c0 .579.48 1.048 1.073 1.048a1.06 1.06 0 001.073-1.048c0-4.554 8.476-8.38 19.854-8.38 11.378 0 19.854 3.826 19.854 8.38 0 4.68-8.683 8.905-19.854 8.905-5.364 0-10.05-.687-13.561-2.024a1.082 1.082 0 00-1.39.596 1.04 1.04 0 00.61 1.356zM11.7 63.764a44.071 44.071 0 0014.713 2.617C38.946 66.381 49 61.747 49 55.685c0-.562-.493-1.018-1.101-1.018-.609 0-1.102.456-1.102 1.018 0 4.551-8.911 8.659-20.377 8.659a41.7 41.7 0 01-13.917-2.477c-.566-.205-1.205.054-1.427.577-.222.524.058 1.115.624 1.32zM9.199 2.13h63.58c.51-.009.973.208 1.271.582l3.979 6.872H12.507c-.605 0-1.096.477-1.096 1.065s.49 1.065 1.096 1.065h67.396c.838 0 1.366-.877.955-1.587l-4.932-8.519a1.061 1.061 0 00-.066-.1A3.774 3.774 0 0072.759 0H9.219a3.764 3.764 0 00-3.08 1.507 1.061 1.061 0 00-.067.1l-4.931 8.52a1.049 1.049 0 00.418 1.45 1.113 1.113 0 001.492-.407l4.897-8.46c.299-.373.762-.59 1.25-.58z" />
    </g>
  </svg>
)

export default ExploreDatastoreTile
