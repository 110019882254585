import React from 'react'

let WorkflowFileStatus = (props) => (
  <svg width={80} height={80} viewBox="0 0 80 80" {...props}>
    <path d="M53.435 54.765l20.68 20.68a.94.94 0 001.33-1.33l-20.68-20.68a.94.94 0 00-1.33 1.33zM57.86 31.07c0 14.795-11.995 26.79-26.79 26.79S4.28 45.865 4.28 31.07 16.275 4.28 31.07 4.28s26.79 11.995 26.79 26.79zm-1.88 0c0-13.757-11.153-24.91-24.91-24.91S6.16 17.313 6.16 31.07s11.153 24.91 24.91 24.91 24.91-11.153 24.91-24.91z" />
    <path d="M26.78 43.604c.333.878 1.583.856 1.884-.034l7.686-22.75 3.517 10.011a1 1 0 00.944.669h6.892a1 1 0 100-2h-6.184l-4.244-12.081c-.316-.898-1.587-.89-1.891.01l-7.721 22.854-3.836-10.137a1 1 0 00-.935-.646H16a1 1 0 000 2h6.201l4.58 12.104z" />
  </svg>
)

export default WorkflowFileStatus
