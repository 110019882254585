import React from 'react'
import { Page, Title, Subtitle } from '../../resources/DemoPage'
import Demo, { generateDemos } from '../../resources/Demo'
import Simple from './demos/Simple'

let demoNames = ['Simple']

export default function Link() {
  let demos = generateDemos('core-component-demo/link', demoNames)

  return (
    <Page components={'Link'}>
      <Title>Link</Title>
      <Subtitle>
        The Link component allows you to easily customize anchor elements with
        your theme colors and typography styles.
      </Subtitle>

      <Demo demo={demos.Simple}>
        <Simple />
      </Demo>
    </Page>
  )
}
