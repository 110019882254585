/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: 'MuiTableCell',
  description:
    'The component renders a `<th>` element when the parent context is a header\r\nor otherwise a `<td>` element.',
  displayName: 'TableCell',
  methods: [],
  props: {
    align: {
      type: {
        name: 'enum',
        value: [
          { value: "'center'", computed: false },
          { value: "'inherit'", computed: false },
          { value: "'justify'", computed: false },
          { value: "'left'", computed: false },
          { value: "'right'", computed: false },
        ],
      },
      required: false,
      description:
        'Set the text-align on the table cell content.\r\n\r\nMonetary or generally number fields **should be right aligned** as that allows\r\nyou to add them up quickly in your head without having to worry about decimals.',
      defaultValue: { value: "'inherit'", computed: false },
    },
    children: {
      type: { name: 'node' },
      required: false,
      description: 'The table cell contents.',
    },
    classes: {
      type: { name: 'object' },
      required: false,
      description:
        'Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details.',
    },
    className: {
      type: { name: 'string' },
      required: false,
      description: '@ignore',
    },
    component: {
      type: { name: 'elementType' },
      required: false,
      description:
        'The component used for the root node.\r\nEither a string to use a HTML element or a component.',
    },
    padding: {
      type: {
        name: 'custom',
        raw: "chainPropTypes(PropTypes.oneOf(['normal', 'checkbox', 'none', 'default']), props => {\n  if (props.padding === 'default') {\n    return new Error('Material-UI: padding=\"default\" was renamed to padding=\"normal\" for consistency.');\n  }\n\n  return null;\n})",
      },
      required: false,
      description:
        'Sets the padding applied to the cell.\r\nBy default, the Table parent component set the value (`normal`).\r\n`default` is deprecated, use `normal` instead.',
    },
    scope: {
      type: { name: 'string' },
      required: false,
      description: 'Set scope attribute.',
    },
    size: {
      type: {
        name: 'enum',
        value: [
          { value: "'medium'", computed: false },
          { value: "'small'", computed: false },
        ],
      },
      required: false,
      description:
        'Specify the size of the cell.\r\nBy default, the Table parent component set the value (`medium`).',
    },
    sortDirection: {
      type: {
        name: 'enum',
        value: [
          { value: "'asc'", computed: false },
          { value: "'desc'", computed: false },
          { value: 'false', computed: false },
        ],
      },
      required: false,
      description: 'Set aria-sort direction.',
    },
    variant: {
      type: {
        name: 'enum',
        value: [
          { value: "'body'", computed: false },
          { value: "'footer'", computed: false },
          { value: "'head'", computed: false },
        ],
      },
      required: false,
      description:
        'Specify the cell type.\r\nBy default, the TableHead, TableBody or TableFooter parent component set the value.',
    },
  },
  styles: {
    root: { description: 'Styles applied to the root element.' },
    head: {
      description:
        'Styles applied to the root element if `variant="head"` or `context.table.head`.',
    },
    body: {
      description:
        'Styles applied to the root element if `variant="body"` or `context.table.body`.',
    },
    footer: {
      description:
        'Styles applied to the root element if `variant="footer"` or `context.table.footer`.',
    },
    sizeSmall: {
      description: 'Styles applied to the root element if `size="small"`.',
    },
    paddingCheckbox: {
      description:
        'Styles applied to the root element if `padding="checkbox"`.',
    },
    paddingNone: {
      description: 'Styles applied to the root element if `padding="none"`.',
    },
    alignLeft: {
      description: 'Styles applied to the root element if `align="left"`.',
    },
    alignCenter: {
      description: 'Styles applied to the root element if `align="center"`.',
    },
    alignRight: {
      description: 'Styles applied to the root element if `align="right"`.',
    },
    alignJustify: {
      description: 'Styles applied to the root element if `align="justify"`.',
    },
    stickyHeader: {
      description:
        'Styles applied to the root element if `context.table.stickyHeader={true}`.',
    },
  },
}
export default generatedDocs
