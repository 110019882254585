import React from 'react'
import RichDataTable from '@liveramp/motif/core/RichDataTable'
import Button from '@liveramp/motif/core/Button'
import ButtonFormGroup from '@liveramp/motif/core/ButtonFormGroup'
import { columns, rows } from '../../demos/data'

export default function SlotsDGFooterDemo() {
  const actions = ({ apiRef }) => {
    const handleDeleteClick = () => {
      let selectedRows = apiRef.current.getSelectedRows()
      if (selectedRows.size === apiRef.current.getAllRowIds().length) {
        alert('Resetting all the rows for you to delete again 🙃')
        handleResetClick()
        apiRef.current.selectRows([], true, true)
      } else if (selectedRows.size > 0) {
        apiRef.current.updateRows(
          [...selectedRows.keys()].map((key) => ({
            id: key,
            _action: 'delete',
          })),
        )
      }
    }

    const handleResetClick = () => {
      apiRef.current.updateRows(rows)
    }

    return (
      <ButtonFormGroup>
        <Button variant="secondary" onClick={handleResetClick}>
          Reset Rows
        </Button>
        <Button onClick={handleDeleteClick}>Delete Rows</Button>
      </ButtonFormGroup>
    )
  }

  return (
    <RichDataTable
      columns={columns}
      rows={rows}
      pagination
      footerActions={actions}
    />
  )
}
