import React from 'react'
import MuiTooltip from '@material-ui/core/Tooltip'
import { makeStyles, mergeClasses } from '../../utils'
import { TooltipStyle } from '../../utils/styleKeys'
import { pxToRem as rem } from '../../utils'

const TooltipContent = React.forwardRef((props, ref) => (
  <div {...props} ref={ref}>
    {props.children}
  </div>
))

export default function Tooltip({
  classes,
  title = '',
  placement = 'top',
  size = 'small',
  children,
  ...props
}) {
  const defaultClasses = useStyles({ size })

  return (
    <MuiTooltip
      title={title && title}
      classes={mergeClasses(defaultClasses, classes)}
      placement={placement}
      arrow
      {...props}
    >
      <TooltipContent>{children}</TooltipContent>
    </MuiTooltip>
  )
}

const useStyles = makeStyles(
  (theme) => ({
    ...TooltipStyle,
    tooltip: {
      ...theme.typography.body2,
      backgroundColor: theme.palette.common.black,
      overflowWrap: 'break-word',
      maxWidth: `${rem(340)}`,
      borderRadius: 'unset',
      padding: ({ size }) =>
        size === 'medium' ? `${rem(16)}` : `${rem(8)} ${rem(16)}`,
    },
    arrow: { color: theme.palette.common.black },
  }),
  { name: 'MuiTooltip' },
)
