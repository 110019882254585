let overrideDocs = {
  props: {
    color: {
      type: {
        name: 'enum',
        value: [
          { value: "'primary'", computed: false },
          { value: "'secondary'", computed: false },
          { value: "'danger'", computed: false },
        ],
      },
      required: false,
      description: 'The color of the component.',
      defaultValue: { value: "'primary'", computed: false },
    },
    disableRipple: {
      description: '@ignore',
    },
    disableFocusRipple: {
      description: '@ignore',
    },
    variant: {
      type: {
        name: 'enum',
        value: [
          { value: "'primary'" },
          { value: "'secondary'" },
          { value: "'contained'" },
          { value: "'outlined'" },
          { value: "'text'" },
        ],
      },
      required: false,
      description: 'The variant to use.',
      defaultValue: { value: "'primary'", computed: false },
    },
  },
}

export default overrideDocs
