import React, { useState } from 'react'
import clsx from 'clsx'
import Button from '@liveramp/motif/core/Button'
import IconButton from '@liveramp/motif/core/IconButton'
import LogoCard from '@liveramp/motif/core/LogoCard'
import LogoCardActions from '@liveramp/motif/core/LogoCardActions'
import LogoCardContent from '@liveramp/motif/core/LogoCardContent'
import LogoCardHeader from '@liveramp/motif/core/LogoCardHeader'
import LogoCardMedia from '@liveramp/motif/core/LogoCardMedia'
import Tooltip from '@liveramp/motif/core/Tooltip'
import Collapse from '@liveramp/motif/core/Collapse'

import css from '../../../resources/Page.module.css'
import makeStyles from '@liveramp/motif/styles/makeStyles'
import StatusSuccess from '@liveramp/motif/icons/StatusSuccess'
import SegmentRobot from '@liveramp/motif/icons/SegmentRobot'
import CaretDown from '@liveramp/motif/icons/CaretDown'

let lorem =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'

export default function LargeDemo() {
  let [open, setOpen] = useState(false)
  let [openNoHover, setOpenNoHover] = useState(false)
  let classes = useStyles()

  return (
    <div className={css.grid}>
      <span>
        <LogoCard size="large">
          <LogoCardHeader
            title={
              <>
                Custom<code>renderOnHover</code>
              </>
            }
            status={
              <Tooltip title="Success">
                <StatusSuccess />
              </Tooltip>
            }
          />
          <LogoCardMedia>
            <SegmentRobot alt="LiveRamp Logo" title="LiveRamp Logo" />
          </LogoCardMedia>
          <LogoCardActions
            renderOnHover={
              <div className={classes.actions}>
                <Button variant="outlined" color="secondary">
                  CTA
                </Button>
                <IconButton
                  className={clsx(classes.iconButton, {
                    [classes.iconButtonOpen]: openNoHover,
                  })}
                  onClick={() => setOpen(!open)}
                  aria-expanded={open}
                  aria-label="Show content"
                >
                  <CaretDown />
                </IconButton>
              </div>
            }
          />
          <Collapse in={open}>
            <LogoCardContent>
              <p className={classes.p}>{lorem}</p>
            </LogoCardContent>
          </Collapse>
        </LogoCard>
      </span>

      <span>
        <LogoCard size="large">
          <LogoCardHeader
            title={<code>renderOnHover={`{false}`}</code>}
            status={
              <Tooltip title="Success">
                <StatusSuccess />
              </Tooltip>
            }
          />
          <LogoCardMedia>
            <SegmentRobot alt="LiveRamp Logo" title="LiveRamp Logo" />
          </LogoCardMedia>
          <LogoCardActions renderOnHover={false}>
            <div className={classes.actions}>
              <Button variant="outlined" color="secondary">
                CTA
              </Button>
              <IconButton
                className={clsx(classes.iconButton, {
                  [classes.iconButtonOpen]: openNoHover,
                })}
                onClick={() => setOpenNoHover(!openNoHover)}
                aria-expanded={openNoHover}
                aria-label="Show content"
              >
                <CaretDown />
              </IconButton>
            </div>
          </LogoCardActions>
          <Collapse in={openNoHover}>
            <LogoCardContent>
              <p className={classes.p}>{lorem}</p>
            </LogoCardContent>
          </Collapse>
        </LogoCard>
      </span>
    </div>
  )
}

let useStyles = makeStyles((theme) => ({
  iconButton: {
    transition: theme.transitions.common.short,
  },
  iconButtonOpen: {
    transform: 'rotate(180deg)',
  },
  p: {
    margin: 0,
  },
  expiration: {
    ...theme.typography.subtitle2,
    color: theme.palette.error.main,
    display: 'flex',
    alignItems: 'center',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))
