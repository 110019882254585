import React from 'react'
import MuiTreeItem from '@material-ui/lab/TreeItem'
import { makeStyles, mergeClasses } from '../../utils'
import { TreeItemStyle } from '../../utils/styleKeys'
import { pxToRem as rem } from '../../utils'

const TreeItem = ({ classes, size, ...props }) => {
  const defaultClasses = useStyles({ size })
  return (
    <MuiTreeItem classes={mergeClasses(defaultClasses, classes)} {...props} />
  )
}

const variations = {
  small: {
    labelGap: '8px',
    contentGap: 0,
    marginLeft: '16px',
  },
  medium: {
    labelGap: '8px',
    contentGap: 0,
    marginLeft: '32px',
  },
  large: {
    labelGap: '16px',
    contentGap: '8px',
    marginLeft: '32px',
  },
}

const useStyles = makeStyles(
  (theme) => ({
    ...TreeItemStyle,
    root: {
      '& svg': {
        fill: theme.palette.secondary.main,
      },
    },
    content: {
      minHeight: `${rem(32)}`,
      padding: 0,
      '& .MuitreeItem-iconContainer': {
        svg: {
          fontSize: `${rem(24)}`,
        },
      },
      '.MuiTreeItem-root.Mui-selected > &, &:hover, .MuiTreeItem-root.Mui-selected > &:hover, .MuiTreeItem-root.Mui-selected > &.Mui-focused':
        {
          backgroundColor: 'transparent',
        },
      ['.MuiTreeItem-root.Mui-selected > & .MuiTreeItem-label']: {
        backgroundColor: theme.palette.success['x-light'],
      },
      ['.MuiTreeItem-root.Mui-selected > &:hover .MuiTreeItem-label']: {
        backgroundColor: theme.palette.success.light,
      },
      ['&:hover .MuiTreeItem-label']: {
        backgroundColor: theme.palette.secondary.light,
      },
    },
    group: {
      marginLeft: `${rem(34)}`,
    },
    label: {
      ...theme.typography.body2,
      display: 'flex',
      gap: '10px',
      alignItems: 'center',
      color: theme.palette.common.black,
      paddingLeft: `${rem(10)}`,
      minHeight: `${rem(32)}`,
    },
  }),
  { name: 'MuiTreeItem' },
)

export default TreeItem
