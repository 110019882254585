import React from 'react'
import MuiSwitch from '@material-ui/core/Switch'
import withStyles from '../../styles/withStyles'
import { SwitchStyle } from '../../utils/styleKeys'

function Switch(props) {
  return <MuiSwitch color="default" {...props} />
}

let styles = (theme) => ({
  ...SwitchStyle,
  switchBase: {
    padding: (props) => (props.icon || props.iconChecked ? '6px' : undefined),
    color: theme.palette.secondary.light,
    '&:hover': {
      backgroundColor: theme.palette.primary.hover,
    },
    '&.Mui-checked + .MuiSwitch-track': {
      opacity: 1,
    },
  },
  thumb: {
    boxShadow: theme.shadows[2],
  },
  track: {
    opacity: 1,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '10px',
  },
  checked: {
    color: theme.palette.primary.dark,
    '& + .MuiSwitch-track': {
      backgroundColor: theme.palette.primary.light,
    },
    '& + .MuiSwitch-thumb': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  disabled: {
    '&.MuiSwitch-switchBase + .MuiSwitch-track, &.Mui-checked + .MuiSwitch-track':
      {
        opacity: 1,
        backgroundColor: theme.palette.disabled.main,
      },
  },
})

export default withStyles(styles, { name: 'MuiSwitch' })(Switch)
