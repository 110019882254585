import React from 'react'
import createSvgIcon from './utils/createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path
      d="M12 2a9.97 9.97 0 017.139 2.997l1.438-1.451.079-.07a.829.829 0 011.1.07c.288.29.321.74.1 1.068L4.576 22.026a.843.843 0 00-.099.12l-.222.228-.079.07a.829.829 0 01-1.1-.07.845.845 0 010-1.188l1.977-1.992A9.97 9.97 0 012 12C2 6.477 6.477 2 12 2zm10 10c0 5.523-4.477 10-10 10a9.963 9.963 0 01-4.148-.898l5.932-5.982.187-.004.034 1.177 1.21-.038.053 1.273 2.274-.052a.216.216 0 00.209-.164l.006-.06-.03-1.924-2.083-2.083 5.424-5.466A9.962 9.962 0 0122 12zM6.919 6.975c-1.367 1.367-1.36 3.59.016 4.966a3.526 3.526 0 003.09.987l.217-.044.538.537 2.51-2.53-.489-.489a3.526 3.526 0 00-.916-3.41c-1.376-1.377-3.6-1.384-4.966-.017zm2.026.752a.903.903 0 01.004 1.278.903.903 0 01-1.278-.004.903.903 0 01-.004-1.278.903.903 0 011.278.004z"
      fill="#BDBDBD"
      fillRule="evenodd"
    />
  </React.Fragment>,
  'StatusRevoked',
)
