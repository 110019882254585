import React, { useState } from 'react'
import Checkbox from '@liveramp/motif/core/Checkbox'
import FormControl from '@liveramp/motif/core/FormControl'
import FormControlLabel from '@liveramp/motif/core/FormControlLabel'
import FormGroup from '@liveramp/motif/core/FormGroup'
import FormHelperText from '@liveramp/motif/core/FormHelperText'
import FormLabel from '@liveramp/motif/core/FormLabel'
import css from '../../../resources/Page.module.css'

export default function FormGroupDemo() {
  let [blueberry, setBlueberry] = useState(true)
  let [mango, setMango] = useState(true)
  let [peach, setPeach] = useState(false)
  let [strawberry, setStrawberry] = useState(false)

  let error = [strawberry, blueberry, mango, peach].filter(Boolean).length < 3

  return (
    <div className={css.grid}>
      <FormControl component="fieldset">
        <FormLabel component="legend">Favorite Fruits</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={blueberry}
                onChange={() => setBlueberry(!blueberry)}
                value="blueberry"
              />
            }
            label="Blueberry"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={mango}
                onChange={() => setMango(!mango)}
                value="mango"
              />
            }
            label="Mango"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={peach}
                onChange={() => setPeach(!peach)}
                value="peach"
              />
            }
            label="Peach"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={strawberry}
                onChange={() => setStrawberry(!strawberry)}
                value="strawberry"
              />
            }
            label="Strawberry"
          />
        </FormGroup>
        <FormHelperText>This is your helper text</FormHelperText>
      </FormControl>
      <FormControl component="fieldset" required error={error}>
        <FormLabel component="legend">Favorite Fruits</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={blueberry}
                onChange={() => setBlueberry(!blueberry)}
                value="blueberry"
              />
            }
            label="Blueberry"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={mango}
                onChange={() => setMango(!mango)}
                value="mango"
              />
            }
            label="Mango"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={peach}
                onChange={() => setPeach(!peach)}
                value="peach"
              />
            }
            label="Peach"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={strawberry}
                onChange={() => setStrawberry(!strawberry)}
                value="strawberry"
              />
            }
            label="Strawberry"
          />
        </FormGroup>
        <FormHelperText>Please pick at least three</FormHelperText>
      </FormControl>
    </div>
  )
}
