/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: 'MuiToggleButtonGroup',
  description: '',
  displayName: 'ToggleButtonGroup',
  methods: [],
  props: {
    children: {
      type: { name: 'node' },
      required: false,
      description: 'The content of the button.',
    },
    classes: {
      type: { name: 'object' },
      required: false,
      description:
        'Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details.',
    },
    className: {
      type: { name: 'string' },
      required: false,
      description: '@ignore',
    },
    exclusive: {
      type: { name: 'bool' },
      required: false,
      description:
        'If `true`, only allow one of the child ToggleButton values to be selected.',
      defaultValue: { value: 'false', computed: false },
    },
    onChange: {
      type: { name: 'func' },
      required: false,
      description:
        'Callback fired when the value changes.\r\n\r\n@param {object} event The event source of the callback.\r\n@param {any} value of the selected buttons. When `exclusive` is true\r\nthis is a single value; when false an array of selected values. If no value\r\nis selected and `exclusive` is true the value is null; when false an empty array.',
    },
    orientation: {
      type: {
        name: 'enum',
        value: [
          { value: "'horizontal'", computed: false },
          { value: "'vertical'", computed: false },
        ],
      },
      required: false,
      description: 'The group orientation (layout flow direction).',
      defaultValue: { value: "'horizontal'", computed: false },
    },
    size: {
      type: {
        name: 'enum',
        value: [
          { value: "'large'", computed: false },
          { value: "'medium'", computed: false },
          { value: "'small'", computed: false },
        ],
      },
      required: false,
      description: 'The size of the buttons.',
      defaultValue: { value: "'medium'", computed: false },
    },
    value: {
      type: { name: 'any' },
      required: false,
      description:
        'The currently selected value within the group or an array of selected\r\nvalues when `exclusive` is false.\r\n\r\nThe value must have reference equality with the option in order to be selected.',
    },
  },
  styles: {
    root: { description: 'Styles applied to the root element.' },
    vertical: {
      description:
        'Styles applied to the root element if `orientation="vertical"`.',
    },
    grouped: { description: 'Styles applied to the children.' },
    groupedHorizontal: {
      description:
        'Styles applied to the children if `orientation="horizontal"`.',
    },
    groupedVertical: {
      description:
        'Styles applied to the children if `orientation="vertical"`.',
    },
  },
}
export default generatedDocs
