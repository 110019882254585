import FormHelperText from '@material-ui/core/FormHelperText'
import { FormHelperTextStyle } from '../../utils/styleKeys'
import { pxToRem as rem } from '../../utils'
export default FormHelperText

export let styles = (theme) => ({
  ...FormHelperTextStyle,
  root: {
    ...theme.typography.body4,
    marginTop: rem(4),
    lineHeight: rem(14),
    color: theme.palette.secondary.dark,
    '&.Mui-error': {
      color: theme.palette.error.dark,
    },
    '&.MuiFormHelperText-disabled:not(.MuiFormHelperText-error)': {
      color: theme.palette.disabled.main,
    },
  },
  contained: {
    margin: `${rem(4)} ${rem(8)} 0`,
  },
})
