import React from 'react'
import { Title, Subtitle } from '../resources/Page'

export default function CoreDemos() {
  return (
    <div>
      <Title>Core Components</Title>
      <Subtitle>
        Basic components themed to match LiveRamp's design language
      </Subtitle>
      <p>
        These components are designed to be the basic atoms and molecules to
        create more complex organisms, templates and pages.
      </p>
      <ul>
        <li>
          Styled directly from Material UI with very little customization from
          AX.
        </li>
        <li>Un-opinionated and have no concept of business logic.</li>
        <li>Should be easy to use and customize.</li>
        <li>
          If you see a component in Material UI's library that we do not have,
          please contact us at{' '}
          <a href="https://liveramp.slack.com/archives/C012Q4QCZG8">#dev-ax</a>{' '}
          to look into adding it.
        </li>
        <li>
          If you would like to contribute instead;{' '}
          <a href="https://github.com/LiveRamp/motif/blob/master/CONTRIBUTING.md">
            please check out our guide!
          </a>{' '}
          (but also chat to us too!)
        </li>
      </ul>
      <p>
        Atomic design is the basis of Motif and all of its components. To learn
        more about what Atomic Design is, check out{' '}
        <a href="https://bradfrost.com/blog/post/atomic-web-design/">
          Brad Frost's write up here
        </a>
        .
      </p>
    </div>
  )
}
