import React, { useRef, useState, useLayoutEffect } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import Tooltip from '../../core-components/Tooltip'
import makeStyles from '../../styles/makeStyles'
import Typography from '../../core-components/Typography'

const Truncate = (props) => {
  const {
    children,
    className,
    wrapped = false,
    tooltipPosition = 'bottom',
    forceTooltip = false,
    ...typographyProps
  } = props

  const validTooltips = ['top', 'bottom', 'left', 'right']
  if (!validTooltips.includes(tooltipPosition)) {
    throw new Error(
      `Invalid tooltip position! Must be ${validTooltips.join(', ')}.`,
    )
  }

  if (wrapped !== true && wrapped !== false) {
    throw new Error('Invalid wrapped!')
  }

  if (forceTooltip !== true && forceTooltip !== false) {
    throw new Error('Invalid forceTooltip!')
  }

  /*
    This isn't ready
  */
  // if(!children || typeof children !== 'string') {
  //   throw new Error('Children prop is required and needs to be a string!');
  // }

  useStyles()
  const textElementRef = useRef()

  const [firstText, setFirstText] = useState('')
  const [secondText, setSecondText] = useState('')
  const [truncate, setTruncate] = useState(false)

  useLayoutEffect(() => {
    const splitIndex = Math.round(children.length * 0.75)
    const firstHalf = children.slice(0, splitIndex)
    const secondHalf = children.slice(splitIndex)

    setFirstText(firstHalf)
    setSecondText(secondHalf)
  }, [truncate])

  useLayoutEffect(() => {
    const checkHeight = () => {
      const lineHeight = parseInt(
        window.getComputedStyle(textElementRef?.current).lineHeight,
        10,
      )
      let scrollHeight = textElementRef?.current?.scrollHeight

      const threshold = wrapped ? lineHeight * 2 : lineHeight
      const isTextOverflow = scrollHeight > threshold
      isTextOverflow && setTruncate(true)
    }

    checkHeight()

    window.addEventListener('resize', checkHeight)
    return () => {
      window.removeEventListener('resize', checkHeight)
    }
  }, [])

  return (
    <div
      data-testid="Truncate"
      className={clsx('Truncate', className)}
      ref={textElementRef}
    >
      <Tooltip
        title={children}
        placement={tooltipPosition}
        disableHoverListener={!truncate && !forceTooltip}
      >
        <Typography {...typographyProps}>
          {truncate ? (
            <div
              className="Truncate__text Truncate-root"
              style={{ display: wrapped ? 'block' : 'flex' }}
            >
              <div className="Truncate__text__start">{firstText}</div>
              <div className="Truncate__text__end">{secondText}</div>
            </div>
          ) : (
            children
          )}
        </Typography>
      </Tooltip>
    </div>
  )
}

export default Truncate

const useStyles = makeStyles({
  '@global': {
    '.Truncate': {
      overflow: 'auto hidden',

      '&__text': {
        // .Truncate__text__start
        '&__start': {
          flex: '0 1 auto',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'pre',
        },
        // .Truncate__text__end
        '&__end': {
          flex: '1 0 auto',
          overflow: 'hidden',
          whiteSpace: 'pre',
        },
      },
    },
  },
})

Truncate.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  wrapped: PropTypes.bool,
  tooltipPosition: PropTypes.string,
  forceTooltip: PropTypes.bool,
}
