import React from 'react'
import TextField from '@liveramp/motif/core/TextField'
import { Search, User } from '@liveramp/motif/icons'
import css from './Adornments.module.css'

export default function AdornmentsUnderlinedDemo() {
  return (
    <div className={css.grid}>
      <TextField
        id="underlined-start-str-adornment"
        variant="underlined"
        label="Price"
        defaultValue="100"
        helperText="Start Adornment"
        InputProps={{
          startAdornment: '$',
        }}
      />
      <TextField
        id="underlined-end-str-adornment"
        variant="underlined"
        label="Weight"
        defaultValue="100"
        helperText="End Adornment"
        InputProps={{
          endAdornment: 'kg',
        }}
      />
      <TextField
        id="underlined-start-icon-adornment"
        variant="underlined"
        label="Username"
        defaultValue="Nexus"
        helperText="Start Adornment"
        InputProps={{
          startAdornment: <User />,
        }}
      />
      <TextField
        id="underlined-end-icon-adornment"
        variant="underlined"
        label="Search"
        defaultValue="Nexus"
        helperText="End Adornment"
        InputProps={{
          endAdornment: <Search />,
        }}
      />
    </div>
  )
}
