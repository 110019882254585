/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: 'MuiStepIcon',
  description: '',
  displayName: 'StepIcon',
  methods: [],
  props: {
    active: {
      type: { name: 'bool' },
      required: false,
      description: 'Whether this step is active.',
      defaultValue: { value: 'false', computed: false },
    },
    classes: {
      type: { name: 'object' },
      required: false,
      description:
        'Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details.',
    },
    completed: {
      type: { name: 'bool' },
      required: false,
      description: 'Mark the step as completed. Is passed to child components.',
      defaultValue: { value: 'false', computed: false },
    },
    error: {
      type: { name: 'bool' },
      required: false,
      description: 'Mark the step as failed.',
      defaultValue: { value: 'false', computed: false },
    },
    icon: {
      type: { name: 'node' },
      required: false,
      description: 'The label displayed in the step icon.',
    },
  },
  styles: {
    root: { description: 'Styles applied to the root element.' },
    text: { description: 'Styles applied to the SVG text element.' },
    active: {
      description:
        'Pseudo-class applied to the root element if `active={true}`.',
    },
    completed: {
      description:
        'Pseudo-class applied to the root element if `completed={true}`.',
    },
    error: {
      description:
        'Pseudo-class applied to the root element if `error={true}`.',
    },
  },
}
export default generatedDocs
