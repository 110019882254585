import React, { useState, useEffect, useRef } from 'react'
import MuiButton from '@material-ui/core/Button'
import withStyles from '../../styles/withStyles'
import { pxToRem as rem } from '../../utils'
import PropTypes from 'prop-types'
import { lab } from 'chroma-js'

export let finalVariant = (variant) => {
  if (variant === 'secondary' || variant === 'outlined') return 'outlined'
  if (variant === 'text') return 'text'
  return 'contained'
}

const Button = React.forwardRef(function Button(
  {
    classes,
    className,
    color,
    disableFocusRipple, // ignore - we have our own button animations
    disableRipple, // ignore - we have our own button animations
    onClick,
    variant,
    ...props
  },
  ref,
) {
  let [active, setActive] = useState(false)
  let timeoutIds = useRef([])

  let colorClasses = (color, classes) => {
    let prefix = color
    if (!['primary', 'secondary', 'danger'].includes(color)) prefix = 'primary'
    return {
      text: classes[`${prefix}Text`],
      contained: classes[`${prefix}Contained`],
      outlined: classes[`${prefix}Outlined`],
    }
  }
  let buttonClasses = { root: classes.root, ...colorClasses(color, classes) }

  let registerTimeout = (f, ms) => {
    let timeoutId = setTimeout(f, ms)
    timeoutIds.current.push(timeoutId)
  }

  let handleClick = (e) => {
    e.persist()
    setActive(true)
    if (typeof onClick === 'function') onClick(e)
  }

  let cleanup = () => {
    timeoutIds.current.forEach(clearTimeout)
  }

  useEffect(() => {
    if (active === true) {
      registerTimeout(() => setActive(false), 250)
    }
    return cleanup
  })

  return (
    <MuiButton
      ref={ref}
      variant={finalVariant(variant)}
      className={`${active ? 'Mui-active' : ''} ${className}`}
      classes={buttonClasses}
      onClick={handleClick}
      centerRipple
      {...props}
    />
  )
})

let containedStyle = (theme) => ({
  boxShadow: 'none',
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.primary.main,
  border: `${rem(1)} solid ${theme.palette.primary.main}`,
  padding: `${rem(7)} ${rem(15)}`,
  textTransform: 'none',
  '&:hover, &:active, &.Mui-focusVisible, &.Mui-active': {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.dark,
    border: `${rem(1)} solid ${theme.palette.primary.dark}`,
    boxShadow: 'none',
  },

  '&.Mui-disabled': {
    color: theme.palette.disabled.main,
    backgroundColor: theme.palette.disabled.light,
    border: `${rem(1)} solid ${theme.palette.disabled.light}`,
  },

  '&.MuiButton-dangerContained': {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.error.dark,
    border: `${rem(1)} solid ${theme.palette.error.dark}`,
    '&:hover, &:active, &.Mui-focusVisible, &.Mui-active': {
      backgroundColor: theme.palette.error.main,
    },
  },
})

let outlinedStyle = (theme) => ({
  color: theme.palette.text.primary,
  borderColor: theme.palette.common.black,
  padding: `${rem(7)} ${rem(15)}`,
  textTransform: 'none',

  '&:hover, &:active, &.Mui-focusVisible, &.Mui-active': {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.light,
  },
})

let textStyle = (theme) => ({
  color: theme.palette.common.black,
  padding: `${rem(7)} ${rem(15)}`,
  border: `${rem(1)} solid transparent`,
  textTransform: 'none',
  '&:hover, &:active, &.Mui-focusVisible, &.Mui-active': {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.light,
  },
})

let styles = (theme) => ({
  root: {
    borderRadius: rem(4),
    fontWeight: '600',
    transition: theme.transitions.common.short,
  },
  label: {
    lineHeight: rem(20),
  },
  // contained button styles
  primaryContained: containedStyle(theme),
  secondaryContained: containedStyle(theme),
  dangerContained: containedStyle(theme),

  // outlined button styles
  primaryOutlined: outlinedStyle(theme),
  secondaryOutlined: outlinedStyle(theme),
  dangerOutlined: outlinedStyle(theme),

  // text only button styles
  primaryText: textStyle(theme),
  secondaryText: textStyle(theme),
  dangerText: textStyle(theme),
})

Button.propTypes = Object.assign({}, MuiButton.propTypes, {
  color: PropTypes.oneOf(['default', 'primary', 'secondary', 'danger']),
  variant: PropTypes.oneOf([
    'default',
    'primary',
    'secondary',
    'contained',
    'outlined',
    'text',
  ]),
})

Button.defaultProps = Object.assign({}, MuiButton.defaultProps, {
  className: '',
  color: 'default',
  variant: 'default',
})

export default withStyles(styles, { name: 'MuiButton' })(Button)
