import React from 'react'
import theme from '@liveramp/motif/theme'
import Button from '@liveramp/motif/core/Button'
import ButtonFormGroup from '@liveramp/motif/core/ButtonFormGroup'
import Tooltip from '@liveramp/motif/core/Tooltip'

export default function HtmlDemo() {
  const htmlTitle = (
    <>
      <h3 style={{ ...theme.typography.subtitle1 }}>This is an HTML tooltip</h3>
      <p>
        Cat ipsum dolor sit amet, crash against wall but walk away like nothing
        happened gimme attention, <i>gimme attention</i>, <b>gimme attention</b>{' '}
        just kidding I don't want it anymore meow bye.
      </p>
    </>
  )
  return (
    <ButtonFormGroup>
      <Tooltip title={htmlTitle} interactive>
        <Button variant="secondary">HTML tooltip</Button>
      </Tooltip>
    </ButtonFormGroup>
  )
}
