import React from 'react'
import Select from '@liveramp/motif/core/Select'

export default function LimitTagsDemo() {
  let list = [
    { label: 'one' },
    { label: 'two' },
    { label: 'three' },
    { label: 'four' },
  ]
  return (
    <Select
      id="limit-tags"
      label="Label"
      variant="underlined"
      options={list}
      getOptionLabel={(option) => option.label}
      defaultValue={[list[0], list[1], list[3], list[2]]}
      multiple
      limitTags={2}
    />
  )
}
