import React from 'react'

let WorkflowDistribute = (props) => (
  <svg width={82} height={82} viewBox="0 0 82 82" {...props}>
    <g fillRule="nonzero">
      <path d="M42 54.923V1.077C42 .482 41.552 0 41 0s-1 .482-1 1.077v53.846c0 .595.448 1.077 1 1.077s1-.482 1-1.077z" />
      <path d="M54.096 14.686a1.148 1.148 0 001.577 0 1.042 1.042 0 000-1.515L42.29.314a1.148 1.148 0 00-1.578 0L27.327 13.17a1.042 1.042 0 000 1.515 1.148 1.148 0 001.577 0L41.5 2.586l12.596 12.1zM51.82 59.688L81.689 29.82a1.067 1.067 0 00-1.509-1.509L50.312 58.18a1.067 1.067 0 001.509 1.509z" />
      <path d="M79.79 30.21v17.685a1.105 1.105 0 102.21 0v-18.79c0-.61-.495-1.105-1.105-1.105h-18.79a1.105 1.105 0 000 2.21H79.79zM31.688 58.18L1.82 28.311a1.067 1.067 0 00-1.509 1.509L30.18 59.688a1.067 1.067 0 001.509-1.509z" />
      <path d="M2.21 30.21h17.685a1.105 1.105 0 100-2.21H1.105C.495 28 0 28.495 0 29.105v18.79a1.105 1.105 0 102.21 0V30.21zM41.5 82a14.5 14.5 0 10-9.792-3.806 1.115 1.115 0 101.506-1.645 12.269 12.269 0 118.286 3.22 1.115 1.115 0 000 2.231z" />
    </g>
  </svg>
)

export default WorkflowDistribute
