import React from 'react'
import GlobalAdmin from '@liveramp/motif/core/GlobalAdmin'
import GlobalAdminButton from '@liveramp/motif/core/GlobalAdminButton'
import GlobalAdminNotificationsButton from '@liveramp/motif/core/GlobalAdminNotificationsButton'
import MenuItem from '@liveramp/motif/core/MenuItem'
import MenuList from '@liveramp/motif/core/MenuList'
import { SegmentRobot as AvatarIcon } from '@liveramp/motif/icons'

export default function GlobalAdminDemo() {
  return (
    <GlobalAdmin>
      <GlobalAdminNotificationsButton
        onMarkReadClick={(notification) =>
          alert(`marking notification ${notification.id} as read`)
        }
        onSeeAllClick={() =>
          alert('clicked "see all" on the Notifications Menu!')
        }
        notifications={exampleNotifications}
      />
      <GlobalAdminButton
        label="Documentation Page"
        menu={
          <MenuList>
            <MenuItem onClick={() => alert('You clicked on Documentation!')}>
              Documentation
            </MenuItem>
            <MenuItem
              onClick={() => alert('You clicked on Open a Support Ticket!')}
            >
              Open a Support Ticket
            </MenuItem>
          </MenuList>
        }
        style={{ paddingTop: '2px' }}
      >
        ?
      </GlobalAdminButton>
      <GlobalAdminButton
        label="Profile"
        onClick={() => alert('You clicked on the Profile button!')}
      >
        <AvatarIcon />
      </GlobalAdminButton>
    </GlobalAdmin>
  )
}

let exampleNotifications = [
  {
    id: 'a',
    link: 'https://connect.liveramp.com/file_tracking',
    type: 'warning',
    createdAt: new Date('12/15/2020'),
    read: false,
    message:
      'Data from your file FB_OPTOUT_FB_20201205.csv has been delivered.',
  },
  {
    id: 'b',
    link: 'https://connect.liveramp.com/distribution',
    type: 'success',
    createdAt: new Date('12/15/2020'),
    read: false,
    message:
      'Your file FB_OPTOUT_FB_20201205.csv has finished processing in audience Facebook FR - Acxiom O... and is ready for distribution.',
  },
  {
    id: 'c',
    link: 'https://connect.liveramp.com/distribution',
    type: 'error',
    createdAt: new Date('12/15/2020'),
    read: true,
    message:
      'Your file ILUv17.1_DigitalDeleti... has finished processing in audience Facebook UK - Acxiom O... and is ready for distribution.',
  },
  {
    id: 'd',
    link: 'https://connect.liveramp.com/file_tracking',
    type: 'warning',
    createdAt: new Date('12/15/2020'),
    read: false,
    message: 'Sometimes msg is short.',
  },
  {
    id: 'e',
    link: 'https://connect.liveramp.com/distribution',
    type: 'success',
    createdAt: new Date('12/15/2020'),
    read: false,
    message:
      'Your file FB_OPTOUT_FB_20201205.csv has finished processing in audience Facebook FR - Acxiom O... and is ready for distribution.',
  },
  {
    id: 'f',
    link: 'https://connect.liveramp.com/distribution',
    type: 'error',
    createdAt: new Date('12/15/2020'),
    read: true,
    message:
      'Your file ILUv17.1_DigitalDeleti... has finished processing in audience Facebook UK - Acxiom O... and is ready for distribution.',
  },
]
