import React from 'react'
import createSvgIcon from './utils/createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path
      d="M21.254 21.254a.925.925 0 110 1.85.925.925 0 010-1.85zm-3.702 0a.925.925 0 110 1.85.925.925 0 010-1.85zm-3.701 0a.925.925 0 110 1.85.925.925 0 010-1.85zm-3.702 0a.925.925 0 110 1.85.925.925 0 010-1.85zm0-20.359c1.209 0 2.237.773 2.618 1.851h3.86a1.85 1.85 0 011.85 1.85v12.956a1.85 1.85 0 01-1.85 1.851H3.672a1.85 1.85 0 01-1.851-1.85V4.596a1.85 1.85 0 011.85-1.85h3.86A2.777 2.777 0 0110.149.894zm11.105 16.657a.925.925 0 110 1.851.925.925 0 010-1.85zM5.525 4.597H3.672v12.955h12.955V4.597h-1.834l-.008.934a.925.925 0 01-.926.917H6.45a.925.925 0 01-.925-.926v-.925zm15.729 9.254a.925.925 0 110 1.85.925.925 0 010-1.85zm-11.105 0a.925.925 0 110 1.85.925.925 0 010-1.85zm0-3.702a.925.925 0 110 1.851.925.925 0 010-1.85zm3.702 0a.925.925 0 110 1.851.925.925 0 010-1.85zm7.403 0a.925.925 0 110 1.851.925.925 0 010-1.85zM10.149 2.746a.925.925 0 100 1.85.925.925 0 000-1.85z"
      fillRule="nonzero"
    />
  </React.Fragment>,
  'CopyToClipboard',
)
