/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: 'MuiChip',
  description:
    'Chips represent complex entities in small blocks, such as a contact.',
  displayName: 'Chip',
  methods: [],
  props: {
    avatar: {
      type: { name: 'element' },
      required: false,
      description: 'Avatar element.',
    },
    children: {
      type: { name: 'custom', raw: 'unsupportedProp' },
      required: false,
      description:
        "This prop isn't supported.\r\nUse the `component` prop if you need to change the children structure.",
    },
    classes: {
      type: { name: 'object' },
      required: false,
      description:
        'Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details.',
    },
    className: {
      type: { name: 'string' },
      required: false,
      description: '@ignore',
    },
    clickable: {
      type: { name: 'bool' },
      required: false,
      description:
        'If `true`, the chip will appear clickable, and will raise when pressed,\r\neven if the onClick prop is not defined.\r\nIf false, the chip will not be clickable, even if onClick prop is defined.\r\nThis can be used, for example,\r\nalong with the component prop to indicate an anchor Chip is clickable.',
    },
    color: {
      type: {
        name: 'enum',
        value: [
          { value: "'default'", computed: false },
          { value: "'primary'", computed: false },
          { value: "'secondary'", computed: false },
        ],
      },
      required: false,
      description:
        'The color of the component. It supports those theme colors that make sense for this component.',
      defaultValue: { value: "'default'", computed: false },
    },
    component: {
      type: { name: 'elementType' },
      required: false,
      description:
        'The component used for the root node.\r\nEither a string to use a HTML element or a component.',
    },
    deleteIcon: {
      type: { name: 'element' },
      required: false,
      description:
        'Override the default delete icon element. Shown only if `onDelete` is set.',
    },
    disabled: {
      type: { name: 'bool' },
      required: false,
      description:
        'If `true`, the chip should be displayed in a disabled state.',
      defaultValue: { value: 'false', computed: false },
    },
    icon: {
      type: { name: 'element' },
      required: false,
      description: 'Icon element.',
    },
    label: {
      type: { name: 'node' },
      required: false,
      description: 'The content of the label.',
    },
    onClick: {
      type: { name: 'func' },
      required: false,
      description: '@ignore',
    },
    onDelete: {
      type: { name: 'func' },
      required: false,
      description:
        'Callback function fired when the delete icon is clicked.\r\nIf set, the delete icon will be shown.',
    },
    onKeyDown: {
      type: { name: 'func' },
      required: false,
      description: '@ignore',
    },
    onKeyUp: {
      type: { name: 'func' },
      required: false,
      description: '@ignore',
    },
    size: {
      type: {
        name: 'enum',
        value: [
          { value: "'medium'", computed: false },
          { value: "'small'", computed: false },
        ],
      },
      required: false,
      description: 'The size of the chip.',
      defaultValue: { value: "'medium'", computed: false },
    },
    variant: {
      type: {
        name: 'enum',
        value: [
          { value: "'default'", computed: false },
          { value: "'outlined'", computed: false },
        ],
      },
      required: false,
      description: 'The variant to use.',
      defaultValue: { value: "'default'", computed: false },
    },
  },
  styles: {
    root: { description: 'Styles applied to the root element.' },
    sizeSmall: {
      description: 'Styles applied to the root element if `size="small"`.',
    },
    colorPrimary: {
      description: 'Styles applied to the root element if `color="primary"`.',
    },
    colorSecondary: {
      description: 'Styles applied to the root element if `color="secondary"`.',
    },
    disabled: {
      description:
        'Pseudo-class applied to the root element if `disabled={true}`.',
    },
    clickable: {
      description:
        'Styles applied to the root element if `onClick` is defined or `clickable={true}`.',
    },
    clickableColorPrimary: {
      description:
        'Styles applied to the root element if `onClick` and `color="primary"` is defined or `clickable={true}`.',
    },
    clickableColorSecondary: {
      description:
        'Styles applied to the root element if `onClick` and `color="secondary"` is defined or `clickable={true}`.',
    },
    deletable: {
      description:
        'Styles applied to the root element if `onDelete` is defined.',
    },
    deletableColorPrimary: {
      description:
        'Styles applied to the root element if `onDelete` and `color="primary"` is defined.',
    },
    deletableColorSecondary: {
      description:
        'Styles applied to the root element if `onDelete` and `color="secondary"` is defined.',
    },
    outlined: {
      description:
        'Styles applied to the root element if `variant="outlined"`.',
    },
    outlinedPrimary: {
      description:
        'Styles applied to the root element if `variant="outlined"` and `color="primary"`.',
    },
    outlinedSecondary: {
      description:
        'Styles applied to the root element if `variant="outlined"` and `color="secondary"`.',
    },
    avatar: { description: 'Styles applied to the `avatar` element.' },
    avatarSmall: {
      description: 'Styles applied to the `avatar` element if `size="small"`.',
    },
    avatarColorPrimary: {
      description:
        'Styles applied to the `avatar` element if `color="primary"`.',
    },
    avatarColorSecondary: {
      description:
        'Styles applied to the `avatar` element if `color="secondary"`.',
    },
    icon: { description: 'Styles applied to the `icon` element.' },
    iconSmall: {
      description: 'Styles applied to the `icon` element if `size="small"`.',
    },
    iconColorPrimary: {
      description: 'Styles applied to the `icon` element if `color="primary"`.',
    },
    iconColorSecondary: {
      description:
        'Styles applied to the `icon` element if `color="secondary"`.',
    },
    label: { description: 'Styles applied to the label `span` element.' },
    labelSmall: {
      description:
        'Styles applied to the label `span` element if `size="small"`.',
    },
    deleteIcon: { description: 'Styles applied to the `deleteIcon` element.' },
    deleteIconSmall: {
      description:
        'Styles applied to the `deleteIcon` element if `size="small"`.',
    },
    deleteIconColorPrimary: {
      description:
        'Styles applied to the deleteIcon element if `color="primary"` and `variant="default"`.',
    },
    deleteIconColorSecondary: {
      description:
        'Styles applied to the deleteIcon element if `color="secondary"` and `variant="default"`.',
    },
    deleteIconOutlinedColorPrimary: {
      description:
        'Styles applied to the deleteIcon element if `color="primary"` and `variant="outlined"`.',
    },
    deleteIconOutlinedColorSecondary: {
      description:
        'Styles applied to the deleteIcon element if `color="secondary"` and `variant="outlined"`.',
    },
  },
}
export default generatedDocs
