import React, { useContext, useState } from 'react'
import clsx from 'clsx'
import { GlobalNavigationContext } from './GlobalNavigation'
import SearchField from './SearchField'
import ListItem from '../../core-components/ListItem'
import List from '../../core-components/List'
import Skeleton from '../../core-components/Skeleton'
import useAutocomplete from '../../core-components/useAutocomplete'
import VirtualizedFixedSizeList from '../../core-components/VirtualizedFixedSizeList'
import makeStyles from '../../styles/makeStyles'

export default function L3BusinessObjSelectorAutocomplete({
  className,
  onChange,
  onClick,
  parent,
  getOptionLabel,
  setValue,
  value,
  label,
  ...props
}) {
  const { isAltNav } = useContext(GlobalNavigationContext)

  const ITEM_HEIGHT = 36
  const MAX_ITEMS_SHOWING = isAltNav ? 5 : 3
  const OVERSCAN_COUNT = 3

  useStyles()
  const { loading } = props
  const [acInputValue, setAcInputValue] = useState('')

  const handleChange = (event, value) => {
    onClick(value?.route, value?.onNavigate)
    onChange(event, value)
    setValue(value)
  }

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getOptionProps,
    getListboxProps,
    groupedOptions,
  } = useAutocomplete({
    onChange: handleChange,
    onInputChange: (event, value) => setAcInputValue(value),
    // default autocomplete behavior is that the input field (SearchField in this case) will be updated with the selected value
    // this is not a filter/select component so we do not want this behavior so we introduce the check below when updating the inputValue
    inputValue: acInputValue,
    getOptionLabel: getOptionLabel,
    disableClearable: true,
    open: true,
    ...props,
  })

  // changing the inputValue that is passed into the autocomplete hook might have some unintended side effects
  // as it re-renders the whole autocomplete as a new component, so we're modifying the input value outside the hook,
  // before passing it into the searchField
  const getUpdatedInputProps = (inputProps) => {
    const updatedAcInputValue =
      acInputValue === getOptionLabel(value) ? '' : acInputValue
    if (inputProps) inputProps.value = updatedAcInputValue
    return inputProps
  }

  const renderInputProps = {
    InputLabelProps: getInputLabelProps(),
    inputProps: getUpdatedInputProps(getInputProps()),
  }

  return (
    <div className="L3BusinessObjSelectorAutocomplete" {...getRootProps()}>
      <SearchField renderInputProps={renderInputProps} />
      {!loading && groupedOptions.length === 0 && (
        <div className="L3BusinessObjSelectorAutocomplete--noOptions">
          {isAltNav
            ? `Your ${label.toLowerCase()} will appear here for quick access once it is set up.`
            : 'No account names match your search'}
        </div>
      )}
      {loading && groupedOptions.length === 0 && (
        <div className="L3BusinessObjSelectorAutocomplete--loading">
          <Skeleton />
        </div>
      )}
      {groupedOptions.length > 0 && (
        <>
          <VirtualizedFixedSizeList
            height={
              groupedOptions.length < MAX_ITEMS_SHOWING
                ? ITEM_HEIGHT * groupedOptions.length
                : ITEM_HEIGHT * MAX_ITEMS_SHOWING
            }
            innerElementType={(vfxlProps) => (
              <List {...getListboxProps()} {...vfxlProps} />
            )}
            itemCount={groupedOptions.length}
            itemData={groupedOptions}
            itemSize={ITEM_HEIGHT}
            overscanCount={OVERSCAN_COUNT}
            width="100%"
          >
            {({ index, style }) => {
              const option = groupedOptions[index]
              const optionProps = getOptionProps({ option, index })
              return (
                <ListItem
                  className={clsx(
                    'MuiAutocomplete-option',
                    'L3BusinessObjSelectorAutocomplete-option',
                  )}
                  title={option.name}
                  style={style}
                  {...optionProps}
                >
                  {option.name}
                </ListItem>
              )
            }}
          </VirtualizedFixedSizeList>
        </>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.L3BusinessObjSelectorAutocomplete': {
      backgroundColor: theme.palette.grey[100],

      // .L3BusinessObjSelectorAutocomplete-option
      '&-option': {
        color: theme.palette.common.black,
        ...theme.typography.body2,
        minHeight: '36px',
        padding: '6px 10px',
        display: 'block',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',

        "&:hover, &[data-focus='true']": {
          backgroundColor: theme.palette.secondary.hover,
        },
        cursor: 'pointer',

        '&[aria-selected="true"]': {
          backgroundColor: theme.palette.secondary.active,
        },
      },

      // .L3BusinessObjSelectorAutocomplete--loading
      '&--loading': {
        padding: '13px 10px',

        '& .MuiSkeleton-root': {
          width: '100%',
        },
      },

      // .L3BusinessObjSelectorAutocomplete--noOptions
      '&--noOptions': {
        color: theme.palette.common.black,
        fontSize: theme.typography.caption.fontSize,
        fontStyle: 'italic',
        padding: '10px',

        '&.Mui-disabled': {
          opacity: 1,
        },

        '& > .MuiSvgIcon-root': {
          fontSize: '0.75rem',
          paddingRight: '4px',
        },
      },
    },
  },
}))
