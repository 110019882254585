import React from 'react'
import { GridLinkOperator } from '@liveramp/motif/core/RichDataTable'
import Button from '@liveramp/motif/core/Button'
import ButtonFormGroup from '@liveramp/motif/core/ButtonFormGroup'
import ButtonMenu from '@liveramp/motif/core/ButtonMenu'
import Filter from '@liveramp/motif/core/Filter'
import MenuItem from '@liveramp/motif/core/MenuItem'
import MenuList from '@liveramp/motif/core/MenuList'
import MultiFilter from '@liveramp/motif/core/MultiFilter'
import { markets, multiFilterOptions } from './RdtColumnDefinitions'
import makeStyles from '@liveramp/motif/styles/makeStyles'

export default function ToolbarActions(
  { apiRef },
  { deleteSearches },
  filterValue,
  setFilterValue,
  multiFilterValue,
  setMultiFilterValue,
) {
  const classes = useStyles()

  const difference = (newValue = [], oldValue = []) =>
    oldValue
      .filter((x) => !newValue.includes(x))
      .concat(newValue.filter((x) => !oldValue.includes(x)))

  const handleMarketsFilter = (diffValue, newFilterValue) => {
    diffValue.forEach(({ id }) => {
      if (newFilterValue.map((value) => value.id).includes(id)) {
        apiRef.current.upsertFilter({
          id: `markets-filter-${id}`,
          columnField: 'available_markets',
          operatorValue: 'contains',
          value: id,
        })
      } else {
        apiRef.current.deleteFilter({
          id: `markets-filter-${id}`,
        })
      }
    })
  }

  const handleReleaseYearFilter = (diffValue, newFilterValue) => {
    diffValue.forEach(({ id }) => {
      if (newFilterValue.map((value) => value.id).includes(id)) {
        apiRef.current.upsertFilter({
          id: `release-filter-gt-${id}`,
          columnField: 'release_date',
          operatorValue: 'onOrAfter',
          value: `${id}-01-01`,
        })
        apiRef.current.upsertFilter({
          id: `release-filter-lt-${id}`,
          columnField: 'release_date',
          operatorValue: 'before',
          value: `${id + 10}-12-31`,
        })
      } else {
        apiRef.current.deleteFilter({
          id: `release-filter-gt-${id}`,
        })
        apiRef.current.deleteFilter({
          id: `release-filter-lt-${id}`,
        })
      }
    })
  }

  const handlePopularityFilter = (diffValue, newFilterValue) => {
    diffValue.forEach(({ id }) => {
      if (newFilterValue.map((value) => value.id).includes(id)) {
        const idSplit = id.split('-')
        apiRef.current.upsertFilter({
          id: `popularity-filter-gt-${id}`,
          columnField: 'popularity',
          operatorValue: '>=',
          value: idSplit[0],
        })
        apiRef.current.upsertFilter({
          id: `popularity-filter-lt-${id}`,
          columnField: 'popularity',
          operatorValue: '<=',
          value: idSplit[1],
        })
      } else {
        apiRef.current.deleteFilter({
          id: `popularity-filter-gt-${id}`,
        })
        apiRef.current.deleteFilter({
          id: `popularity-filter-lt-${id}`,
        })
      }
    })
  }

  const handleAudioPreviewFilter = (diffValue, newFilterValue) => {
    diffValue.forEach(({ id }) => {
      if (newFilterValue.map((value) => value.id).includes(id)) {
        apiRef.current.upsertFilter({
          id: `audio-preview-filter-${id}`,
          columnField: 'preview_url',
          operatorValue: id === 'unavailable' ? 'isEmpty' : 'isNotEmpty',
        })
      } else {
        apiRef.current.deleteFilter({
          id: `audio-preview-filter-${id}`,
        })
      }
    })
  }

  const handleFilterChange = (e, newFilterValue) => {
    apiRef.current.applyFilterLinkOperator(GridLinkOperator.And)

    const diffValue = difference(newFilterValue, filterValue)
    handleMarketsFilter(diffValue, newFilterValue)

    setFilterValue(newFilterValue)
  }

  const handleMultiFilterChange = (e, newMultiFilterValue) => {
    apiRef.current.applyFilterLinkOperator(GridLinkOperator.And)

    if (newMultiFilterValue.length > 0) {
      multiFilterOptions.forEach((option) => {
        let newFvOptions = newMultiFilterValue.find(
          (newSingleFv) => newSingleFv.id === option.id,
        )?.options

        let oldFvOptions = multiFilterValue.find(
          (oldSingleFv) => oldSingleFv.id === option.id,
        )?.options

        apiRef.current.applyFilterLinkOperator(GridLinkOperator.And)
        const diffValue = difference(newFvOptions, oldFvOptions)

        if (option.id === 'release-year') {
          handleReleaseYearFilter(diffValue, newFvOptions)
        } else if (option.id === 'popularity') {
          handlePopularityFilter(diffValue, newFvOptions)
        } else if (option.id === 'audio-preview') {
          handleAudioPreviewFilter(diffValue, newFvOptions)
        }
      })
    } else {
      handleClearSearch()
    }

    setMultiFilterValue(newMultiFilterValue)
  }

  const handleClearSearch = () => {
    const filterItems = apiRef.current.state.filter.items
    if (filterItems.length > 0) {
      filterItems.map((item) => apiRef.current.deleteFilter(item))
    }

    setFilterValue([])
    deleteSearches()
  }

  return (
    <>
      <ButtonFormGroup>
        <Filter
          id="filter-markets"
          label="Available Markets"
          options={markets}
          value={filterValue}
          onChange={handleFilterChange}
          getOptionLabel={(option) => option.country}
        />
        <MultiFilter
          id="multifilter"
          label="Other Filters"
          options={multiFilterOptions}
          value={multiFilterValue}
          onApply={handleMultiFilterChange}
          getOptionLabel={(option) => option.label}
        />
        <Button onClick={handleClearSearch}>Clear Search</Button>
      </ButtonFormGroup>
      <ButtonFormGroup className={classes.endToolbar} end>
        <ButtonMenu>
          <MenuList>
            <MenuItem onClick={apiRef.current.exportDataAsCsv}>
              Download as CSV
            </MenuItem>
          </MenuList>
        </ButtonMenu>
      </ButtonFormGroup>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  endToolbar: {
    flex: 'auto',
  },
}))
