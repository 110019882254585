import React, { useState } from 'react'
import chroma from 'chroma-js'
import Fab from '../../core-components/Fab'
import Menu from '../../core-components/Menu'
import Tooltip from '../../core-components/Tooltip'
import makeStyles from '../../styles/makeStyles'
import { FabStyle, MenuStyle } from '../../utils/styleKeys'

export default function GlobalAdminButton({
  label,
  menu: menuContent,
  menuProps,
  ...props
}) {
  const fabClasses = useFabStyles()
  const menuClasses = useMenuStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => setAnchorEl(null)

  return menuContent ? (
    <div>
      <Tooltip className="GlobalAdminButton-tooltip" title={label}>
        <Fab
          className="GlobalAdminButton-fab"
          classes={fabClasses}
          onClick={handleClick}
          {...props}
        />
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        classes={menuClasses}
        className="GlobalAdminButton-menu"
        elevation={6}
        getContentAnchorEl={null}
        onClick={handleClose}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        {...menuProps}
      >
        {typeof menuContent === 'function'
          ? menuContent(handleClose)
          : menuContent}
      </Menu>
    </div>
  ) : (
    <Tooltip className="GlobalAdminButton-tooltip" title={label}>
      <Fab className="GlobalAdminButton-fab" classes={fabClasses} {...props} />
    </Tooltip>
  )
}

const useFabStyles = makeStyles((theme) => ({
  ...FabStyle,
  root: {
    boxShadow: theme.shadows[2],
    width: '28px',
    height: '28px',
    minWidth: '28px',
    minHeight: '28px',
    backgroundColor: theme.palette.grey[900],
    border: `2px solid ${theme.palette.grey[900]}`,

    '&:hover': {
      backgroundColor: chroma(theme.palette.grey[900]).alpha(0.85).css(),
      border: `2px solid transparent`,
    },

    '& .MuiFab-label': {
      color: theme.palette.common.white,
      fontFamily: theme.typography.fontFamilyHeader,
      width: '24px',
      height: '24px',
      fontSize: '24px',

      '& > *': {
        fontSize: '24px',
        width: '24px',
        height: '24px',
      },
    },
  },
}))

const useMenuStyles = makeStyles((theme) => ({
  ...MenuStyle,
  paper: {
    marginTop: '8px',
  },
}))
