import React, { createRef } from 'react'
import RichDataTable, {
  useGridSlotComponentProps,
  GridToolbarContainer,
} from '@liveramp/motif/core/RichDataTable'
import { SettingsMenu } from '@liveramp/motif/core/RichDataTableSlots'
import ButtonFormGroup from '@liveramp/motif/core/ButtonFormGroup'
import Button from '@liveramp/motif/core/Button'
import { columns, rows, generateRows } from '../../demos/data'

const Toolbar = () => {
  const { apiRef } = useGridSlotComponentProps()
  const toolbarRef = createRef()

  const handleRandomizeClick = () => {
    apiRef.current.updateRows(generateRows())
  }

  const handleDeleteClick = () => {
    let selectedRows = apiRef.current.getSelectedRows()
    if (selectedRows.length > 0) {
      apiRef.current.updateRows(
        selectedRows.map((row) => ({ ...row, _action: 'delete' })),
      )
    }
  }

  const handleResetClick = () => {
    apiRef.current.updateRows(
      apiRef.current.getAllRowIds().map((id) => ({ id, _action: 'delete' })),
    )
    apiRef.current.updateRows(rows)
  }

  return (
    <>
      <GridToolbarContainer ref={toolbarRef}>
        <ButtonFormGroup start>
          <Button onClick={handleRandomizeClick}>Randomize Rows</Button>
          <Button onClick={handleDeleteClick}>Delete Selected Rows</Button>
          <Button onClick={handleResetClick}>Reset Original Rows</Button>
        </ButtonFormGroup>
      </GridToolbarContainer>
      <SettingsMenu apiRef={apiRef} toolbarRef={toolbarRef} />
    </>
  )
}

export default function SlotsDGToolbarDemo() {
  return (
    <RichDataTable
      columns={columns}
      rows={rows}
      components={{
        Toolbar: () => <Toolbar />,
      }}
    />
  )
}
