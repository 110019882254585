import React from 'react'
import ReactMarkdown from 'react-markdown'
import Table from '@liveramp/motif/core/Table'
import TableHeader from '@liveramp/motif/core/TableHeader'
import TableBody from '@liveramp/motif/core/TableBody'
import TableRow from '@liveramp/motif/core/TableRow'
import TableCell from '@liveramp/motif/core/TableCell'
import { GenerateName, generateDesc } from './TableUtils'
import css from '../resources/Page.module.css'

// Sort the props so that all required props are floated
// to the top of the table for higher visibility.
export function sortByRequiredAndAlphabetical(props) {
  let keys = Object.keys(props)
  return keys
    .sort((a, b) => a.localeCompare(b))
    .sort((a, b) => {
      if (a === 'classes' || b === 'classes') {
        return 0
      }
      return props[b].required - props[a].required
    })
}

// Styles unique types in a visually distinguishable way:
export function generateType(type) {
  if (typeof type.name === 'undefined') return ''

  let typeValue = type.name
  if (type.value || type.raw) {
    if (type.name === 'enum') {
      // enum: [ a, b, c ]
      typeValue = '[' + type.value.map((x) => x.value).join(' | ') + ']'
    } else if (type.name === 'union') {
      // union: a OR b OR c
      typeValue = type.value
        .map((subtype) => generateType(subtype))
        .join(' OR ')
    } else if (type.name === 'shape') {
      // shape: { a, b, c }
      typeValue = '{ '
      typeValue += Object.keys(type.value)
        .map((key) => `${key}: ${generateType(type.value[key])}`)
        .join(', ')
      typeValue += ' }'
    } else if (type.name === 'custom') {
      // custom: element
      if (/elementAcceptingRef/.test(type.raw)) {
        typeValue = 'element'
      } else if (/elementTypeAcceptingRef/.test(type.raw)) {
        typeValue = 'elementType'
      } else {
        typeValue = type.raw
      }
    }
  }
  return typeValue
}

export default function PropsTable({ props }) {
  let buildBody = () => {
    let keys = sortByRequiredAndAlphabetical(props)
    return keys.map((key) => {
      let row = props[key]
      if (row.description.match(/@ignore/)) return null
      let name = <GenerateName required={row.required}>{key}</GenerateName>
      let type = generateType(row.type)
      let defaultValue = row.defaultValue && (
        <code>{row.defaultValue.value}</code>
      )
      let description = generateDesc(row.description)

      return (
        <TableRow key={key}>
          <TableCell size="small">
            <code>{name}</code>
          </TableCell>
          <TableCell size="small">
            <code>{type}</code>
          </TableCell>
          <TableCell size="small">{defaultValue}</TableCell>
          <TableCell>
            <ReactMarkdown className={css.markdown} source={description} />
          </TableCell>
        </TableRow>
      )
    })
  }

  let emptyBody = () => (
    <TableRow hover={false}>
      <TableCell colSpan={4}>No props found.</TableCell>
    </TableRow>
  )

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableCell size="small">Name</TableCell>
          <TableCell size="small">Type</TableCell>
          <TableCell size="small">Default</TableCell>
          <TableCell>Description</TableCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        {Object.keys(props).length > 0 ? buildBody() : emptyBody()}
      </TableBody>
    </Table>
  )
}
