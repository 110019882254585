import React, { useContext } from 'react'
import clsx from 'clsx'
import Tooltip from '../../core-components/Tooltip'
import List from '../../core-components/List'
import { GlobalNavigationContext, handleRoute } from './GlobalNavigation'
import makeStyles from '../../styles/makeStyles'

function areEqual(node, other) {
  return node.label === other.label && node.level === other.level
}

function isNodeActive(node, activeL3) {
  if (!activeL3) {
    return false
  }
  return areEqual(node, activeL3) || isNodeActive(node, activeL3.parent)
}

function L1L2Item(props) {
  const {
    customisation,
    displayLevel,
    setDisplayLevel,
    skipL2,
    setCurrentL3,
    currentL3,
    isAltNav,
  } = useContext(GlobalNavigationContext)

  useStyles({ customisation })

  const { icon: Icon, label, children, route, onNavigate } = props
  const renderL2 = !skipL2 && children?.length > 1 && children[0].level === 2
  const displayL2 = renderL2 && displayLevel === 2

  const active = isNodeActive(props, currentL3)

  const L1ItemWrapper = ({ children, ...props }) => {
    return (
      <div className="L1ItemWrapper" {...props}>
        {isAltNav ? (
          children
        ) : (
          <Tooltip
            className="GNL1L2Item-tooltip"
            title={label}
            placement="right"
          >
            {children}
          </Tooltip>
        )}
      </div>
    )
  }

  return (
    <li
      className={clsx('GNL1L2Item-root', {
        ['l1l2']: renderL2,
        ['l1l2Open']: displayL2,
        ['activeL1']: displayL2 && active,
        ['activeL2']: (!renderL2 || displayLevel !== 2) && active,
        ['activel1IsAltNav']:
          (!renderL2 || displayLevel !== 2) && active && isAltNav,
        ['l1IsAltNav']: isAltNav,
      })}
    >
      <L1ItemWrapper
        onClick={() => {
          if (renderL2) {
            setCurrentL3(children[0])
            setDisplayLevel(2)
          } else if (children) {
            if (children[0]?.level === 2) {
              setCurrentL3(children[0])
              setDisplayLevel(3)
            } else {
              setCurrentL3(props)
              setDisplayLevel(3)
            }
          } else {
            setCurrentL3(props)
            setDisplayLevel(1)
            handleRoute(route, onNavigate)
          }
        }}
      >
        <Icon className="GNL1L2Item-icon" />
        {isAltNav ? <span className="L1Item-label">{label}</span> : null}
      </L1ItemWrapper>
      {displayL2 && (
        <List className="GNL1L2Item-list" disablePadding>
          {children.map((l2Item) => (
            <li
              key={l2Item.label}
              className={clsx('GNL1L2Item-L2', {
                ['activeL2']: isNodeActive(l2Item),
              })}
              onClick={() => {
                if (l2Item?.children) {
                  setDisplayLevel(3)
                  setCurrentL3(l2Item)
                } else {
                  setCurrentL3(null)
                  handleRoute(l2Item.route)
                }
              }}
            >
              {l2Item.label}
            </li>
          ))}
        </List>
      )}
    </li>
  )
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.L1ItemWrapper': {
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: 'rgba(255, 255, 255, 0.18)',
      },
      '& > .L1Item-label': {
        display: 'block',
        fontSize: '10px',
        fontWeight: '100',
        textAlign: 'center',
        lineHeight: '1.825',
        color: '#fff',
        // keep label on one line
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'clip',
      },
    },
    '.GNL1L2Item-root': {
      display: 'grid',
      gridTemplateColumns: '50px',

      '& > .L1ItemWrapper': {
        height: '36px',
      },
    },
    // isAltNav
    '.l1IsAltNav': {
      '& > .L1ItemWrapper': {
        display: 'flex',
        flexDirection: 'column',
        height: '68px',
        paddingTop: '6px',
        paddingBottom: '6px',
      },
    },
    '.l1l2': {
      gridTemplateColumns: '50px 1fr',
      '& .GNL1Item-list': {
        height: 0,
        transition: theme.transitions.common.leavingScreen,
      },
    },
    '.l1l2Open': {
      '& .GNL1Item-list': {
        height: 'auto',
        transition: theme.transitions.common.enteringScreen,
      },
    },
    '.activeL1': {
      '&:not(:hover) > div': {
        backgroundColor: 'rgba(255, 255, 255, 0.18)',
      },
    },
    '.activeL2': {
      backgroundColor: 'rgba(255, 255, 255, 0.18)',
      borderRight: ({ customisation }) =>
        `6px solid ${
          customisation?.theme
            ? customisation.theme
            : theme.palette.primary.main
        }`,
    },
    // isAltNav
    '.activel1IsAltNav': {
      borderRight: ({ customisation }) =>
        `3px solid ${
          customisation?.theme
            ? customisation.theme
            : theme.palette.primary.main
        }`,
    },
    '.GNL1L2Item-list': {
      transition: theme.transitions.common.short,
    },
    '.GNL1L2Item-icon': {
      fill: theme.palette.common.white,
      padding: '6px 13px',
      boxSizing: 'content-box',
    },
    '.GNL1L2Item-L2': {
      height: '36px',
      display: 'flex',
      alignItems: 'center',
      padding: '6px 13px',
      paddingLeft: '10px',
      color: theme.palette.common.white,
      fontSize: '14px',
      fontWeight: '200',
      whiteSpace: 'nowrap',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.18)',
      },
    },
  },
}))

export default L1L2Item
