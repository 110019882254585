import React from 'react'
import clsx from 'clsx'
import Paper from '@liveramp/motif/core/Paper'
import VirtualizedFixedSizeList from '@liveramp/motif/core/VirtualizedFixedSizeList'
import ListItem from '@liveramp/motif/core/ListItem'
import ListItemText from '@liveramp/motif/core/ListItemText'
import { makeStyles } from '@liveramp/motif/utils'
import css from '../../../resources/Page.module.css'

export default function VirtualizedFixedSizeListDemo() {
  const classes = useStyles()
  const itemCount = 1500
  const vHeight = 36
  const vWidth = 150

  const hHeight = 72
  const hWidth = 100

  return (
    <div className={css.grid}>
      <div>
        <h5>Vertical List</h5>
        <Paper>
          <VirtualizedFixedSizeList
            itemCount={itemCount}
            height={vHeight * 4}
            width={vWidth}
            itemSize={vHeight}
          >
            {({ index, style }) => (
              <ListItem
                button
                className={clsx({ [classes.even]: index % 2 === 0 })}
                key={index}
                style={style}
              >
                <ListItemText>Item {index + 1}</ListItemText>
              </ListItem>
            )}
          </VirtualizedFixedSizeList>
        </Paper>
      </div>
      <div>
        <h5>Horizontal List</h5>
        <Paper>
          <VirtualizedFixedSizeList
            itemCount={itemCount}
            height={hHeight}
            width={hWidth * 4}
            itemSize={hWidth}
            layout="horizontal"
          >
            {({ index, style }) => (
              <ListItem
                button
                className={clsx({ [classes.even]: index % 2 === 0 })}
                key={index}
                style={style}
              >
                <ListItemText>Item {index + 1}</ListItemText>
              </ListItem>
            )}
          </VirtualizedFixedSizeList>
        </Paper>
      </div>
    </div>
  )
}

let useStyles = makeStyles((theme) => ({
  even: { backgroundColor: theme.palette.grey[50] },
}))
