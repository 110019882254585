import React, { Component } from 'react'
import Button from '@liveramp/motif/core/Button'
import Tooltip from '@liveramp/motif/core/Tooltip'

export default class ControlledDemo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      count: 0,
      open: false,
    }
  }

  handleTooltipClose = () => {
    if (this.state.count > 10) {
      this.setState({ count: 0, open: false })
    }
  }

  handleTooltipOpen = () => {
    this.setState({ count: this.state.count + 1, open: true })
  }

  render() {
    return (
      <Tooltip
        onClose={this.handleTooltipClose}
        onOpen={this.handleTooltipOpen}
        open={this.state.open}
        title="I'm a controlled tooltip"
      >
        <div>
          <Button variant="secondary">
            This tooltip will only close when the count is greater than 10
            <br />
            Count: {this.state.count}
          </Button>
        </div>
      </Tooltip>
    )
  }
}
