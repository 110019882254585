/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: '',
  description:
    'The Grow transition is used by the [Tooltip](/components/tooltips/) and\r\n[Popover](/components/popover/) components.\r\nIt uses [react-transition-group](https://github.com/reactjs/react-transition-group) internally.',
  displayName: 'Grow',
  methods: [],
  props: {
    children: {
      type: { name: 'element' },
      required: false,
      description: 'A single child content element.',
    },
    disableStrictModeCompat: {
      type: { name: 'bool' },
      required: false,
      description:
        "Enable this prop if you encounter 'Function components cannot be given refs',\r\nuse `unstable_createStrictModeTheme`,\r\nand can't forward the ref in the child component.",
      defaultValue: { value: 'false', computed: false },
    },
    in: {
      type: { name: 'bool' },
      required: false,
      description:
        'If `true`, show the component; triggers the enter or exit animation.',
    },
    onEnter: {
      type: { name: 'func' },
      required: false,
      description: '@ignore',
    },
    onEntered: {
      type: { name: 'func' },
      required: false,
      description: '@ignore',
    },
    onEntering: {
      type: { name: 'func' },
      required: false,
      description: '@ignore',
    },
    onExit: { type: { name: 'func' }, required: false, description: '@ignore' },
    onExited: {
      type: { name: 'func' },
      required: false,
      description: '@ignore',
    },
    onExiting: {
      type: { name: 'func' },
      required: false,
      description: '@ignore',
    },
    style: {
      type: { name: 'object' },
      required: false,
      description: '@ignore',
    },
    timeout: {
      type: {
        name: 'union',
        value: [
          { name: 'enum', value: [{ value: "'auto'", computed: false }] },
          { name: 'number' },
          {
            name: 'shape',
            value: {
              appear: { name: 'number', required: false },
              enter: { name: 'number', required: false },
              exit: { name: 'number', required: false },
            },
          },
        ],
      },
      required: false,
      description:
        "The duration for the transition, in milliseconds.\r\nYou may specify a single timeout for all transitions, or individually with an object.\r\n\r\nSet to 'auto' to automatically calculate transition time based on height.",
      defaultValue: { value: "'auto'", computed: false },
    },
  },
  styles: {},
}
export default generatedDocs
