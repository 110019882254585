import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import IconButton from '../../core-components/IconButton'
import EditIcon from '../../icons/Edit'
import makeStyles from '../../styles/makeStyles'

export default function SidePanelFieldValueRow({
  className,
  children,
  editAction,
  fieldValueProps = {},
  ...props
}) {
  useStyles()

  let fieldValuePropsClassName = fieldValueProps.className
  delete fieldValueProps.className
  return (
    <div
      className={clsx(
        'SidePanelFieldValueRow',
        'SidePanelFieldValueRow-root',
        className,
      )}
      {...props}
    >
      <div
        className={clsx(
          'SidePanelFieldValueRow__fieldValue',
          'SidePanelFieldValueRow-fieldValue',
          editAction ? 'SidePanelFieldValueRow__withEdit' : '',
          fieldValuePropsClassName,
        )}
        {...fieldValueProps}
      >
        {children}
      </div>
      {editAction && (
        <IconButton
          onClick={editAction}
          className="SidePanelFieldValueRow__edit-action SidePanelFieldValueRow-editAction"
        >
          <EditIcon />
        </IconButton>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.SidePanelFieldValueRow': {
      backgroundColor: theme.palette.grey[100],
      padding: '16px',
      position: 'relative',

      '&:first-child': {
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
      },

      '&:last-child': {
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
      },

      // .SidePanelFieldValueRow__fieldValue
      '&__fieldValue': {
        display: 'flex',
        flexDirection: 'row',
        gap: '16px',
      },

      '&__withEdit': {
        paddingRight: '16px',
      },

      // .SidePanelFieldValueRow__edit-action
      '&__edit-action': {
        position: 'absolute',
        top: '2px',
        right: '2px',
      },
    },
  },
}))

SidePanelFieldValueRow.propTypes = {
  children: PropTypes.node.isRequired,
  editAction: PropTypes.func,
}
