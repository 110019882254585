import React from 'react'
import Drawer from '@liveramp/motif/core/Drawer'
import Button from '@liveramp/motif/core/Button'
import List from '@liveramp/motif/core/List'
import ListItem from '@liveramp/motif/core/ListItem'
import ListItemIcon from '@liveramp/motif/core/ListItemIcon'
import ListItemText from '@liveramp/motif/core/ListItemText'
import Divider from '@liveramp/motif/core/Divider'
import Activate from '@liveramp/motif/icons/Activate'
import Puzzle from '@liveramp/motif/icons/Puzzle'
import Heart from '@liveramp/motif/icons/Favorite'
import Settings from '@liveramp/motif/icons/Settings'
import User from '@liveramp/motif/icons/User'
import css from './Temporary.module.css'

export default function Simple() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  const list = (anchor) => (
    <div
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      className={css.temporary}
    >
      <List>
        {[
          { text: 'Activate', icon: <Activate /> },
          { text: 'Favorite', icon: <Heart /> },
          { text: 'Search', icon: <Puzzle /> },
        ].map(({ text, icon, i }) => {
          return (
            <ListItem key={text}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          )
        })}
      </List>
      <Divider />
      <List>
        {[
          { text: 'Settings', icon: <Settings /> },
          { text: 'User', icon: <User /> },
        ].map(({ text, icon, i }) => {
          return (
            <ListItem key={text}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          )
        })}
      </List>
    </div>
  )

  return (
    <div>
      {['left', 'right', 'top', 'bottom'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button variant="text" onClick={toggleDrawer(anchor, true)}>
            {anchor}
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            variant="temporary"
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  )
}
