import React from 'react'
import Card from '@liveramp/motif/core/Card'
import CardActions from '@liveramp/motif/core/CardActions'
import CardContent from '@liveramp/motif/core/CardContent'
import CardHeader from '@liveramp/motif/core/CardHeader'
import Avatar from '@liveramp/motif/core/Avatar'
import Button from '@liveramp/motif/core/Button'
import css from './Simple.module.css'

export default function SimpleDemo() {
  return (
    <Card className={css.card}>
      <CardHeader
        title="Simple Card Header"
        avatar={
          <Avatar className={css.avatar} aria-label="simple">
            S
          </Avatar>
        }
      />
      <CardContent>
        Simple card content. Typical content inside of a card is text or media.
      </CardContent>
      <CardActions>
        <Button variant="outlined">Secondary</Button>
        <Button variant="primary">Primary</Button>
      </CardActions>
    </Card>
  )
}
