import React, { useState } from 'react'
import Checkbox from '@liveramp/motif/core/Checkbox'

export default function SimpleDemo() {
  let [checked, setChecked] = useState(true)

  return (
    <Checkbox
      checked={checked}
      onChange={() => setChecked(!checked)}
      value="checked"
    />
  )
}
