import React from 'react'
import {
  Page,
  Title,
  Subtitle,
  Supporting,
  Usage,
  SectionTitle,
} from '../../resources/DemoPage'
import Demo, { generateDemos } from '../../resources/Demo'

import Simple from './demos/Simple'
import CustomLabel from './demos/CustomLabel'

let demoNames = ['Simple', 'Custom Label']

export default function Dialog() {
  let demos = generateDemos('core-component-demo/status', demoNames)

  return (
    <Page components={['Status']}>
      <Title>Statuses</Title>
      <Subtitle>
        Statuses express an indicator that can be used as a part of any other
        component
      </Subtitle>
      <Supporting>
        Statuses inform users of a current status according to the outside logic
      </Supporting>
      <Usage>
        <ul>
          <li>
            General usage of statuses is to display status of table row if
            needed
          </li>
          <li>Status can be used in any other form where you need it</li>
        </ul>
      </Usage>
      <SectionTitle>Status variants</SectionTitle>
      <p>
        There are multiple status variants and according to prop{' '}
        <code>variant</code> color and text styles will be applied
      </p>
      <p>
        Prop <code>variant</code> is required and there are supported values:
      </p>
      <div>
        <code>active</code>,<code>error</code>,<code>pending</code>,
        <code>scheduled</code>,<code>revoked</code>,<code>expired</code>,
      </div>
      For backwards compatibility reasons we also support `success`, `running`
      and `canceled`, which appeared in an earlier version of this package.
      Those variants will be removed in a future version and should be migrated
      away from.
      <Demo demo={demos.Simple}>
        <Simple />
      </Demo>
      <SectionTitle>Custom Label</SectionTitle>
      <p>
        You can provide a custom label to Status by using the{' '}
        <code>customLabel</code> prop.
      </p>
      <Demo demo={demos.Simple}>
        <CustomLabel />
      </Demo>
    </Page>
  )
}
