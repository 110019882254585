import React, { Component } from 'react'
import chroma from 'chroma-js'
import PropTypes from 'prop-types'
import { Motif2DeprecationWarning } from '../../utils/internal'
import withStyles from '../../styles/withStyles'

class Item extends Component {
  constructor(props) {
    super(props)
    this.state = {
      flowUpwards: false,
    }
    this.menuRef = React.createRef()
    Motif2DeprecationWarning('VerticalNav', 'GlobalNavigation')
  }

  setMenuFlow = () => {
    if (this.menuRef.current) {
      let menuBottomPosition =
        this.menuRef.current.getBoundingClientRect().bottom
      let containerHeight = this.menuRef.current
        .closest('div[data-motif="VerticalNav"]')
        .parentElement.getBoundingClientRect().bottom
      if (menuBottomPosition > containerHeight) {
        this.setState({ flowUpwards: true })
      }
    }
  }

  render(
    {
      classes,
      className,
      active,
      caretColor,
      icon,
      text,
      children,
      ...props
    } = this.props,
  ) {
    let menu = null
    if (children !== null) {
      let menuClasses = [classes.menu]
      if (this.state.flowUpwards) {
        menuClasses.push(classes.menuUp)
      } else {
        if (icon && !text) {
          menuClasses.push(classes.menuIconOnly)
        }
        if (!icon && text) {
          menuClasses.push(classes.menuTextOnly)
        }
      }

      menu = (
        <div ref={this.menuRef} className={menuClasses.join(' ')}>
          {React.Children.map(children, (child) =>
            React.cloneElement(child, { className: classes.menuItem }),
          )}
        </div>
      )
    }

    return (
      <div
        className={`
          ${classes.item}
          ${active ? classes.active : ''}
          ${!menu ? 'Item-noSubMenu' : ''}
          ${className}`}
        onMouseEnter={menu ? this.setMenuFlow : () => {}}
        {...props}
      >
        {icon ? <div className={classes.icon}>{icon}</div> : ''}
        {text ? <div className={classes.text}>{text}</div> : ''}
        {menu}
      </div>
    )
  }
}

const styles = (theme) => ({
  '@keyframes animateItem': {
    '0%': {
      display: 'none',
      opacity: 0,
    },
    '1%': {
      display: 'block',
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px 0',
    cursor: (props) => (props.onClick ? 'pointer' : 'default'),
    position: 'relative',
    transition: theme.transitions.common.enteringScreen,
    '&:hover': {
      backgroundColor: chroma('white')
        .alpha(theme.palette.action.hoverOpacity)
        .css(),
    },
    '&:hover $text, &:hover $icon g': {
      fill: theme.palette.common.white,
      color: theme.palette.common.white,
    },
    '&:hover $menu': {
      display: 'block',
      // transitions will not affect display, but animations do!
      animation: '$animateItem 225ms cubic-bezier(0.4, 0, 0.2, 1)',
    },
    '&:hover:not(.Item-noSubMenu)::after': {
      borderStyle: 'solid',
      borderWidth: '7px 7px 7px 0',
      borderColor: `transparent ${theme.palette.grey[800]} transparent transparent`,
      content: "' '",
      position: 'absolute',
      left: '53px', // = nav width (60px) - triangle width (7px)
      top: 'calc(50% - 7px)', // = height of nav item - height of triangle (7px)
    },
  },
  active: {
    '&::after': {
      borderStyle: 'solid',
      borderWidth: '7px 7px 7px 0',
      borderColor: (props) =>
        `transparent ${
          props.caretColor ? props.caretColor : '#fff'
        } transparent transparent`,
      content: "' '",
      position: 'absolute',
      left: '53px', // = nav width (60px) - triangle width (7px)
      top: 'calc(50% - 7px)', // = height of nav item - height of triangle (7px)
    },
  },
  icon: {
    // removes padding-bottom from display: inline-block
    '& svg': {
      display: 'block',
      padding: '2px',
    },
    '& g': {
      fill: theme.palette.grey[400],
      transition: theme.transitions.common.enteringScreen,
    },
  },
  text: {
    fontSize: '14px',
    textAlign: 'center',
    color: theme.palette.grey[400],
    textShadow: `0 0 0 ${chroma(theme.palette.grey[400]).alpha(0.87)}`,
    transition: theme.transitions.common.enteringScreen,
  },
  menu: {
    display: 'none',
    top: '4px', // * see notes
    left: '60px',
    position: 'absolute',
    background: theme.palette.grey[800],
    minWidth: '180px',
    maxWidth: '275px',
    maxHeight: '400px',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  menuUp: {
    top: 'unset',
    bottom: '-4px', // * see notes
  },
  menuTextOnly: {
    top: '-9px', // * see notes
  },
  menuIconOnly: {
    top: '-5px', // * see notes
  },
  menuItem: {
    cursor: 'pointer',
    padding: '20px',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: theme.palette.common.white,
    transition: theme.transitions.common.enteringScreen,
    '&:hover': {
      background: `${chroma.mix(
        theme.palette.grey[700],
        theme.palette.grey[800],
      )}`,
    },
  },
})

export default withStyles(styles, { name: 'MuiVerticalNavItem' })(Item)

// * These pixel sizes have been calculated given the following factors:
// - height of a single element menu = 61px
//
// - height of a combined nav item = 69px
// - height of an icon only nav item = 50px
// - height of a text only nav item = 41px

Item.propTypes = {
  active: PropTypes.bool,
  caretColor: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.object,
  onClick: PropTypes.func,
  text: PropTypes.string,
}

Item.defaultProps = {
  active: false,
  caretColor: '#fff',
  children: null,
  className: '',
  icon: null,
  onClick: () => {},
  text: '',
}
