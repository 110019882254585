import React, { Component } from 'react'
import Prism from 'prismjs'
import 'prismjs/components/prism-jsx'
import 'prismjs/components/prism-sass'
import 'prismjs/components/prism-css-extras'
import './prism/prism-json'
import './prism/prism-treeview'
import './prism/prism.css'

// if you find your custom prism language isn't being highlighted, you may need
// to include the relevant prism component
export default class CodeChunk extends Component {
  componentDidMount() {
    Prism.highlightAll()
  }

  reindent = (input) => {
    // if there's only one line, return
    let inputSplit = input.split('\n')
    if (inputSplit.length <= 1) return input

    // if there's no leading spaces in the next line
    // string was from a file and already correctly formatted, return
    let first = inputSplit[1].match(/^\s+/)
    if (!first) return input

    // if the end of the first string is an open bracket or tag, reduce the
    // leading spaces to remove by two to properly indent the next line
    first = first[0]
    if (inputSplit[0].match(/[^/][{>]$/)) {
      first = first.substring(2)
    }

    return input
      .split('\n')
      .map((line) => line.replace(new RegExp('^' + first), ''))
      .join('\n')
  }

  render({ jsx, css, sass, language, children, ...props } = this.props) {
    let lang
    if (css) {
      lang = 'css'
    } else if (sass) {
      lang = 'sass'
    } else if (language) {
      lang = language
    } else {
      lang = 'jsx'
      jsx = true
    }

    let code
    if (typeof children !== 'string') {
      if (jsx) code = children.jsx
      if (css) code = children.css
    } else {
      code = this.reindent(children)
    }

    return (
      <pre>
        <code className={`language-${lang}`} {...props}>
          {code}
        </code>
      </pre>
    )
  }
}
