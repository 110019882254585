/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: 'MuiStep',
  description: '',
  displayName: 'Step',
  methods: [],
  props: {
    active: {
      type: { name: 'bool' },
      required: false,
      description: 'Sets the step as active. Is passed to child components.',
      defaultValue: { value: 'false', computed: false },
    },
    children: {
      type: { name: 'node' },
      required: false,
      description:
        'Should be `Step` sub-components such as `StepLabel`, `StepContent`.',
    },
    classes: {
      type: { name: 'object' },
      required: false,
      description:
        'Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details.',
    },
    className: {
      type: { name: 'string' },
      required: false,
      description: '@ignore',
    },
    completed: {
      type: { name: 'bool' },
      required: false,
      description: 'Mark the step as completed. Is passed to child components.',
      defaultValue: { value: 'false', computed: false },
    },
    disabled: {
      type: { name: 'bool' },
      required: false,
      description:
        'Mark the step as disabled, will also disable the button if\r\n`StepButton` is a child of `Step`. Is passed to child components.',
      defaultValue: { value: 'false', computed: false },
    },
    expanded: {
      type: { name: 'bool' },
      required: false,
      description: 'Expand the step.',
      defaultValue: { value: 'false', computed: false },
    },
  },
  styles: {
    root: { description: 'Styles applied to the root element.' },
    horizontal: {
      description:
        'Styles applied to the root element if `orientation="horizontal"`.',
    },
    vertical: {
      description:
        'Styles applied to the root element if `orientation="vertical"`.',
    },
    alternativeLabel: {
      description:
        'Styles applied to the root element if `alternativeLabel={true}`.',
    },
    completed: {
      description:
        'Pseudo-class applied to the root element if `completed={true}`.',
    },
  },
}
export default generatedDocs
