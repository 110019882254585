import React from 'react'
import chroma from 'chroma-js'
import MuiSnackbarContent from '@material-ui/core/SnackbarContent'
import Button from '../Button'
import CloseButton from '../CloseButton'
import withStyles from '../../styles/withStyles'
import CheckCircleOutline from '../../icons/CheckCircleOutline'
import WarningOutline from '../../icons/WarningOutline'
import ErrorOutline from '../../icons/ErrorOutline'
import InfoOutline from '../../icons/InfoOutline'
import PrivacyOutline from '../../icons/PrivacyOutline'

function Content({
  children,
  classes,
  icon,
  onClickButton,
  onClickButtonLabel,
  onClose,
  variant,
  showCloseIcon = true,
  ...props
}) {
  if (!['info', 'success', 'warning', 'danger', 'privacy'].includes(variant))
    variant = 'info'
  let alertClasses = {
    root: classes[`${variant}Root`],
    action: classes.action,
  }
  let buttonClasses = classes[`${variant}Button`]
  let iconComponent = (variant) => {
    if (variant === 'success')
      return (
        <CheckCircleOutline
          className={`${classes.iconPadding} ${classes.successIcon}`}
        />
      )
    if (variant === 'warning')
      return (
        <WarningOutline
          className={`${classes.iconPadding} ${classes.warningIcon}`}
        />
      )
    if (variant === 'danger')
      return (
        <ErrorOutline
          className={`${classes.iconPadding} ${classes.errorIcon}`}
        />
      )
    if (variant === 'privacy') {
      return (
        <PrivacyOutline
          className={`${classes.iconPadding} ${classes.privacyIcon}`}
        />
      )
    }
    return (
      <InfoOutline className={`${classes.iconPadding} ${classes.infoIcon}`} />
    )
  }

  let message = (
    <span className={classes.message}>
      {icon && iconComponent(variant)}
      {children}
    </span>
  )

  let action = (
    <span>
      {onClickButton && onClickButtonLabel !== '' && (
        <Button
          variant="text"
          color="secondary"
          className={buttonClasses}
          onClick={onClickButton}
        >
          {onClickButtonLabel}
        </Button>
      )}
      {showCloseIcon && <CloseButton onClick={onClose} />}
    </span>
  )

  return (
    <MuiSnackbarContent
      classes={alertClasses}
      message={message}
      action={action}
      {...props}
    />
  )
}

let defaultStyle = (theme, color) => {
  return {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: '500',
    flexGrow: '1',
    maxWidth: 'none',
    margin: '12px',
    color: theme.palette.text.primary,
    textShadow: `0 0 0 ${theme.palette.text.primary}`,
    backgroundColor: chroma(color).css(),
    flexWrap: 'nowrap',
  }
}

let styles = (theme) => ({
  infoRoot: defaultStyle(theme, theme.palette.info.hue[50]),
  successRoot: defaultStyle(theme, theme.palette.success.hue[50]),
  warningRoot: defaultStyle(theme, theme.palette.warning.hue[50]),
  dangerRoot: defaultStyle(theme, theme.palette.danger.hue[50]),
  privacyRoot: defaultStyle(theme, theme.palette.privacy.hue[50]),

  infoIcon: { fill: theme.palette.info.dark },
  successIcon: { fill: theme.palette.success.dark },
  warningIcon: { fill: theme.palette.warning.dark },
  errorIcon: { fill: theme.palette.danger.dark },
  privacyIcon: { fill: theme.palette.privacy.dark },

  message: {
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    gap: '8px',
  },
  iconPadding: {
    height: '20px',
    width: '24px',
  },
  action: {
    flexShrink: 0,
  },
})

export default withStyles(styles)(Content)
