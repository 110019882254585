import React, { useEffect, createRef, useState } from 'react'
import clsx from 'clsx'
import CardHeader from '../../core-components/CardHeader'
import Tooltip from '../../core-components/Tooltip'
import { makeStyles, mergeClasses } from '../../utils'
import { CardHeaderStyle } from '../../utils/styleKeys'

export default function LogoCardHeader({
  classes,
  className,
  status = null,
  title,
  tooltipProps,
  ...props
}) {
  const [showTooltip, setShowTooltip] = useState(true)
  const [openTooltip, setOpenTooltip] = useState(false)
  const ref = createRef()
  const defaultClasses = useStyles()

  const lineHeight = 40 // max-height(56px) - padding(16px)

  useEffect(() => {
    // default to always showing the tooltip on hover.
    if (ref?.current.scrollHeight <= lineHeight) setShowTooltip(false)
  }, [ref])

  return (
    <CardHeader
      data-testid="LogoCardHeader"
      action={status}
      className={clsx('LogoCardHeader', 'LogoCardHeader-root', className)}
      classes={mergeClasses(defaultClasses, classes)}
      title={
        <Tooltip
          title={title}
          open={showTooltip && openTooltip}
          onClose={() => setOpenTooltip(false)}
          onOpen={() => setOpenTooltip(true)}
          {...tooltipProps}
        >
          <div ref={ref}>{title}</div>
        </Tooltip>
      }
      {...props}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  ...CardHeaderStyle,
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '14px 14px 0', // -2px TLR border,
    height: '56px',
    overflow: 'hidden',
    ...theme.typography.body2,
  },
  content: {
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    ...theme.typography.body2,
  },
  action: {
    lineHeight: 0,
    margin: 0,
  },
}))
