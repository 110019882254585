// this export is icon files w/o the js extension; include icon names in this export if an icon should be rendered in the display page under the Business Object category

let icons = [
  'Account',
  'Active',
  'Attribute',
  'AttributeOutline',
  'Audience',
  'Briefcase',
  'BriefcaseOutline',
  'Campaign',
  'CampaignOutline',
  'Category',
  'CompactedFiles',
  'Connection',
  'DataScience',
  'DataScienceOutline',
  'DataSet',
  'DataStore',
  'Destination',
  'DerivedView',
  'File',
  'Folder',
  'FolderOutline',
  'Functions',
  'Lookalike',
  'MyData',
  'PartnerData',
  'Price',
  'Query',
  'RebaseEdit',
  'Segment',
  'SuperUniverse',
  'Taxonomy',
  'Universe',
  'User',
  'UserOutline',
  'Value',
  'Oauth',
  'IntegrationConnection',
]

export default icons
