/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: 'MuiInputLabel',
  description: '',
  displayName: 'InputLabel',
  methods: [],
  props: {
    children: {
      type: { name: 'node' },
      required: false,
      description: 'The contents of the `InputLabel`.',
    },
    classes: {
      type: { name: 'object' },
      required: false,
      description:
        'Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details.',
    },
    className: {
      type: { name: 'string' },
      required: false,
      description: '@ignore',
    },
    color: {
      type: {
        name: 'enum',
        value: [
          { value: "'primary'", computed: false },
          { value: "'secondary'", computed: false },
        ],
      },
      required: false,
      description:
        'The color of the component. It supports those theme colors that make sense for this component.',
    },
    disableAnimation: {
      type: { name: 'bool' },
      required: false,
      description: 'If `true`, the transition animation is disabled.',
      defaultValue: { value: 'false', computed: false },
    },
    disabled: {
      type: { name: 'bool' },
      required: false,
      description: 'If `true`, apply disabled class.',
    },
    error: {
      type: { name: 'bool' },
      required: false,
      description: 'If `true`, the label will be displayed in an error state.',
    },
    focused: {
      type: { name: 'bool' },
      required: false,
      description: 'If `true`, the input of this label is focused.',
    },
    margin: {
      type: { name: 'enum', value: [{ value: "'dense'", computed: false }] },
      required: false,
      description:
        'If `dense`, will adjust vertical spacing. This is normally obtained via context from\r\nFormControl.',
    },
    required: {
      type: { name: 'bool' },
      required: false,
      description:
        'if `true`, the label will indicate that the input is required.',
    },
    shrink: {
      type: { name: 'bool' },
      required: false,
      description: 'If `true`, the label is shrunk.',
    },
    variant: {
      type: {
        name: 'enum',
        value: [
          { value: "'filled'", computed: false },
          { value: "'outlined'", computed: false },
          { value: "'standard'", computed: false },
        ],
      },
      required: false,
      description: 'The variant to use.',
    },
  },
  styles: {
    root: { description: 'Styles applied to the root element.' },
    focused: {
      description:
        'Pseudo-class applied to the root element if `focused={true}`.',
    },
    disabled: {
      description:
        'Pseudo-class applied to the root element if `disabled={true}`.',
    },
    error: {
      description:
        'Pseudo-class applied to the root element if `error={true}`.',
    },
    required: {
      description:
        'Pseudo-class applied to the root element if `required={true}`.',
    },
    asterisk: { description: 'Pseudo-class applied to the asterisk element.' },
    formControl: {
      description:
        'Styles applied to the root element if the component is a descendant of `FormControl`.',
    },
    marginDense: {
      description: 'Styles applied to the root element if `margin="dense"`.',
    },
    shrink: {
      description: 'Styles applied to the `input` element if `shrink={true}`.',
    },
    animated: {
      description:
        'Styles applied to the `input` element if `disableAnimation={false}`.',
    },
    filled: {
      description: 'Styles applied to the root element if `variant="filled"`.',
    },
    outlined: {
      description:
        'Styles applied to the root element if `variant="outlined"`.',
    },
  },
}
export default generatedDocs
