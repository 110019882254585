import React from 'react'
import {
  Page,
  Title,
  Subtitle,
  Supporting,
  Usage,
  NoticePanel,
  SectionTitle,
} from '../../resources/DemoPage'
import Demo, { generateDemos } from '../../resources/Demo'

import Simple from './demos/Simple'
import Alert from './demos/Alert'

let demoNames = ['Simple', 'Alert']

export default function Dialog() {
  let demos = generateDemos('composed-component-demo/dialog', demoNames)

  return (
    <Page
      components={['Dialog', 'DialogTitle', 'DialogContent', 'DialogActions']}
    >
      <Title>Dialogs</Title>
      <Subtitle>
        Dialogs inform users about a task and can contain critical information,
        require decisions, or involve multiple tasks
      </Subtitle>
      <Supporting>
        A Dialog is a type of modal window that appears in front of app content
        to provide critical information or ask for a decision. Dialogs disable
        all app functionality when they appear, and remain on screen until
        confirmed, dismissed, or a required action has been taken.
      </Supporting>
      <Usage>
        <ul>
          <li>
            Dialogs are purposefully interruptive, so they should be used
            sparingly.
          </li>
        </ul>
      </Usage>

      <SectionTitle>Simple Dialog</SectionTitle>
      <p>
        Simple dialogs can provide additional details or actions about a list
        item. For example, they can display avatars, icons, clarifying subtext,
        or orthogonal actions (such as adding an account).
      </p>
      <Demo demo={demos.Simple}>
        <Simple />
      </Demo>

      <SectionTitle>Alert Dialog</SectionTitle>
      <p>
        Alerts are urgent interruptions, requiring acknowledgement, that inform
        the user about a situation.
      </p>
      <p>
        Most alerts don't need titles. They summarize a decision in a sentence
        or two by either:
      </p>
      <ul>
        <li>Asking a question (e.g. "Delete this conversation?")</li>
        <li>Making a statement related to the action buttons</li>
      </ul>
      <p>
        Use title bar alerts only for high-risk situations, such as the
        potential loss of connectivity. Users should be able to understand the
        choices based on the title and button text alone. Be judicious about the
        use of red buttons; please see the button component demos for more
        details.
      </p>
      <p>If a title is required:</p>
      <ul>
        <li>
          Use a clear question or statement with an explanation in the content
          area, such as "Erase USB storage?".
        </li>
        <li>
          Avoid apologies, ambiguity, or questions, such as “Warning!” or “Are
          you sure?”
        </li>
      </ul>
      <Demo demo={demos.Alert}>
        <Alert />
      </Demo>

      <SectionTitle>Full Screen Dialog</SectionTitle>
      <NoticePanel variant="info">
        <p>
          Full Screen Dialog is now a composed component, and can be found{' '}
          <a href="/composed/full-screen-dialog">here</a>. For more information
          on core versus composed components, check{' '}
          <a href="/composed">the breakdown</a> out.
        </p>
      </NoticePanel>
    </Page>
  )
}
