import React from 'react'
import ButtonFormGroup from '@liveramp/motif/core/ButtonFormGroup'
import PartnerButton, {
  PARTNER_BUTTONS,
} from '@liveramp/motif/core/PartnerButton'

export default function PartnerButtonDemo() {
  return (
    <ButtonFormGroup>
      <PartnerButton
        variant={PARTNER_BUTTONS.Google.sign_in}
        onClick={() => {
          alert('Signed in with Google')
        }}
      />
    </ButtonFormGroup>
  )
}
