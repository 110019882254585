import React from 'react'
import LogoCard from '@liveramp/motif/core/LogoCard'
import LogoCardHeader from '@liveramp/motif/core/LogoCardHeader'
import LogoCardMedia from '@liveramp/motif/core/LogoCardMedia'
import Tooltip from '@liveramp/motif/core/Tooltip'
import css from '../../../resources/Page.module.css'

import StatusSuccess from '@liveramp/motif/icons/StatusSuccess'
import SegmentRobot from '@liveramp/motif/icons/SegmentRobot'

export default function LongTitleNamesDemo() {
  return (
    <div className={css.grid}>
      <LogoCard>
        <LogoCardHeader
          title="Small Card with a very long title that renders over two lines but can be seen in a tooltip on hover"
          status={
            <Tooltip title="Success">
              <StatusSuccess />
            </Tooltip>
          }
        />
        <LogoCardMedia>
          <SegmentRobot alt="LiveRamp Logo" title="LiveRamp Logo" />
        </LogoCardMedia>
      </LogoCard>

      <LogoCard size="large">
        <LogoCardHeader
          title="Large Card with a very long title that renders over two lines but can be seen in a tooltip on hover"
          status={
            <Tooltip title="Success">
              <StatusSuccess />
            </Tooltip>
          }
        />
        <LogoCardMedia>
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/368px-Google_2015_logo.svg.png"
            alt="Google Logo"
            title="Google Logo"
          />
        </LogoCardMedia>
      </LogoCard>
    </div>
  )
}
