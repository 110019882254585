import React, { Component } from 'react'
import {
  Subtitle,
  Supporting,
  NoticePanel,
  SectionTitle,
} from '../../resources/Page'
import Demo, { generateDemos } from '../../resources/Demo'
import CodeChunk from '../../resources/CodeChunk'

import Styling from './demos/Styling'

let demoNames = ['Styling']

export default class Icons extends Component {
  render() {
    let demos = generateDemos('style/icons', demoNames)

    return (
      <div>
        <h1>Icons</h1>
        <Subtitle>Guidance and suggestions for using icons with Motif</Subtitle>
        <Supporting>
          A system icon or UI icon, symbolizes a command, file, device, or
          directory. System icons are also used to represent common actions like
          trash, print, and save, and are commonly found in app bars, buttons,
          and lists. See our <a href="/icons">icon library</a>.
        </Supporting>

        <SectionTitle>Imports</SectionTitle>
        <ul>
          <li>
            If your environment doesn't support tree-shaking, the recommended
            way to import the icons is the following:
          </li>
          <CodeChunk>
            {`import Universe from "@liveramp/motif/icons/Universe";
              import SuperUniverse from "@liveramp/motif/icons/SuperUniverse";`}
          </CodeChunk>
          <li>
            If your environment does support tree-shaking you can also import
            the icons this way:
          </li>
          <CodeChunk>
            {`import { Universe, SuperUniverse } from "@liveramp/motif/icons";`}
          </CodeChunk>
          <p>
            Note: Importing named exports in this way will result in the code
            for every icon being included in your project, so is not recommended
            unless you configure tree-shaking. It may also impact Hot Module
            Reload performance.
          </p>
        </ul>
        <SectionTitle>Styling</SectionTitle>
        <NoticePanel variant="warning">
          <p>
            Material UI treats all icons as if they were text; i.e. inline{' '}
            <code>fill</code>,<code>height</code> and <code>width</code> do not
            take precedence and will always be overwritten by the default size{' '}
            <code>1.5rem</code> and color{' '}
            <code>theme.palette.text.primary</code>.
          </p>
        </NoticePanel>
        <p>
          Use the <code>color</code>, <code>htmlColor</code> or{' '}
          <code>fontSize</code> prop, <code>withStyles</code> and the{' '}
          <code>classes</code> prop, or pass in a <code>className</code> or{' '}
          <code>style</code> to adjust the appearance.
        </p>
        <Demo demo={demos.Styling}>
          <Styling />
        </Demo>

        <SectionTitle>Accessibility</SectionTitle>
        <p>
          Icons can convey all sorts of meaningful information, so it’s
          important that they reach the largest amount of people possible. There
          are two use cases you’ll want to consider:
        </p>

        <h4>Decorative SVG Icons</h4>
        <p>
          These icons are only being used for visual or branding reinforcement.
          If they were removed from the page, your users should still understand
          and be able to use your page.
        </p>
        <p>
          If they are decorative, add the <code>aria-hidden=true</code>{' '}
          attribute so that your icons are properly accessible (invisible).
        </p>
        <CodeChunk>{`<User aria-hidden="true" />`}</CodeChunk>

        <h4>Semantic SVG Icons</h4>
        <p>
          Semantic icons are ones that you use to convey meaning, rather than
          just pure decoration. This includes icons without text next to them
          used as interactive controls — buttons, form elements, toggles, etc.
        </p>
        <p>
          If your icon has semantic meaning, all you need to do is add a{' '}
          <code>titleAccess="meaning"</code> property. The{' '}
          <code>role="img"</code> attribute and the title element are already
          added so that your icons are properly accessible.
        </p>
        <CodeChunk>{`<Delete titleAccess="Delete this item" />`}</CodeChunk>
        <p>
          In the case of focusable interactive elements, like when used with an
          icon button, you can use the aria-label property:
        </p>
        <CodeChunk>
          {`<IconButton aria-label="Delete">
              <Delete />
            </IconButton>`}
        </CodeChunk>
      </div>
    )
  }
}
