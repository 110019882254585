import React from 'react'
import Tabs from '@material-ui/core/Tabs'
import PropTypes from 'prop-types'
import withStyles from '../../styles/withStyles'
import { TabsStyle } from '../../utils/styleKeys'

let styles = (theme) => ({
  ...TabsStyle,
  root: {
    position: 'relative',
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
  scrollButtons: {
    width: 24,
    height: 24,
    alignSelf: 'center',
    borderRadius: 12,
    '&:hover': {
      backgroundColor: 'rgba(0, 0 , 0, 0.1)',
    },
    '&:active': {
      backgroundColor: 'rgba(0, 0 , 0, 0.2)',
    },
  },
  withBorder: {
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
  },
})

const CustomTabs = ({ withBorder, classes, ...props }) => {
  return <Tabs className={withBorder ? classes.withBorder : ''} {...props} />
}

CustomTabs.propTypes = {
  withBorder: PropTypes.bool,
  classes: PropTypes.object.isRequired,
}

CustomTabs.defaultProps = {
  withBorder: false,
}

export default withStyles(styles, { name: 'MuiTabs' })(CustomTabs)

import MotifTabs from './Tabs'
import Tab from './Tab'

export { MotifTabs as Tabs, Tab }
