import React from 'react'
import Truncate from '@liveramp/motif/core/Truncate'

export default function TruncateForceTooltip() {
  const children = 'Hello, this is some short text.'
  return (
    <Truncate tooltipPosition={'left'} forceTooltip wrapped>
      {children}
    </Truncate>
  )
}
