import React from 'react'
import MuiCard from '@material-ui/core/Card'

export default function Card(props) {
  return <MuiCard elevation={1} {...props} />
}

import MotifCard from './Card'
import Actions from './Actions/'
import Content from './Content'
import Header from './Header'
import Media from './Media'

export { MotifCard as Card, Actions, Content, Header, Media }
