import React, { useContext, useState } from 'react'
import { GlobalNavigationContext } from './GlobalNavigation'
import L3BusinessObjSelectorAutocomplete from './L3BusinessObjSelectorAutocomplete'
import ButtonBase from '../../core-components/ButtonBase'
import Collapse from '../../core-components/Collapse'
import makeStyles from '../../styles/makeStyles'
import CaretUp from '../../icons/CaretUp'
import CaretDown from '../../icons/CaretDown'

export default function L3BusinessObjectSelector({
  className,
  open: openProp = false,
  parent,
  value: valueProp = {},
  label,
  ...props
}) {
  const { isAltNav } = useContext(GlobalNavigationContext)
  const defaultOpen = isAltNav ? true : openProp

  const [open, setOpen] = useState(defaultOpen)
  const [value, setValue] = useState(valueProp ?? {})
  useStyles({ open })

  return (
    <>
      <Collapse in={open}>
        <L3BusinessObjSelectorAutocomplete
          setValue={setValue}
          value={value}
          label={label}
          {...props}
        />
      </Collapse>
      <ButtonBase
        onClick={() => setOpen(!open)}
        className="L3BusinessObjectSelector-button"
      >
        {/* 
          TODO: change this to a CSS animation in MUI5 when classname
          collisions won't flip the entire nav upsidedown
        */}
        {open ? <CaretUp /> : <CaretDown />}
      </ButtonBase>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.L3BusinessObjectSelector-button': {
      backgroundColor: ({ open }) =>
        open ? theme.palette.secondary.active : theme.palette.secondary.light,
      width: '100%',
      height: '1.5rem',
      textAlign: 'center',
      '& .MuiSvgIcon-root': {
        fill: theme.palette.text.secondary,
      },
      '&:hover': {
        backgroundColor: theme.palette.secondary.hover,
        cursor: 'pointer',
      },
    },
  },
}))
