import React, { useEffect, useState, useRef } from 'react'
import RichDataTable from '@liveramp/motif/core/RichDataTable'
import { columns, generateRows } from '../../demos/data'

const data = generateRows(100)
const PAGE_SIZE = 5

function loadServerRows(page, data) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(data.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE))
    }, Math.random() * 500 + 100) // simulate network latency
  })
}

export default function ControlledSelectionServerPaginationGrid() {
  const [page, setPage] = useState(0)
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectionModel, setSelectionModel] = useState([])
  const prevSelectionModel = useRef(selectionModel)

  useEffect(
    () => {
      let active = true

      ;(async () => {
        setLoading(true)
        const newRows = await loadServerRows(page, data)

        if (!active) {
          return
        }

        setRows(newRows)
        setLoading(false)
        setTimeout(() => {
          setSelectionModel(prevSelectionModel.current)
        })
      })()

      return () => {
        active = false
      }
    },
    // eslint-disable-next-line
    [page, data],
  )

  return (
    <RichDataTable
      rows={rows}
      columns={columns}
      pagination
      checkboxSelection
      pageSize={PAGE_SIZE}
      rowsPerPageOptions={[PAGE_SIZE]}
      rowCount={100}
      paginationMode="server"
      onPageChange={(newPage) => {
        prevSelectionModel.current = selectionModel
        setPage(newPage)
      }}
      onSelectionModelChange={(newSelectionModel) => {
        setSelectionModel(newSelectionModel)
      }}
      selectionModel={selectionModel}
      loading={loading}
    />
  )
}
