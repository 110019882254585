import React, { useState } from 'react'
import Button from '@liveramp/motif/core/Button'
import ButtonFormGroup from '@liveramp/motif/core/ButtonFormGroup'
import Dialog from '@liveramp/motif/core/Dialog'
import DialogTitle from '@liveramp/motif/core/DialogTitle'
import DialogContent from '@liveramp/motif/core/DialogContent'
import DialogActions from '@liveramp/motif/core/DialogActions'

export default function SimpleDemo() {
  let [open, setOpen] = useState(false)

  return (
    <>
      <ButtonFormGroup>
        <Button
          variant="secondary"
          color="primary"
          onClick={() => setOpen('md')}
        >
          Standard Dialog
        </Button>
        <Button
          variant="secondary"
          color="primary"
          onClick={() => setOpen('sm')}
        >
          Small Dialog
        </Button>
      </ButtonFormGroup>

      <Dialog open={open === 'md'} onClose={() => setOpen('')}>
        <DialogTitle onClose={() => setOpen('')}>Dialog Title</DialogTitle>
        <DialogContent>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => setOpen('')}>
            Cancel
          </Button>
          <Button onClick={() => setOpen('')}>Confirm</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={open === 'sm'} onClose={() => setOpen('')} size="small">
        <DialogTitle onClose={() => setOpen('')}>Dialog's Title</DialogTitle>
        <DialogContent>
          <p>Lorem ipsum dolor sit amet</p>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => setOpen('')}>
            Cancel
          </Button>
          <Button onClick={() => setOpen('')}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
