import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import Typography from '../../core-components/Typography'
import makeStyles from '../../styles/makeStyles'

export default function SidePanelFieldValue({
  children,
  className,
  label,
  fieldNoWrap = true,
  valueNoWrap = true,
  ...props
}) {
  useStyles()
  return (
    <span className={clsx('SidePanelFieldValue-root', className)} {...props}>
      <Typography
        className="SidePanelFieldValue-field"
        variant="body3"
        color="secondary"
        paragraph={false}
        noWrap={fieldNoWrap}
        title={label}
      >
        {label}
      </Typography>
      <Typography
        className="SidePanelFieldValue-value"
        variant="body2"
        paragraph={false}
        noWrap={valueNoWrap}
        title={children}
      >
        {children}
      </Typography>
    </span>
  )
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.SidePanelFieldValue-root': {
      flex: 1,
      width: 0,
      overflow: 'hidden',
      wordBreak: 'break-all',
    },
  },
}))

SidePanelFieldValue.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.node.isRequired,
}
