import React from 'react'
import Typography from '@liveramp/motif/core/Typography'
import Chip from '@liveramp/motif/core/Chip'
import css from './Demos.module.css'

export default function DisplayDemo() {
  return (
    <div className={css.display}>
      <div>
        <Chip className={css.chip}>
          <Typography variant="subtitle2" display="inline">
            Paragraph and Display:{' '}
          </Typography>
          <Typography variant="body2" display="inline">
            To use the <code className={css.code}>body</code> variants without
            paragraph spacing, use the paragraph prop
          </Typography>
        </Chip>
        <Typography variant="body1" display="block" paragraph={false}>
          Body 1
        </Typography>
        <Typography variant="body2" display="block" paragraph={false}>
          Body 2
        </Typography>
        <Typography variant="body3" display="block" paragraph={false}>
          Body 3
        </Typography>
      </div>
      <div>
        <Chip className={css.chip}>
          <Typography variant="subtitle1" display="inline">
            Text Alignment:{' '}
          </Typography>
          <Typography variant="body1" display="inline">
            Align text with the align prop
          </Typography>
        </Chip>
        <div className={css.alignment}>
          <Typography variant="body1" align="left">
            Aligned Left
          </Typography>
        </div>
        <div className={css.alignment}>
          <Typography variant="body1" align="center">
            Aligned Center
          </Typography>
        </div>
        <div className={css.alignment}>
          <Typography variant="body1" align="right">
            Aligned Right
          </Typography>
        </div>
      </div>
      <div>
        <Chip className={css.chip}>
          <Typography variant="subtitle1" display="inline">
            Color:{' '}
          </Typography>
          <Typography variant="body1" display="inline">
            Apply colors with the color prop
          </Typography>
        </Chip>
        <div className={css.color}>
          <Typography variant="body1" color="primary">
            primary
          </Typography>
          <Typography variant="body1" color="secondary">
            secondary
          </Typography>
          <Typography variant="body1" color="error">
            error
          </Typography>
          <Typography variant="body1" color="textPrimary">
            textPrimary
          </Typography>
          <Typography variant="body1" color="textSecondary">
            textSecondary
          </Typography>
        </div>
      </div>
    </div>
  )
}
