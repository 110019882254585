/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: 'MuiCardMedia',
  description: '',
  displayName: 'CardMedia',
  methods: [],
  props: {
    children: {
      type: {
        name: 'custom',
        raw: "chainPropTypes(PropTypes.node, props => {\n  if (!props.children && !props.image && !props.src && !props.component) {\n    return new Error('Material-UI: Either `children`, `image`, `src` or `component` prop must be specified.');\n  }\n\n  return null;\n})",
      },
      required: false,
      description: 'The content of the component.',
    },
    classes: {
      type: { name: 'object' },
      required: false,
      description:
        'Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details.',
    },
    className: {
      type: { name: 'string' },
      required: false,
      description: '@ignore',
    },
    component: {
      type: { name: 'elementType' },
      required: false,
      description:
        'The component used for the root node.\r\nEither a string to use a HTML element or a component.',
      defaultValue: { value: "'div'", computed: false },
    },
    image: {
      type: { name: 'string' },
      required: false,
      description:
        'Image to be displayed as a background image.\r\nEither `image` or `src` prop must be specified.\r\nNote that caller must specify height otherwise the image will not be visible.',
    },
    src: {
      type: { name: 'string' },
      required: false,
      description:
        'An alias for `image` property.\r\nAvailable only with media components.\r\nMedia components: `video`, `audio`, `picture`, `iframe`, `img`.',
    },
    style: {
      type: { name: 'object' },
      required: false,
      description: '@ignore',
    },
  },
  styles: {
    root: { description: 'Styles applied to the root element.' },
    media: {
      description:
        'Styles applied to the root element if `component="video, audio, picture, iframe, or img"`.',
    },
    img: {
      description:
        'Styles applied to the root element if `component="picture or img"`.',
    },
  },
}
export default generatedDocs
