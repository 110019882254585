import React, { useContext } from 'react'
import clsx from 'clsx'
import ButtonBase from '../../core-components/ButtonBase'
import Tooltip from '../../core-components/Tooltip'
import AccountSwitcher from './AccountSwitcher'
import { GlobalNavigationContext } from './GlobalNavigation'
import Account from '../../icons/AccountPicker'
import withStyles from '../../styles/withStyles'

function L1L2AccountSwitcher() {
  const {
    accountSwitcherProps,
    displayLevel,
    setDisplayLevel,
    skipL2,
    currentL3,
  } = useContext(GlobalNavigationContext)

  const { accountSwitcherOpen, setAccountSwitcherOpen } = accountSwitcherProps

  return (
    <div className="GNL1L2AccountSwitcher-root">
      <Tooltip
        className="GNL1L2AccountSwitcher-tooltip"
        title="Account Switcher"
        placement="right"
      >
        <ButtonBase
          className="GNL1L2AccountSwitcher-button"
          onClick={() => {
            if (displayLevel === 1) {
              setDisplayLevel(skipL2 && currentL3 ? 3 : 2)
            } else if (displayLevel === 3 && !skipL2) {
              setDisplayLevel(2)
            }
            setAccountSwitcherOpen(!accountSwitcherOpen)
          }}
        >
          <Account />
        </ButtonBase>
      </Tooltip>
      <span
        className={clsx({
          ['accountSwitcherHidden']: displayLevel !== 2,
        })}
      >
        <AccountSwitcher lightColor />
      </span>
    </div>
  )
}

const styles = (theme) => ({
  '@global': {
    '.GNL1L2AccountSwitcher-root': {
      display: 'grid',
      gridTemplateColumns: '50px 1fr',

      '& > .MuiCollapse-container': {
        gridColumnStart: '2',
      },
    },

    '.GNL1L2AccountSwitcher-tooltip': {
      maxHeight: '60px',
    },

    '.GNL1L2AccountSwitcher-button': {
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.18)',
      },

      '& > .MuiSvgIcon-root': {
        fill: theme.palette.common.white,
        padding: '18px 13px',
        boxSizing: 'content-box',
      },
    },
    '.accountSwitcherHidden': {
      visibility: 'hidden',
    },
  },
})

export default withStyles(styles)(L1L2AccountSwitcher)
