let red = {
  50: '#fde4e9',
  100: '#ffc5d0',
  200: '#ff9fb1',
  300: '#f68297',
  400: '#fa5372',
  500: '#f21d44',
  600: '#de3652',
  700: '#d1213b',
  800: '#a50428',
  900: '#820612',
}

export default red
