import React from 'react'
import {
  Page,
  Title,
  Subtitle,
  Supporting,
  SectionTitle,
} from '../../resources/DemoPage'
import Demo, { generateDemos } from '../../resources/Demo'

import Simple from './demos/Simple'
import TabsPanel from './demos/TabsPanel'
import Custom from './demos/Custom'
import Overflow from './demos/Overflow'

let demoNames = ['Simple', 'TabsPanel', 'Custom', 'Overflow']

export default function Tabs() {
  let demos = generateDemos('core-component-demo/tabs', demoNames)

  return (
    <Page components={['Tabs', 'Tab']}>
      <Title>Tabs</Title>
      <Subtitle>
        Tabs organize content across different screens, data sets, and other
        interactions.
      </Subtitle>
      <Supporting>
        Tabs organize and allow navigation between groups of content that are
        related and at the same level of hierarchy.
      </Supporting>
      <Demo demo={demos.Simple}>
        <Simple />
      </Demo>

      <SectionTitle>Tabs with Panel</SectionTitle>
      <p>
        Users selecting/clicking on a tab will commonly show a container/panel
        with data related to the tab label.
      </p>
      <Demo demo={demos.TabsPanel}>
        <TabsPanel />
      </Demo>

      <SectionTitle>Overflow Tabs</SectionTitle>
      <p>
        If you expect to have more tabs than there is space to show all of the
        tabs necessary, set <code>variant="scrollable"</code> and{' '}
        <code>scrollButtons="auto"</code> to render icon buttons that allow
        clicking through all of rendered tabs, along with scrolling capability.
      </p>
      <Demo demo={demos.Overflow}>
        <Overflow />
      </Demo>

      <SectionTitle>Custom Tabs</SectionTitle>
      <p>
        There are three ways you can use custom styling on Motif components:
        using custom css module, makeStyles API or withStyles API. You can
        reference the code attached to this demo for more details. The
        components below share the same state for demo purposes.
      </p>
      <Demo demo={demos.Custom}>
        <Custom />
      </Demo>
    </Page>
  )
}
