import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  Title,
  Subtitle,
  Supporting,
  Usage,
  NoticePanel,
  SectionTitle,
} from './Page'
import componentMap from './mapping'

/*  LAB_COMPONENTS is temporary until the Select (Autocomplete) in
    the MUI lab is moved to the core package */
const LAB_COMPONENTS = ['Select']
const motifSrc = (composed) =>
  `https://github.com/LiveRamp/motif/tree/master/lib/src/${
    composed ? 'composed-' : 'core-'
  }components`
const muiSrc =
  'https://github.com/mui-org/material-ui/tree/master/packages/material-ui/src'
const muiLabSrc =
  'https://github.com/mui-org/material-ui/tree/master/packages/material-ui-lab/src'

export { Title, Subtitle, Supporting, Usage, NoticePanel, SectionTitle }

export function Page({ components = null, children }) {
  return (
    <div>
      {children}
      {components !== null && (
        <ComponentSourceUrls
          components={Array.isArray(components) ? components : [components]}
        />
      )}
    </div>
  )
}

function ComponentSourceUrls({ components }) {
  let links = components.map((component) => {
    let {
      motif,
      material,
      composed = false,
    } = componentMap.find((i) => i.motif === component)
    let sourceURL = LAB_COMPONENTS.includes(component) ? muiLabSrc : muiSrc
    let muiCmp = (
      <a href={`${sourceURL}/${material}/${material}.js`}>Material</a>
    )
    let motifCmp = <a href={`${motifSrc(composed)}/${motif}/index.js`}>Motif</a>

    return (
      <li key={motif}>
        {motif}: {motifCmp}
        {material ? ' | ' : ''}
        {material ? muiCmp : ''}
      </li>
    )
  })

  return components.length > 0 ? (
    <Fragment>
      <h3>Source</h3>
      <ul>{links}</ul>
    </Fragment>
  ) : null
}

Page.propTypes = {
  components: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  children: PropTypes.node.isRequired,
}

Page.defaultProps = {
  components: [],
  children: '',
}

Supporting.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.node,
  ]).isRequired,
}

Supporting.defaultProps = {
  children: '',
}
