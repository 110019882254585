import React from 'react'
import {
  Page,
  Title,
  Subtitle,
  Supporting,
  Usage,
  NoticePanel,
  SectionTitle,
} from '../../resources/DemoPage'
import Demo, { generateDemos } from '../../resources/Demo'
import CodeChunk from '../../resources/CodeChunk'

import PrimaryButton from './demos/PrimaryButton'
import OutlinedButton from './demos/OutlinedButton'
import TextButton from './demos/TextButton'
import IconsAndLabel from './demos/IconsAndLabel'
import ButtonFormGroup from './demos/ButtonFormGroup'
import VariedButtonFormGroup from './demos/VariedButtonFormGroup'
import Custom from './demos/Custom'
import Partner from './demos/Partner'

let demoNames = [
  'PrimaryButton',
  'OutlinedButton',
  'TextButton',
  'IconsAndLabel',
  'ButtonFormGroup',
  'VariedButtonFormGroup',
  'Custom',
  'Partner',
  'ThirdPartyRoutingLibrary',
  'ThirdPartyRoutingLibraryCollision',
]

export default function Button() {
  let demos = generateDemos('core-component-demo/button', demoNames)

  return (
    <Page components="Button">
      <Title>Buttons</Title>
      <Subtitle>
        Buttons allow users to take actions, and make choices, with a single
        click
      </Subtitle>
      <Supporting>Buttons communicate actions that users can take.</Supporting>
      <Usage>
        <ul>
          <li>Button groups can contain mixed variants.</li>
          <li>
            Avoid using both the LiveRamp green and red color in one group.
          </li>
          <li>
            Destructive or high severity actions don't always need to be big,
            red and bold. If it is not the primary action on the page, reduce
            its prominence.
          </li>
        </ul>
      </Usage>

      <SectionTitle>Primary Buttons</SectionTitle>
      <p>Primary buttons should be for obvious actions.</p>
      <Demo demo={demos.PrimaryButton}>
        <PrimaryButton />
      </Demo>

      <SectionTitle>Outlined Buttons</SectionTitle>
      <p>Outlined buttons should be clear but not for prominent actions.</p>
      <Demo demo={demos.OutlinedButton}>
        <OutlinedButton />
      </Demo>

      <SectionTitle>Text Buttons</SectionTitle>
      <p>
        Text buttons should be discoverable but unobtrusive. They should exist
        on components that are separate surfaces to your application, such as
        dialogs and cards.
      </p>
      <Demo demo={demos.TextButton}>
        <TextButton />
      </Demo>

      <SectionTitle>Buttons with Icons and Label</SectionTitle>
      <p>
        Icons can enhance the user experience for certain buttons as logos are
        more easily recognizable than plain text. They can also be mixed with
        other colored buttons.
      </p>
      <p>
        It is highly discouraged to use icons on the right of the button's text.
        Motif has also already provided styling specific to left-aligned icons.
      </p>
      <Demo demo={demos.IconsAndLabel}>
        <IconsAndLabel />
      </Demo>

      <SectionTitle>Button Form Group</SectionTitle>
      <p>
        Multiple buttons should use ButtonFormGroup to organize their layout.
      </p>
      <Demo demo={demos.ButtonFormGroup}>
        <ButtonFormGroup />
      </Demo>
      <NoticePanel>
        <p>
          Be cautious about using too many variants and colors in one
          ButtonFormGroup.
        </p>
      </NoticePanel>
      <Demo demo={demos.VariedButtonFormGroup}>
        <VariedButtonFormGroup />
      </Demo>

      <SectionTitle>Partner Buttons</SectionTitle>
      <p>
        Some partners require us to use pre-styled or formatted buttons in
        specific cases (e.g. for third party logins). For these cases, there's a
        specific <code>PartnerButton</code> component to handle the custom
        assets and styling.
      </p>
      <Demo demo={demos.Partner}>
        <Partner />
      </Demo>

      <SectionTitle>Custom Buttons</SectionTitle>
      <Demo demo={demos.Custom}>
        <Custom />
      </Demo>

      <SectionTitle>Third-Party Routing Library</SectionTitle>
      <p>
        One common use case is to use the button to trigger a navigation to a
        new page. The <code>ButtonBase</code> component provides a property to
        handle this use case: <code>component</code>. Given that a lot of our
        interactive components rely on <code>ButtonBase</code>, you should be
        able to take advantage of it everywhere:
      </p>
      <CodeChunk>{demos.ThirdPartyRoutingLibrary}</CodeChunk>
      <p>or if you want to avoid a prop collision:</p>
      <CodeChunk>{demos.ThirdPartyRoutingLibraryCollision}</CodeChunk>
    </Page>
  )
}
