import React from 'react'
import { Star, StarHalf, StarOutline } from '@liveramp/motif/icons'

const columns = [
  {
    field: 'name',
    headerName: 'Name',
    width: 300,
    valueGetter: (params) => params.row.name,
    searchable: true,
  },
  {
    field: 'duration_ms',
    headerName: '🕒',
    type: 'number',
    valueFormatter: (params) => {
      const ms = params.row.duration_ms
      const minutes = Math.floor(ms / 60000)
      const seconds = ((ms % 60000) / 1000).toFixed(0)
      return `${minutes}:${String(seconds).padStart(2, '0')}`
    },
    width: 90,
  },
  {
    field: 'artists',
    headerName: 'Artists',
    valueFormatter: (params) =>
      params.row.artists.map((artist) => artist.name).join(', '),
    width: 200,
  },
  {
    field: 'album',
    headerName: 'Album',
    valueGetter: (params) => params.row.album.name,
    width: 200,
  },
  {
    field: 'album_type',
    headerName: 'Album Type',
    valueGetter: (params) => params.row.album.album_type,
    hide: true,
    width: 100,
  },
  {
    field: 'release_date',
    headerName: 'Release Date',
    type: 'date',
    width: 150,
    valueGetter: (params) => params.row.album.release_date,
  },
  {
    field: 'popularity',
    headerName: 'Popularity',
    type: 'number',
    // Render popularity out of 100 as 5 stars
    renderCell: (params) => {
      const total = params.row.popularity / 10 / 2
      const fullStars = Math.round(total)
      const noStars = 5 - Math.round(total)
      const halfStars = 5 - fullStars - noStars

      const renderStars = (count, star) => {
        const stars = []
        for (let i = 0; i < count; i++) {
          const name = star.type.type.render.displayName
          stars.push(React.cloneElement(star, { key: `${name}-${i}` }))
        }
        return stars
      }

      return (
        <>
          {renderStars(fullStars, <Star />)}
          {renderStars(halfStars, <StarHalf />)}
          {renderStars(noStars, <StarOutline />)}
        </>
      )
    },
    width: 175,
    headerAlign: 'left',
    align: 'left',
  },
  {
    field: 'available_markets',
    headerName: 'Available Markets',
    valueFormatter: (params) => params.row.available_markets.join(', '),
    hide: true,
    width: 200,
  },
  {
    field: 'preview_url',
    headerName: 'Audio Preview',
    renderCell: (params) =>
      params.row.preview_url ? (
        <audio controls>
          <source src={params.row.preview_url} />
        </audio>
      ) : (
        <p style={{ paddingLeft: '22px' }}>No preview track available</p>
      ),
    width: 300,
    sortable: false,
  },
]

const multiFilterOptions = [
  {
    id: 'release-year',
    label: 'Release Year',
    options: [
      { id: 1960, label: '1960s' },
      { id: 1970, label: '1970s' },
      { id: 1980, label: '1980s' },
      { id: 1990, label: '1990s' },
      { id: 2000, label: '2000s' },
      { id: 2010, label: '2010s' },
      { id: 2020, label: '2020s' },
    ],
  },
  {
    id: 'popularity',
    label: 'Popularity',
    options: [
      { id: '0-20', label: '0 - 20' },
      { id: '21-40', label: '21 - 40' },
      { id: '41-60', label: '41 - 60' },
      { id: '61-80', label: '61 - 80' },
      { id: '81-100', label: '81 - 100' },
    ],
  },
  {
    id: 'audio-preview',
    label: 'Audio Preview',
    options: [
      { id: 'available', label: 'Available' },
      { id: 'unavailable', label: 'Unavailable' },
    ],
  },
]

const markets = [
  { id: 'AD', country: 'Andorra' },
  { id: 'AE', country: 'United Arab Emirates' },
  { id: 'AG', country: 'Antigua and Barbuda' },
  { id: 'AL', country: 'Albania' },
  { id: 'AM', country: 'Armenia' },
  { id: 'AO', country: 'Angola' },
  { id: 'AR', country: 'Argentina' },
  { id: 'AT', country: 'Austria' },
  { id: 'AU', country: 'Australia' },
  { id: 'AZ', country: 'Azerbaijan' },
  { id: 'BA', country: 'Bosnia and Herzegovina' },
  { id: 'BB', country: 'Barbados' },
  { id: 'BD', country: 'Bangladesh' },
  { id: 'BE', country: 'Belgium' },
  { id: 'BF', country: 'Burkina Faso' },
  { id: 'BG', country: 'Bulgaria' },
  { id: 'BH', country: 'Bahrain' },
  { id: 'BI', country: 'Burundi' },
  { id: 'BJ', country: 'Benin' },
  { id: 'BN', country: 'Brunei Darussalam' },
  { id: 'BO', country: 'Bolivia (Plurinational State of)' },
  { id: 'BR', country: 'Brazil' },
  { id: 'BS', country: 'Bahamas' },
  { id: 'BT', country: 'Bhutan' },
  { id: 'BW', country: 'Botswana' },
  { id: 'BY', country: 'Belarus' },
  { id: 'BZ', country: 'Belize' },
  { id: 'CA', country: 'Canada' },
  { id: 'CH', country: 'Switzerland' },
  { id: 'CI', country: "Côte d'Ivoire" },
  { id: 'CL', country: 'Chile' },
  { id: 'CM', country: 'Cameroon' },
  { id: 'CO', country: 'Colombia' },
  { id: 'CR', country: 'Costa Rica' },
  { id: 'CV', country: 'Cabo Verde' },
  { id: 'CW', country: 'Curaçao' },
  { id: 'CY', country: 'Cyprus' },
  { id: 'CZ', country: 'Czechia' },
  { id: 'DE', country: 'Germany' },
  { id: 'DJ', country: 'Djibouti' },
  { id: 'DK', country: 'Denmark' },
  { id: 'DM', country: 'Dominica' },
  { id: 'DO', country: 'Dominican Republic' },
  { id: 'DZ', country: 'Algeria' },
  { id: 'EC', country: 'Ecuador' },
  { id: 'EE', country: 'Estonia' },
  { id: 'EG', country: 'Egypt' },
  { id: 'ES', country: 'Spain' },
  { id: 'FI', country: 'Finland' },
  { id: 'FJ', country: 'Fiji' },
  { id: 'FM', country: 'Micronesia (Federated States of)' },
  { id: 'FR', country: 'France' },
  { id: 'GA', country: 'Gabon' },
  { id: 'GB', country: 'United Kingdom of Great Britain and Northern Ireland' },
  { id: 'GD', country: 'Grenada' },
  { id: 'GE', country: 'Georgia' },
  { id: 'GH', country: 'Ghana' },
  { id: 'GM', country: 'Gambia' },
  { id: 'GN', country: 'Guinea' },
  { id: 'GQ', country: 'Equatorial Guinea' },
  { id: 'GR', country: 'Greece' },
  { id: 'GT', country: 'Guatemala' },
  { id: 'GW', country: 'Guinea-Bissau' },
  { id: 'GY', country: 'Guyana' },
  { id: 'HK', country: 'Hong Kong' },
  { id: 'HN', country: 'Honduras' },
  { id: 'HR', country: 'Croatia' },
  { id: 'HT', country: 'Haiti' },
  { id: 'HU', country: 'Hungary' },
  { id: 'ID', country: 'Indonesia' },
  { id: 'IE', country: 'Ireland' },
  { id: 'IL', country: 'Israel' },
  { id: 'IN', country: 'India' },
  { id: 'IS', country: 'Iceland' },
  { id: 'IT', country: 'Italy' },
  { id: 'JM', country: 'Jamaica' },
  { id: 'JO', country: 'Jordan' },
  { id: 'JP', country: 'Japan' },
  { id: 'KE', country: 'Kenya' },
  { id: 'KG', country: 'Kyrgyzstan' },
  { id: 'KH', country: 'Cambodia' },
  { id: 'KI', country: 'Kiribati' },
  { id: 'KM', country: 'Comoros' },
  { id: 'KN', country: 'Saint Kitts and Nevis' },
  { id: 'KR', country: 'Korea, Republic of' },
  { id: 'KW', country: 'Kuwait' },
  { id: 'KZ', country: 'Kazakhstan' },
  { id: 'LA', country: "Lao People's Democratic Republic" },
  { id: 'LB', country: 'Lebanon' },
  { id: 'LC', country: 'Saint Lucia' },
  { id: 'LI', country: 'Liechtenstein' },
  { id: 'LK', country: 'Sri Lanka' },
  { id: 'LR', country: 'Liberia' },
  { id: 'LS', country: 'Lesotho' },
  { id: 'LT', country: 'Lithuania' },
  { id: 'LU', country: 'Luxembourg' },
  { id: 'LV', country: 'Latvia' },
  { id: 'MA', country: 'Morocco' },
  { id: 'MC', country: 'Monaco' },
  { id: 'MD', country: 'Moldova, Republic of' },
  { id: 'ME', country: 'Montenegro' },
  { id: 'MG', country: 'Madagascar' },
  { id: 'MH', country: 'Marshall Islands' },
  { id: 'MK', country: 'North Macedonia' },
  { id: 'ML', country: 'Mali' },
  { id: 'MN', country: 'Mongolia' },
  { id: 'MO', country: 'Macao' },
  { id: 'MR', country: 'Mauritania' },
  { id: 'MT', country: 'Malta' },
  { id: 'MU', country: 'Mauritius' },
  { id: 'MV', country: 'Maldives' },
  { id: 'MW', country: 'Malawi' },
  { id: 'MX', country: 'Mexico' },
  { id: 'MY', country: 'Malaysia' },
  { id: 'MZ', country: 'Mozambique' },
  { id: 'NA', country: 'Namibia' },
  { id: 'NE', country: 'Niger' },
  { id: 'NG', country: 'Nigeria' },
  { id: 'NI', country: 'Nicaragua' },
  { id: 'NL', country: 'Netherlands' },
  { id: 'NO', country: 'Norway' },
  { id: 'NP', country: 'Nepal' },
  { id: 'NR', country: 'Nauru' },
  { id: 'NZ', country: 'New Zealand' },
  { id: 'OM', country: 'Oman' },
  { id: 'PA', country: 'Panama' },
  { id: 'PE', country: 'Peru' },
  { id: 'PG', country: 'Papua New Guinea' },
  { id: 'PH', country: 'Philippines' },
  { id: 'PK', country: 'Pakistan' },
  { id: 'PL', country: 'Poland' },
  { id: 'PS', country: 'Palestine, State of' },
  { id: 'PT', country: 'Portugal' },
  { id: 'PW', country: 'Palau' },
  { id: 'PY', country: 'Paraguay' },
  { id: 'QA', country: 'Qatar' },
  { id: 'RO', country: 'Romania' },
  { id: 'RS', country: 'Serbia' },
  { id: 'RU', country: 'Russian Federation' },
  { id: 'RW', country: 'Rwanda' },
  { id: 'SA', country: 'Saudi Arabia' },
  { id: 'SB', country: 'Solomon Islands' },
  { id: 'SC', country: 'Seychelles' },
  { id: 'SE', country: 'Sweden' },
  { id: 'SG', country: 'Singapore' },
  { id: 'SI', country: 'Slovenia' },
  { id: 'SK', country: 'Slovakia' },
  { id: 'SL', country: 'Sierra Leone' },
  { id: 'SM', country: 'San Marino' },
  { id: 'SN', country: 'Senegal' },
  { id: 'SR', country: 'Suriname' },
  { id: 'ST', country: 'Sao Tome and Principe' },
  { id: 'SV', country: 'El Salvador' },
  { id: 'SZ', country: 'Eswatini' },
  { id: 'TD', country: 'Chad' },
  { id: 'TG', country: 'Togo' },
  { id: 'TH', country: 'Thailand' },
  { id: 'TL', country: 'Timor-Leste' },
  { id: 'TN', country: 'Tunisia' },
  { id: 'TO', country: 'Tonga' },
  { id: 'TR', country: 'Turkey' },
  { id: 'TT', country: 'Trinidad and Tobago' },
  { id: 'TV', country: 'Tuvalu' },
  { id: 'TW', country: 'Taiwan, Province of China' },
  { id: 'TZ', country: 'Tanzania, United Republic of' },
  { id: 'UA', country: 'Ukraine' },
  { id: 'UG', country: 'Uganda' },
  { id: 'US', country: 'United States of America' },
  { id: 'UY', country: 'Uruguay' },
  { id: 'UZ', country: 'Uzbekistan' },
  { id: 'VC', country: 'Saint Vincent and the Grenadines' },
  { id: 'VN', country: 'Viet Nam' },
  { id: 'VU', country: 'Vanuatu' },
  { id: 'WS', country: 'Samoa' },
  { id: 'XK', country: 'Kosovo' },
  { id: 'ZA', country: 'South Africa' },
  { id: 'ZM', country: 'Zambia' },
  { id: 'ZW', country: 'Zimbabwe' },
]

export default columns
export { columns, markets, multiFilterOptions }
