import React from 'react'
import Footer from '@liveramp/motif/core/Footer'
import Button from '@liveramp/motif/core/Button'
import ButtonFormGroup from '@liveramp/motif/core/ButtonFormGroup'

export default function RegularFooter() {
  const [open, setOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setOpen(!open)
  }

  return (
    <>
      <Button variant="text" onClick={handleDrawerToggle}>
        Open Footer
      </Button>
      <Footer open={open} onClick={handleDrawerToggle}>
        <ButtonFormGroup>
          <Button>Primary</Button>
          <Button variant="secondary">Secondary</Button>
        </ButtonFormGroup>
      </Footer>
    </>
  )
}
