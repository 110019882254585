import React from 'react'
import { UploadContextProvider } from './UploadContext'

const withBasicProvider = (Provider) => (Component) => (props) =>
  (
    <Provider>
      <Component {...props} />
    </Provider>
  )

const withUploadContextProvider = withBasicProvider(UploadContextProvider)
export default withUploadContextProvider
