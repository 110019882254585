import React from 'react'
import {
  Page,
  Title,
  Subtitle,
  SectionTitle,
} from '../../../resources/DemoPage'
import { RdtNotices } from '../index'
import Demo, { generateDemos } from '../../../resources/Demo'
import RenderCellDemo from './demos/RenderCell'

let demoNames = ['RenderCell']

export default function RichDataTable() {
  let demos = generateDemos(
    'composed-component-demo/rich-data-table/columns',
    demoNames,
  )

  return (
    <Page>
      <Title>Rich Data Table - Columns</Title>
      <Subtitle>Rich Data Table for React apps</Subtitle>
      <RdtNotices />

      <p>
        Grid columns are defined with the <code>columns</code> prop; it expects
        an array of objects. <code>field</code> is the only required property
        since it is used as the column identifier.
      </p>
      <p>
        All column properties can be found in MUIs{' '}
        <a href="https://material-ui.com/api/data-grid/grid-col-def/">
          Column Definition Interface API
        </a>
        .
      </p>
      <SectionTitle>Render Cell</SectionTitle>
      <p>
        By default, the grid will resolve the rendered cell in the following
        order:
      </p>
      <ul>
        <li>
          <code>renderCell() => ReactElement</code>
        </li>
        <li>
          <code>valueFormatter() => string</code>
        </li>
        <li>
          <code>valueGetter() => string</code>
        </li>
        <li>
          <code>row[field]</code>
        </li>
      </ul>
      <Demo demo={demos.RenderCell}>
        <RenderCellDemo />
      </Demo>
    </Page>
  )
}
