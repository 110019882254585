import React, { createRef, useLayoutEffect, useState } from 'react'
import clsx from 'clsx'
import ListItem from '../../core-components/ListItem'
import Tooltip from '../../core-components/Tooltip'
import { makeStyles, mergeClasses } from '../../utils'

export default function SidePanelListItem({
  children,
  childrenProps = {},
  classes,
  className,
  label,
  labelProps = {},
  tooltipProps = {},
  ...props
}) {
  const defaultClasses = useStyles()
  const [showTooltip, setShowTooltip] = useState(true)
  const [openTooltip, setOpenTooltip] = useState(false)
  const ref = createRef()

  useLayoutEffect(() => {
    // default to always showing the tooltip on hover.
    ref?.current.clientWidth === ref?.current.scrollWidth
      ? setShowTooltip(false)
      : setShowTooltip(true)
  }, [ref])

  let labelClassName = labelProps.className
  delete labelProps.className
  let childrenClassName = childrenProps.className
  delete childrenProps.className
  let tooltipClassName = tooltipProps.className
  delete tooltipProps.className

  return (
    <ListItem
      className={clsx('SidePanelListItem-root', className)}
      classes={mergeClasses(defaultClasses, classes)}
      {...props}
    >
      <div
        className={clsx('SidePanelListItem-label', labelClassName)}
        {...labelProps}
      >
        {label}
      </div>
      <div className={clsx('SidePanelListItem-children', childrenClassName)}>
        <Tooltip
          className={clsx('SidePanelListItem-tooltip', tooltipClassName)}
          onClose={() => setOpenTooltip(false)}
          onOpen={() => setOpenTooltip(true)}
          open={showTooltip && openTooltip}
          title={children}
          {...tooltipProps}
        >
          <div className="SidePanelListItem-childrenWrapper" ref={ref}>
            {children}
          </div>
        </Tooltip>
      </div>
    </ListItem>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    justifyContent: 'space-between',
    gap: '16px',
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    '&:last-child': {
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .SidePanelListItem-label': { ...theme.typography.subtitle3 },
    '& .SidePanelListItem-children': {
      overflow: 'hidden',
      '& > .SidePanelListItem-tooltip > .SidePanelListItem-childrenWrapper': {
        ...theme.typography.body3,
        textAlign: 'right',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },
}))
