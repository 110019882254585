/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: 'Tooltip',
  description: '',
  displayName: 'Tooltip',
  methods: [],
  props: {
    arrow: {
      type: { name: 'bool' },
      required: false,
      description: 'If `true`, adds an arrow to the tooltip.',
      defaultValue: { value: 'false', computed: false },
    },
    children: {
      type: { name: 'custom', raw: 'elementAcceptingRef.isRequired' },
      required: false,
      description: 'Tooltip reference element.',
    },
    classes: {
      type: { name: 'object' },
      required: false,
      description:
        'Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details.',
    },
    className: {
      type: { name: 'string' },
      required: false,
      description: '@ignore',
    },
    disableFocusListener: {
      type: { name: 'bool' },
      required: false,
      description: 'Do not respond to focus events.',
      defaultValue: { value: 'false', computed: false },
    },
    disableHoverListener: {
      type: { name: 'bool' },
      required: false,
      description: 'Do not respond to hover events.',
      defaultValue: { value: 'false', computed: false },
    },
    disableTouchListener: {
      type: { name: 'bool' },
      required: false,
      description: 'Do not respond to long press touch events.',
      defaultValue: { value: 'false', computed: false },
    },
    enterDelay: {
      type: { name: 'number' },
      required: false,
      description:
        "The number of milliseconds to wait before showing the tooltip.\r\nThis prop won't impact the enter touch delay (`enterTouchDelay`).",
      defaultValue: { value: '100', computed: false },
    },
    enterNextDelay: {
      type: { name: 'number' },
      required: false,
      description:
        'The number of milliseconds to wait before showing the tooltip when one was already recently opened.',
      defaultValue: { value: '0', computed: false },
    },
    enterTouchDelay: {
      type: { name: 'number' },
      required: false,
      description:
        'The number of milliseconds a user must touch the element before showing the tooltip.',
      defaultValue: { value: '700', computed: false },
    },
    id: {
      type: { name: 'string' },
      required: false,
      description:
        "This prop is used to help implement the accessibility logic.\r\nIf you don't provide this prop. It falls back to a randomly generated id.",
    },
    interactive: {
      type: { name: 'bool' },
      required: false,
      description:
        'Makes a tooltip interactive, i.e. will not close when the user\r\nhovers over the tooltip before the `leaveDelay` is expired.',
      defaultValue: { value: 'false', computed: false },
    },
    leaveDelay: {
      type: { name: 'number' },
      required: false,
      description:
        "The number of milliseconds to wait before hiding the tooltip.\r\nThis prop won't impact the leave touch delay (`leaveTouchDelay`).",
      defaultValue: { value: '0', computed: false },
    },
    leaveTouchDelay: {
      type: { name: 'number' },
      required: false,
      description:
        'The number of milliseconds after the user stops touching an element before hiding the tooltip.',
      defaultValue: { value: '1500', computed: false },
    },
    onClose: {
      type: { name: 'func' },
      required: false,
      description:
        'Callback fired when the component requests to be closed.\r\n\r\n@param {object} event The event source of the callback.',
    },
    onOpen: {
      type: { name: 'func' },
      required: false,
      description:
        'Callback fired when the component requests to be open.\r\n\r\n@param {object} event The event source of the callback.',
    },
    open: {
      type: { name: 'bool' },
      required: false,
      description: 'If `true`, the tooltip is shown.',
    },
    placement: {
      type: {
        name: 'enum',
        value: [
          { value: "'bottom-end'", computed: false },
          { value: "'bottom-start'", computed: false },
          { value: "'bottom'", computed: false },
          { value: "'left-end'", computed: false },
          { value: "'left-start'", computed: false },
          { value: "'left'", computed: false },
          { value: "'right-end'", computed: false },
          { value: "'right-start'", computed: false },
          { value: "'right'", computed: false },
          { value: "'top-end'", computed: false },
          { value: "'top-start'", computed: false },
          { value: "'top'", computed: false },
        ],
      },
      required: false,
      description: 'Tooltip placement.',
      defaultValue: { value: "'bottom'", computed: false },
    },
    PopperComponent: {
      type: { name: 'elementType' },
      required: false,
      description: 'The component used for the popper.',
      defaultValue: { value: 'Popper', computed: true },
    },
    PopperProps: {
      type: { name: 'object' },
      required: false,
      description: 'Props applied to the [`Popper`](/api/popper/) element.',
    },
    title: {
      type: { name: 'node' },
      required: true,
      description:
        'Tooltip title. Zero-length titles string are never displayed.',
    },
    TransitionComponent: {
      type: { name: 'elementType' },
      required: false,
      description:
        'The component used for the transition.\r\n[Follow this guide](/components/transitions/#transitioncomponent-prop) to learn more about the requirements for this component.',
      defaultValue: { value: 'Grow', computed: true },
    },
    TransitionProps: {
      type: { name: 'object' },
      required: false,
      description:
        'Props applied to the [`Transition`](http://reactcommunity.org/react-transition-group/transition#Transition-props) element.',
    },
  },
  styles: {
    popper: { description: 'Styles applied to the Popper component.' },
    popperInteractive: {
      description:
        'Styles applied to the Popper component if `interactive={true}`.',
    },
    popperArrow: {
      description: 'Styles applied to the Popper component if `arrow={true}`.',
    },
    tooltip: {
      description: 'Styles applied to the tooltip (label wrapper) element.',
    },
    tooltipArrow: {
      description:
        'Styles applied to the tooltip (label wrapper) element if `arrow={true}`.',
    },
    arrow: { description: 'Styles applied to the arrow element.' },
    '': { description: '= width / sqrt(2) = (length of the hypotenuse)' },
    touch: {
      description:
        'Styles applied to the tooltip (label wrapper) element if the tooltip is opened by touch.',
    },
    tooltipPlacementLeft: {
      description:
        'Styles applied to the tooltip (label wrapper) element if `placement` contains "left".',
    },
    tooltipPlacementRight: {
      description:
        'Styles applied to the tooltip (label wrapper) element if `placement` contains "right".',
    },
    tooltipPlacementTop: {
      description:
        'Styles applied to the tooltip (label wrapper) element if `placement` contains "top".',
    },
    tooltipPlacementBottom: {
      description:
        'Styles applied to the tooltip (label wrapper) element if `placement` contains "bottom".',
    },
  },
}
export default generatedDocs
