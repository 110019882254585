import * as React from 'react'
import createSvgIcon from './utils/createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M4.587 3C3.714 3 3 3.714 3 4.587s.714 1.587 1.587 1.587 1.587-.714 1.587-1.587S5.46 3 4.587 3zM4.587 7.79C3.714 7.79 3 8.506 3 9.379c0 .873.714 1.587 1.587 1.587s1.587-.714 1.587-1.587S5.46 7.79 4.587 7.79zM4.587 12.581c-.873 0-1.587.714-1.587 1.587s.714 1.587 1.587 1.587 1.587-.714 1.587-1.587-.714-1.587-1.587-1.587zM4.587 17.372c-.873 0-1.587.714-1.587 1.587s.714 1.587 1.587 1.587 1.587-.714 1.587-1.587-.714-1.587-1.587-1.587zM9.403 17.372c-.873 0-1.587.714-1.587 1.587s.714 1.587 1.587 1.587 1.587-.714 1.587-1.587-.714-1.587-1.587-1.587zM9.403 12.581c-.873 0-1.587.714-1.587 1.587s.714 1.587 1.587 1.587 1.587-.714 1.587-1.587-.714-1.587-1.587-1.587zM9.403 7.79c-.873 0-1.587.715-1.587 1.588 0 .873.714 1.587 1.587 1.587s1.587-.714 1.587-1.587-.714-1.587-1.587-1.587zM14.219 7.79c-.873 0-1.588.715-1.588 1.588 0 .873.715 1.587 1.588 1.587.872 0 1.587-.714 1.587-1.587S15.09 7.79 14.219 7.79zM14.219 12.581c-.873 0-1.588.714-1.588 1.587s.715 1.587 1.588 1.587c.872 0 1.587-.714 1.587-1.587s-.715-1.587-1.587-1.587zM14.219 17.372c-.873 0-1.588.714-1.588 1.587s.715 1.587 1.588 1.587c.872 0 1.587-.714 1.587-1.587s-.715-1.587-1.587-1.587zM19.034 12.581c-.873 0-1.587.714-1.587 1.587s.714 1.587 1.587 1.587 1.587-.714 1.587-1.587-.714-1.587-1.587-1.587zM19.034 7.79c-.873 0-1.587.715-1.587 1.588 0 .873.714 1.587 1.587 1.587s1.587-.714 1.587-1.587-.714-1.587-1.587-1.587zM19.034 3c-.873 0-1.587.714-1.587 1.587s.714 1.587 1.587 1.587 1.587-.714 1.587-1.587S19.907 3 19.034 3z" />
    ,
  </React.Fragment>,
  'DerivedView',
)
