import React from 'react'
import Select from '@liveramp/motif/core/Select'
import { films } from './data.jsx'
import css from './Feature.module.css'

export default function FeatureDemo() {
  let [value, setValue] = React.useState(null)

  return (
    <div className={css.grid}>
      <Select
        id="disable-close-on-select"
        label="disableCloseOnSelect"
        variant="underlined"
        options={films}
        getOptionLabel={(option) => option.title}
        helperText="This dropdown will not close after selecting a value"
        disableCloseOnSelect
      />
      <Select
        id="clear-on-escape"
        label="clearOnEscape"
        variant="underlined"
        options={films}
        getOptionLabel={(option) => option.title}
        helperText="This input will accept 'Esc' to clear the selected value"
        clearOnEscape
      />
      <Select
        id="disable-clearable"
        label="disableClearable"
        variant="underlined"
        options={films}
        getOptionLabel={(option) => option.title}
        helperText="This input cannot be cleared after a value is selected"
        disableClearable
      />
      <Select
        id="controlled"
        label="controlled"
        variant="underlined"
        options={films}
        getOptionLabel={(option) => option.title}
        helperText="This select is controlled"
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue)
        }}
      />
      <Select
        id="auto-highlight"
        label="autoHighlight"
        variant="underlined"
        options={films}
        getOptionLabel={(option) => option.title}
        helperText="This dropdown's first option is automatically highlighted"
        autoHighlight
      />
      <Select
        id="auto-select"
        label="autoSelect"
        variant="underlined"
        options={films}
        getOptionLabel={(option) => option.title}
        helperText="The value will automatically be set to the highlighted option on keyboard navigation"
        autoSelect
      />
      <Select
        id="auto-complete"
        label="autoComplete"
        variant="underlined"
        options={films}
        getOptionLabel={(option) => option.title}
        helperText="If autoComplete=false, the selected option completion string (portion of string not yet typed) wont appear after the cursor in the input"
        autoComplete={false}
      />
      <Select
        id="custom-no-option-message"
        label="noOptionsText"
        variant="underlined"
        helperText="This dropdown has a custom no option message"
        options={[]}
        noOptionsText={'🐙 Custom Message 🐙'}
      />
    </div>
  )
}
