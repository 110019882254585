import React from 'react'
import LogoCardGroup from '@liveramp/motif/core/LogoCardGroup'
import LogoCard from '@liveramp/motif/core/LogoCard'
import LogoCardHeader from '@liveramp/motif/core/LogoCardHeader'
import LogoCardMedia from '@liveramp/motif/core/LogoCardMedia'
import Tooltip from '@liveramp/motif/core/Tooltip'

import StatusSuccess from '@liveramp/motif/icons/StatusSuccess'
import StatusPending from '@liveramp/motif/icons/StatusPending'
import StatusStopped from '@liveramp/motif/icons/StatusStopped'
import Facebook from '@liveramp/motif/icons/Facebook'
import Twitter from '@liveramp/motif/icons/Twitter'
import Snapchat from '@liveramp/motif/icons/Snapchat'
import LinkedIn from '@liveramp/motif/icons/LinkedIn'
import Microsoft from '@liveramp/motif/icons/Microsoft'

export default function FormGroupDemo() {
  return (
    <LogoCardGroup>
      <LogoCard key="facebookSm" id="facebookSm" defaultChecked>
        <LogoCardHeader
          title="Facebook - DefaultChecked"
          status={
            <Tooltip title="Success">
              <StatusSuccess />
            </Tooltip>
          }
        />
        <LogoCardMedia>
          <Facebook alt="Facebook Logo" title="Facebook Logo" />
        </LogoCardMedia>
      </LogoCard>
      <LogoCard key="twitterSm" id="twitterSm" checked>
        <LogoCardHeader
          title="Twitter - Checked"
          status={
            <Tooltip title="Success">
              <StatusSuccess />
            </Tooltip>
          }
        />
        <LogoCardMedia>
          <Twitter alt="Twitter Logo" title="Twitter Logo" />
        </LogoCardMedia>
      </LogoCard>
      <LogoCard key="snapchatSm" id="snapchatSm">
        <LogoCardHeader
          title="Snapchat - Unchecked"
          status={
            <Tooltip title="Pending">
              <StatusPending />
            </Tooltip>
          }
        />
        <LogoCardMedia>
          <Snapchat alt="Snapchat Logo" title="Snapchat Logo" />
        </LogoCardMedia>
      </LogoCard>
      <LogoCard key="microsoftSm" id="microsoftSm" checked disabled>
        <LogoCardHeader
          title="Microsoft - Disabled Checked"
          status={
            <Tooltip title="Success">
              <StatusSuccess />
            </Tooltip>
          }
        />
        <LogoCardMedia>
          <Microsoft alt="Microsoft Logo" title="Microsoft Logo" />
        </LogoCardMedia>
      </LogoCard>
      <LogoCard key="linkedinSm" id="linkedinSm" disabled>
        <LogoCardHeader
          title="LinkedIn - Disabled Unchecked"
          status={
            <Tooltip title="Stopped">
              <StatusStopped />
            </Tooltip>
          }
        />
        <LogoCardMedia>
          <LinkedIn alt="LinkedIn Logo" title="LinkedIn Logo" />
        </LogoCardMedia>
      </LogoCard>
    </LogoCardGroup>
  )
}
