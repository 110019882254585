import React from 'react'
import clsx from 'clsx'
import Paper from '@liveramp/motif/core/Paper'
import VirtualizedVariableSizeList from '@liveramp/motif/core/VirtualizedVariableSizeList'
import ListItem from '@liveramp/motif/core/ListItem'
import ListItemText from '@liveramp/motif/core/ListItemText'
import { makeStyles } from '@liveramp/motif/utils'
import css from '../../../resources/Page.module.css'

export default function VirtualizedVariableSizeListDemo() {
  const classes = useStyles()
  const itemCount = 1500
  const vHeight = 36
  const vWidth = 150
  const hHeight = 72
  const hWidth = 100

  const rowHeights = new Array(1000)
    .fill(true)
    .map(() => vHeight + Math.round(Math.random() * 50))

  const columnWidths = new Array(1000)
    .fill(true)
    .map(() => hWidth + Math.round(Math.random() * 50))

  return (
    <div className={css.grid}>
      <div>
        <h4>Vertical List</h4>
        <Paper>
          <VirtualizedVariableSizeList
            itemCount={itemCount}
            height={vHeight * 4}
            width={vWidth}
            itemSize={(index) => rowHeights[index]}
          >
            {({ index, style }) => (
              <ListItem
                button
                className={clsx({ [classes.even]: index % 2 === 0 })}
                key={index}
                style={style}
              >
                <ListItemText>Item {index + 1}</ListItemText>
              </ListItem>
            )}
          </VirtualizedVariableSizeList>
        </Paper>
      </div>
      <div>
        <h4>Horizontal List</h4>
        <Paper>
          <VirtualizedVariableSizeList
            itemCount={itemCount}
            height={hHeight}
            width={hWidth * 4}
            itemSize={(index) => columnWidths[index]}
            layout="horizontal"
          >
            {({ index, style }) => (
              <ListItem
                button
                className={clsx({ [classes.even]: index % 2 === 0 })}
                key={index}
                style={style}
              >
                <ListItemText>Item {index + 1}</ListItemText>
              </ListItem>
            )}
          </VirtualizedVariableSizeList>
        </Paper>
      </div>
    </div>
  )
}

let useStyles = makeStyles((theme) => ({
  even: { backgroundColor: theme.palette.grey[50] },
}))
