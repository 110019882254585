import * as icons from '@liveramp/motif/icons'

import actionIconNames from './icon-sections/ActionIcons'
import alertIconNames from './icon-sections/AlertIcons'
import brandIconNames from './icon-sections/BrandIcons'
import dashboardIconNames from './icon-sections/DashboardIcons'
import dataStoreIconNames from './icon-sections/DataStoreIcons'
import deviceIconNames from './icon-sections/DevicesIcons'
import imageIconNames from './icon-sections/ImageIcons'
import indicatorIconNames from './icon-sections/IndicatorIcons'
import verticalNavIconNames from './icon-sections/VerticalNavIcons'
import navigationIconNames from './icon-sections/NavigationIcons'
import globalNavIconNames from './icon-sections/GlobalNavIcons'
import countryFlagIconNames from './icon-sections/CountryFlagIcons'
import businessObjectIconNames from './icon-sections/BusinessObjectIcons'
import statusIconNames from './icon-sections/StatusIcons'
import tableIconNames from './icon-sections/TableIcons'
import toggleIconNames from './icon-sections/ToggleIcons'
import workflowIconNames from './icon-sections/WorkflowIcons'

let sections = {
  'Action Icons': filterIconsByKeys(icons, actionIconNames),
  'Alert Icons': filterIconsByKeys(icons, alertIconNames),
  'Brand Icons': filterIconsByKeys(icons, brandIconNames),
  'Business Object Icons': filterIconsByKeys(icons, businessObjectIconNames),
  'Dashboard Icons': filterIconsByKeys(icons, dashboardIconNames),
  'Data Store Icons': filterIconsByKeys(icons, dataStoreIconNames),
  'Device Icons': filterIconsByKeys(icons, deviceIconNames),
  'Vertical Nav Icons': filterIconsByKeys(icons, verticalNavIconNames),
  'Navigation Icons': filterIconsByKeys(icons, navigationIconNames),
  'Global Navigation Icons': filterIconsByKeys(icons, globalNavIconNames),
  'Country Flag Icons': filterIconsByKeys(icons, countryFlagIconNames),
  'Status Icons': filterIconsByKeys(icons, statusIconNames),
  'Indicator Icons': filterIconsByKeys(icons, indicatorIconNames),
  'Table Icons': filterIconsByKeys(icons, tableIconNames),
  'Toggle Icons': filterIconsByKeys(icons, toggleIconNames),
  'Workflow Icons': filterIconsByKeys(icons, workflowIconNames),
  Images: filterIconsByKeys(icons, imageIconNames),
}

function filterIconsByKeys(icons, names) {
  return names.reduce((result, key) => ({ ...result, [key]: icons[key] }), {})
}

export default sections
