import React from 'react'
import createSvgIcon from './utils/createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <g fill="#26B6E5">
      <path d="M11 21.2C5.367 21.2.8 16.633.8 11 .8 5.367 5.367.8 11 .8 16.633.8 21.2 5.367 21.2 11c0 5.633-4.567 10.2-10.2 10.2zm-.488-16.992L7.988 6.733a.384.384 0 00.276.652h1.757v5.282a2.308 2.308 0 00-1.065.782l-3.11-1.797.878-1.522a.38.38 0 00-.43-.558l-3.45.924a.38.38 0 00-.272.472l.924 3.449c.087.325.53.386.703.087l.879-1.522 3.416 1.972-.004-.117a2.302 2.302 0 004.602.113l3.406-1.966.878 1.522a.38.38 0 00.7-.094L19 10.963a.38.38 0 00-.273-.472l-3.449-.924a.384.384 0 00-.427.565l.88 1.522-3.104 1.792a2.308 2.308 0 00-1.07-.781v-5.28h1.757a.38.38 0 00.268-.652l-2.525-2.525a.38.38 0 00-.545 0z" />
      <path d="M11 21.2C5.367 21.2.8 16.633.8 11 .8 5.367 5.367.8 11 .8 16.633.8 21.2 5.367 21.2 11c0 5.633-4.567 10.2-10.2 10.2zm-.488-16.992L7.988 6.733a.384.384 0 00.276.652h1.757v5.282a2.308 2.308 0 00-1.065.782l-3.11-1.797.878-1.522a.38.38 0 00-.43-.558l-3.45.924a.38.38 0 00-.272.472l.924 3.449c.087.325.53.386.703.087l.879-1.522 3.416 1.972-.004-.117a2.302 2.302 0 004.602.113l3.406-1.966.878 1.522a.38.38 0 00.7-.094L19 10.963a.38.38 0 00-.273-.472l-3.449-.924a.384.384 0 00-.427.565l.88 1.522-3.104 1.792a2.308 2.308 0 00-1.07-.781v-5.28h1.757a.38.38 0 00.268-.652l-2.525-2.525a.38.38 0 00-.545 0z" />
    </g>
  </React.Fragment>,
  'StatusDistributionActive',
)
