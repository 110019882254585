import React from 'react'
import createSvgIcon from './utils/createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <g fillRule="evenodd">
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        d="M16.13 4A7.87 7.87 0 1112 18.572a7.87 7.87 0 110-13.403A7.834 7.834 0 0116.13 4zM7.87 5.749a6.122 6.122 0 102.65 11.642 7.845 7.845 0 01-2.261-5.52c0-2.151.862-4.1 2.26-5.52a6.097 6.097 0 00-2.648-.602zm8.26 0c-.95 0-1.848.216-2.65.601a7.845 7.845 0 012.261 5.52c0 2.151-.862 4.1-2.26 5.52A6.122 6.122 0 1016.13 5.749z"
        fillRule="nonzero"
      />
    </g>
  </React.Fragment>,
  'Overlap',
)
