/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: 'MuiTableRow',
  description:
    'Will automatically set dynamic row height\r\nbased on the material table element parent (head, body, etc).',
  displayName: 'TableRow',
  methods: [],
  props: {
    children: {
      type: { name: 'node' },
      required: false,
      description: 'Should be valid <tr> children such as `TableCell`.',
    },
    classes: {
      type: { name: 'object' },
      required: true,
      description:
        'Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details.',
    },
    className: {
      type: { name: 'string' },
      required: false,
      description: '@ignore',
    },
    component: {
      type: { name: 'elementType' },
      required: false,
      description:
        'The component used for the root node.\r\nEither a string to use a HTML element or a component.',
      defaultValue: { value: "'tr'", computed: false },
    },
    hover: {
      type: { name: 'bool' },
      required: false,
      description: 'If `true`, the table row will shade on hover.',
      defaultValue: { value: 'false', computed: false },
    },
    selected: {
      type: { name: 'bool' },
      required: false,
      description: 'If `true`, the table row will have the selected shading.',
      defaultValue: { value: 'false', computed: false },
    },
  },
  styles: {
    root: { description: 'Styles applied to the root element.' },
    selected: {
      description:
        'Pseudo-class applied to the root element if `selected={true}`.',
    },
    hover: {
      description:
        'Pseudo-class applied to the root element if `hover={true}`.',
    },
    head: {
      description:
        'Styles applied to the root element if table variant="head".',
    },
    footer: {
      description:
        'Styles applied to the root element if table variant="footer".',
    },
  },
}
export default generatedDocs
