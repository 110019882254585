/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: 'MuiStepLabel',
  description: '',
  displayName: 'StepLabel',
  methods: [],
  props: {
    children: {
      type: { name: 'node' },
      required: false,
      description:
        'In most cases will simply be a string containing a title for the label.',
    },
    classes: {
      type: { name: 'object' },
      required: false,
      description:
        'Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details.',
    },
    className: {
      type: { name: 'string' },
      required: false,
      description: '@ignore',
    },
    disabled: {
      type: { name: 'bool' },
      required: false,
      description:
        'Mark the step as disabled, will also disable the button if\r\n`StepLabelButton` is a child of `StepLabel`. Is passed to child components.',
      defaultValue: { value: 'false', computed: false },
    },
    error: {
      type: { name: 'bool' },
      required: false,
      description: 'Mark the step as failed.',
      defaultValue: { value: 'false', computed: false },
    },
    icon: {
      type: { name: 'node' },
      required: false,
      description: 'Override the default label of the step icon.',
    },
    optional: {
      type: { name: 'node' },
      required: false,
      description: 'The optional node to display.',
    },
    StepIconComponent: {
      type: { name: 'elementType' },
      required: false,
      description:
        'The component to render in place of the [`StepIcon`](/api/step-icon/).',
    },
    StepIconProps: {
      type: { name: 'object' },
      required: false,
      description:
        'Props applied to the [`StepIcon`](/api/step-icon/) element.',
    },
  },
  styles: {
    root: { description: 'Styles applied to the root element.' },
    horizontal: {
      description:
        'Styles applied to the root element if `orientation="horizontal"`.',
    },
    vertical: {
      description:
        'Styles applied to the root element if `orientation="vertical"`.',
    },
    label: {
      description:
        'Styles applied to the `Typography` component which wraps `children`.',
    },
    active: {
      description:
        'Pseudo-class applied to the `Typography` component if `active={true}`.',
    },
    completed: {
      description:
        'Pseudo-class applied to the `Typography` component if `completed={true}`.',
    },
    error: {
      description:
        'Pseudo-class applied to the root element and `Typography` component if `error={true}`.',
    },
    disabled: {
      description:
        'Pseudo-class applied to the root element and `Typography` component if `disabled={true}`.',
    },
    iconContainer: {
      description: 'Styles applied to the `icon` container element.',
    },
    alternativeLabel: {
      description:
        'Pseudo-class applied to the root and icon container and `Typography` if `alternativeLabel={true}`.',
    },
    labelContainer: {
      description:
        'Styles applied to the container element which wraps `Typography` and `optional`.',
    },
  },
}
export default generatedDocs
