import React from 'react'
import Card from '@liveramp/motif/core/Card'
import CardHeader from '@liveramp/motif/core/CardHeader'
import CardContent from '@liveramp/motif/core/CardContent'
import {
  WorkflowCreateSegment,
  WorkflowUpload,
  WorkflowReports,
} from '@liveramp/motif/icons'
import css from './Custom.module.css'

export default function CustomDemo() {
  return (
    <div className={css.grid}>
      <Card className={css.tile}>
        <CardHeader className={css.label} title="Create Segment" />
        <CardContent className={css.content}>
          <WorkflowCreateSegment className={css.icon} />
        </CardContent>
      </Card>
      <Card className={css.tile}>
        <CardHeader className={css.label} title="Upload Files" />
        <CardContent className={css.content}>
          <WorkflowUpload className={css.icon} />
        </CardContent>
      </Card>
      <Card className={css.tile}>
        <CardHeader className={css.label} title="Measure" />
        <CardContent className={css.content}>
          <WorkflowReports className={css.icon} />
        </CardContent>
      </Card>
    </div>
  )
}
