import React from 'react'
import createSvgIcon from './utils/createSvgIcon'

export default createSvgIcon(
  <>
    <path
      d="M11.552 13.552a.49.49 0 01.695 0l2.41 2.41 2.411-2.405a.49.49 0 01.695 0 .494.494 0 010 .69l-2.41 2.41 2.41 2.411a.49.49 0 11-.695.695l-2.41-2.41-2.411 2.41a.49.49 0 11-.695-.695l2.41-2.41-2.41-2.411a.49.49 0 010-.695z"
      fill="#EB989E"
    />
    <path
      d="M16.439 2.917c.23 0 .417.186.417.417l-.002.013 5.525 5.526c.244.243.101.648-.218.704l-.077.007h-5.833a.417.417 0 01-.41-.342l-.007-.075V3.75H7.971a.055.055 0 00-.051.04l-.003.018v19.469c0 .026.016.047.037.055l.017.003h13.643a.055.055 0 00.051-.04l.003-.018V11.05a.417.417 0 01.83-.049l.003.049v12.227a.892.892 0 01-.37.724H7.454a.892.892 0 01-.368-.66l-.002-.064V3.808a.89.89 0 01.823-.889l.064-.002h8.468zM12.084 0a.417.417 0 01.048.83l-.048.003H3.399a.065.065 0 00-.062.04l-.004.019V20.36c0 .024.018.047.045.055l.021.003h1.206a.417.417 0 01.049.83l-.049.003H3.4a.896.896 0 01-.897-.827L2.5 20.36V.892c0-.473.37-.857.835-.89L3.4 0h8.685zm4.583 4.34v4.41h4.411l-4.41-4.41z"
      stroke="#424342"
      strokeWidth=".1"
      fill="#424242"
    />
  </>,
  'TableNullState',
)
