import React, { useState } from 'react'
import Stepper from '@liveramp/motif/core/Stepper'
import Step from '@liveramp/motif/core/Step'
import StepLabel from '@liveramp/motif/core/StepLabel'
import ButtonFormGroup from '@liveramp/motif/core/ButtonFormGroup'
import Button from '@liveramp/motif/core/Button'
import css from '../../../resources/Page.module.css'

export default function OptionalStepDemo() {
  const steps = getSteps()
  const [activeStep, setActiveStep] = useState(0)
  const [skipped, setSkipped] = useState(new Set())

  const isStepOptional = (step) => {
    return step === 1
  }

  const isStepSkipped = (step) => {
    return skipped.has(step)
  }

  const handleNext = () => {
    let newSkipped = skipped
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped(newSkipped)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.")
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values())
      newSkipped.add(activeStep)
      return newSkipped
    })
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  return (
    <div className={css.fullWidth}>
      <Stepper activeStep={activeStep}>
        {steps.map(({ label, labelProps }, index) => {
          const stepProps = {}

          if (isStepSkipped(index)) {
            stepProps.completed = false
          }

          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          )
        })}
      </Stepper>
      <ButtonFormGroup>
        {activeStep === steps.length ? (
          <Button onClick={handleReset}>Reset</Button>
        ) : (
          <>
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              variant="outlined"
            >
              Back
            </Button>
            {isStepOptional(activeStep) && (
              <Button onClick={handleSkip}>Skip</Button>
            )}
            <Button onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </>
        )}
      </ButtonFormGroup>
    </div>
  )
}

function getSteps() {
  return [
    {
      label: 'Step One',
    },
    {
      label: 'Step Two',
      labelProps: {
        optional: 'Optional',
      },
    },
    {
      label: 'Step 3',
      labelProps: {
        optional: 'This text is optional, not the step',
      },
    },
  ]
}
