import React from 'react'
import createSvgIcon from './utils/createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path
      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zM6.414 10.64c-1.253 0-1.758.914-1.758 1.721s.712 1.479 1.758 1.479c1.047 0 1.67-.666 1.67-1.479 0-.813-.416-1.721-1.67-1.721zm5.318 0c-1.253 0-1.758.914-1.758 1.721s.711 1.479 1.758 1.479c1.046 0 1.67-.666 1.67-1.479 0-.813-.417-1.721-1.67-1.721zm5.317 0c-1.253 0-1.758.914-1.758 1.721s.712 1.479 1.758 1.479c1.047 0 1.67-.666 1.67-1.479 0-.813-.416-1.721-1.67-1.721z"
      fill="#26B6E5"
      fillRule="evenodd"
    />
  </React.Fragment>,
  'StatusMore',
)
