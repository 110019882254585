import React from 'react'
import createSvgIcon from './utils/createSvgIcon'

export default createSvgIcon(
  <path
    d="M17.63 16.238l.058.037 3.89 3.136a.3.3 0 01-.317.504l-.059-.037-3.89-3.136a.3.3 0 01.318-.504zM11 3.7a7.3 7.3 0 110 14.6 7.3 7.3 0 010-14.6zm0 .6a6.7 6.7 0 100 13.4 6.7 6.7 0 000-13.4zm0 1.4a5.287 5.287 0 013.914 1.727.3.3 0 01-.443.404 4.7 4.7 0 10.836 1.283.3.3 0 11.549-.24A5.3 5.3 0 1111 5.7z"
    fill="#55555A"
    fillRule="nonzero"
  />,
  'DashboardExploreMoreDestinations',
)
