import React from 'react'
import createSvgIcon from './utils/createSvgIcon'

export default createSvgIcon(
  <>
    <path
      d="M13 10.02h2V21h-5v-5h.75l.153-.005a2.249 2.249 0 0 0 2.091-2.073l.006-.16v-3.743Zm9-.02v9c0 1.05-.82 1.918-1.851 1.994L20 21h-3V10h5ZM2.999 15.986l.125.01.126.004H8v5H5c-1.05 0-1.918-.82-1.994-1.851L3 19l-.001-3.014ZM7 2c1.725 0 3.125 1.387 3.125 3.095v1.238h.625c.688 0 1.25.557 1.25 1.238v6.19c0 .682-.563 1.239-1.25 1.239h-7.5C2.562 15 2 14.443 2 13.762v-6.19c0-.682.563-1.239 1.25-1.239h.625V5.095C3.875 3.387 5.275 2 7 2Zm0 7.429c-.688 0-1.25.557-1.25 1.238 0 .68.563 1.238 1.25 1.238.688 0 1.25-.557 1.25-1.238 0-.681-.563-1.238-1.25-1.238Zm3.544-6.43L20 3c1.05 0 1.918.82 1.994 1.851L22 5v3h-9V7.57l-.005-.153-.018-.167a2.25 2.25 0 0 0-1.615-1.833l-.16-.04-.077-.013v-.27l-.005-.204a4.05 4.05 0 0 0-.576-1.892ZM7 3.176a1.93 1.93 0 0 0-1.938 1.92v1.237h3.875V5.095A1.93 1.93 0 0 0 7 3.176Z"
      fillRule="nonzero"
    />
  </>,
  'TableViewPrivacy',
)
