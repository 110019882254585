import React from 'react'
import {
  Page,
  Title,
  Subtitle,
  Supporting,
  Usage,
  SectionTitle,
} from '../../resources/DemoPage'
import Demo, { generateDemos } from '../../resources/Demo'
import CodeChunk from '../../resources/CodeChunk'

import Simple from './demos/Simple'
import Small from './demos/Small'
import Skeleton from './demos/Skeleton'
import SkeletonVariant from './demos/SkeletonVariant'

let demoNames = ['Simple', 'Small', 'Skeleton', 'SkeletonVariant']

export default function Dialog() {
  let demos = generateDemos('core-component-demo/loader', demoNames)

  return (
    <Page components={['Loader', 'Skeleton']}>
      <Title>Loaders</Title>
      <Subtitle>
        Loaders express an unspecified wait time or display the length of a
        process
      </Subtitle>
      <Supporting>
        Loaders inform users about the status of ongoing processes, such as
        loading an app, submitting a form, or saving updates. They communicate
        an app’s state and indicate available actions, such as whether users can
        navigate away from the current screen
      </Supporting>
      <Usage>
        <ul>
          <li>Determinate loaders display how long an operation will take.</li>
          <li>Indeterminate loaders visualize an unspecified wait time.</li>
        </ul>
      </Usage>

      <SectionTitle>Indeterminate Loaders</SectionTitle>
      <h4>Default Loader</h4>
      <p>The default loader can be used to show a full page load.</p>
      <Demo demo={demos.Simple}>
        <Simple />
      </Demo>

      <SectionTitle subtitle>Small Loader</SectionTitle>
      <p>
        The small loader can be used in small format, like within a{' '}
        <code>TableRow</code> or a<code>Button</code>
      </p>
      <Demo demo={demos.Small}>
        <Small />
      </Demo>

      <SectionTitle subtitle>Skeleton Loader</SectionTitle>
      <p>
        Display a placeholder preview of your content before the data gets
        loaded to reduce load-time frustration.
      </p>
      <p>
        The component is designed to be used directly in your components. For
        instance:
      </p>
      <CodeChunk>
        {`{ item ? ( <div> {item.title} </div> ) : ( <Skeleton width={200} height={20} /> ) }`}
      </CodeChunk>

      <Demo demo={demos.Skeleton}>
        <Skeleton />
      </Demo>
      <p>
        The component supports different shape variants. Below is an example of
        how to use the variants to create an incremental loading effect for a
        <code>Card</code>.
      </p>
      <Demo demo={demos.SkeletonVariant}>
        <SkeletonVariant />
      </Demo>
    </Page>
  )
}
