import React from 'react'
import TreeView from '@material-ui/lab/TreeView'

// As the treeitem is nested with other treeviews we need to
// recurse down the tree to drill props
const recursiveMap = (children, fn) =>
  React.Children.map(children, (child) => {
    if (!React.isValidElement(child)) {
      return child
    }
    if (child.props.children) {
      child = React.cloneElement(child, {
        children: recursiveMap(child.props.children, fn),
      })
    }
    return fn(child)
  })

const TreeViewWithProps = ({ size = 'large', children, ...props }) => {
  const childrenWithProps = recursiveMap(children, (child) =>
    React.cloneElement(child, {
      size,
    }),
  )
  return <TreeView {...props}>{childrenWithProps}</TreeView>
}

export default TreeViewWithProps
