import React, { useState } from 'react'
import Tabs from '@liveramp/motif/core/Tabs'
import Tab from '@liveramp/motif/core/Tab'

export default function SimpleDemo() {
  let [value, setValue] = useState(1)

  let handleChange = (event, value) => {
    setValue(value)
  }

  return (
    <Tabs
      withBorder
      value={value}
      onChange={handleChange}
      aria-label="Simple tabs"
    >
      <Tab value={1} label={1} {...a11yProps('1')} />
      <Tab value={2} label={2} {...a11yProps('2')} />
      <Tab value={3} label={3} {...a11yProps('3')} />
      <Tab value={4} label={4} {...a11yProps('4')} disabled />
    </Tabs>
  )
}

function a11yProps(tab) {
  return {
    id: `simple-tab-${tab}`,
    'aria-controls': `simple-tabpanel-${tab}`,
  }
}
