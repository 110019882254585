import React from 'react'
import {
  Page,
  Title,
  Subtitle,
  SectionTitle,
} from '../../../resources/DemoPage'
import { RdtNotices } from '../index'
import Demo, { generateDemos } from '../../../resources/Demo'
import PredefinedHeightDemo from './demos/PredefinedHeight'

let demoNames = ['PredefinedHeight']

export default function RichDataTable() {
  let demos = generateDemos(
    'composed-component-demo/rich-data-table/layout',
    demoNames,
  )

  return (
    <Page>
      <Title>Rich Data Table - Layout</Title>
      <Subtitle>Rich Data Table for React apps</Subtitle>
      <RdtNotices />

      <p>
        By default, the grid has no intrinsic dimensions. It occupies the space
        its parent leaves.
      </p>

      <SectionTitle>Predefined Height</SectionTitle>
      <p>
        Set <code>{`autoHeight={false}`}</code> and pass in a{' '}
        <code>height</code> prop.
      </p>
      <Demo demo={demos.PredefinedHeight}>
        <PredefinedHeightDemo />
      </Demo>
    </Page>
  )
}
