export function Motif2RenameWarning(oldName, newName) {
  let msg = `DEPRECATION WARNING: The ${oldName} component is being renamed in Motif 2.0 to ${newName}. Please update your import to use ${newName}.`
  Motif2Wrapper(msg)
}

export function Motif2DeprecationWarning(name, substitute = null) {
  let msg = `DEPRECATION WARNING: ${name} is being removed in Motif 2.0. `
  if (substitute)
    msg.concat(
      `If you need a substitute component, consider using ${substitute}.`,
    )
  Motif2Wrapper(msg)
}

function Motif2Wrapper(msg) {
  console.warn(`Motif 2.0\n${msg}`)
}

export function RDTDeprecationWarning() {
  let msg = `DEPRECATION WARNING: The RDT is going to be removed in Motif in favor of its own package to help parallelize AXs efforts. We will provide updates when this new package is released.`
  console.warn(`Rich Data Table\n${msg}`)
}
