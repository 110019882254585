import React, { useState } from 'react'
import Filter from '@liveramp/motif/core/Filter'
import Dialog from '@liveramp/motif/core/Dialog'
import Button from '@liveramp/motif/core/Button'
import css from '../../../resources/Page.module.css'

let options = []
for (let i = 1; i <= 20; i++) {
  options.push({
    label: `Option ${i}`,
    optCount: Math.floor(Math.random() * 100) + 1,
  })
}

export default function SimpleDemo() {
  let [value, setValue] = useState([options[0], options[1]])
  let [open, setOpen] = useState(false)

  return (
    <div>
      <div className={css.grid}>
        <Filter
          id="filter-uncontrolled-demo"
          label="Uncontrolled"
          options={options}
          defaultValue={value}
          getOptionLabel={(option) => option.label}
        />
        <Filter
          id="filter-controlled-demo"
          label="Controlled"
          options={options}
          value={value}
          onChange={(e, v) => setValue(v)}
          getOptionLabel={(option) => option.label}
        />
        <Button
          variant="secondary"
          color="primary"
          onClick={() => setOpen(true)}
        >
          Dialog
        </Button>
        <Dialog open={open} onClose={() => setOpen(false)} size="small">
          <Filter
            id="filter-uncontrolled-demo"
            label="Uncontrolled"
            options={options}
            defaultValue={value}
            getOptionLabel={(option) => option.label}
          />
          <br />
          <Filter
            id="filter-controlled-demo"
            label="Controlled"
            listPlacement="top-start"
            options={options}
            value={value}
            onChange={(e, v) => setValue(v)}
            getOptionLabel={(option) => option.label}
          />
        </Dialog>
      </div>
      <br />
      <hr />
      <br />
      <div className={css.grid}>
        <Filter
          id="filter-uncontrolled-demo"
          label="Loading"
          options={[]}
          defaultValue={[]}
          getOptionLabel={(option) => option.label}
          loading
        />
        <Filter
          id="filter-controlled-demo"
          label="on_open"
          options={options}
          value={value}
          onChange={(e, v) => setValue(v)}
          getOptionLabel={(option) => option.label}
          onOpen={(node) => alert(`onOpen called with ${node}`)}
        />
        <Filter
          id="filter-controlled-demo"
          label="on_close"
          options={options}
          value={value}
          onChange={(e, v) => setValue(v)}
          getOptionLabel={(option) => option.label}
          onClose={(node) => alert(`onClose called with ${node}`)}
        />
      </div>
    </div>
  )
}
