import UnderlinedInput from '@material-ui/core/Input'
import { UnderlinedInputStyle } from '../../utils/styleKeys'
export default UnderlinedInput

export let styles = (theme) => ({
  ...UnderlinedInputStyle,
  root: {
    padding: '8px 0',
    '&.MuiInputBase-adornedStart, &.MuiInputBase-adornedEnd': {
      padding: '6px 0',
      '&:not(.MuiInputBase-adornedEnd) > svg': {
        paddingRight: '4px',
      },
      '&:not(.MuiInputBase-adornedStart) > svg': {
        paddingLeft: '4px',
      },
    },
  },
  input: {
    padding: 'unset',
    minHeight: '20px',
  },
  formControl: {
    'label + &': {
      marginTop: '14px',
    },
  },
  underline: {
    '&:after': {
      borderColor: theme.palette.primary.main,
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    '&.Mui-error:not(.Mui-focused):before': {
      borderBottom: `1px solid ${theme.palette.error.main}`,
    },
    '&.Mui-error:not(.Mui-focused):after': {
      transform: 'scaleX(0)',
      borderBottom: `2px solid ${theme.palette.error.main}`,
    },
  },
})
