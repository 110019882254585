import React from 'react'
import FileName from './FileName'
import FileError from './FileError'
import MultipleFileResult from './MultipleFileResult'
import MultipleStatusButton from './MultipleStatusButton'
import grey from '../../../styles/colors/grey'
import { makeStyles } from '../../../utils'

export default function MultipleStatus({
  successComponent,
  errorMessage,
  file = null,
}) {
  const { isError, name, size } = file

  useStyles(isError)

  return (
    <div data-testid="UploaderMultipleStatus">
      <div className="UploaderMultipleStatus">
        <div className="UploaderMultipleStatus__file">
          <div className="UploaderMultipleStatus__file-data">
            <FileName name={name} maxChar={55} />
          </div>
          <div className="UploaderMultipleStatus__file-size">{size}</div>
          <div className="UploaderMultipleStatus__status">
            <MultipleStatusButton
              file={file}
              successComponent={successComponent}
            />
            <div className="UploaderMultipleStatus__loader">
              <MultipleFileResult file={file} />
            </div>
          </div>
        </div>
      </div>
      <FileError file={file} multiple customError={errorMessage} />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.UploaderMultipleStatus': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      ...theme.typography.body3,

      '& > div': {
        flex: 1,
      },

      // .UploaderMultipleStatus__file
      '&__file': {
        position: 'relative',
        background: grey[50],
        borderRadius: '4px',
        padding: '0px 24px',
        minHeight: '56px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },

      // .UploaderMultipleStatus__file-data
      '&__file-data': {
        alignItems: 'center',
        display: 'flex',
        gap: '16px',
        color: theme.palette.text.primary,
        whiteSpace: 'nowrap',
        ...theme.typography.body2,
        fontWeight: '400',
      },

      // .UploaderMultipleStatus__file-size
      '&__file-size': {
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        color: theme.palette.text.primary,
        ...theme.typography.body2,
        textTransform: 'uppercase',
        fontWeight: '400',
      },

      // .UploaderMultipleStatus__status
      '&__status': {
        justifySelf: 'flex-end',
        display: 'flex',
        alignItems: 'center',
        gap: '16px',
      },

      // .UploaderMultipleStatus__loader
      '&__loader': {
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
}))
