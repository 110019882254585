import React from 'react'
import {
  Page,
  Title,
  Subtitle,
  Usage,
  SectionTitle,
} from '../../resources/DemoPage'
import Demo, { generateDemos } from '../../resources/Demo'
import Simple from './demos/Simple'
import CloseButton from './demos/CloseButton'

let demoNames = ['Simple', 'CloseButton']

export default function IconButton() {
  let demos = generateDemos('core-component-demo/icon-button', demoNames)

  return (
    <Page components="IconButton">
      <Title>Icon Button</Title>
      <Subtitle>
        Icon buttons are commonly found in app bars and toolbars. They are also
        appropriate for toggles, such as adding or removing a star to an item.
      </Subtitle>
      <Usage>
        <ul>
          <li>
            Icons that are unique to LiveRamp's branding, such as the Status and
            Workflow icons should not be used in icon buttons.
          </li>
        </ul>
      </Usage>

      <Demo demo={demos.Simple}>
        <Simple />
      </Demo>

      <SectionTitle>Close Button</SectionTitle>
      <p>
        A smaller sized Close Button component has also been made available to
        be used when they are contained in other components; e.g. table rows and
        inputs.
      </p>
      <Demo demo={demos.CloseButton}>
        <CloseButton />
      </Demo>
    </Page>
  )
}
