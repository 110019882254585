import React from 'react'
import { Page, Title, Subtitle } from '../../resources/DemoPage'
import { CustomizationWarning } from '../index'

export default function Button() {
  return (
    <Page components={['GlobalNavigation']}>
      <Title>Global Navigation</Title>
      <Subtitle>
        The new unified navigation solution for LiveRamp products
      </Subtitle>
      <p>
        Please see the{' '}
        <a href="/composed/global-navigation/sandbox">
          sandbox for the Global Nav demo!
        </a>
      </p>
      <CustomizationWarning />
    </Page>
  )
}
