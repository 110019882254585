/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: 'MuiListItemIcon',
  description:
    'A simple wrapper to apply `List` styles to an `Icon` or `SvgIcon`.',
  displayName: 'ListItemIcon',
  methods: [],
  props: {
    children: {
      type: { name: 'node' },
      required: false,
      description:
        'The content of the component, normally `Icon`, `SvgIcon`,\r\nor a `@material-ui/icons` SVG icon element.',
    },
    classes: {
      type: { name: 'object' },
      required: false,
      description:
        'Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details.',
    },
    className: {
      type: { name: 'string' },
      required: false,
      description: '@ignore',
    },
  },
  styles: {
    root: { description: 'Styles applied to the root element.' },
    alignItemsFlexStart: {
      description:
        'Styles applied to the root element when the parent `ListItem` uses `alignItems="flex-start"`.',
    },
  },
}
export default generatedDocs
