import React from 'react'

const WarningCircleOutline = ({ fill = '#000', stroke = '#000' }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31 16C31 24.2843 24.2843 31 16 31C7.71573 31 1 24.2843 1 16C1 7.71573 7.71573 1 16 1C24.2843 1 31 7.71573 31 16Z"
        fill="white"
        stroke={stroke}
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00137 23.0075H23.0614C24.6014 23.0075 25.5614 21.3375 24.7914 20.0075L17.2614 6.9975C16.4914 5.6675 14.5714 5.6675 13.8014 6.9975L6.27137 20.0075C5.50137 21.3375 6.46137 23.0075 8.00137 23.0075ZM15.5314 16.0075C14.9814 16.0075 14.5314 15.5575 14.5314 15.0075V13.0075C14.5314 12.4575 14.9814 12.0075 15.5314 12.0075C16.0814 12.0075 16.5314 12.4575 16.5314 13.0075V15.0075C16.5314 15.5575 16.0814 16.0075 15.5314 16.0075ZM16.5314 20.0075H14.5314V18.0075H16.5314V20.0075Z"
        fill={fill}
      />
    </svg>
  )
}

export default WarningCircleOutline
