import React, { useEffect, useState, createContext } from 'react'
import clsx from 'clsx'
import IconButton from '../../core-components/IconButton'
import L1L2 from './L1L2'
import L3 from './L3'
import CaretLeft from '../../icons/CaretLeft'
import CaretRight from '../../icons/CaretRight'
import withStyles from '../../styles/withStyles'
import light from '../../styles/colors/light'

function addParentRefs(currentNode, parent) {
  if (Array.isArray(currentNode)) {
    currentNode.forEach((child) => addParentRefs(child, parent))
  } else {
    currentNode.parent = parent
  }

  if (currentNode.children) {
    addParentRefs(currentNode.children, currentNode)
  }
}

export const GlobalNavigationContext = createContext({})

export const handleRoute = (route, onNavigate) => {
  const normalizedRoute = typeof route === 'function' ? route() : route

  if (onNavigate) {
    history.pushState({}, '', normalizedRoute)
    onNavigate(normalizedRoute)
  } else {
    window.location = normalizedRoute
  }
}

export function isActive(
  matcher,
  exact = true,
  path = window.location.pathname,
) {
  switch (typeof matcher) {
    case 'string':
      return isActive(new RegExp(`^${matcher}${exact ? '$' : ''}`), exact, path)
    case 'function':
      return isActive(matcher(), exact, path)
    default:
      if (matcher instanceof RegExp) {
        return matcher.test(path)
      } else if (Array.isArray(matcher)) {
        return matcher.some((elem) => isActive(elem, exact, path))
      } else {
        return false
      }
  }
}

function isNodeActive(node) {
  return isActive(node.activeOn || node.route, false)
}

function GlobalNavigation({
  classes,
  navigation,
  accountSwitcher,
  customisation,
  openOnLoad,
  defaultActiveOnL3,
  setGlobalNavDisplayLevel = () => {},
  onOpen = () => {},
  onClose = () => {},
  isAltNav = false,
  ...props
}) {
  // Add parent refs to top nav items
  addParentRefs(navigation[0])

  const testNavigationL2 = (navigation) => {
    // L1 top and bottom lists
    for (let i = 0; i < navigation.length; i++) {
      // L1 items
      for (let j = 0; j < navigation[i].length; j++) {
        if (
          navigation[i][j]?.children?.length > 1 &&
          navigation[i][j]?.children[0].level === 2
        ) {
          return false
        }
      }
    }

    return true
  }

  // Assumption: the first L2 under the first L1 is the default menu
  const getActiveL3 = (navigation) => {
    let defaultActive
    // L1 top and bottom lists
    for (let i = 0; i < navigation.length; i++) {
      // L1 items
      for (let j = 0; j < navigation[i].length; j++) {
        // check if this L1 has an L2
        if (
          navigation[i][j].children &&
          navigation[i][j]?.children[0]?.level === 2
        ) {
          // check this L2 for the L3 that is active
          for (let k = 0; k < navigation[i][j].children.length; k++) {
            if (navigation[i][j].children[k]?.children) {
              defaultActive = defaultActive || navigation[i][j].children[k] // If this is the first L3 we've seen, set it as default L3
              if (isNodeActive(navigation[i][j].children[k])) {
                return navigation[i][j].children[k]
              }
            }
          }
        } else {
          // check if this L1 has an L3 that is active
          defaultActive = defaultActive || navigation[i][j] // If _this_ is the first L3 we've seen, set it as default L3
          if (isNodeActive(navigation[i][j])) {
            return navigation[i][j]
          }
        }
      }
    }

    return defaultActive // Always return _something_ as active
  }

  const [activeOnL3, setActiveOnL3] = useState(defaultActiveOnL3)
  const [skipL2, setSkipL2] = useState(testNavigationL2(navigation))
  const [currentL3, setCurrentL3] = useState(getActiveL3(navigation))
  const [displayLevel, setDisplayLevel] = useState(openOnLoad ? 3 : 1)
  const [accountSwitcherOpen, setAccountSwitcherOpen] = useState(false)
  const [accountSwitcherProps, setAccountSwitcherProps] = useState(
    Object.assign(accountSwitcher, {
      accountSwitcherOpen,
      setAccountSwitcherOpen,
    }),
  )

  useEffect(() => {
    setGlobalNavDisplayLevel(displayLevel)
  }, [displayLevel])

  useEffect(() => {
    setAccountSwitcherProps(accountSwitcher)
  }, [accountSwitcher])

  useEffect(() => {
    setSkipL2(testNavigationL2(navigation))
    setCurrentL3(getActiveL3(navigation))
  }, [navigation])

  const state = {
    navigation,
    accountSwitcherProps,
    customisation,
    displayLevel,
    setDisplayLevel,
    skipL2,
    currentL3,
    setCurrentL3,
    enableSetActiveOnL3: !!defaultActiveOnL3,
    activeOnL3,
    setActiveOnL3,
    isAltNav,
  }

  return (
    <GlobalNavigationContext.Provider value={state}>
      <nav className="GN" {...props}>
        <L1L2 />
        <L3 />
      </nav>
      <IconButton
        className="GN-iconButton"
        classes={{
          root: clsx({
            ['GN-iconButton--isOpen']: displayLevel > 1,
          }),
        }}
        size="small"
        onClick={() => {
          if (displayLevel === 1) {
            setDisplayLevel(currentL3 ? 3 : 2)
            onOpen()
          } else if (displayLevel > 1) {
            setDisplayLevel(1)
            onClose()
          }
        }}
      >
        {displayLevel > 1 ? <CaretLeft /> : <CaretRight />}
      </IconButton>
    </GlobalNavigationContext.Provider>
  )
}

const styles = (theme) => ({
  '@global': {
    '.GN': {
      position: 'relative',
      zIndex: 1210,
      display: 'flex',
      flexDirection: 'row',
      fontFamily: theme.typography.fontFamilyHeader,
      // TODO: remove focus styling from autocomplete-based components in the
      // global nav. This can be done in the theme object once MUI is upgraded
      // to v5 where the autocomplete component is promoted out of the lab and
      // recognised in theme level overrides.
      '& .MuiList-root': {
        '&:not(.GN-lightColor)': {
          "& .MuiAutocomplete-option[data-focus='true']": {
            backgroundColor: 'unset',
          },
          "& .MuiAutocomplete-option[aria-selected='true']": {
            backgroundColor: theme.palette.action.selected,
          },
          '& .MuiAutocomplete-option:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        '&.GN-lightColor': {
          "& .MuiAutocomplete-option[data-focus='true']": {
            backgroundColor: 'unset',
          },
          "& .MuiAutocomplete-option[aria-selected='true']": {
            backgroundColor: light.action.selected,
          },
          '& .MuiAutocomplete-option:hover': {
            backgroundColor: light.action.hover,
          },
        },
      },

      // .GN-iconButton
      '&-iconButton': {
        zIndex: '1210',
        position: 'fixed',
        background: 'white',
        boxShadow:
          '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
        height: '24px',
        width: '24px',
        top: '35px',
        left: '42px',
        transition: '225ms cubic-bezier(0.4, 0, 0.2, 1)',

        // .GN-iconButton--isOpen
        '&--isOpen': {
          left: '250px',
        },

        '& .MuiSvgIcon-root': {
          fill: theme.palette.grey[900],
          fontSize: '1.75rem',
        },

        '&:hover': {
          background: theme.palette.primary.main,

          '& .MuiSvgIcon-root': {
            fill: theme.palette.common.white,
          },
        },
      },
    },
  },
})

export default withStyles(styles)(GlobalNavigation)
