let overrideDocs = {
  props: {
    classes: {
      type: { name: 'object' },
      required: true,
      description:
        'Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details.',
    },
    color: {
      description: '@ignore',
    },
  },
}

export default overrideDocs
