import React from 'react'
import {
  Page,
  Title,
  Subtitle,
  Supporting,
  Usage,
  NoticePanel,
} from '../../resources/DemoPage'
import { CustomizationWarning } from '../index'
import Demo, { generateDemos } from '../../resources/Demo'
import SimpleDemo from './demos/Simple'

let demoNames = ['Simple']

export default function RichDataTable() {
  let demos = generateDemos(
    'composed-component-demo/rich-data-table',
    demoNames,
  )
  return (
    <Page>
      <Title>Rich Data Table</Title>
      <Subtitle>Rich Data Table for React apps</Subtitle>
      <RdtNotices />
      <Supporting>
        Data tables display information in a grid-like format of rows and
        columns. They organize information in a way that’s easy to scan so that
        users can look for patterns and insights. The <code>RichDataTable</code>{' '}
        (RDT) is a composed and extended version of the <code>DataGrid</code>{' '}
        component, providing support for common functionality such as searching
        and footer actions. UX recommends using the <code>RichDataTable</code>{' '}
        component over the <code>DataGrid</code> component when possible to
        ensure consistent experiences across LiveRamp applications.
      </Supporting>
      <Usage>
        <ul>
          <li>
            Docs for the full DataGrid API can be found under{' '}
            <a href="https://material-ui.com/api/data-grid/">
              Material UIs official DataGrid documentation
            </a>
            .
          </li>
          <li>
            Because the DataGrid has been modified to suit LiveRamp's needs,
            documentation there may conflict with ours here; assume that Motif
            takes precedence.
          </li>
        </ul>
      </Usage>

      <Demo demo={demos.Simple}>
        <SimpleDemo />
      </Demo>
    </Page>
  )
}

export function RdtNotices() {
  return (
    <>
      <NoticePanel variant="warning">
        <div>
          <span role="img" aria-label="lightning spark">
            ⚡💥
          </span>
          The RDT is built on a material-ui lab component (outside of the core
          package, for components not yet ready to be introduced to the core
          library), therefore there may be breaking changes in the coming
          months. We will communicate when and if that happens! Please feel free
          to use the RDT <a href="/rich-data-table/sandbox">Sandbox</a> to check
          out a combination of the demos.
          <span role="img" aria-label="lightning spark">
            ⚡💥
          </span>
        </div>
      </NoticePanel>
      <CustomizationWarning />
    </>
  )
}
