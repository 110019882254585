import React, { useState } from 'react'
import Radio from '@liveramp/motif/core/Radio'
import RadioGroup from '@liveramp/motif/core/RadioGroup'
import FormControl from '@liveramp/motif/core/FormControl'
import FormLabel from '@liveramp/motif/core/FormLabel'
import FormControlLabel from '@liveramp/motif/core/FormControlLabel'
import FormHelperText from '@liveramp/motif/core/FormHelperText'
import css from '../../../resources/Page.module.css'

export default function FormGroupDemo() {
  let [value, setValue] = useState('')
  let handleChange = (event) => setValue(event.target.value)

  return (
    <FormControl className={css.grid}>
      <FormControl component="fieldset">
        <FormLabel component="legend">Are you a Dog or a Cat person?</FormLabel>
        <RadioGroup value={value} onChange={handleChange}>
          <FormControlLabel value="dog" control={<Radio />} label="Dog" />
          <FormControlLabel value="cat" control={<Radio />} label="Cat" />
          <FormControlLabel
            value="disabled"
            disabled
            control={<Radio />}
            label="Neither"
          />
        </RadioGroup>
        <FormHelperText>This is your helper text</FormHelperText>
      </FormControl>
      <FormControl component="fieldset" required error={value === ''}>
        <FormLabel component="legend">Are you a Dog or a Cat person?</FormLabel>
        <RadioGroup value={value} onChange={handleChange}>
          <FormControlLabel value="dog" control={<Radio />} label="Dog" />
          <FormControlLabel value="cat" control={<Radio />} label="Cat" />
          <FormControlLabel
            value="disabled"
            disabled
            control={<Radio />}
            label="Neither"
          />
        </RadioGroup>
        <FormHelperText>Please pick at least one</FormHelperText>
      </FormControl>
    </FormControl>
  )
}
