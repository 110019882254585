import clsx from 'clsx'
import { useEffect } from 'react'
import makeStyles from '../styles/makeStyles'
import withStyles from '../styles/withStyles'

const baseSize = 16

// Merge MUI's classes object keys and concat className strings
// e.g.
// let obj1 = { a: "123", b: "456", c: "789"}
// let obj2 = { b: "str2" }
// let obj3 = { c: "str3" }
// mergeClasses(obj1, obj2, obj3, ...)
// results in { a: "123", b: "456 str2", c: "789 str3"}
let mergeClasses = (...classes) => {
  return classes.reduce((acc, cur) => {
    if (cur) {
      Object.keys(cur).forEach((key) => {
        if (acc.hasOwnProperty(key)) {
          acc[key] = clsx(acc[key], cur[key])
        } else {
          acc[key] = cur[key]
        }
      })
    }

    return acc
  }, {})
}

// PropTypes test
// Ensure that if any of the supplied proptypes are used, that all of them are used
// e.g.
// 1 1 - true
// 0 1 - false
// 1 0 - false
// 0 0 - true
let dependentProps = (propNames) => (props, propName, componentName) => {
  if (
    propNames.reduce((acc, curr) => (props[curr] !== undefined) ^ acc, false)
  ) {
    return new Error(
      `${componentName}: [${propNames.join(
        ', ',
      )}] are required to use this functionality.`,
    )
  }
}

// Escape string for regex parsing
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
let escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

const noop = () => {}
// Function to convert to biggest size possible
// https://stackoverflow.com/a/18650828
const bytesToSize = (b) => {
  const sizes = ['b', 'kb', 'mb', 'gb']
  if (b == 0) return '0 b'
  var i = parseInt(Math.floor(Math.log(b) / Math.log(1024)))
  return Math.round(b / Math.pow(1024, i), 2) + ' ' + sizes[i]
}

function useOutsideAlerter(ref) {
  useEffect(() => {
    // function to handle click outside of ref element
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        ref.current.classList.remove('Mui-selected')
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

function pxToRem(size) {
  return `${size / baseSize}rem`
}

export {
  mergeClasses,
  dependentProps,
  escapeRegExp,
  makeStyles,
  withStyles,
  noop,
  bytesToSize,
  useOutsideAlerter,
  pxToRem,
}
