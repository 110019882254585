/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

const AccordionStyle = { root: {}, rounded: {}, expanded: {}, disabled: {} }
const AccordionActionsStyle = { root: {}, spacing: {} }
const AccordionDetailsStyle = { root: {} }
const AccordionSummaryStyle = {
  root: {},
  expanded: {},
  focusVisible: {},
  disabled: {},
  content: {},
  expandIcon: {},
}
const AlertStyle = {
  root: {},
  anchorOriginTopCenter: {},
  anchorOriginBottomCenter: {},
  anchorOriginTopRight: {},
  anchorOriginBottomRight: {},
  anchorOriginTopLeft: {},
  anchorOriginBottomLeft: {},
}
const AlertInlineStyle = {
  root: {},
  standardSuccess: {},
  standardInfo: {},
  standardWarning: {},
  standardError: {},
  outlinedSuccess: {},
  outlinedInfo: {},
  outlinedWarning: {},
  outlinedError: {},
  filledSuccess: {},
  filledInfo: {},
  filledWarning: {},
  filledError: {},
  icon: {},
  message: {},
  action: {},
}
const AppBarStyle = {
  root: {},
  positionFixed: {},
  positionAbsolute: {},
  positionSticky: {},
  positionStatic: {},
  positionRelative: {},
  colorDefault: {},
  colorPrimary: {},
  colorSecondary: {},
  colorInherit: {},
  colorTransparent: {},
}
const AutocompleteStyle = {
  root: {},
  fullWidth: {},
  focused: {},
  tag: {},
  tagSizeSmall: {},
  hasPopupIcon: {},
  hasClearIcon: {},
  inputRoot: {},
  input: {},
  inputFocused: {},
  endAdornment: {},
  clearIndicator: {},
  clearIndicatorDirty: {},
  popupIndicator: {},
  popupIndicatorOpen: {},
  popper: {},
  popperDisablePortal: {},
  paper: {},
  listbox: {},
  loading: {},
  noOptions: {},
  option: {},
  groupLabel: {},
  groupUl: {},
}
const AvatarStyle = {
  root: {},
  colorDefault: {},
  circle: {},
  circular: {},
  rounded: {},
  square: {},
  img: {},
  fallback: {},
}
const BadgeStyle = {
  root: {},
  badge: {},
  colorPrimary: {},
  colorSecondary: {},
  colorError: {},
  dot: {},
  anchorOriginTopRightRectangle: {},
  anchorOriginTopRightRectangular: {},
  anchorOriginBottomRightRectangle: {},
  anchorOriginBottomRightRectangular: {},
  anchorOriginTopLeftRectangle: {},
  anchorOriginTopLeftRectangular: {},
  anchorOriginBottomLeftRectangle: {},
  anchorOriginBottomLeftRectangular: {},
  anchorOriginTopRightCircle: {},
  anchorOriginTopRightCircular: {},
  anchorOriginBottomRightCircle: {},
  anchorOriginBottomRightCircular: {},
  anchorOriginTopLeftCircle: {},
  anchorOriginTopLeftCircular: {},
  anchorOriginBottomLeftCircle: {},
  anchorOriginBottomLeftCircular: {},
  invisible: {},
}
const BreadcrumbsStyle = { root: {}, ol: {}, li: {}, separator: {} }
const ButtonStyle = {
  root: {},
  label: {},
  text: {},
  textPrimary: {},
  textSecondary: {},
  outlined: {},
  outlinedPrimary: {},
  outlinedSecondary: {},
  contained: {},
  containedPrimary: {},
  containedSecondary: {},
  disableElevation: {},
  focusVisible: {},
  disabled: {},
  colorInherit: {},
  textSizeSmall: {},
  textSizeLarge: {},
  outlinedSizeSmall: {},
  outlinedSizeLarge: {},
  containedSizeSmall: {},
  containedSizeLarge: {},
  sizeSmall: {},
  sizeLarge: {},
  fullWidth: {},
  startIcon: {},
  endIcon: {},
  iconSizeSmall: {},
  iconSizeMedium: {},
  iconSizeLarge: {},
}
const ButtonBaseStyle = { root: {}, disabled: {}, focusVisible: {} }
const ButtonFormGroupStyle = {}
const ButtonGroupStyle = {}
const ButtonMenuStyle = {}
const CardStyle = { root: {} }
const CardActionsStyle = { root: {}, spacing: {} }
const CardContentStyle = { root: {} }
const CardHeaderStyle = {
  root: {},
  avatar: {},
  action: {},
  content: {},
  title: {},
  subheader: {},
}
const CardMediaStyle = { root: {}, media: {}, img: {} }
const CheckboxStyle = {
  root: {},
  checked: {},
  disabled: {},
  indeterminate: {},
  colorPrimary: {},
  colorSecondary: {},
}
const ChipStyle = {
  root: {},
  sizeSmall: {},
  colorPrimary: {},
  colorSecondary: {},
  disabled: {},
  clickable: {},
  clickableColorPrimary: {},
  clickableColorSecondary: {},
  deletable: {},
  deletableColorPrimary: {},
  deletableColorSecondary: {},
  outlined: {},
  outlinedPrimary: {},
  outlinedSecondary: {},
  avatar: {},
  avatarSmall: {},
  avatarColorPrimary: {},
  avatarColorSecondary: {},
  icon: {},
  iconSmall: {},
  iconColorPrimary: {},
  iconColorSecondary: {},
  label: {},
  labelSmall: {},
  deleteIcon: {},
  deleteIconSmall: {},
  deleteIconColorPrimary: {},
  deleteIconColorSecondary: {},
  deleteIconOutlinedColorPrimary: {},
  deleteIconOutlinedColorSecondary: {},
}
const ClickAwayListenerStyle = {}
const CloseButtonStyle = {}
const CollapseStyle = {
  root: {},
  entered: {},
  hidden: {},
  wrapper: {},
  wrapperInner: {},
}
const DialogStyle = {
  root: {},
  scrollPaper: {},
  scrollBody: {},
  container: {},
  paper: {},
  paperScrollPaper: {},
  paperScrollBody: {},
  paperWidthFalse: {},
  paperWidthXs: {},
  paperWidthSm: {},
  paperWidthMd: {},
  paperWidthLg: {},
  paperWidthXl: {},
  paperFullWidth: {},
  paperFullScreen: {},
}
const DialogActionsStyle = { root: {}, spacing: {} }
const DialogContentStyle = { root: {}, dividers: {} }
const DialogTitleStyle = { root: {} }
const DividerStyle = {
  root: {},
  absolute: {},
  inset: {},
  light: {},
  middle: {},
  vertical: {},
  flexItem: {},
}
const DrawerStyle = {
  root: {},
  docked: {},
  paper: {},
  paperAnchorLeft: {},
  paperAnchorRight: {},
  paperAnchorTop: {},
  paperAnchorBottom: {},
  paperAnchorDockedLeft: {},
  paperAnchorDockedTop: {},
  paperAnchorDockedRight: {},
  paperAnchorDockedBottom: {},
  modal: {},
}
const ErrorBoundaryStyle = {}
const FabStyle = {
  root: {},
  label: {},
  primary: {},
  secondary: {},
  extended: {},
  focusVisible: {},
  disabled: {},
  colorInherit: {},
  sizeSmall: {},
  sizeMedium: {},
}
const FilterStyle = {
  root: {},
  fullWidth: {},
  focused: {},
  tag: {},
  tagSizeSmall: {},
  hasPopupIcon: {},
  hasClearIcon: {},
  inputRoot: {},
  input: {},
  inputFocused: {},
  endAdornment: {},
  clearIndicator: {},
  clearIndicatorDirty: {},
  popupIndicator: {},
  popupIndicatorOpen: {},
  popper: {},
  popperDisablePortal: {},
  paper: {},
  listbox: {},
  loading: {},
  noOptions: {},
  option: {},
  groupLabel: {},
  groupUl: {},
}
const FooterStyle = {
  root: {},
  docked: {},
  paper: {},
  paperAnchorLeft: {},
  paperAnchorRight: {},
  paperAnchorTop: {},
  paperAnchorBottom: {},
  paperAnchorDockedLeft: {},
  paperAnchorDockedTop: {},
  paperAnchorDockedRight: {},
  paperAnchorDockedBottom: {},
  modal: {},
}
const FormControlStyle = {
  root: {},
  marginNormal: {},
  marginDense: {},
  fullWidth: {},
}
const FormControlLabelStyle = {
  root: {},
  labelPlacementStart: {},
  labelPlacementTop: {},
  labelPlacementBottom: {},
  disabled: {},
  label: {},
}
const FormGroupStyle = { root: {}, row: {} }
const FormHelperTextStyle = {
  root: {},
  error: {},
  disabled: {},
  marginDense: {},
  contained: {},
  focused: {},
  filled: {},
  required: {},
}
const FormLabelStyle = {
  root: {},
  colorSecondary: {},
  focused: {},
  disabled: {},
  error: {},
  filled: {},
  required: {},
  asterisk: {},
}
const FullScreenDialogStyle = {
  root: {},
  scrollPaper: {},
  scrollBody: {},
  container: {},
  paper: {},
  paperScrollPaper: {},
  paperScrollBody: {},
  paperWidthFalse: {},
  paperWidthXs: {},
  paperWidthSm: {},
  paperWidthMd: {},
  paperWidthLg: {},
  paperWidthXl: {},
  paperFullWidth: {},
  paperFullScreen: {},
}
const FullScreenDialogActionsStyle = { root: {}, spacing: {} }
const FullScreenDialogContentStyle = { root: {}, dividers: {} }
const FullScreenDialogTitleStyle = { root: {} }
const GlobalActionButtonStyle = {
  root: {},
  label: {},
  primary: {},
  secondary: {},
  extended: {},
  focusVisible: {},
  disabled: {},
  colorInherit: {},
  sizeSmall: {},
  sizeMedium: {},
}
const GlobalActionButtonActionStyle = {
  root: {},
  gutters: {},
  selected: {},
  dense: {},
}
const GlobalAdminStyle = {}
const GlobalAdminButtonStyle = {}
const GlobalAdminNotificationsButtonStyle = {}
const GlobalNavigationStyle = {}
const GridStyle = { root: {}, container: {}, item: {}, zeroMinWidth: {} }
const GrowStyle = {}
const HeaderStyle = {}
const IconButtonStyle = {
  root: {},
  edgeStart: {},
  edgeEnd: {},
  colorInherit: {},
  colorPrimary: {},
  colorSecondary: {},
  disabled: {},
  sizeSmall: {},
  label: {},
}
const InputAdornmentStyle = {
  root: {},
  filled: {},
  positionStart: {},
  positionEnd: {},
  disablePointerEvents: {},
  hiddenLabel: {},
  marginDense: {},
}
const InputBaseStyle = {
  root: {},
  formControl: {},
  focused: {},
  disabled: {},
  adornedStart: {},
  adornedEnd: {},
  error: {},
  marginDense: {},
  multiline: {},
  colorSecondary: {},
  fullWidth: {},
  input: {},
  inputMarginDense: {},
  inputMultiline: {},
  inputTypeSearch: {},
  inputAdornedStart: {},
  inputAdornedEnd: {},
  inputHiddenLabel: {},
}
const InputLabelStyle = {
  root: {},
  focused: {},
  disabled: {},
  error: {},
  required: {},
  asterisk: {},
  formControl: {},
  marginDense: {},
  shrink: {},
  animated: {},
  filled: {},
  outlined: {},
}
const LinearProgressStyle = {
  root: {},
  colorPrimary: {},
  colorSecondary: {},
  determinate: {},
  indeterminate: {},
  buffer: {},
  query: {},
  dashed: {},
  dashedColorPrimary: {},
  dashedColorSecondary: {},
  bar: {},
  barColorPrimary: {},
  barColorSecondary: {},
  bar1Indeterminate: {},
  bar1Determinate: {},
  bar1Buffer: {},
  bar2Indeterminate: {},
  bar2Buffer: {},
}
const LinkStyle = {
  root: {},
  underlineNone: {},
  underlineHover: {},
  underlineAlways: {},
  button: {},
  focusVisible: {},
}
const ListStyle = { root: {}, padding: {}, dense: {}, subheader: {} }
const ListItemStyle = {
  root: {},
  container: {},
  focusVisible: {},
  dense: {},
  alignItemsFlexStart: {},
  disabled: {},
  divider: {},
  gutters: {},
  button: {},
  secondaryAction: {},
  selected: {},
}
const ListItemIconStyle = { root: {}, alignItemsFlexStart: {} }
const ListItemTextStyle = {
  root: {},
  multiline: {},
  dense: {},
  inset: {},
  primary: {},
  secondary: {},
}
const ListSubheaderStyle = {
  root: {},
  colorPrimary: {},
  colorInherit: {},
  gutters: {},
  inset: {},
  sticky: {},
}
const LoaderStyle = {}
const LogoCardStyle = { root: {} }
const LogoCardActionsStyle = { root: {}, spacing: {} }
const LogoCardContentStyle = { root: {} }
const LogoCardGroupStyle = { root: {}, row: {} }
const LogoCardHeaderStyle = {
  root: {},
  avatar: {},
  action: {},
  content: {},
  title: {},
  subheader: {},
}
const LogoCardMediaStyle = {}
const MenuStyle = { paper: {}, list: {} }
const MenuItemStyle = { root: {}, gutters: {}, selected: {}, dense: {} }
const MenuListStyle = {}
const MotifStyle = {}
const NotificationContentStyle = {}
const OutlinedInputStyle = {
  root: {},
  colorSecondary: {},
  focused: {},
  disabled: {},
  adornedStart: {},
  adornedEnd: {},
  error: {},
  marginDense: {},
  multiline: {},
  notchedOutline: {},
  input: {},
  inputMarginDense: {},
  inputMultiline: {},
  inputAdornedStart: {},
  inputAdornedEnd: {},
}
const PaperStyle = { root: {}, rounded: {}, outlined: {} }
const PopperStyle = {}
const RadioStyle = {
  root: {},
  checked: {},
  disabled: {},
  colorPrimary: {},
  colorSecondary: {},
}
const RadioGroupStyle = {}
const RichDataTableStyle = {}
const SelectStyle = {
  root: {},
  fullWidth: {},
  focused: {},
  tag: {},
  tagSizeSmall: {},
  hasPopupIcon: {},
  hasClearIcon: {},
  inputRoot: {},
  input: {},
  inputFocused: {},
  endAdornment: {},
  clearIndicator: {},
  clearIndicatorDirty: {},
  popupIndicator: {},
  popupIndicatorOpen: {},
  popper: {},
  popperDisablePortal: {},
  paper: {},
  listbox: {},
  loading: {},
  noOptions: {},
  option: {},
  groupLabel: {},
  groupUl: {},
}
const SidePanelStyle = {
  root: {},
  docked: {},
  paper: {},
  paperAnchorLeft: {},
  paperAnchorRight: {},
  paperAnchorTop: {},
  paperAnchorBottom: {},
  paperAnchorDockedLeft: {},
  paperAnchorDockedTop: {},
  paperAnchorDockedRight: {},
  paperAnchorDockedBottom: {},
  modal: {},
}
const SidePanelContentStyle = {}
const SidePanelFieldValueRowStyle = {}
const SidePanelHeaderStyle = {}
const SidePanelImageSectionStyle = {}
const SidePanelListStyle = { root: {}, padding: {}, dense: {}, subheader: {} }
const SidePanelListItemStyle = {
  root: {},
  container: {},
  focusVisible: {},
  dense: {},
  alignItemsFlexStart: {},
  disabled: {},
  divider: {},
  gutters: {},
  button: {},
  secondaryAction: {},
  selected: {},
}
const SidePanelSubsectionStyle = {}
const SkeletonStyle = {
  root: {},
  text: {},
  rect: {},
  circle: {},
  pulse: {},
  wave: {},
  withChildren: {},
  fitContent: {},
  heightAuto: {},
}
const StatusStyle = {}
const StepStyle = {
  root: {},
  horizontal: {},
  vertical: {},
  alternativeLabel: {},
  completed: {},
}
const StepButtonStyle = {
  root: {},
  horizontal: {},
  vertical: {},
  touchRipple: {},
}
const StepConnectorStyle = {
  root: {},
  horizontal: {},
  vertical: {},
  alternativeLabel: {},
  active: {},
  completed: {},
  disabled: {},
  line: {},
  lineHorizontal: {},
  lineVertical: {},
}
const StepContentStyle = { root: {}, last: {}, transition: {} }
const StepIconStyle = {
  root: {},
  text: {},
  active: {},
  completed: {},
  error: {},
}
const StepLabelStyle = {
  root: {},
  horizontal: {},
  vertical: {},
  label: {},
  active: {},
  completed: {},
  error: {},
  disabled: {},
  iconContainer: {},
  alternativeLabel: {},
  labelContainer: {},
}
const StepperStyle = {
  root: {},
  horizontal: {},
  vertical: {},
  alternativeLabel: {},
}
const SwitchStyle = {
  root: {},
  edgeStart: {},
  edgeEnd: {},
  switchBase: {},
  colorPrimary: {},
  colorSecondary: {},
  sizeSmall: {},
  checked: {},
  disabled: {},
  input: {},
  thumb: {},
  track: {},
}
const TabStyle = {
  root: {},
  labelIcon: {},
  textColorInherit: {},
  textColorPrimary: {},
  textColorSecondary: {},
  selected: {},
  disabled: {},
  fullWidth: {},
  wrapped: {},
  wrapper: {},
}
const TableStyle = { root: {}, stickyHeader: {} }
const TableBodyStyle = { root: {} }
const TableCellStyle = {
  root: {},
  head: {},
  body: {},
  footer: {},
  sizeSmall: {},
  paddingCheckbox: {},
  paddingNone: {},
  alignLeft: {},
  alignCenter: {},
  alignRight: {},
  alignJustify: {},
  stickyHeader: {},
}
const TableFooterStyle = { root: {} }
const TableHeaderStyle = { root: {} }
const TableRowStyle = {
  root: {},
  selected: {},
  hover: {},
  head: {},
  footer: {},
}
const TabsStyle = {
  root: {},
  vertical: {},
  flexContainer: {},
  flexContainerVertical: {},
  centered: {},
  scroller: {},
  fixed: {},
  scrollable: {},
  scrollButtons: {},
  scrollButtonsDesktop: {},
  indicator: {},
}
const TextFieldStyle = { root: {} }
const ToggleButtonStyle = {
  root: {},
  disabled: {},
  selected: {},
  label: {},
  sizeSmall: {},
  sizeLarge: {},
}
const ToggleButtonGroupStyle = {
  root: {},
  vertical: {},
  grouped: {},
  groupedHorizontal: {},
  groupedVertical: {},
}
const ToolbarStyle = { root: {}, gutters: {}, regular: {}, dense: {} }
const TooltipStyle = {
  popper: {},
  popperInteractive: {},
  popperArrow: {},
  tooltip: {},
  tooltipArrow: {},
  arrow: {},
  touch: {},
  tooltipPlacementLeft: {},
  tooltipPlacementRight: {},
  tooltipPlacementTop: {},
  tooltipPlacementBottom: {},
}
const TreeItemStyle = {
  root: {},
  expanded: {},
  selected: {},
  group: {},
  content: {},
  iconContainer: {},
  label: {},
}
const TreeViewStyle = { root: {} }
const TypographyStyle = {
  root: {},
  body2: {},
  body1: {},
  caption: {},
  button: {},
  h1: {},
  h2: {},
  h3: {},
  h4: {},
  h5: {},
  h6: {},
  subtitle1: {},
  subtitle2: {},
  overline: {},
  srOnly: {},
  alignLeft: {},
  alignCenter: {},
  alignRight: {},
  alignJustify: {},
  noWrap: {},
  gutterBottom: {},
  paragraph: {},
  colorInherit: {},
  colorPrimary: {},
  colorSecondary: {},
  colorTextPrimary: {},
  colorTextSecondary: {},
  colorError: {},
  displayInline: {},
  displayBlock: {},
}
const UnderlinedInputStyle = {
  root: {},
  formControl: {},
  focused: {},
  disabled: {},
  colorSecondary: {},
  underline: {},
  error: {},
  marginDense: {},
  multiline: {},
  fullWidth: {},
  input: {},
  inputMarginDense: {},
  inputMultiline: {},
  inputTypeSearch: {},
}
const UploaderStyle = {}
const VerticalNavStyle = {}
const VerticalNavDividerStyle = {}
const VerticalNavItemStyle = {}
const VirtualizedFixedSizeListStyle = {}
const VirtualizedVariableSizeListStyle = {}

export {
  AccordionStyle,
  AccordionActionsStyle,
  AccordionDetailsStyle,
  AccordionSummaryStyle,
  AlertStyle,
  AlertInlineStyle,
  AppBarStyle,
  AutocompleteStyle,
  AvatarStyle,
  BadgeStyle,
  BreadcrumbsStyle,
  ButtonStyle,
  ButtonBaseStyle,
  ButtonFormGroupStyle,
  ButtonGroupStyle,
  ButtonMenuStyle,
  CardStyle,
  CardActionsStyle,
  CardContentStyle,
  CardHeaderStyle,
  CardMediaStyle,
  CheckboxStyle,
  ChipStyle,
  ClickAwayListenerStyle,
  CloseButtonStyle,
  CollapseStyle,
  DialogStyle,
  DialogActionsStyle,
  DialogContentStyle,
  DialogTitleStyle,
  DividerStyle,
  DrawerStyle,
  ErrorBoundaryStyle,
  FabStyle,
  FilterStyle,
  FooterStyle,
  FormControlStyle,
  FormControlLabelStyle,
  FormGroupStyle,
  FormHelperTextStyle,
  FormLabelStyle,
  FullScreenDialogStyle,
  FullScreenDialogActionsStyle,
  FullScreenDialogContentStyle,
  FullScreenDialogTitleStyle,
  GlobalActionButtonStyle,
  GlobalActionButtonActionStyle,
  GlobalAdminStyle,
  GlobalAdminButtonStyle,
  GlobalAdminNotificationsButtonStyle,
  GlobalNavigationStyle,
  GridStyle,
  GrowStyle,
  HeaderStyle,
  IconButtonStyle,
  InputAdornmentStyle,
  InputBaseStyle,
  InputLabelStyle,
  LinearProgressStyle,
  LinkStyle,
  ListStyle,
  ListItemStyle,
  ListItemIconStyle,
  ListItemTextStyle,
  ListSubheaderStyle,
  LoaderStyle,
  LogoCardStyle,
  LogoCardActionsStyle,
  LogoCardContentStyle,
  LogoCardGroupStyle,
  LogoCardHeaderStyle,
  LogoCardMediaStyle,
  MenuStyle,
  MenuItemStyle,
  MenuListStyle,
  MotifStyle,
  NotificationContentStyle,
  OutlinedInputStyle,
  PaperStyle,
  PopperStyle,
  RadioStyle,
  RadioGroupStyle,
  RichDataTableStyle,
  SelectStyle,
  SidePanelStyle,
  SidePanelContentStyle,
  SidePanelFieldValueRowStyle,
  SidePanelHeaderStyle,
  SidePanelImageSectionStyle,
  SidePanelListStyle,
  SidePanelListItemStyle,
  SidePanelSubsectionStyle,
  SkeletonStyle,
  StatusStyle,
  StepStyle,
  StepButtonStyle,
  StepConnectorStyle,
  StepContentStyle,
  StepIconStyle,
  StepLabelStyle,
  StepperStyle,
  SwitchStyle,
  TabStyle,
  TableStyle,
  TableBodyStyle,
  TableCellStyle,
  TableFooterStyle,
  TableHeaderStyle,
  TableRowStyle,
  TabsStyle,
  TextFieldStyle,
  ToggleButtonStyle,
  ToggleButtonGroupStyle,
  ToolbarStyle,
  TooltipStyle,
  TreeItemStyle,
  TreeViewStyle,
  TypographyStyle,
  UnderlinedInputStyle,
  UploaderStyle,
  VerticalNavStyle,
  VerticalNavDividerStyle,
  VerticalNavItemStyle,
  VirtualizedFixedSizeListStyle,
  VirtualizedVariableSizeListStyle,
}
