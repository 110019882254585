import React from 'react'
import {
  Page,
  Title,
  Subtitle,
  Supporting,
  Usage,
  SectionTitle,
} from '../../resources/DemoPage'
import Demo, { generateDemos } from '../../resources/Demo'

import Simple from './demos/Simple'
import Media from './demos/Media'
import Custom from './demos/Custom'

let demoNames = ['Simple', 'Media', 'Custom']

export default function Card() {
  let demos = generateDemos('core-component-demo/card', demoNames)
  return (
    <Page
      components={[
        'Card',
        'CardActions',
        'CardContent',
        'CardHeader',
        'CardMedia',
      ]}
    >
      <Title>Card</Title>
      <Subtitle>
        Cards contain content and actions about a single subject.
      </Subtitle>
      <Supporting />
      <Usage>
        <ul>
          <li>
            Cards should be easy to scan for relevant and actionable
            information.
          </li>
          <li>
            Keep card content simple - cards are entry points to more complex
            and detailed information.
          </li>
          <li>
            Cards can combine different children components to support spacing,
            styling and alignment of content.
          </li>
        </ul>
      </Usage>
      <Demo demo={demos.Simple}>
        <Simple />
      </Demo>

      <SectionTitle>Card with Media</SectionTitle>
      <p>
        Example of a card using an image to reinforce the content. You must
        specify the height otherwise the image will not be visible.
      </p>
      <Demo demo={demos.Media}>
        <Media />
      </Demo>

      <SectionTitle>Custom Cards</SectionTitle>
      <p>
        Example of a card that links a user to a product workflow, typically
        used within application landing pages.
      </p>
      <Demo demo={demos.Custom}>
        <Custom />
      </Demo>
    </Page>
  )
}
