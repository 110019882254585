/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: 'MuiToolbar',
  description: '',
  displayName: 'Toolbar',
  methods: [],
  props: {
    children: {
      type: { name: 'node' },
      required: false,
      description:
        'Toolbar children, usually a mixture of `IconButton`, `Button` and `Typography`.',
    },
    classes: {
      type: { name: 'object' },
      required: true,
      description:
        'Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details.',
    },
    className: {
      type: { name: 'string' },
      required: false,
      description: '@ignore',
    },
    component: {
      type: { name: 'elementType' },
      required: false,
      description:
        'The component used for the root node.\r\nEither a string to use a HTML element or a component.',
      defaultValue: { value: "'div'", computed: false },
    },
    disableGutters: {
      type: { name: 'bool' },
      required: false,
      description: 'If `true`, disables gutter padding.',
      defaultValue: { value: 'false', computed: false },
    },
    variant: {
      type: {
        name: 'enum',
        value: [
          { value: "'regular'", computed: false },
          { value: "'dense'", computed: false },
        ],
      },
      required: false,
      description: 'The variant to use.',
      defaultValue: { value: "'regular'", computed: false },
    },
  },
  styles: {
    root: { description: 'Styles applied to the root element.' },
    gutters: {
      description:
        'Styles applied to the root element if `disableGutters={false}`.',
    },
    regular: {
      description: 'Styles applied to the root element if `variant="regular"`.',
    },
    dense: {
      description: 'Styles applied to the root element if `variant="dense"`.',
    },
  },
}
export default generatedDocs
