import React from 'react'
import MultipleStatus from './MultipleStatus'
import { makeStyles } from '../../../utils'

const FileList = ({ files, successComponent, customErrorMessage }) => {
  useStyles()

  return (
    <div data-testid="UploaderFileList" className="UploaderFileList">
      {files.map((file) => (
        <MultipleStatus
          key={file.motifId}
          file={file}
          successComponent={successComponent}
          errorMessage={customErrorMessage}
        />
      ))}
    </div>
  )
}

const useStyles = makeStyles(() => ({
  '@global': {
    '.UploaderFileList': {
      width: '100%',
      display: 'flex',
      gap: '16px',
      flexDirection: 'column',
      maxHeight: '356px', // 5 file bar height + error message
      overflowY: 'auto',
    },
  },
}))

export default FileList
