import React from 'react'

let FlagEU = (props) => (
  <svg
    width="24"
    height="18"
    viewBox="0 0 24 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 0H2C.89543 0 0 .89543 0 2v14c0 1.1046.89543 2 2 2h20c1.1046 0 2-.8954 2-2V2c0-1.10457-.8954-2-2-2z"
      fill="#043CAE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8081 13.9786l-.2367.8081.2367.8081L12 15.3581l-.8081.2367.2367-.8081-.2367-.8081.8081.2367.8081-.2367zm-2.85711-.7656l-.2367.8081.2367.8081-.80812-.2366-.80813.2366.2367-.8081-.2367-.8081.80813.2367.80812-.2367zm5.71431 0l-.2367.8081.2367.8081-.8081-.2366-.8082.2366.2367-.8081-.2367-.8081.8082.2367.8081-.2367zm2.0915-2.0916l-.2366.8082.2366.8081-.8081-.2367-.8081.2367.2367-.8081-.2367-.8082.8081.2367.8081-.2367zm-9.89739 0l-.23669.8082.23669.8081-.80812-.2367-.80812.2367.23669-.8081-.23669-.8082.80812.2367.80812-.2367zM18.5224 8.26429l-.2367.80812.2367.80812-.8081-.23669-.8081.23669.2367-.80812-.2367-.80812.8081.23669.8081-.23669zm-11.42856 0l-.23669.80812.23669.80812-.80812-.23669-.80812.23669.23669-.80812-.23669-.80812.80812.23669.80812-.23669zM17.7568 5.40715l-.2366.80812.2366.80812-.8081-.23669-.8081.23669.2367-.80812-.2367-.80812.8081.23669.8081-.23669zm-9.89739 0l-.23669.80812.23669.80812-.80812-.23669-.80812.23669.23669-.80812-.23669-.80812.80812.23669.80812-.23669zm7.80589-2.09158l-.2367.80812.2367.80813-.8081-.2367-.8082.2367.2367-.80813-.2367-.80812.8082.2367.8081-.2367zm-5.71431 0l-.2367.80812.2367.80813-.80812-.2367-.80813.2367.2367-.80813-.2367-.80812.80813.2367.80812-.2367zM12.8081 2.55l-.2367.80813.2367.80812L12 3.92955l-.8081.2367.2367-.80812-.2367-.80813.8081.2367.8081-.2367z"
      fill="#FFD429"
    />
  </svg>
)

export default FlagEU
