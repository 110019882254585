import React, { useCallback, useEffect, useRef } from 'react'
import { useFormControl } from '@material-ui/core'
import useAutocomplete from '../../core-components/useAutocomplete'
import Button from '../../core-components/Button'
import ButtonFormGroup from '../../core-components/ButtonFormGroup'
import Checkbox from '../../core-components/Checkbox'
import FormControlLabel from '../../core-components/FormControlLabel'
import List from '../../core-components/List'
import ListItem from '../../core-components/ListItem'
import Paper from '../../core-components/Paper'
import TextField from '../../core-components/TextField'
import VirtualizedList from '../../core-components/VirtualizedFixedSizeList'

import makeStyles from '../../styles/makeStyles'
import SearchIcon from '../../icons/Search'
import CircularProgressIcon from '../../core-components/CircularProgress'

const ITEM_HEIGHT = 52
const ITEM_WIDTH = 240
const MAX_ITEMS_SHOWING = 7
const OVERSCAN_COUNT = 5

function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const renderListItem = ({
  option,
  optionProps,
  renderOptionLabel,
  getOptionLabel,
  style = {},
}) => {
  return (
    <ListItem
      className={'FilterAutocomplete-option FilterAutocomplete-optionLabel'}
      style={style}
      {...optionProps}
    >
      <FormControlLabel
        className="FilterAutocomplete__optionLabel"
        control={<Checkbox checked={optionProps['aria-selected']} />}
        label={
          renderOptionLabel
            ? renderOptionLabel(option, optionProps)
            : getOptionLabel(option)
        }
      />
      {option.optCount && `(${option.optCount})`}
    </ListItem>
  )
}

export default function FilterAutocomplete({
  onBlur,
  onFocus,
  onChange,
  renderOptionLabel = null,
  setValue,
  setInputValue,
  virtualized,
  virtualizedWidth,
  loading,
  ...props
}) {
  const { getOptionLabel, options } = props
  const classes = useStyles()
  const muiFormControl = useFormControl()

  if (muiFormControl) {
    if (typeof disabled === 'undefined') {
      disabled = muiFormControl.disabled
    }
  }

  const handleBlur = (event) => {
    if (onBlur) {
      onBlur(event)
    }

    if (muiFormControl?.onBlur) {
      muiFormControl.onBlur(event)
    }
  }

  const handleFocus = (event) => {
    if (onFocus) {
      onFocus(event)
    }

    if (muiFormControl?.onFocus) {
      muiFormControl.onFocus(event)
    }
  }

  const handleChange = (event, value) => {
    setValue(value)
    handleInputChange(event, inputValue)

    if (onChange) {
      onChange(event, value)
    }
  }

  const handleInputChange = (event, value) => {
    setInputValue(value)
  }

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    getClearProps,
    groupedOptions,
    inputValue,
    value,
  } = useAutocomplete({
    disableCloseOnSelect: true,
    multiple: true,
    onBlur: handleBlur,
    onFocus: handleFocus,
    onChange: handleChange,
    onInputChange: handleInputChange,
    open: true,
    ...props,
  })

  const setTextFieldListener = useCallback((node) => {
    if (node) {
      node.addEventListener('keydown', handleKeyDown, {
        capture: true,
      })
    }
  }, [])

  const handleKeyDown = (e) => {
    switch (e.key) {
      case 'Backspace':
        // Currently the backspace key is being hooked into an internal
        // useAutocomplete handler which clears the currently selected values
        // if the inputValue is empty; we do not want this to happen
        if (inputValue === '') {
          e.stopPropagation()
        }
        break
      default:
    }
  }

  // Currently the inputValue is cleared on option selection. To work around this
  // while updates are not being applied to v4 of MUI, we apply a useEffect that
  // occurs after a change is detected in the value.
  const prevValue = usePrevious(value)
  useEffect(() => {
    if (inputValue && prevValue.length !== value.length) {
      handleInputChange(null, inputValue)
    }
  }, [value, prevValue])

  const getAdornment = () => {
    if (loading) return <CircularProgressIcon size={24} />
    return <SearchIcon />
  }

  return (
    <Paper
      data-testid="FilterAutocomplete"
      className="FilterAutocomplete"
      {...getRootProps()}
    >
      <TextField
        className="FilterAutocomplete__input FilterAutocomplete-textField"
        helperText={
          inputValue !== ''
            ? `Showing ${groupedOptions.length} of ${options.length} filters`
            : ''
        }
        InputLabelProps={getInputLabelProps()}
        InputProps={{ endAdornment: getAdornment() }}
        inputProps={getInputProps()}
        inputRef={setTextFieldListener}
        placeholder="Search"
        variant="underlined"
        autoFocus
        fullWidth
      />
      {groupedOptions.length > 0 && (
        <>
          {virtualized ? (
            <VirtualizedList
              className="FilterAutocomplete__list"
              height={
                groupedOptions.length < MAX_ITEMS_SHOWING
                  ? ITEM_HEIGHT * groupedOptions.length
                  : ITEM_HEIGHT * MAX_ITEMS_SHOWING
              }
              itemSize={ITEM_HEIGHT}
              itemCount={groupedOptions.length}
              overscanCount={OVERSCAN_COUNT}
              width={virtualizedWidth}
              {...getListboxProps}
            >
              {({ index, style }) => {
                const option = groupedOptions[index]
                const optionProps = getOptionProps({ option, index })
                return renderListItem({
                  option,
                  optionProps,
                  renderOptionLabel,
                  getOptionLabel,
                  classes,
                  style,
                })
              }}
            </VirtualizedList>
          ) : (
            <List className="FilterAutocomplete__list" {...getListboxProps()}>
              {groupedOptions.map((option, index) =>
                renderListItem({
                  option,
                  optionProps: getOptionProps({ option, index }),
                  renderOptionLabel,
                  getOptionLabel,
                  classes,
                }),
              )}
            </List>
          )}
        </>
      )}
      {!loading && (
        <ButtonFormGroup className="FilterAutocomplete__actions">
          {value.length > 0 ? (
            <Button variant="text" color="secondary" {...getClearProps()}>
              Clear All
            </Button>
          ) : (
            <Button
              variant="text"
              color="secondary"
              onClick={(event) => {
                handleChange(event, options)
              }}
            >
              Select All
            </Button>
          )}
        </ButtonFormGroup>
      )}
    </Paper>
  )
}

const useStyles = makeStyles({
  '@global': {
    '.FilterAutocomplete__input': {
      padding: '8px 16px',
      '& .MuiFormHelperText-root': {
        textAlign: 'right',
      },
    },
    '.FilterAutocomplete__list': {
      maxHeight: '366px', // option height (50px) * 7 + 8px top padding
      minWidth: ITEM_WIDTH,
      overflow: 'auto',
      '& .MuiAutocomplete-option': {
        paddingLeft: '4px',
      },
      // make list appear clickable
      cursor: 'pointer',
    },
    '.FilterAutocomplete__optionLabel': {
      flex: '1',
      // - fixes issue where clicking on the span elements inside
      //  the label doesn't select the item and closes the list
      pointerEvents: 'none',
    },
    '.FilterAutocomplete__actions': {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '4px 6px',
    },
  },
})
