/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: 'MuiCardHeader',
  description: '',
  displayName: 'CardHeader',
  methods: [],
  props: {
    action: {
      type: { name: 'node' },
      required: false,
      description: 'The action to display in the card header.',
    },
    avatar: {
      type: { name: 'node' },
      required: false,
      description: 'The Avatar for the Card Header.',
    },
    children: {
      type: { name: 'node' },
      required: false,
      description: '@ignore',
    },
    classes: {
      type: { name: 'object' },
      required: false,
      description:
        'Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details.',
    },
    className: {
      type: { name: 'string' },
      required: false,
      description: '@ignore',
    },
    component: {
      type: { name: 'elementType' },
      required: false,
      description:
        'The component used for the root node.\r\nEither a string to use a HTML element or a component.',
      defaultValue: { value: "'div'", computed: false },
    },
    disableTypography: {
      type: { name: 'bool' },
      required: false,
      description:
        "If `true`, `subheader` and `title` won't be wrapped by a Typography component.\r\nThis can be useful to render an alternative Typography variant by wrapping\r\nthe `title` text, and optional `subheader` text\r\nwith the Typography component.",
      defaultValue: { value: 'false', computed: false },
    },
    subheader: {
      type: { name: 'node' },
      required: false,
      description: 'The content of the component.',
    },
    subheaderTypographyProps: {
      type: { name: 'object' },
      required: false,
      description:
        'These props will be forwarded to the subheader\r\n(as long as disableTypography is not `true`).',
    },
    title: {
      type: { name: 'node' },
      required: false,
      description: 'The content of the Card Title.',
    },
    titleTypographyProps: {
      type: { name: 'object' },
      required: false,
      description:
        'These props will be forwarded to the title\r\n(as long as disableTypography is not `true`).',
    },
  },
  styles: {
    root: { description: 'Styles applied to the root element.' },
    avatar: { description: 'Styles applied to the avatar element.' },
    action: { description: 'Styles applied to the action element.' },
    content: { description: 'Styles applied to the content wrapper element.' },
    title: { description: 'Styles applied to the title Typography element.' },
    subheader: {
      description: 'Styles applied to the subheader Typography element.',
    },
  },
}
export default generatedDocs
