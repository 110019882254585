import React from 'react'
import createSvgIcon from './utils/createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path
      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-7.341a1.26 1.26 0 01-1.25-1.27c0-.701.56-1.27 1.25-1.27.688 0 1.25.565 1.25 1.27 0 .701-.56 1.27-1.25 1.27zm3.75-5.715h-.625v-1.27a3.2 3.2 0 00-.915-2.244A3.1 3.1 0 0012 4.5c-1.726 0-3.125 1.421-3.125 3.175v1.27H8.25A1.26 1.26 0 007 10.214v6.348c0 .702.56 1.27 1.25 1.27h7.5c.69 0 1.25-.568 1.25-1.27v-6.349c0-.701-.56-1.27-1.25-1.27zM12 5.77c1.036 0 1.875.853 1.875 1.905v1.27h-3.75v-1.27c0-1.052.84-1.905 1.875-1.905z"
      fill="#BDBDBD"
      fillRule="evenodd"
    />
  </React.Fragment>,
  'StatusRestricted',
)
