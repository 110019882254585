import React, { Fragment } from 'react'
import { SectionTitle } from '../resources/Page'
import { copyToClipboard } from '../resources/utils'
import css from './IconSection.module.css'
import PropTypes from 'prop-types'

export default function IconSection({ title, icons }) {
  return (
    <Fragment>
      <SectionTitle>{title}</SectionTitle>
      <div className={css.iconGrid}>
        {Object.keys(icons).map((icon) => (
          <IconCard name={icon} key={icon} display={icons[icon]} />
        ))}
      </div>
    </Fragment>
  )
}

function IconCard({ name, display: Icon }) {
  if (Icon) {
    let splitOnCamel = (name) =>
      name.replace(/([A-Z]+)/g, ' $1').replace(/^ /, '')

    return (
      <div>
        <div className={css.iconImg} onClick={() => copyToClipboard(name)}>
          <Icon className={css.icon} />
        </div>
        <p className={css.iconLabel}>{splitOnCamel(name)}</p>
      </div>
    )
  }

  console.log(`${name} is not being exported from @liveramp/motif`)
  return null
}

IconSection.propTypes = {
  title: PropTypes.string.isRequired,
  icons: PropTypes.object,
}

IconCard.prototypes = {
  name: PropTypes.string,
  display: PropTypes.object,
}
