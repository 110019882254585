import React from 'react'
import {
  Title,
  Subtitle,
  Supporting,
  NoticePanel,
  SectionTitle,
} from '../../resources/Page'
import { generateDemos } from '../../resources/Demo'
import CodeChunk from '../../resources/CodeChunk'

let demoNames = ['QuickDemo']

export default function Usage() {
  let demos = generateDemos('getting-started/usage', demoNames)

  return (
    <div>
      <Title>Usage</Title>
      <Subtitle>Learn how Motif works and the assets that support it</Subtitle>
      <Supporting>
        Motif has a provided a wrapper component that should encapsulate your
        application. This wrapper will handle injecting only the styles
        necessary to display the page. You can use any of the components as
        demonstrated in the documentation. Please refer to each component's demo
        page to see how they should be imported.
      </Supporting>

      <SectionTitle>Quick Start</SectionTitle>
      <p>
        The Motif component wrapper is necessary to inject the LiveRamp styles
        into all the components built in the toolkit. Without this, you will see
        artifacts from the default Material theme including{' '}
        <code style={{ color: '#3f51b5' }}>indigo</code> and{' '}
        <code style={{ color: '#f50057' }}>pink</code> colored components.
      </p>
      <p>
        Pass a productionPrefix prop (string) to Motif to customize generated
        classnames in production.
      </p>
      <p>Here's a quick example to get started:</p>
      <CodeChunk>{demos.QuickDemo}</CodeChunk>
      <NoticePanel variant="warning">
        <p>
          Importing and using Material UI components directly will lead to a
          console warning about using and initializing multiple copies of{' '}
          <code>@material-ui/core/styles</code>. If you are having trouble
          re-styling our components, please message us at{' '}
          <a href="https://liveramp.slack.com/archives/C012Q4QCZG8">#dev-ax</a>{' '}
          if you need help.
        </p>
      </NoticePanel>

      <SectionTitle>Assets</SectionTitle>
      <p>
        LiveRamp specific assets have been made publicly available via our{' '}
        <a href="https://storage.cloud.google.com/liveramp-public-assets/">
          GCP bucket
        </a>
        . It includes the LiveRamp logo in various formats and sizes, our font
        liveramp sans, and a very basic global stylesheet to get you started.
      </p>
      <CodeChunk language="treeview">
        {[
          'https://storage.cloud.google.com/liveramp-public-assets/',
          '├── fonts/',
          '│   ├── ...',
          '│   └── liverampsans.css',
          '├── img/',
          '│   ├── favicon/',
          '│   │   ├── logo32.png',
          '│   │   ├── logo64.png',
          '│   │   └── logo192.png',
          '│   ├── logo-ramp.svg',
          '│   ├── logo.png',
          '│   └── logo.svg',
          '└── global-style.css',
        ].join(`\n`)}
      </CodeChunk>
    </div>
  )
}
