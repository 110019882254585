/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: 'MuiStepContent',
  description: '',
  displayName: 'StepContent',
  methods: [],
  props: {
    children: {
      type: { name: 'node' },
      required: false,
      description: 'Step content.',
    },
    classes: {
      type: { name: 'object' },
      required: false,
      description:
        'Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details.',
    },
    className: {
      type: { name: 'string' },
      required: false,
      description: '@ignore',
    },
    TransitionComponent: {
      type: { name: 'elementType' },
      required: false,
      description:
        'The component used for the transition.\r\n[Follow this guide](/components/transitions/#transitioncomponent-prop) to learn more about the requirements for this component.',
      defaultValue: { value: 'Collapse', computed: true },
    },
    transitionDuration: {
      type: {
        name: 'union',
        value: [
          { name: 'enum', value: [{ value: "'auto'", computed: false }] },
          { name: 'number' },
          {
            name: 'shape',
            value: {
              appear: { name: 'number', required: false },
              enter: { name: 'number', required: false },
              exit: { name: 'number', required: false },
            },
          },
        ],
      },
      required: false,
      description:
        "Adjust the duration of the content expand transition.\r\nPassed as a prop to the transition component.\r\n\r\nSet to 'auto' to automatically calculate transition time based on height.",
      defaultValue: { value: "'auto'", computed: false },
    },
    TransitionProps: {
      type: { name: 'object' },
      required: false,
      description:
        'Props applied to the [`Transition`](http://reactcommunity.org/react-transition-group/transition#Transition-props) element.',
    },
  },
  styles: {
    root: { description: 'Styles applied to the root element.' },
    last: {
      description:
        'Styles applied to the root element if `last={true}` (controlled by `Step`).',
    },
    transition: { description: 'Styles applied to the Transition component.' },
  },
}
export default generatedDocs
