import React from 'react'

let WorkflowReports = (props) => (
  <svg width={80} height={80} viewBox="0 0 80 80" {...props}>
    <path
      d="M1.09 0a1.09 1.09 0 011.084.972l.007.118v76.795h76.794a1.09 1.09 0 01.119 2.174l-.119.006H1.09a1.09 1.09 0 01-1.084-.971L0 78.975V1.09C0 .488.488 0 1.09 0zm23.366 47.25a1.09 1.09 0 011.084.972l.006.118v20.77a1.09 1.09 0 01-.971 1.084l-.12.006H14.072a1.09 1.09 0 01-1.084-.972l-.006-.118V48.34c0-.562.425-1.024.971-1.084l.12-.006h10.384zM34.84 27.52h10.385a1.09 1.09 0 011.084.97l.006.12v40.5a1.09 1.09 0 01-.971 1.084l-.119.006H34.84a1.09 1.09 0 01-1.084-.972l-.006-.118v-40.5c0-.562.425-1.025.972-1.084l.118-.007h10.385H34.84zM65.994 8.826c.602 0 1.09.488 1.09 1.09V69.11a1.09 1.09 0 01-1.09 1.09H55.61a1.09 1.09 0 01-1.09-1.09V9.917c0-.602.487-1.09 1.09-1.09h10.384zM23.365 49.43h-8.203v18.588h8.203V49.431zM44.135 29.7H35.93v38.32h8.204V29.7zm20.769-18.692H56.7v57.011h8.204V11.008z"
      fillRule="nonzero"
    />
  </svg>
)

export default WorkflowReports
