import React from 'react'
import MuiChip from '@material-ui/core/Chip'
import withStyles from '../../styles/withStyles'
import { ChipStyle } from '../../utils/styleKeys'
import { pxToRem as rem } from '../../utils'

function Chip({ children, ...props }) {
  return <MuiChip label={children} {...props} />
}

let styles = (theme) => ({
  ...ChipStyle,
  root: {
    ...theme.typography.body2,
    borderRadius: rem(4),
    padding: `0 ${rem(8)}`,
    gap: rem(6),
    minHeight: rem(32),
    height: 'unset',
    // backgroundColor: theme.palette.secondary.light,
    color: theme.palette.text.primary,
  },
  icon: {
    margin: 0,
  },
  label: {
    padding: 0,
  },
  deleteIcon: {
    margin: 0,
    fontSize: rem(24),
  },

  sizeSmall: {
    ...theme.typography.body3,
    minHeight: rem(24),
  },
  deleteIconSmall: {
    fontSize: rem(16),
  },

  colorPrimary: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.common.white,
  },
  deletableColorPrimary: {
    '&:focus': {
      backgroundColor: theme.palette.info.hue[400],
    },
  },
  deleteIconColorPrimary: {
    fill: theme.palette.info.hue[100],
    '&:hover, &:active': {
      fill: theme.palette.common.white,
    },
  },

  colorSecondary: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.text.primary,
  },
})

export default withStyles(styles, { name: 'MuiChip' })(Chip)
