import React from 'react'
import clsx from 'clsx'
import Fab from '@material-ui/core/Fab'
import Menu from '../../core-components/Menu'
import chroma from 'chroma-js'
import makeStyles from '../../styles/makeStyles'
import { Motif2DeprecationWarning } from '../../utils/internal'

const MenuContent = React.forwardRef((props, ref) => (
  <div {...props} ref={ref}>
    {props.children}
  </div>
))

export default function GlobalActionButton({ children, icon, ...props }) {
  Motif2DeprecationWarning('GlobalActionButton', 'MenuButton')
  useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    typeof props.onClick === 'function' && props.onClick()
    !props.noMenu && setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const activeState =
    anchorEl != null ? 'GlobalActionButton__Fab--isActive' : ''

  return (
    <div data-testid="GlobalActionButton">
      <Fab
        classes={{ root: clsx('GlobalActionButton__Fab', activeState) }}
        onClick={handleClick}
        {...props}
      >
        {icon}
      </Fab>
      <Menu
        classes={{ paper: 'GlobalActionButton__Menu' }}
        elevation={6}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuContent>{children}</MenuContent>
      </Menu>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.GlobalActionButton__Fab': {
      boxShadow: 'none',
      width: '36px',
      height: '36px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette.primary.main,
      '&:focus': {
        boxShadow: `0 0 0 0.16rem rgba(${chroma(
          theme.palette.primary.main,
        ).rgb()}, 0.3)`,
      },
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      '& .MuiSvgIcon-root': {
        fill: 'white',
        width: '30px',
        height: '30px',
      },

      '&.GlobalActionButton__Fab--isActive': {
        backgroundColor: theme.palette.primary.dark,
        boxShadow: `0 0 0 0.16rem rgba(${chroma(
          theme.palette.primary.main,
        ).rgb()}, 0.3)`,
      },
    },
    '.GlobalActionButton__Menu': {
      marginTop: '8px',
    },
  },
}))

import Action from './Action'
export { GlobalActionButton, Action }
