import React from 'react'
import {
  Attachment,
  Campaign,
  CampaignOutline,
  CombinedData,
  GlobalAction,
  HourglassOutline,
  Laptop,
  LightbulbOutline,
  Mail,
  MyData,
  NotificationsOutline,
  Overlap,
  Palette,
  PartnerData,
  Refresh,
  Search,
  StarOutline,
  SuperUniverse,
  Universe,
  UserOutline,
} from '@liveramp/motif/icons'
import css from './IconShuffle.module.css'

export default function IconShuffle() {
  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1))
      ;[array[i], array[j]] = [array[j], array[i]]
    }
  }

  let icons = [
    <Attachment key="Attachment" />,
    <Campaign key="Campaign" />,
    <CampaignOutline key="CampaignOutline" />,
    <CombinedData key="CombinedData" />,
    <GlobalAction key="GlobalAction" />,
    <HourglassOutline key="HourglassOutline" />,
    <Laptop key="Laptop" />,
    <LightbulbOutline key="LightbulbOutline" />,
    <Mail key="Mail" />,
    <MyData key="MyData" />,
    <NotificationsOutline key="NotificationsOutline" />,
    <Overlap key="Overlap" />,
    <Palette key="Palette" />,
    <PartnerData key="PartnerData" />,
    <Refresh key="Refresh" />,
    <Search key="Search" />,
    <StarOutline key="StarOutline" />,
    <SuperUniverse key="SuperUniverse" />,
    <Universe key="Universe" />,
    <UserOutline key="UserOutline" />,
  ]
  shuffleArray(icons)

  return <div className={css.grid}>{icons}</div>
}
