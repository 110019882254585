/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: 'MuiSwitch',
  description: '',
  displayName: 'Switch',
  methods: [],
  props: {
    checked: {
      type: { name: 'bool' },
      required: false,
      description: 'If `true`, the component is checked.',
    },
    checkedIcon: {
      type: { name: 'node' },
      required: false,
      description: 'The icon to display when the component is checked.',
    },
    classes: {
      type: { name: 'object' },
      required: false,
      description:
        'Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details.',
    },
    className: {
      type: { name: 'string' },
      required: false,
      description: '@ignore',
    },
    color: {
      type: {
        name: 'enum',
        value: [
          { value: "'default'", computed: false },
          { value: "'primary'", computed: false },
          { value: "'secondary'", computed: false },
        ],
      },
      required: false,
      description:
        'The color of the component. It supports those theme colors that make sense for this component.',
      defaultValue: { value: "'secondary'", computed: false },
    },
    defaultChecked: {
      type: { name: 'bool' },
      required: false,
      description: '@ignore',
    },
    disabled: {
      type: { name: 'bool' },
      required: false,
      description: 'If `true`, the switch will be disabled.',
    },
    disableRipple: {
      type: { name: 'bool' },
      required: false,
      description: 'If `true`, the ripple effect will be disabled.',
    },
    edge: {
      type: {
        name: 'enum',
        value: [
          { value: "'end'", computed: false },
          { value: "'start'", computed: false },
          { value: 'false', computed: false },
        ],
      },
      required: false,
      description:
        'If given, uses a negative margin to counteract the padding on one\r\nside (this is often helpful for aligning the left or right\r\nside of the icon with content above or below, without ruining the border\r\nsize and shape).',
      defaultValue: { value: 'false', computed: false },
    },
    icon: {
      type: { name: 'node' },
      required: false,
      description: 'The icon to display when the component is unchecked.',
    },
    id: {
      type: { name: 'string' },
      required: false,
      description: 'The id of the `input` element.',
    },
    inputProps: {
      type: { name: 'object' },
      required: false,
      description:
        '[Attributes](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Attributes) applied to the `input` element.',
    },
    inputRef: {
      type: { name: 'custom', raw: 'refType' },
      required: false,
      description: 'Pass a ref to the `input` element.',
    },
    onChange: {
      type: { name: 'func' },
      required: false,
      description:
        'Callback fired when the state is changed.\r\n\r\n@param {object} event The event source of the callback.\r\nYou can pull out the new value by accessing `event.target.value` (string).\r\nYou can pull out the new checked state by accessing `event.target.checked` (boolean).',
    },
    required: {
      type: { name: 'bool' },
      required: false,
      description: 'If `true`, the `input` element will be required.',
    },
    size: {
      type: {
        name: 'enum',
        value: [
          { value: "'medium'", computed: false },
          { value: "'small'", computed: false },
        ],
      },
      required: false,
      description:
        'The size of the switch.\r\n`small` is equivalent to the dense switch styling.',
      defaultValue: { value: "'medium'", computed: false },
    },
    value: {
      type: { name: 'any' },
      required: false,
      description:
        'The value of the component. The DOM API casts this to a string.\r\nThe browser uses "on" as the default value.',
    },
  },
  styles: {
    root: { description: 'Styles applied to the root element.' },
    edgeStart: {
      description: 'Styles applied to the root element if `edge="start"`.',
    },
    edgeEnd: {
      description: 'Styles applied to the root element if `edge="end"`.',
    },
    switchBase: {
      description:
        "Styles applied to the internal `SwitchBase` component's `root` class.",
    },
    colorPrimary: {
      description:
        'Styles applied to the internal SwitchBase component\'s root element if `color="primary"`.',
    },
    colorSecondary: {
      description:
        'Styles applied to the internal SwitchBase component\'s root element if `color="secondary"`.',
    },
    sizeSmall: {
      description: 'Styles applied to the root element if `size="small"`.',
    },
    checked: {
      description:
        "Pseudo-class applied to the internal `SwitchBase` component's `checked` class.",
    },
    disabled: {
      description:
        "Pseudo-class applied to the internal SwitchBase component's disabled class.",
    },
    input: {
      description:
        "Styles applied to the internal SwitchBase component's input element.",
    },
    thumb: {
      description:
        'Styles used to create the thumb passed to the internal `SwitchBase` component `icon` prop.',
    },
    track: { description: 'Styles applied to the track element.' },
  },
}
export default generatedDocs
