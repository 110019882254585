import React from 'react'
import {
  Page,
  Title,
  Subtitle,
  Supporting,
  Usage,
  SectionTitle,
} from '../../resources/DemoPage'
import Demo, { generateDemos } from '../../resources/Demo'

import Simple from './demos/Simple'
import Full from './demos/Full'

let demoNames = ['Simple', 'Full']

export default function Dialog() {
  let demos = generateDemos('core-component-demo/header', demoNames)

  return (
    <Page components="Header">
      <Title>Header</Title>
      <Subtitle>
        Headers state the topic and display important information about the
        current page
      </Subtitle>
      <Supporting />
      <Usage>
        <ul>
          <li>
            Keep header titles concise and clear, under one-line to prevent
            wrapping.
          </li>
          <li>
            Only one header should be used per page/area. Don't use to separate
            sections on a single page.
          </li>
        </ul>
      </Usage>

      <Demo demo={demos.Simple}>
        <Simple />
      </Demo>

      <SectionTitle>Fully Featured Header</SectionTitle>
      <Supporting />
      <ul>
        <li>
          The <code>onClickBackArrow</code> prop accepts a function that can be
          used to easily navigate to the previous page.
        </li>
        <li>
          To give additional information about a page's content, use
          <code>helpText</code> to explain the page's default workflow or task,
          and provide usage instructions for any complex affordances.
        </li>
        <li>
          The <code>rightContent</code> prop should be used to display icons or
          elements such as text fields. If displaying text, pass in{' '}
          <code>rightContentText</code> to ensure that all the header text is
          correctly aligned.
        </li>
      </ul>

      <Demo demo={demos.Full}>
        <Full />
      </Demo>
    </Page>
  )
}
