import React from 'react'
import Button from '@liveramp/motif/core/Button'
import ButtonFormGroup from '@liveramp/motif/core/ButtonFormGroup'
import css from './IconsAndLabel.module.css'

import { Delete, Save, Search, Send } from '@liveramp/motif/icons' // Edit, Help,

export default function IconsAndLabelDemo() {
  return (
    <div>
      <ButtonFormGroup>
        <Button color="primary">
          <Send className={css.icon} />
          Send
        </Button>
        <Button color="secondary">
          <Save className={css.icon} />
          Save
        </Button>
        <Button color="danger">
          <Delete className={css.icon} />
          Delete
        </Button>
        <Button disabled>
          <Search className={css.icon} />
          Search
        </Button>
      </ButtonFormGroup>
      {/* <br />
      <ButtonFormGroup>
        <Button variant="outlined" color="primary">
          <Edit className={css.icon} />
          Edit
        </Button>
        <Button className={css.blueButton}>
          <Help className={css.icon} />
          Help
        </Button>
      </ButtonFormGroup> */}
    </div>
  )
}
