let blue = {
  50: '#d6f6fc',
  100: '#bbf1fa',
  200: '#9febf7',
  300: '#76e2f4',
  400: '#67cbf2',
  500: '#26b5ed',
  600: '#0d8de0',
  700: '#0680ef',
  800: '#2d2ea8',
  900: '#0d115b',
}

export default blue
