import React, { useState } from 'react'
import Stepper from '@liveramp/motif/core/Stepper'
import Step from '@liveramp/motif/core/Step'
import StepLabel from '@liveramp/motif/core/StepLabel'
import StepButton from '@liveramp/motif/core/StepButton'

export default function VerticalNonLinearDemo() {
  const [activeStep, setActiveStep] = useState(1)
  const steps = getSteps()

  return (
    <Stepper nonLinear activeStep={activeStep} orientation="vertical">
      {steps.map(({ label, labelProps }, index) => (
        <Step key={label}>
          <StepButton hideStepNum onClick={() => setActiveStep(index)}>
            <StepLabel {...labelProps}>{label}</StepLabel>
          </StepButton>
        </Step>
      ))}
    </Stepper>
  )
}

function getSteps() {
  return [
    {
      label: 'Step One',
      labelProps: {
        optional: 'This is step 1',
      },
    },
    {
      label: 'Step Two',
      labelProps: {
        optional: 'Vertical step with optional text',
      },
    },
    {
      label: 'Step 3',
    },
  ]
}
