/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

import React from 'react'
import Page from '../Page'
import { tryRequireOverride } from '../../resources/utils'
import generatedDocs from '../components-generated/VirtualizedFixedSizeList'

export default function VirtualizedFixedSizeListApi() {
  let docs = generatedDocs
  let overrideDocs = tryRequireOverride('VirtualizedFixedSizeList')
  if (overrideDocs) {
    Object.assign(docs.props, overrideDocs.props)
    Object.assign(docs.styles, overrideDocs.styles)
  }

  return <Page component="VirtualizedFixedSizeList" docs={docs} />
}
