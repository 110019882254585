let purple = {
  50: '#edeaff',
  100: '#d9d1f8',
  200: '#c8b2e2',
  300: '#9582e3',
  400: '#866fe7',
  500: '#735dd0',
  600: '#6857b7',
  700: '#4a37a1',
  800: '#3f2286',
  900: '#231673',
}

export default purple
