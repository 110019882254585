import React, { useState, useContext } from 'react'
import clsx from 'clsx'
import ButtonBase from '../../core-components/ButtonBase'
import Divider from '../../core-components/Divider'
import ClickAwayListener from '../../core-components/ClickAwayListener'
import Collapse from '../../core-components/Collapse'
import List from '../../core-components/List'
import ListItem from '../../core-components/ListItem'
import makeStyles from '../../styles/makeStyles'
import { GlobalNavigationContext } from './GlobalNavigation'
import ArrowDropDown from '../../icons/ArrowDropDown'
import ArrowDropUp from '../../icons/ArrowDropUp'
import FlagUS from '../../icons/FlagUS'
import FlagCN from '../../icons/FlagCN'
import FlagAU from '../../icons/FlagAU'
import FlagEU from '../../icons/FlagEU'
import FlagJP from '../../icons/FlagJP'

const countryData = {
  US: { name: 'United States', flag: FlagUS },
  CN: { name: 'China', flag: FlagCN },
  AU: { name: 'Australia', flag: FlagAU },
  EU: { name: 'Europe', flag: FlagEU },
  JP: { name: 'Japan', flag: FlagJP },
}

export default function L3CountryPicker({
  label,
  countries,
  currentCountry: defaultCountry,
  onCountryClick = () => {},
}) {
  const { isAltNav } = useContext(GlobalNavigationContext)
  useStyles()
  const [open, setOpen] = useState(false)
  const [currentCountry, setCurrentCountry] = useState(defaultCountry)

  const Arrow = open ? ArrowDropUp : ArrowDropDown

  const handleClick = (country) => {
    setCurrentCountry(country)
    setOpen(false)
    onCountryClick(country)
  }

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className="GNL3CountryPicker">
        <div
          className={clsx('GNL3CountryPicker-header', {
            ['GNL3CountryPicker-header--isAltNav']: isAltNav,
          })}
        >
          {label}
          <ButtonBase
            className="GNL3CountryPicker-button"
            onClick={() => setOpen(!open)}
          >
            {currentCountry}
            <Arrow className="GNL3CountryPicker-caret" />
          </ButtonBase>
        </div>
        <Collapse className="GNL3CountryPicker-collapse" in={open}>
          <Divider />
          <List className="GNL3CountryPicker-list">
            {countries.map((country) => {
              let countryDetails = countryData[country]

              return country ? (
                <ListItem
                  key={country}
                  className="GNL3CountryPicker-item"
                  onClick={() => handleClick(country)}
                >
                  <countryDetails.flag />
                  <span>{countryDetails.name}</span>
                  <span>({country})</span>
                </ListItem>
              ) : null
            })}
          </List>
        </Collapse>
      </div>
    </ClickAwayListener>
  )
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.GNL3CountryPicker': {
      // .GNL3CountryPicker-header
      '&-header': {
        minHeight: '40px',
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft: '10px',
        fontSize: '16px',
        alignItems: 'center',
        fontWeight: '600',
        overflowX: 'hidden',
        whiteSpace: 'nowrap',

        // .GNL3CountryPicker-header--isAltNav
        '&--isAltNav': {
          ...theme.typography.h4Branding,
          fontWeight: '300',
        },
      },

      // .GNL3CountryPicker-button
      '&-button': {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: '10px',
        minWidth: '72px',
        minHeight: '40px',

        '&:hover': {
          backgroundColor: theme.palette.secondary.hover,
        },
      },

      // .GNL3CountryPicker-caret
      '&--caret': {
        transition: theme.transitions.common.short,
      },

      // .GNL3CountryPicker-item
      '&-item': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: '14px',
        fontWeight: '400',

        '& span:first-of-type': {
          flex: '1',
          paddingLeft: '8px',
        },
      },
    },
  },
}))
