import React from 'react'
import {
  Page,
  Title,
  Subtitle,
  SectionTitle,
} from '../../../resources/DemoPage'
import { RdtNotices } from '../index'
import Demo, { generateDemos } from '../../../resources/Demo'
import HeaderDemo from './demos/Header'
import RowMenusDemo from './demos/RowMenus'
import SidePanelDemo from './demos/SidePanel'
import DownloadDemo from './demos/Download'
import RowCursorPointer from './demos/RowCursorPointer'

let demoNames = [
  'Header',
  'RowMenus',
  'SidePanel',
  'Download',
  'RowCursorPointer',
]

export default function RichDataTable() {
  let demos = generateDemos(
    'composed-component-demo/rich-data-table/other-functionality',
    demoNames,
  )

  return (
    <Page>
      <Title>Rich Data Table - Other Functionality</Title>
      <Subtitle>Rich Data Table for React apps</Subtitle>
      <RdtNotices />

      <SectionTitle>Header</SectionTitle>
      <ul>
        <li>
          The effect to determine the header counts must include the data as a
          dependency so it knows when to initially run.
        </li>
      </ul>
      <Demo demo={demos.Header}>
        <HeaderDemo />
      </Demo>

      <SectionTitle>Row Menu</SectionTitle>
      <ul>
        <li>
          The hover effect can be applied to the column by using{' '}
          <code>field: "__rowMenu__"</code> and{' '}
          <code>cellClassName: "MuiDataGrid-cellRowMenu"</code> when defining
          the column object.
        </li>
      </ul>
      <Demo demo={demos.RowMenus}>
        <RowMenusDemo />
      </Demo>

      <SectionTitle>Row Cursor Pointer</SectionTitle>
      <ul>
        <li>
          You can change the cursor when hovering over the row to a pointer by
          adding the <code>showPointerOnRows</code> prop.
        </li>
      </ul>
      <Demo demo={demos.RowCursorPointer}>
        <RowCursorPointer />
      </Demo>

      <SectionTitle>Side Panel</SectionTitle>
      <ul>
        <li>
          To ensure the side panel slides out when initially opened; the side
          panel must be rendered in the DOM. It does not have to be visually
          rendered on the page, and it can be empty until you have data for it.
        </li>
        <li>
          The active row will be blue when side panel is open by adding the{' '}
          <code>showActiveRow</code> prop.
        </li>
      </ul>
      <Demo demo={demos.SidePanel}>
        <SidePanelDemo />
      </Demo>

      <SectionTitle>Downloads</SectionTitle>
      <ul>
        <li>
          The CSV download functionality is provided as is by MUI and cannot be
          altered.
        </li>
        <li>The fetch example is more typical for LiveRamp use cases.</li>
      </ul>
      <Demo demo={demos.Download}>
        <DownloadDemo />
      </Demo>
    </Page>
  )
}
