import React, { useState } from 'react'
import Button from '@liveramp/motif/core/Button'
import Dialog from '@liveramp/motif/core/Dialog'
import DialogContent from '@liveramp/motif/core/DialogContent'
import DialogActions from '@liveramp/motif/core/DialogActions'

export default function AlertDemo() {
  let [open, setOpen] = useState(false)

  return (
    <>
      <Button variant="secondary" color="primary" onClick={() => setOpen('sm')}>
        Alert Dialog
      </Button>

      <Dialog open={open === 'sm'} onClose={() => setOpen('')} size="small">
        <DialogContent>
          <p>
            Are you sure you want to delete this entry? This action cannot be
            undone.
          </p>
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="secondary" onClick={() => setOpen('')}>
            Cancel
          </Button>
          <Button color="danger" onClick={() => setOpen('')}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
