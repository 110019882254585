import React, { useState } from 'react'
import Switch from '@liveramp/motif/core/Switch'
import FormGroup from '@liveramp/motif/core/FormGroup'
import css from './CustomDestructive.module.css'
import withStyles from '@liveramp/motif/styles/withStyles'
import makeStyles from '@liveramp/motif/styles/makeStyles'

function CustomDestructiveDemo({ classes: withStylesClasses }) {
  let [cssModules, setCss] = useState(false)
  let [jssWithStyles, setJssWithStyles] = useState(false)
  let [jssMakeStyles, setJssMakeStyles] = useState(false)

  let makeStylesClasses = useStyles()

  return (
    <FormGroup>
      <h6>CSS Modules</h6>
      <div className={css.flex}>
        <div onClick={() => setCss(false)}>Exclude</div>
        <Switch
          className={css.switch}
          checked={cssModules}
          onChange={() => setCss(!cssModules)}
        />
        <div onClick={() => setCss(true)}>Include</div>
      </div>
      <br />
      <h6>JSS using withStyles</h6>
      <div className={css.flex}>
        <div onClick={() => setJssWithStyles(false)}>Exclude</div>
        <Switch
          classes={withStylesClasses}
          checked={jssWithStyles}
          onChange={() => setJssWithStyles(!jssWithStyles)}
        />
        <div onClick={() => setJssWithStyles(true)}>Include</div>
      </div>
      <br />
      <h6>JSS using makeStyles</h6>
      <div className={css.flex}>
        <div onClick={() => setJssMakeStyles(false)}>Exclude</div>
        <Switch
          classes={makeStylesClasses}
          checked={jssMakeStyles}
          onChange={() => setJssMakeStyles(!jssMakeStyles)}
        />
        <div onClick={() => setJssMakeStyles(true)}>Include</div>
      </div>
    </FormGroup>
  )
}

let withStylesStyles = (theme) => ({
  switchBase: {
    '&:not(.Mui-checked)': { color: theme.palette.error.main },
    '&:not(.Mui-checked):hover': {
      backgroundColor: theme.palette.error.hover,
    },
  },
  track: {
    ':not(.Mui-checked) + &': {
      backgroundColor: theme.palette.error.light,
    },
  },
})

let useStyles = makeStyles((theme) => ({
  switchBase: {
    '&:not(.Mui-checked)': { color: theme.palette.error.main },
    '&:not(.Mui-checked):hover': {
      backgroundColor: theme.palette.error.hover,
    },
  },
  track: {
    ':not(.Mui-checked) + &': {
      backgroundColor: theme.palette.error.light,
    },
  },
}))

export default withStyles(withStylesStyles)(CustomDestructiveDemo)
