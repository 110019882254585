import React from 'react'
import Chip from '@liveramp/motif/core/Chip'
import css from '../../../resources/Page.module.css'

function SimpleDemo() {
  const style = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '16px',
    alignItems: 'center',
    justifyContent: 'center',
  }

  return (
    <div className={css.grid} style={{ gridAutoFlow: 'row' }}>
      <div style={style}>
        <Chip color="secondary">Default/Secondary</Chip>
        <Chip size="small">Small</Chip>
        <Chip onDelete={() => alert('You clicked to delete this chip!')}>
          Deletable
        </Chip>
      </div>
      <div style={style}>
        <Chip color="primary">Primary Color</Chip>
        <Chip color="primary" size="small">
          Small
        </Chip>
        <Chip
          color="primary"
          onDelete={() => alert('You clicked to delete this chip!')}
        >
          Deletable
        </Chip>
      </div>
    </div>
  )
}

export default SimpleDemo
