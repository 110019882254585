/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: 'MuiStepper',
  description: '',
  displayName: 'Stepper',
  methods: [],
  props: {
    activeStep: {
      type: { name: 'number' },
      required: false,
      description:
        'Set the active step (zero based index).\r\nSet to -1 to disable all the steps.',
      defaultValue: { value: '0', computed: false },
    },
    alternativeLabel: {
      type: { name: 'bool' },
      required: false,
      description:
        "If set to 'true' and orientation is horizontal,\r\nthen the step label will be positioned under the icon.",
      defaultValue: { value: 'false', computed: false },
    },
    children: {
      type: { name: 'node' },
      required: false,
      description: 'Two or more `<Step />` components.',
    },
    classes: {
      type: { name: 'object' },
      required: false,
      description:
        'Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details.',
    },
    className: {
      type: { name: 'string' },
      required: false,
      description: '@ignore',
    },
    connector: {
      type: { name: 'element' },
      required: false,
      description: 'An element to be placed between each step.',
      defaultValue: {
        value: 'React.createElement(StepConnector, null)',
        computed: true,
      },
    },
    nonLinear: {
      type: { name: 'bool' },
      required: false,
      description:
        'If set the `Stepper` will not assist in controlling steps for linear flow.',
      defaultValue: { value: 'false', computed: false },
    },
    orientation: {
      type: {
        name: 'enum',
        value: [
          { value: "'horizontal'", computed: false },
          { value: "'vertical'", computed: false },
        ],
      },
      required: false,
      description: 'The stepper orientation (layout flow direction).',
      defaultValue: { value: "'horizontal'", computed: false },
    },
  },
  styles: {
    root: { description: 'Styles applied to the root element.' },
    horizontal: {
      description:
        'Styles applied to the root element if `orientation="horizontal"`.',
    },
    vertical: {
      description:
        'Styles applied to the root element if `orientation="vertical"`.',
    },
    alternativeLabel: {
      description:
        'Styles applied to the root element if `alternativeLabel={true}`.',
    },
  },
}
export default generatedDocs
