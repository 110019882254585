import React, { useState } from 'react'
import Switch from '@liveramp/motif/core/Switch'

export default function SimpleDemo() {
  let [checked, setChecked] = useState(true)

  return (
    <Switch
      checked={checked}
      onChange={() => setChecked(!checked)}
      value="checked"
    />
  )
}
