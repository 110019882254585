import Skeleton from '@material-ui/lab/Skeleton'
import withStyles from '../../styles/withStyles'
import { SkeletonStyle } from '../../utils/styleKeys'

let styles = (theme) => ({
  ...SkeletonStyle,
  root: {
    borderRadius: 0,
    backgroundColor: theme.palette.grey[200],
  },
})

export default withStyles(styles, { name: 'MuiSkeleton' })(Skeleton)
