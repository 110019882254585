import React from 'react'
import createSvgIcon from './utils/createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M5.89439 16.0346C6.55073 15.7908 7.0833 15.2986 7.16018 14.3384V12.6488H8.56663C10.0462 12.6488 10.8826 11.0455 10.8826 9.79563C10.8825 9.25379 10.688 8.72997 10.3344 8.31942C9.98078 7.90886 9.49159 7.63884 8.95574 7.55845L6.98391 7.53689C7.17144 7.28654 7.37396 7.04651 7.58774 6.8196C7.93279 6.45111 8.23565 5.99167 8.6407 5.68976C10.0725 4.61805 11.9224 4.10516 13.8445 4.10516V12.7407H22.4426C22.4426 17.5104 18.5936 21.3763 13.8445 21.3763C10.2581 21.3763 7.18456 19.17 5.89532 16.0346H5.89439ZM15.8333 2.62371C20.3423 2.62371 24 6.2964 24 10.828H15.8323V2.62371H15.8333Z" />
    <path d="M4.90707 5.24344C4.29292 5.24344 3.78379 5.69632 3.69565 6.28702L3.68252 6.46799V8.91707H1.23438C0.923698 8.91458 0.623687 9.03027 0.395122 9.24071C0.166556 9.45115 0.0265261 9.7406 0.00339942 10.0504C-0.0197272 10.3602 0.0757788 10.6673 0.270569 10.9093C0.46536 11.1514 0.74487 11.3103 1.05248 11.354L1.23438 11.3671H3.68159V13.8171C3.68104 14.1266 3.79767 14.4247 4.00801 14.6517C4.21836 14.8786 4.50682 15.0175 4.81541 15.0405C5.124 15.0634 5.42982 14.9686 5.67139 14.7752C5.91296 14.5819 6.07236 14.3042 6.11755 13.9981L6.13161 13.8171V11.3671H8.5807C8.89013 11.3676 9.1883 11.251 9.41525 11.0407C9.6422 10.8303 9.78111 10.5419 9.80403 10.2333C9.82695 9.92469 9.7322 9.61886 9.53882 9.37729C9.34544 9.13572 9.06778 8.97632 8.76166 8.93114L8.5807 8.91707H6.13255V6.46799C6.13255 5.79102 5.58403 5.24344 4.90707 5.24344V5.24344Z" />
  </React.Fragment>,
  'CreateSegment',
)
