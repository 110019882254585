import React from 'react'
import SidePanelContent from '@liveramp/motif/core/SidePanelContent'
import SidePanelSubsection from '@liveramp/motif/core/SidePanelSubsection'
import SidePanelImageSection from '@liveramp/motif/core/SidePanelImageSection'
import SidePanelFieldValueRow from '@liveramp/motif/core/SidePanelFieldValueRow'
import SidePanelFieldValue from '@liveramp/motif/core/SidePanelFieldValue'
import SidePanelList from '@liveramp/motif/core/SidePanelList'
import SidePanelListItem from '@liveramp/motif/core/SidePanelListItem'
import Table, { TableWrapper } from '@liveramp/motif/core/Table'
import TableHeader from '@liveramp/motif/core/TableHeader'
import TableBody from '@liveramp/motif/core/TableBody'
import TableRow from '@liveramp/motif/core/TableRow'
import TableCell from '@liveramp/motif/core/TableCell'
import TableFooter from '@liveramp/motif/core/TableFooter'
import Chip from '@liveramp/motif/core/Chip'
import Info from '@liveramp/motif/icons/Info'
import css from './Simple.module.css'

export default function SectionsDemo() {
  let data = [
    { name: 'Cappuccino', caffeine: true },
    { name: 'Latte', caffeine: true },
    { name: 'Cortado', caffeine: true },
    { name: 'Flat White', caffeine: true },
    { name: 'Affogato', caffeine: false },
    { name: 'Cold Brew', caffeine: true },
    { name: 'Decaf Latte', caffeine: false },
  ]
  data = [...data, ...data, ...data, ...data, ...data]

  return (
    <SidePanelContent className={css.sections}>
      <SidePanelImageSection image="https://storage.googleapis.com/motif-docs-images/side-panel/lr-logo.png">
        <SidePanelFieldValueRow
          editAction={() => alert('Edit action triggered')}
        >
          {fvPair}
        </SidePanelFieldValueRow>
        <SidePanelFieldValueRow>{fvPair}</SidePanelFieldValueRow>
      </SidePanelImageSection>
      <SidePanelSubsection title="Subsection - General">
        <div className={css.fvPairSection}>
          {fvPair}
          {fvPair}
          {fvPair}
        </div>
      </SidePanelSubsection>
      <SidePanelSubsection title="Subsection Title">
        <Chip
          avatar={<Info className={css.chipIcon} />}
          label="Subsection is open by default"
          className={css.chip}
        />
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </p>
      </SidePanelSubsection>
      <SidePanelSubsection title="Subsection Title - closed" open={false}>
        <Chip
          avatar={<Info className={css.chipIcon} />}
          label="But can be closed by default with open={false}"
          className={css.chip}
        />
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </p>
      </SidePanelSubsection>
      <SidePanelSubsection title="Subsection - List">
        <SidePanelList>
          <SidePanelListItem label="Normal Field">
            Normal Value
          </SidePanelListItem>
          <SidePanelListItem label="Short Field">
            Value with a really long child text that should show a tooltip
          </SidePanelListItem>
          <SidePanelListItem label="Super duper long Field with a really long title that wraps to another line">
            Value
          </SidePanelListItem>
          <SidePanelListItem label="Field with a really long title that wraps to another line">
            Value with a really long child text that should show a tooltip
          </SidePanelListItem>
        </SidePanelList>
      </SidePanelSubsection>
      <SidePanelSubsection title="Subsection - Table">
        {/* 7 rows of 36px, header + footer */}
        <TableWrapper height={`${7 * 36 + 40 + 40}px`}>
          <Table stickyHeader size="small">
            <TableHeader>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Caffeine</TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {data.map((row, i) => (
                <TableRow key={i} className={css.row}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.caffeine ? 'High' : 'Low'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={2}>
                  Showing rows <b>1</b> - <b>{data.length}</b>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableWrapper>
      </SidePanelSubsection>
    </SidePanelContent>
  )
}

const fvPair = <SidePanelFieldValue label={'Field'}>Value</SidePanelFieldValue>
