import React, { useState } from 'react'
import FormGroup from '@liveramp/motif/core/FormGroup'
import FormControlLabel from '@liveramp/motif/core/FormControlLabel'
import Checkbox from '@liveramp/motif/core/Checkbox'

export default function SizeDemo() {
  let [medium, setMedium] = useState(true)
  let [small, setSmall] = useState(true)

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={medium}
            onChange={() => setMedium(!medium)}
            value="medium"
          />
        }
        label="Medium"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={small}
            onChange={() => setSmall(!small)}
            size="small"
            value="size"
          />
        }
        size="small"
        label="Small"
      />
    </FormGroup>
  )
}
