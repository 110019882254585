import React, { useState } from 'react'
import Button from '@liveramp/motif/core/Button'
import ButtonFormGroup from '@liveramp/motif/core/ButtonFormGroup'
import FullScreenDialog from '@liveramp/motif/core/FullScreenDialog'
import FullScreenDialogTitle from '@liveramp/motif/core/FullScreenDialogTitle'
import FullScreenDialogContent from '@liveramp/motif/core/FullScreenDialogContent'
import Grid from '@liveramp/motif/core/Grid'
import Paper from '@liveramp/motif/core/Paper'
import makeStyles from '@liveramp/motif/styles/makeStyles'

export default function SimpleDemo() {
  let classes = useStyles()
  let [open, setOpen] = useState(false)

  return (
    <>
      <Button variant="secondary" color="primary" onClick={() => setOpen(true)}>
        Open
      </Button>
      <FullScreenDialog open={open} onClose={() => setOpen(false)} fullScreen>
        <FullScreenDialogTitle
          renderContentRight={
            <ButtonFormGroup>
              <Button variant="text" onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <Button onClick={() => setOpen(false)}>Confirm</Button>
            </ButtonFormGroup>
          }
        >
          Dialog Title
        </FullScreenDialogTitle>
        <FullScreenDialogContent>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Paper className={classes.paper}>xs=3</Paper>
            </Grid>
            <Grid item xs={8}>
              <Paper className={classes.paper}>xs=8</Paper>
            </Grid>
          </Grid>
        </FullScreenDialogContent>
      </FullScreenDialog>
    </>
  )
}

let useStyles = makeStyles((theme) => ({
  paper: {
    height: '600px',
    padding: '1rem',
    backgroundColor: theme.palette.secondary.light,
  },
}))
