import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import withStyles from '../../styles/withStyles'
import { ToggleButtonGroupStyle } from '../../utils/styleKeys'
import { pxToRem as rem } from '../../utils'

let styles = (theme) => ({
  ...ToggleButtonGroupStyle,
  root: {
    '& .MuiToggleButtonGroup-groupedHorizontal:not(:first-child):not(.Mui-disabled)':
      {
        borderLeft: `${rem(1)} solid ${theme.palette.common.black}`,
      },
  },
})

export default withStyles(styles, { name: 'MuiToggleButtonGroup' })(
  ToggleButtonGroup,
)
