import React, { useState } from 'react'
import Button from '@liveramp/motif/core/Button'
import ButtonFormGroup from '@liveramp/motif/core/ButtonFormGroup'
import Dialog from '@liveramp/motif/core/Dialog'
import DialogActions from '@liveramp/motif/core/DialogActions'
import DialogContent from '@liveramp/motif/core/DialogContent'
import DialogTitle from '@liveramp/motif/core/DialogTitle'

export default function Sandbox() {
  let [open, setOpen] = useState('')
  let lorem = (
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
      veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
      commodo consequat.
    </p>
  )

  let handleClose = () => setOpen('')

  return (
    <div>
      <ButtonFormGroup>
        <Button onClick={() => setOpen('small')}>Small</Button>
        <Button onClick={() => setOpen('medium')}>Medium</Button>
        <Button onClick={() => setOpen('fs')}>Full Screen</Button>
      </ButtonFormGroup>

      <Dialog open={open === 'small'} onClose={handleClose} size="small">
        <DialogTitle onClose={handleClose}>Dialog's Title</DialogTitle>
        <DialogContent>
          <p>This dialog's body text/question comes here?</p>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => setOpen('')}>
            Cancel
          </Button>
          <Button onClick={() => setOpen('')}>Confirm</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={open === 'medium'} onClose={handleClose}>
        <DialogTitle onClose={handleClose}>Dialog Title</DialogTitle>
        <DialogContent>{lorem}</DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => setOpen('')}>
            Cancel
          </Button>
          <Button onClick={() => setOpen('')}>Confirm</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={open === 'fs'} onClose={handleClose} fullScreen>
        <DialogTitle
          renderContentRight={
            <ButtonFormGroup>
              <Button variant="text" onClick={() => setOpen('')}>
                Cancel
              </Button>
              <Button onClick={() => setOpen('')}>Confirm</Button>
            </ButtonFormGroup>
          }
        >
          Dialog Title
        </DialogTitle>
        <DialogContent>
          {lorem}
          {lorem}
          {lorem}
          {lorem}
          {lorem}
          {lorem}
          {lorem}
          {lorem}
          {lorem}
          {lorem}
          {lorem}
          {lorem}
          {lorem}
          {lorem}
        </DialogContent>
      </Dialog>
    </div>
  )
}
