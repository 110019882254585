import React from 'react'
import createSvgIcon from './utils/createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path
      d="M12 2C6.477 2 2 6.343 2 11.7c0 2.739 1.171 5.213 3.054 6.977L3.077 20.61a.802.802 0 000 1.152c.3.294.774.317 1.1.068l.079-.068.222-.22a.824.824 0 01.1-.117l17.28-16.892a.801.801 0 00-.102-1.032.848.848 0 00-1.1-.068l-.079.068-1.438 1.407A10.124 10.124 0 0012 2zm9.068 5.605l-3.212 3.139 1.383.363.068.025a.335.335 0 01.192.326l-.012.07-.842 3.077-.026.067a.355.355 0 01-.566.076l-.049-.066-.801-1.357-3.114 1.758-.002.044c-.077 1.066-.985 1.907-2.093 1.907a2.18 2.18 0 01-.508-.06l-3.634 3.553c1.263.56 2.668.872 4.148.872 5.523 0 10-4.343 10-9.7a9.42 9.42 0 00-.932-4.094zM7.972 10.267c.241-.016.43.22.353.448l-.031.068-.801 1.358 2.693 1.519-1.026 1.003-2.367-1.337-.8 1.358-.045.06a.351.351 0 01-.564-.068l-.029-.075-.842-3.077-.012-.07a.335.335 0 01.192-.326l.068-.025 3.144-.825.067-.01zm4.213-4.82l.064.05 2.302 2.253.046.056c.13.196.016.47-.223.519l-.075.007h-1.602v2.873l-1.399 1.367v-4.24H9.696l-.075-.007a.338.338 0 01-.218-.519l.048-.056 2.302-2.253.064-.05a.35.35 0 01.368 0z"
      fill="#F13A42"
    />
  </React.Fragment>,
  'StatusDistributionRejected',
)
