import React, { Component } from 'react'
import { VerticalNav, Item, Divider } from '@liveramp/motif/core/VerticalNav'
import { NavIn, NavOut, NavAdmin, LiverampLogo } from '@liveramp/motif/icons'
import MotifIcon from './MotifIcon'
import reporting1 from '@liveramp/motif/colors/reporting1'
import reporting2 from '@liveramp/motif/colors/reporting2'
import css from './Simple.module.css'

let items = {
  in: ['upload', 'files'],
  out: 'out',
  motif: 'motif',
  settings: 'settings',
  admin: 'admin',
  more: 'more',
}

let lorem = (
  <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
    consequat.
  </p>
)

let backgroundColor = {
  upload: [reporting1[50], reporting1[100], reporting1[200]],
  files: [reporting1[300], reporting1[400], reporting1[500]],
  out: [reporting1[600], reporting1[700], reporting1[800]],
  motif: [reporting2[50], reporting2[100], reporting2[200]],
  settings: [reporting2[200], reporting2[300], reporting2[400]],
  admin: [reporting2[400], reporting2[500], reporting2[600]],
  more: [reporting2[600], reporting2[700], reporting2[800]],
}

export default class SimpleDemo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: 'motif',
    }
  }

  handleChange = (item) => {
    this.setState({ selected: item })
  }

  render({ selected } = this.state) {
    return (
      <div
        className={css.page}
        style={{
          background: `linear-gradient(55deg, ${backgroundColor[selected].join(
            ', ',
          )})`,
        }}
      >
        <VerticalNav className={css.relativeToDemo}>
          <div>
            <Item
              icon={<NavIn />}
              text={'In'}
              caretColor={selected === 'upload' ? '#b8dfcb' : '#47aacd'}
              active={items.in.includes(selected)}
            >
              <div onClick={() => this.handleChange('upload')}>Upload</div>
              <Divider />
              <div onClick={() => this.handleChange('files')}>File 1</div>
              <div onClick={() => this.handleChange('files')}>File 2</div>
              <div onClick={() => this.handleChange('files')}>File 3</div>
              <div onClick={() => this.handleChange('files')}>File 4</div>
              <div onClick={() => this.handleChange('files')}>File 5</div>
            </Item>
            <Item
              icon={<NavOut />}
              text={'Out'}
              caretColor="#2e64a1"
              active={items.out.includes(selected)}
              onClick={() => this.handleChange(items.out)}
            ></Item>
            <Item
              icon={<MotifIcon />}
              text={'Motif'}
              active={items.motif.includes(selected)}
              caretColor="#f5b6a5"
            >
              <div onClick={() => this.handleChange(items.motif)}>Motif</div>
            </Item>
            <Item
              icon={<NavAdmin />}
              caretColor="#c6488b"
              active={items.admin.includes(selected)}
            >
              <div onClick={() => this.handleChange(items.admin)}>
                I have no text.
              </div>
            </Item>
            <Item
              text={'Admin'}
              caretColor="#eb7992"
              active={items.settings.includes(selected)}
            >
              <div onClick={() => this.handleChange(items.settings)}>
                I have no icon
              </div>
            </Item>
          </div>
          <div>
            <Item
              icon={<LiverampLogo className={css.logo} />}
              caretColor="#913291"
              active={items.more.includes(selected)}
            >
              <div onClick={() => this.handleChange(items.more)}>More</div>
              <div>
                I auto-flow upwards to stay within the boundary of the nav
              </div>
            </Item>
          </div>
        </VerticalNav>
        <div className={css.pageDemo}>
          {selected === 'upload' && <h1>Upload Page</h1>}
          {selected === 'files' && <h1>Files</h1>}
          {selected === 'out' && <h1>Destinations</h1>}
          {selected === 'admin' && <h1>Admin</h1>}
          {selected === 'settings' && <h1>Settings</h1>}
          {selected === 'motif' && <h1>Motif</h1>}
          {selected === 'more' && <h1>More</h1>}
          {lorem}
          {lorem}
          {lorem}
          {lorem}
          {lorem}
        </div>
      </div>
    )
  }
}
