import React, { createRef, useState } from 'react'
import {
  useGridSlotComponentProps,
  GridToolbarContainer,
} from '../../core-components/DataGrid'
import ButtonFormGroup from '../../core-components/ButtonFormGroup'
import TextField from '../../core-components/TextField'
import SettingsMenu from './SettingsMenu'
import SearchIcon from '../../icons/Search'
import { escapeRegExp, makeStyles } from '../../utils'

export default function Toolbar({
  actions = null,
  settingsMenu = {},
  ...props
}) {
  const gridSlotComponentProps = useGridSlotComponentProps()
  const { apiRef } = gridSlotComponentProps
  const toolbarRef = createRef()
  useStyles()

  const columns = apiRef.current.state.columns.lookup
  let searchableColumns = {}
  if (columns) {
    searchableColumns = Object.keys(columns).reduce((acc, cur) => {
      if (columns[cur].searchable) acc[cur] = ''
      return acc
    }, {})
  }
  const [searchObj, setSearchObj] = useState(searchableColumns)

  const getSearches = () => searchObj

  const setSingleSearch = (key, value) => {
    const newSearch = Object.assign({}, searchObj)
    if (newSearch[key]) {
      newSearch[key] = value
      setSearchObj(newSearch)
    }
  }

  const deleteSearch = (key) => {
    setSingleSearch(key, '')
  }

  const deleteSearches = () => {
    const emptySearches = {}
    Object.keys(searchObj).forEach((search) => (emptySearches[search] = ''))
    setSearchObj(emptySearches)
  }

  return (
    <>
      {(Object.keys(searchObj).length > 0 || actions) && (
        <GridToolbarContainer ref={toolbarRef}>
          <ButtonFormGroup className="RichDataTable__Toolbar" start {...props}>
            {Object.keys(searchObj).map((field) => {
              const column = apiRef.current.getColumn(field)

              const handleSearch = (e) => {
                let newSearch = Object.assign({}, searchObj)
                newSearch[field] = e.target.value

                apiRef.current.upsertFilter({
                  id: `search-${field}`,
                  columnField: field,
                  operatorValue: 'contains',
                  value: escapeRegExp(newSearch[field]),
                })

                setSearchObj(newSearch)
              }

              const getPlaceholderLabel = `Search ${
                column.headerName ? column.headerName : column.field
              }`

              return (
                <TextField
                  key={searchObj}
                  label={getPlaceholderLabel}
                  InputProps={{
                    endAdornment: <SearchIcon />,
                  }}
                  value={searchObj[field]}
                  onChange={handleSearch}
                />
              )
            })}
            {actions &&
              actions(gridSlotComponentProps, {
                getSearches: getSearches,
                setSearch: setSingleSearch,
                setSearches: setSearchObj,
                deleteSearch,
                deleteSearches,
              })}
          </ButtonFormGroup>
        </GridToolbarContainer>
      )}
      <SettingsMenu apiRef={apiRef} toolbarRef={toolbarRef} {...settingsMenu} />
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.RichDataTable__Toolbar': {
      width: '100%',
      paddingBottom: '24px',
      display: 'flex',
      alignItems: 'flex-end',
      flexWrap: 'wrap',
    },
  },
}))
