import React from 'react'

const WorkflowDataScience = (props) => (
  <svg width={80} height={80} viewBox="0 0 80 80" {...props}>
    <path
      d="M53.579 34.893c10.15 0 17.99 3.72 17.99 9.024l-.003.165.003.059v25.07c0 5.304-7.84 9.023-17.99 9.023l-.375-.001c-9.967-.09-17.615-3.783-17.615-9.023V44.14c0-.037.002-.074.006-.11a.983.983 0 01-.006-.113c0-5.305 7.84-9.024 17.99-9.024zm0 1.932c-9.213 0-16.058 3.247-16.058 7.092a.976.976 0 01-.006.112.983.983 0 01.006.112v25.07c0 3.844 6.845 7.09 16.058 7.09 9.212 0 16.057-3.246 16.057-7.09l.001-9.79c-2.972 3.021-9.072 5.01-16.157 5.01a33.05 33.05 0 01-11.754-2.245.966.966 0 11.7-1.8 31.11 31.11 0 0011.06 2.113c9.094 0 16.15-3.493 16.15-7.316v-6.921c-2.96 3.088-9.023 5.127-16.057 5.127-4.578 0-8.612-.621-11.708-1.86a.966.966 0 11.717-1.794c2.842 1.136 6.64 1.721 10.99 1.721 9.044 0 16.058-3.585 16.058-7.539 0-3.845-6.845-7.092-16.057-7.092zM33.626.892c2.276 0 4.55.275 6.825.824a3.311 3.311 0 012.534 3.219V7.44c0 .918-.377 1.785-1.043 2.411v15.577l2.306 4.61a.966.966 0 11-1.728.864l-2.51-5.018V9.016l.608-.572a1.37 1.37 0 00.434-1.004V4.935c0-.637-.436-1.19-1.055-1.34a27.027 27.027 0 00-6.37-.771c-2.122 0-4.245.257-6.372.77a1.379 1.379 0 00-1.055 1.34V7.44c0 .384.156.742.434 1.004l.608.572v17.54L10.876 59.264a6.511 6.511 0 004.296 9.244 79.372 79.372 0 0015.146 2.151.966.966 0 01-.086 1.93 81.305 81.305 0 01-15.513-2.203 8.444 8.444 0 01-5.571-11.987L25.31 26.1V9.851l-.149-.149a3.3 3.3 0 01-.893-2.262V4.935A3.311 3.311 0 0126.8 1.716c2.275-.55 4.55-.824 6.825-.824zm-8.214 25.004l-.102.204v.229l.102-.433z"
      fillRule="nonzero"
    />
  </svg>
)

export default WorkflowDataScience
