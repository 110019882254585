import React, { useState } from 'react'
import MuiDialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import Fade from '@material-ui/core/Fade'
import { makeStyles, mergeClasses } from '../../utils'
import { DialogStyle } from '../../utils/styleKeys'
import { Motif2DeprecationWarning } from '../../utils/internal'
import { pxToRem as rem } from '../../utils'

let SlideTransition = React.forwardRef((props, ref) => (
  <Slide direction="up" {...props} ref={ref} />
))

export default function Dialog({
  classes,
  maxWidth,
  size,
  TransitionComponent,
  ...props
}) {
  if (props.fullScreen) {
    Motif2DeprecationWarning('<Dialog fullscreen />', 'FullScreenDialog')
  }

  let [scrolled, setScrolled] = useState(false)
  let defaultClasses = useStyles(props)
  let transition = (() => {
    if (TransitionComponent) return TransitionComponent
    if (props.fullScreen) return SlideTransition
    return Fade
  })()

  let setMaxWidth = () => {
    if (props.fullScreen) return false
    if (size === 'small') return 'xs'
    return 'sm'
  }

  let handleScroll = (e) => {
    let prev = scrolled
    let current = e.target.scrollTop !== 0

    if (prev !== current) {
      let titleElement = e.target.parentElement.querySelector(
        '.MuiDialogTitle-root',
      )

      if (current) {
        titleElement.classList.add('Mui-scrollShadow')
      } else {
        titleElement.classList.remove('Mui-scrollShadow')
      }

      setScrolled(current)
    }
  }

  return (
    <MuiDialog
      classes={mergeClasses(defaultClasses, classes)}
      TransitionComponent={transition}
      maxWidth={maxWidth ? maxWidth : setMaxWidth()}
      onScroll={props.fullScreen && handleScroll}
      disableEnforceFocus // hotfix for dialog closing filter (AX-1971)
      {...props}
    />
  )
}

let useStyles = makeStyles(
  (theme) => ({
    ...DialogStyle,
    paper: {
      minWidth: rem(440),
      padding: rem(24),
    },
    paperFullScreen: {
      height: `calc(100% - ${rem(100)})`,
      marginTop: rem(100),
      padding: 0,
    },
  }),
  { name: 'MuiDialog' },
)

import LocalDialog from './Dialog'
import Title from './Title'
import Content from './Content'
import Actions from './Actions'

export { LocalDialog as Dialog, Title, Content, Actions }
