import React, { useEffect } from 'react'
import RichDataTable, {
  useGridApiRef,
} from '@liveramp/motif/core/RichDataTable'
import { columns, generateRows } from '../../demos/data'

const rows = generateRows(10)

export default function UpdatingDeletingRowsDemo() {
  const apiRef = useGridApiRef()

  useEffect(() => {
    const interval = setInterval(
      () => apiRef.current.updateRows(generateRows(10)),
      400,
    )

    return () => {
      clearInterval(interval)
    }
  }, [apiRef])

  return <RichDataTable apiRef={apiRef} columns={columns} rows={rows} />
}
