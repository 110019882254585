import React from 'react'
import MuiCardHeader from '@material-ui/core/CardHeader'
import withStyles from '../../styles/withStyles'
import { CardHeaderStyle } from '../../utils/styleKeys'
import { pxToRem as rem } from '../../utils'

function CardHeader(props) {
  return <MuiCardHeader disableTypography {...props} />
}

const actionStyles = {
  display: 'flex',
  margin: 0,
  alignItems: 'center',
  position: 'relative',
  top: `${rem(2)}`,
}

let styles = (theme) => ({
  ...CardHeaderStyle,
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: `0 ${rem(16)}`,
    gap: rem(8),
  },
  content: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2',
    ...theme.typography.h3Branding,
    color: theme.palette.text.primary,
  },
  avatar: actionStyles,
  action: actionStyles,
})

export default withStyles(styles, { name: 'MuiCardHeader' })(CardHeader)
