import React from 'react'
import clsx from 'clsx'
import Drawer from '../../core-components/Drawer'
import { makeStyles, mergeClasses } from '../../utils'
import { SidePanelStyle } from '../../utils/styleKeys'

export default function SidePanel({ classes, className, ...props }) {
  const defaultClasses = useStyles({ width: props.width })

  return (
    <Drawer
      className={clsx('SidePanel-root', className)}
      classes={mergeClasses(defaultClasses, classes)}
      anchor="right"
      {...props}
    />
  )
}

const useStyles = makeStyles(
  (theme) => ({
    ...SidePanelStyle,
    paper: {
      overflow: 'hidden',
      width: ({ width }) => (width ? width : '100%'),
      '& > .MuiTabs-root': {
        backgroundColor: theme.palette.grey[100],
      },
    },
    paperAnchorDockedRight: {
      borderLeft: `1px solid ${theme.palette.secondary.light}`,
    },
  }),
  { name: 'MuiDrawer' },
)

import Content from './Content'
import MotifSidePanel from './SidePanel'
import Header from './Header'
import SubSection from './SubSection'

export { Content, Header, MotifSidePanel as SidePanel, SubSection }
