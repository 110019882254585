import React from 'react'
import PropTypes from 'prop-types'
import CloseButton from '../../core-components/CloseButton'
import makeStyles from '../../styles/makeStyles'
import Truncate from '../../composed-components/Truncate'

export default function SidePanelHeader({
  children,
  onClose,
  truncateProps = {
    wrapped: true,
  },
  ...props
}) {
  useStyles()

  return (
    <div className="SidePanelHeader SidePanelHeader-root" {...props}>
      {children && typeof children === 'string' ? (
        <Truncate
          className="SidePanelHeader__title SidePanelHeader-title"
          variant="h3"
          color="textPrimary"
          tooltipPosition={'left'}
          {...truncateProps}
        >
          {children}
        </Truncate>
      ) : (
        children
      )}
      <CloseButton onClick={onClose} />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.SidePanelHeader': {
      background: theme.palette.grey[100],
      height: '56px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      paddingLeft: '16px',
      paddingRight: '8px',

      // .SidePanelHeader__title
      '&__title': {
        flex: 1,
        margin: 0,
        paddingBottom: 0,
      },
    },
  },
}))

SidePanelHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onClose: PropTypes.func,
}
