import React from 'react'
import MuiDialogContent from '@material-ui/core/DialogContent'
import { makeStyles, mergeClasses } from '../../utils'
import { DialogContentStyle } from '../../utils/styleKeys'
import { pxToRem as rem } from '../../utils'

export default function DialogContent({ classes, ...props }) {
  let defaultClasses = useStyles()

  return (
    <MuiDialogContent
      classes={mergeClasses(defaultClasses, classes)}
      {...props}
    />
  )
}

let useStyles = makeStyles(
  (theme) => ({
    ...DialogContentStyle,
    root: {
      ...theme.typography.body2,
      padding: 0,
      lineHeight: rem(20),
      fontSize: rem(14),
      '&.FullScreenDialongContent-divider': {
        borderRight: `${rem(1)} solid ${theme.palette.secondary.light}`,
      },
    },
  }),
  { name: 'MuiDialogContent' },
)
