// this export is icon files w/o the js extension; include icon names in this export if an icon should be rendered in the display page under the Vertical Navigation category
let icons = [
  'NavAdmin',
  'NavBilling',
  'NavDataStore',
  'NavIn',
  'NavManage',
  'NavOTTHub',
  'NavOut',
  'NavView',
]

export default icons
