let green = {
  50: '#e6fbf0',
  100: '#b6f9dc',
  200: '#99edc3',
  300: '#70e6aa',
  400: '#51e098',
  500: '#32db86',
  600: '#1ea464',
  700: '#0a6d41',
  800: '#05502a',
  900: '#043326',
}

export default green
