import React, { useState } from 'react'
import Radio from '@liveramp/motif/core/Radio'
import FormControlLabel from '@liveramp/motif/core/FormControlLabel'
import {
  Check,
  Clear,
  Favorite,
  FavoriteOutline,
  Star,
  StarOutline,
  Lightbulb,
  LightbulbOutline,
} from '@liveramp/motif/icons'
import css from './Custom.module.css'
import withStyles from '@liveramp/motif/styles/withStyles'
import makeStyles from '@liveramp/motif/styles/makeStyles'

function CustomDemo({ classes: withStylesClasses }) {
  let [radio, setRadio] = useState('icon')
  let handleChange = (event) => setRadio(event.target.value)
  let makeStylesClasses = useStyles()

  return (
    <>
      <FormControlLabel
        control={
          <Radio
            checked={radio === 'icon'}
            onChange={handleChange}
            icon={<Clear />}
            checkedIcon={<Check />}
            value="icon"
          />
        }
        label="Icon"
      />
      <FormControlLabel
        control={
          <Radio
            className={css.heart}
            checked={radio === 'heart'}
            onChange={handleChange}
            icon={<FavoriteOutline />}
            checkedIcon={<Favorite />}
            value="heart"
          />
        }
        label="CSS Modules"
      />
      <FormControlLabel
        control={
          <Radio
            classes={withStylesClasses}
            checked={radio === 'star'}
            onChange={handleChange}
            icon={<StarOutline />}
            checkedIcon={<Star />}
            value="star"
          />
        }
        label="JSS withStyles"
      />
      <FormControlLabel
        control={
          <Radio
            classes={makeStylesClasses}
            checked={radio === 'lightbulb'}
            onChange={handleChange}
            icon={<LightbulbOutline />}
            checkedIcon={<Lightbulb />}
            value="lightbulb"
          />
        }
        label="JSS makeStyles"
      />
    </>
  )
}

let styles = (theme) => ({
  root: {
    '&, &.Mui-checked': {
      color: theme.palette.warning.main,
    },
    '&:hover, &.Mui-checked&:hover': {
      backgroundColor: theme.palette.warning.hover,
    },
  },
})

let useStyles = makeStyles((theme) => ({
  root: {
    '&, &.Mui-checked': {
      color: theme.palette.info.main,
    },
    '&:hover, &.Mui-checked&:hover': {
      backgroundColor: theme.palette.info.hover,
    },
  },
}))

export default withStyles(styles)(CustomDemo)
