import React from 'react'
import createSvgIcon from './utils/createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path
      d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm4.682 14.682a1.084 1.084 0 01-1.536 0L12 13.535l-3.146 3.147a1.084 1.084 0 01-1.536 0 1.084 1.084 0 010-1.536L10.465 12 7.318 8.854a1.084 1.084 0 010-1.536 1.084 1.084 0 011.536 0L12 10.465l3.146-3.147a1.084 1.084 0 011.536 0c.424.425.424 1.111 0 1.536L13.535 12l3.147 3.146c.413.414.413 1.11 0 1.536z"
      fill="#F13A42"
      fillRule="nonzero"
    />
  </React.Fragment>,
  'StatusError',
)
