import React from 'react'
import {
  Page,
  Title,
  Subtitle,
  Supporting,
  Usage,
  NoticePanel,
  SectionTitle,
} from '../../resources/DemoPage'
import Demo, { generateDemos } from '../../resources/Demo'
import CodeChunk from '../../resources/CodeChunk'

import Simple from './demos/Simple'
import SingleAction from './demos/SingleAction'

let demoNames = [
  'Single',
  'SingleAction',
  'ThirdPartyRoutingLibrary',
  'ThirdPartyRoutingLibraryCollision',
]

export default function Button() {
  let demos = generateDemos(
    'composed-component-demo/global-action-button',
    demoNames,
  )

  return (
    <Page components={['GlobalActionButton', 'GlobalActionButtonAction']}>
      <Title>Global Action Button</Title>
      <Subtitle>
        The Global Action Button represents the primary function of a screen
      </Subtitle>
      <Supporting>
        The global action button (GAB) triggers a menu that gives the user
        access to all the major actions that can be taken on the page.
      </Supporting>
      <NoticePanel variant="danger">
        <p>
          This component is being deprecated. Please refer to the{' '}
          <a href="/composed/button-menu">Button Menu</a> instead.
        </p>
      </NoticePanel>
      <Usage>
        <ul>
          <li>
            Avoid using actions that are tied to one object or item on the page;
            i.e. do not use them in relation to singular table rows or within
            forms and dialogs.
          </li>
          <li>Don’t display multiple FABs on a single screen.</li>
          <li>
            <i>
              E.g.: Creating a new audience, performing an overlap analysis or
              exporting a catalog of data from a table.
            </i>
          </li>
        </ul>
      </Usage>
      <SectionTitle>Global Action Button with Multi Actions</SectionTitle>
      <p>
        GABs with multiple actions within the <code>Menu</code>, should use the
        <code>VerticalMore</code> icon.
      </p>
      <Demo demo={demos.Single}>
        <Simple />
      </Demo>

      <SectionTitle>Global Action Button with Single Action</SectionTitle>
      <p>
        When a GAB has only one action, use an icon that represents the action
        clearly, to improve findability (i.e. use the <code>Add</code> icon when
        your action involes building, creating or adding).
      </p>
      <Demo demo={demos.SingleAction}>
        <SingleAction />
      </Demo>

      <SectionTitle>Third-Party Routing Library</SectionTitle>
      <p>
        One common use case is to use the button to trigger a navigation to a
        new page. The <code>ButtonBase</code> component provides a property to
        handle this use case: <code>component</code>. Given that a lot of our
        interactive components rely on <code>ButtonBase</code>, you should be
        able to take advantage of it everywhere:
      </p>
      <CodeChunk>{demos.ThirdPartyRoutingLibrary}</CodeChunk>
      <p>or if you want to avoid a prop collision:</p>
      <CodeChunk>{demos.ThirdPartyRoutingLibraryCollision}</CodeChunk>
    </Page>
  )
}
