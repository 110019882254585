import React, { Component } from 'react'
import {
  Title,
  Subtitle,
  NoticePanel,
  SectionTitle,
} from '../../resources/Page'
import CodeChunk from '../../resources/CodeChunk'
import Prism from 'prismjs'

export default class Installation extends Component {
  componentDidMount() {
    Prism.highlightAll()
  }

  render() {
    return (
      <div>
        <Title>Installation</Title>
        <Subtitle>
          LiveRamp's Motif is available as an npm package in Artifactory
        </Subtitle>

        <p>
          To install and save in your <code>package.json</code> dependencies,
          run:
        </p>
        <CodeChunk>
          {`// yarn
              yarn add @liveramp/motif --registry=https://library.liveramp.net/artifactory/api/npm/npm

              // npm
              npm install @liveramp/motif --registry=https://library.liveramp.net/artifactory/api/npm/npm`}
        </CodeChunk>
        <NoticePanel variant="warning">
          <h5>
            It is highly recommended that you pin Motif to a single minor
            version before we complete our first major release.
          </h5>
          <p>
            Motif currently does not have a major release, and so all minor
            releases may have breaking changes while we experiment and work
            towards building a complete suite of ready-to-use components. We
            will endeavour to release a changelog after each update and are more
            than happy to help teams upgrade and migrate between each version.
          </p>
        </NoticePanel>

        <p>Please note the following peer dependencies:</p>
        <ul>
          <li>
            <code>"react": "^16.8.1"</code>
          </li>
          <li>
            <code>"react-dom": "^16.8.1"</code>
          </li>
        </ul>
        <NoticePanel variant="warning">
          <p>
            Importing and using Material UI components directly will lead to a
            console warning about using and initializing multiple copies of{' '}
            <code>@material-ui/core/styles</code>. If you are having trouble
            re-styling our components, please message us at{' '}
            <a href="https://liveramp.slack.com/archives/C012Q4QCZG8">
              #dev-ax
            </a>{' '}
            if you need help.
          </p>
        </NoticePanel>

        <SectionTitle>Using Components</SectionTitle>
        <CodeChunk>import Button from '@liveramp/motif/core/Button';</CodeChunk>

        <SectionTitle>Consuming Colors</SectionTitle>
        <SectionTitle subtitle>JS Object</SectionTitle>
        <CodeChunk>
          {`import green from "@liveramp/motif/colors/green"
            let color = green[500]`}
        </CodeChunk>
        <SectionTitle subtitle>CSS Modules</SectionTitle>
        <CodeChunk css>
          {`@value green from "@liveramp/motif/colors.module.css";
            body {
              color: green;
            }`}
        </CodeChunk>
        <SectionTitle subtitle>Vanilla CSS</SectionTitle>
        <p>
          Add this import to your project. It creates the variables at the{' '}
          <code>:root</code> selector which is the highest-level element in the
          DOM
        </p>
        <CodeChunk>import "@liveramp/motif/colors/colors.css"</CodeChunk>
        <p>And utilize like so in your CSS</p>
        <CodeChunk css>
          {`body {
              color: var(--green);
            }`}
        </CodeChunk>

        <SectionTitle>Using SVG Icons</SectionTitle>
        <CodeChunk>import Logo from '@liveramp/motif/icons/Logo';</CodeChunk>

        <SectionTitle>Fonts</SectionTitle>
        <p>Motif was designed with Liveramp sans and Open Sans in mind. </p>
        <ul>
          <li>
            Liveramp sans is reserved for major page titles and headings:{' '}
            <code>h1</code>, <code>h2</code> and <code>h3</code>.
          </li>
          <li>
            Open Sans should be used for everything else: <code>h4</code>,{' '}
            <code>h5</code>, <code>h6</code>, <code>p</code> etc.
          </li>
        </ul>
        <CodeChunk>
          {`// liveramp sans
            <link href="https://cdn-motif.liveramp.com/fonts/liverampsans.css" rel="stylesheet" type="text/css" />

            // Open Sans
            <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700" rel="stylesheet" />

            // IBM Plex Mono
            <link href="https://cdn-motif.liveramp.com/fonts/IBMPlexMono.css" rel="stylesheet" type="text/css" />`}
        </CodeChunk>

        <SectionTitle>Typescript Support</SectionTitle>
        <p>
          TypeScript support is a work-in-progress and may have bugs. AX does
          not prioritize TS bugs fixes currently.
        </p>
        <p>
          There is partial support for types inside components out-of-the-box,
          but please use them at your own risk.
        </p>
        <p>
          If you find a component with no types or with an outdated definition
          and you would like to contribute, feel free to open a PR updating it.
          You can use ts-ignore or override the module definitions from within
          the consuming application.
        </p>

        <CodeChunk>
          {`// Example module declaration:
            declare module "@liveramp/motif/core/Header" {
              export interface HeaderProps {
                subtitle?: JSX.Element
              }
        
              export default function (props: HeaderProps): JSX.Element;
            }

            // Module declaration ignoring the type definition.
            declare module "@liveramp/motif/core/Header";
          `}
        </CodeChunk>
      </div>
    )
  }
}
