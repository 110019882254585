// this export is icon files w/o the js extension; include icon names in this export if an icon should be rendered in the display page under the DataStore category

let icons = [
  'CombinedData',
  'DataStore',
  'DataStoreRemove',
  'MyData',
  'PartnerData',
  'RequestSegment',
  'UnrequestSegment',
]

export default icons
