let grey = {
  50: '#fafafa',
  100: '#f5f5f5',
  200: '#eeeeee',
  300: '#e0e0e0',
  400: '#b3b3b3',
  500: '#808080',
  600: '#606060',
  700: '#4d4d4d',
  800: '#222222',
  900: '#000000',
}

export default grey
