import React from 'react'
import {
  Page,
  Title,
  Supporting,
  Usage,
  SectionTitle,
} from '../../resources/DemoPage'
import Demo, { generateDemos } from '../../resources/Demo'

import Simple from './demos/Simple'
import Sections from './demos/Sections'

let demoNames = ['Simple', 'Sections']

export default function SidePanel() {
  let demos = generateDemos('composed-component-demo/side-panel', demoNames)

  return (
    <Page
      components={[
        'SidePanel',
        'SidePanelHeader',
        'SidePanelContent',
        'SidePanelSubsection',
        'SidePanelFieldValueRow',
        'SidePanelFieldValue',
        'SidePanelImageSection',
      ]}
    >
      <Title>Side Panel</Title>
      <Supporting>
        Side Panels are surfaces containing supplementary content that's
        anchored to the right edge of the screen and provide access to details
        about business objects and workflows. Beneath the hood, a{' '}
        <code>Drawer</code> component is used to create the surface.
      </Supporting>
      <Usage>
        <ul>
          <li>
            For showing additional details, metadata, and information about a
            particular object
          </li>
        </ul>
      </Usage>

      <p>
        Side panel components are commonly found on pages with tables to show
        more details about table row objects. The Side Panel's
        <code>SidePanelHeader</code> component can optionally display a title,
        but requires an onClick function to handle closing it. The{' '}
        <code>SidePanelHeader</code>
        comes out of the box with intelligent multi-line middle truncation,
        complete with a tooltip -- enabling your tables to fall effortlessly in
        line with our UX team's best practices. The{' '}
        <code>SidePanelContent</code> component is a pre-styled grid that
        handles the spacing for each child element.
      </p>
      <Demo demo={demos.Simple}>
        <Simple />
      </Demo>

      <SectionTitle>Side Panel Sections</SectionTitle>
      <p>
        The Side Panel component comes with additional helper
        compoonents/modules to compose the side panel consistently and quickly.
      </p>
      <p>
        The <code>SidePanelSubsection</code> renders a title for a section and
        contains its children within a collapsable area. The default state is
        set to open, but can be rendered at closed if required.
      </p>
      <p>
        <code>SidePanelImageSection</code> can render an image and takes
        children, which should show field value pairs (max number of 2 pairs per
        row, please!). Each <code>SidePanelFieldValueRow</code> can take an
        optional action to attach to the "Edit" icon button.{' '}
        <code>SidePanelFieldValue</code> is a child element and it will be
        evenly distributed in the space provided.
      </p>
      <Demo demo={demos.Sections}>
        <Sections />
      </Demo>
    </Page>
  )
}
