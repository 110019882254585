import React from 'react'

let NavAdmin = (props) => (
  <svg width={26} height={26} viewBox="0 0 25 26" {...props}>
    <g fillRule="nonzero">
      <path d="M5.3 3.767l3.035-1.949 2.405 2.219a.65.65 0 0 0 .44.172h3.348a.65.65 0 0 0 .466-.197l2.148-2.21 3.41 1.795-.975 2.957a.65.65 0 0 0 .071.557l1.937 2.994a.65.65 0 0 0 .318.255l2.447.917v3.432l-2.299 1.054a.65.65 0 0 0-.285.255l-1.574 2.607a.65.65 0 0 0-.082.455l.59 3.153-3.035 1.949-2.405-2.219a.65.65 0 0 0-.44-.172h-3.348a.65.65 0 0 0-.466.197l-2.148 2.21-3.41-1.795.975-2.957a.65.65 0 0 0-.071-.557l-1.937-2.994a.65.65 0 0 0-.318-.256l-2.447-.916V11.29l2.299-1.054a.65.65 0 1 0-.542-1.182L.729 10.283a.65.65 0 0 0-.379.591v4.3a.65.65 0 0 0 .422.608l2.668 1 1.651 2.552-1.05 3.185a.65.65 0 0 0 .314.778l4.328 2.278a.65.65 0 0 0 .769-.122l2.295-2.362h2.818l2.587 2.387c.218.2.543.229.792.069l3.826-2.457a.65.65 0 0 0 .287-.666l-.624-3.34 1.342-2.223 2.496-1.144a.65.65 0 0 0 .379-.591v-4.3a.65.65 0 0 0-.422-.608l-2.668-1-1.651-2.552 1.05-3.185a.65.65 0 0 0-.315-.779L17.317.425a.65.65 0 0 0-.769.122L14.253 2.91h-2.818L8.848.522a.65.65 0 0 0-.792-.069L4.23 2.91a.65.65 0 0 0-.287.666l.669 3.583a.65.65 0 0 0 1.278-.24L5.3 3.768z" />
      <path d="M19.15 13a6.15 6.15 0 1 0-12.3 0 6.15 6.15 0 0 0 12.3 0zm-1.3 0a4.85 4.85 0 1 1-9.7 0 4.85 4.85 0 0 1 9.7 0z" />
    </g>
  </svg>
)

export default NavAdmin
