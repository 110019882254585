import React from 'react'
import {
  Page,
  Title,
  Subtitle,
  Supporting,
  Usage,
  NoticePanel,
  SectionTitle,
} from '../../resources/DemoPage'
import Demo, { generateDemos } from '../../resources/Demo'

import Simple from './demos/Simple'
import SingleStates from './demos/SingleStates'
import Grouped from './demos/Grouped'
import DisabledOptions from './demos/DisabledOptions'
import FreeValue from './demos/FreeValue'
import Feature from './demos/Feature'
import LimitTags from './demos/LimitTags'
import Multi from './demos/Multi'
import MultiStates from './demos/MultiStates'
import FixedValues from './demos/FixedValues'
import Custom from './demos/Custom'

let demoNames = [
  'Simple',
  'SingleStates',
  'Grouped',
  'DisabledOptions',
  'FreeValue',
  'Feature',
  'LimitTags',
  'Multi',
  'MultiStates',
  'FixedValues',
  'Custom',
]

export default function Radio() {
  let demos = generateDemos('core-component-demo/select', demoNames)

  return (
    <Page components="Select">
      <Title>Select</Title>
      <Subtitle>
        The select component is a normal text input enhanced by a panel of
        suggested options
      </Subtitle>
      <Supporting>
        Select uses Material UI's{' '}
        <a href="https://material-ui.com/components/autocomplete">
          Autocomplete
        </a>{' '}
        under the hood to provide a seamless user experience during option
        selection.
      </Supporting>
      <Usage>
        <ul>
          <li>
            The widget is useful for setting the value of a single-line textbox
            in one of two types of scenarios:
            <ul>
              <li>
                The value for the textbox must be chosen from a predefined set
                of allowed values, e.g., a location field must contain a valid
                location name.
              </li>
              <li>
                The textbox may contain any arbitrary value, but it is
                advantageous to suggest possible values to the user, e.g., a
                search field may suggest similar or previous searches to save
                the user time: <code>freeSolo</code>.
              </li>
            </ul>
          </li>
          <li>
            See the <a href="/core/text-field">Text Field</a> component for
            guidance on when to use <code>variant</code> outlined vs.
            underlined.
          </li>
        </ul>
      </Usage>
      <NoticePanel variant="warning">
        <p>
          Select is built on a material-ui lab component (outside of the core
          package, for components not yet ready to be introduced to the core
          library), therefore there may be breaking changes in the coming
          months. We will communicate when and if that happens!
        </p>
      </NoticePanel>

      <SectionTitle>Single Select</SectionTitle>
      <p>
        Select is used for picking a value from a predefined set of allowed
        values.
      </p>
      <Demo demo={demos.Simple}>
        <Simple />
      </Demo>

      <Demo demo={demos.SingleStates}>
        <SingleStates />
      </Demo>

      <SectionTitle subtitle>Grouped</SectionTitle>
      <Demo demo={demos.Grouped}>
        <Grouped />
      </Demo>

      <SectionTitle subtitle>Disabled Options</SectionTitle>
      <Demo demo={demos.DisabledOptions}>
        <DisabledOptions />
      </Demo>

      <SectionTitle subtitle>Free Values</SectionTitle>
      <p>
        This select is not constrained to its options and can hold arbitrary
        text values
      </p>
      <Demo demo={demos.FreeValue}>
        <FreeValue />
      </Demo>

      <SectionTitle subtitle>Additional Features</SectionTitle>
      <Demo demo={demos.Feature}>
        <Feature />
      </Demo>

      <SectionTitle>Multi Select</SectionTitle>
      <p>
        The multi-select allows for multiple values to be selected from a
        pre-defined list. The selected options appear in the form of tags in the
        input field and can be cleared via the cross icon or unselected from the
        dropdown.
      </p>
      <Demo demo={demos.Multi}>
        <Multi />
      </Demo>

      <SectionTitle subtitle>Limit Tags</SectionTitle>
      <p>
        You can use the <code>limitTags</code> prop to limit the number of
        displayed options when not focused.
      </p>
      <Demo demo={demos.LimitTags}>
        <LimitTags />
      </Demo>

      <Demo demo={demos.MultiStates}>
        <MultiStates />
      </Demo>

      <SectionTitle subtitle>Fixed Values</SectionTitle>
      <p>
        The <code>fixedValues</code> prop accepts an array of options that are
        fixed and not removable.
      </p>
      <Demo demo={demos.FixedValues}>
        <FixedValues />
      </Demo>

      <SectionTitle>Advanced Customization</SectionTitle>
      <p>
        The select is a complex component consisting of many smaller components
        which can be leveraged to customize the default look and feel into
        unique solutions.
      </p>
      <NoticePanel variant="warning">
        <p>
          Avoid heavy customization to the select component to prevent
          introducing bad UX practices and patterns. Please verify and consult
          Theresa or Veronica before attempting to build your own select
          component.
        </p>
      </NoticePanel>
      <p>The select has four customizable components which are handled by:</p>
      <ul>
        <li>
          <code>renderInput</code>
        </li>
        <li>
          <code>renderGroup</code>
        </li>
        <li>
          <code>renderOption</code>
        </li>
        <li>
          <code>renderTags</code>
        </li>
      </ul>
      <Demo demo={demos.Custom}>
        <Custom />
      </Demo>
    </Page>
  )
}
