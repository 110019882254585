import React from 'react'
import MuiStepLabel from '@material-ui/core/StepLabel'
import Typography from '../Typography'
import StepIcon from '../StepIcon'
import { makeStyles, mergeClasses } from '../../utils'
import { StepLabelStyle } from '../../utils/styleKeys'

export default function StepLabel({
  classes,
  optional = null,
  variant,
  ...props
}) {
  const defaultClasses = useStyles()

  if (typeof optional === 'string') {
    optional = <Typography variant="caption">{optional}</Typography>
  }

  return (
    <MuiStepLabel
      classes={mergeClasses(defaultClasses, classes)}
      StepIconComponent={StepIcon}
      StepIconProps={{ classes, variant }}
      optional={optional}
      {...props}
    />
  )
}

const useStyles = makeStyles(
  (theme) => ({
    ...StepLabelStyle,
    labelContainer: {
      ...theme.typography.subtitle2,
      '&, & .MuiStepLabel-completed, & .MuiTypography-caption': {
        color: theme.palette.text.secondary,
        fontWeight: 500,
      },
    },
    iconContainer: {
      flex: 1,
      height: '28px',
      width: '28px',
      '.MuiStepLabel-vertical &': {
        padding: 0,
        marginRight: '16px',
      },
    },
    alternativeLabel: {
      '& .MuiTypography-caption': {
        textAlign: 'center',
      },
    },
    active: {
      '.MuiStepLabel-labelContainer &,\
      .MuiStepLabel-labelContainer & + .MuiTypography-caption': {
        color: theme.palette.text.primary,
        '&.MuiStepLabel-label': {
          fontWeight: 700,
        },
      },
    },
  }),
  { name: 'MuiStepLabel' },
)
