import React from 'react'
import Uploader from '@liveramp/motif/core/Uploader'
import Button from '@liveramp/motif/core/Button'

const POST_URL = 'https://httpbin.org/post'

export default function SimpleDemo() {
  return (
    <Uploader
      id="multi-uploader"
      multiple
      url={POST_URL}
      successComponent={() => (
        <Button
          variant="text"
          color="secondary"
          size="small"
          onClick={() => console.log('Audiences Page reroute')}
        >
          Go to audiences
        </Button>
      )}
    />
  )
}
