import React from 'react'

let TableSensitiveColumn = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>table-icons_sensitive-column</title>
    <g
      id="table-icons_sensitive-column"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M9.74742268,2 L9.66060695,2.00748096 C9.40720646,2.05171181 9.22254051,2.2861399 9.25009416,2.55161726 L9.976,9.545 L3.32793578,7.57080385 C3.03777766,7.48461729 2.73818593,7.67398159 2.69153987,7.97305349 L2.00597286,12.3685759 L1.99996542,12.4536923 C2.00364788,12.7057305 2.19804735,12.9220859 2.45826164,12.9438839 L8.591,13.457 L4.58152722,19.0409354 C4.40565923,19.2857876 4.48484791,19.6294281 4.75013697,19.7726199 L8.76560089,21.9399973 L8.85392425,21.9773584 C9.09379714,22.0527183 9.36046776,21.9367016 9.46304511,21.6960711 L11.945,15.871 L14.7215435,21.7144997 C14.8454124,21.975319 15.1638241,22.0773061 15.4161763,21.9369903 L19.3141145,19.7696129 L19.3873312,19.7201023 C19.5896763,19.555581 19.6345119,19.2552203 19.4742452,19.0368162 L15.379,13.457 L21.5415081,12.9439031 C21.824714,12.920311 22.0313394,12.6657215 21.9961526,12.3837213 L21.447699,7.98819891 L21.428628,7.90000979 C21.3492866,7.64608797 21.0780933,7.49278362 20.8122445,7.56990353 L13.984,9.55 L14.7107306,2.55161726 C14.7413457,2.25664242 14.5099614,2 14.2134021,2 L9.74742268,2 Z"
        id="Path"
        fill={props.fill || '#000000'}
        fillRule="nonzero"
      ></path>
    </g>
  </svg>
)

export default TableSensitiveColumn
