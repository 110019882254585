import React, { useState } from 'react'
import NotificationContent from '@liveramp/motif/core/NotificationContent'
import { data } from './data'

export default function SimpleDemo() {
  let [currentData, setCurrentData] = useState(data)

  return (
    /* Click SEE ALL to restore the Notifications for the demos */
    <NotificationContent
      onMarkReadClick={(notification) => setCurrentData([])}
      onSeeAllClick={() => {
        setCurrentData(data)
        alert('Clicked "SEE ALL" on the Notifications!')
      }}
      onClose={() => setCurrentData([])}
      notifications={currentData}
    />
  )
}
