import React from 'react'
import RichDataTable from '@liveramp/motif/core/RichDataTable'
import { caffeineOrder, generateRows } from '../../demos/data'

const rows = generateRows(100)

const columns = [
  { field: 'name', headerName: 'Name', width: 200 },
  {
    field: 'caffeine',
    headerName: 'Caffeine',
    width: 200,
    sortComparator: (value1, value2) =>
      caffeineOrder.indexOf(value1) - caffeineOrder.indexOf(value2),
  },
  {
    field: 'price',
    headerName: 'Price',
    type: 'number',
    width: 200,
    valueFormatter: (params) => `$${params.value.toFixed(2)}`,
  },
  { field: 'desc', headerName: 'Description', width: 750 },
]

export default function ScrollDemo() {
  return (
    <RichDataTable
      columns={columns}
      rows={rows}
      autoHeight={false}
      height={360}
    />
  )
}
