/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: 'MuiSnackbar',
  description: '',
  displayName: 'Snackbar',
  methods: [],
  props: {
    action: {
      type: { name: 'node' },
      required: false,
      description:
        'The action to display. It renders after the message, at the end of the alert.',
    },
    anchorOrigin: {
      type: {
        name: 'shape',
        value: {
          horizontal: {
            name: 'enum',
            value: [
              { value: "'center'", computed: false },
              { value: "'left'", computed: false },
              { value: "'right'", computed: false },
            ],
            required: true,
          },
          vertical: {
            name: 'enum',
            value: [
              { value: "'bottom'", computed: false },
              { value: "'top'", computed: false },
            ],
            required: true,
          },
        },
      },
      required: false,
      description: 'The anchor of the `Alert`.',
      defaultValue: {
        value: "{\n  vertical: 'bottom',\n  horizontal: 'center'\n}",
        computed: false,
      },
    },
    autoHideDuration: {
      type: { name: 'number' },
      required: false,
      description:
        'The number of milliseconds to wait before automatically calling the\r\n`onClose` function. `onClose` should then set the state of the `open`\r\nprop to hide the Alert. This behavior is disabled by default with\r\nthe `null` value.',
      defaultValue: { value: 'null', computed: false },
    },
    children: {
      type: { name: 'element' },
      required: false,
      description: 'Replace the `AlertContent` component.',
    },
    classes: {
      type: { name: 'object' },
      required: false,
      description:
        'Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details.',
    },
    className: {
      type: { name: 'string' },
      required: false,
      description: '@ignore',
    },
    ClickAwayListenerProps: {
      type: { name: 'object' },
      required: false,
      description: 'Props applied to the `ClickAwayListener` element.',
    },
    ContentProps: {
      type: { name: 'object' },
      required: false,
      description:
        'Props applied to the [`AlertContent`](/api/alert-content/) element.',
    },
    disableWindowBlurListener: {
      type: { name: 'bool' },
      required: false,
      description:
        'If `true`, the `autoHideDuration` timer will expire even if the window is not focused.',
      defaultValue: { value: 'false', computed: false },
    },
    key: {
      type: { name: 'any' },
      required: false,
      description:
        'When displaying multiple consecutive Alerts from a parent rendering a single\r\n<Alert/>, add the key prop to ensure independent treatment of each message.\r\ne.g. <Alert key={message} />, otherwise, the message may update-in-place and\r\nfeatures such as autoHideDuration may be canceled.',
    },
    message: {
      type: { name: 'node' },
      required: false,
      description: 'The message to display.',
    },
    onClose: {
      type: { name: 'func' },
      required: false,
      description:
        'Callback fired when the component requests to be closed.\r\nTypically `onClose` is used to set state in the parent component,\r\nwhich is used to control the `Alert` `open` prop.\r\nThe `reason` parameter can optionally be used to control the response to `onClose`,\r\nfor example ignoring `clickaway`.\r\n\r\n@param {object} event The event source of the callback.\r\n@param {string} reason Can be: `"timeout"` (`autoHideDuration` expired), `"clickaway"`.',
    },
    onEnter: {
      type: {
        name: 'custom',
        raw: "deprecatedPropType(PropTypes.func, 'Use the `TransitionProps` prop instead.')",
      },
      required: false,
      description:
        'Callback fired before the transition is entering.\r\n@deprecated Use the `TransitionProps` prop instead.',
    },
    onEntered: {
      type: {
        name: 'custom',
        raw: "deprecatedPropType(PropTypes.func, 'Use the `TransitionProps` prop instead.')",
      },
      required: false,
      description:
        'Callback fired when the transition has entered.\r\n@deprecated Use the `TransitionProps` prop instead.',
    },
    onEntering: {
      type: {
        name: 'custom',
        raw: "deprecatedPropType(PropTypes.func, 'Use the `TransitionProps` prop instead.')",
      },
      required: false,
      description:
        'Callback fired when the transition is entering.\r\n@deprecated Use the `TransitionProps` prop instead.',
    },
    onExit: {
      type: {
        name: 'custom',
        raw: "deprecatedPropType(PropTypes.func, 'Use the `TransitionProps` prop instead.')",
      },
      required: false,
      description:
        'Callback fired before the transition is exiting.\r\n@deprecated Use the `TransitionProps` prop instead.',
    },
    onExited: {
      type: {
        name: 'custom',
        raw: "deprecatedPropType(PropTypes.func, 'Use the `TransitionProps` prop instead.')",
      },
      required: false,
      description:
        'Callback fired when the transition has exited.\r\n@deprecated Use the `TransitionProps` prop instead.',
    },
    onExiting: {
      type: {
        name: 'custom',
        raw: "deprecatedPropType(PropTypes.func, 'Use the `TransitionProps` prop instead.')",
      },
      required: false,
      description:
        'Callback fired when the transition is exiting.\r\n@deprecated Use the `TransitionProps` prop instead.',
    },
    onMouseEnter: {
      type: { name: 'func' },
      required: false,
      description: '@ignore',
    },
    onMouseLeave: {
      type: { name: 'func' },
      required: false,
      description: '@ignore',
    },
    open: {
      type: { name: 'bool' },
      required: false,
      description: 'If `true`, `Alert` is open.',
    },
    resumeHideDuration: {
      type: { name: 'number' },
      required: false,
      description:
        "The number of milliseconds to wait before dismissing after user interaction.\r\nIf `autoHideDuration` prop isn't specified, it does nothing.\r\nIf `autoHideDuration` prop is specified but `resumeHideDuration` isn't,\r\nwe default to `autoHideDuration / 2` ms.",
    },
    TransitionComponent: {
      type: { name: 'elementType' },
      required: false,
      description:
        'The component used for the transition.\r\n[Follow this guide](/components/transitions/#transitioncomponent-prop) to learn more about the requirements for this component.',
      defaultValue: { value: 'Grow', computed: true },
    },
    transitionDuration: {
      type: {
        name: 'union',
        value: [
          { name: 'number' },
          {
            name: 'shape',
            value: {
              appear: { name: 'number', required: false },
              enter: { name: 'number', required: false },
              exit: { name: 'number', required: false },
            },
          },
        ],
      },
      required: false,
      description:
        'The duration for the transition, in milliseconds.\r\nYou may specify a single timeout for all transitions, or individually with an object.',
      defaultValue: {
        value:
          '{\n  enter: duration.enteringScreen,\n  exit: duration.leavingScreen\n}',
        computed: false,
      },
    },
    TransitionProps: {
      type: { name: 'object' },
      required: false,
      description:
        'Props applied to the [`Transition`](http://reactcommunity.org/react-transition-group/transition#Transition-props) element.',
    },
  },
  styles: {
    root: { description: 'Styles applied to the root element.' },
    anchorOriginTopCenter: {
      description:
        "Styles applied to the root element if `anchorOrigin={{ 'top', 'center' }}`.",
    },
    anchorOriginBottomCenter: {
      description:
        "Styles applied to the root element if `anchorOrigin={{ 'bottom', 'center' }}`.",
    },
    anchorOriginTopRight: {
      description:
        "Styles applied to the root element if `anchorOrigin={{ 'top', 'right' }}`.",
    },
    anchorOriginBottomRight: {
      description:
        "Styles applied to the root element if `anchorOrigin={{ 'bottom', 'right' }}`.",
    },
    anchorOriginTopLeft: {
      description:
        "Styles applied to the root element if `anchorOrigin={{ 'top', 'left' }}`.",
    },
    anchorOriginBottomLeft: {
      description:
        "Styles applied to the root element if `anchorOrigin={{ 'bottom', 'left' }}`.",
    },
  },
}
export default generatedDocs
