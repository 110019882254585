import React from 'react'
import createSvgIcon from './utils/createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path
      d="M5.355 12.56v4.001c0 .14.113.253.252.253h1.57a2.018 2.018 0 011.878-1.279h10.388c1.115 0 2.018.904 2.018 2.018v.302a2.017 2.017 0 01-2.018 2.017H9.055c-.952 0-1.75-.659-1.962-1.546H5.607c-.975 0-1.765-.79-1.765-1.765V8.065a2.017 2.017 0 01-1.847-2.01v-.303c0-1.114.903-2.017 2.017-2.017h10.389c1.114 0 2.017.903 2.017 2.017v.303A2.017 2.017 0 0114.4 8.072H5.355v2.975h1.76a2.018 2.018 0 011.94-1.462h10.388c1.115 0 2.018.903 2.018 2.017v.303a2.017 2.017 0 01-2.018 2.017H9.055c-.884 0-1.636-.57-1.908-1.362H5.355z"
      fillRule="nonzero"
    />
  </React.Fragment>,
  'Taxonomy',
)
