import Table from '@material-ui/core/Table'
export default Table

import React from 'react'
import clsx from 'clsx'
import makeStyles from '../../styles/makeStyles'

function TableWrapper({ className, height, ...props }) {
  let defaultStyles = useStyles({ height })

  return <div className={clsx(defaultStyles.root, className)} {...props} />
}

let useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    overflow: 'auto',
    height: (props) => (props.height ? props.height : `${56 + 56 + 52 * 10}px`), // header, footer, default rows
  },
}))

import MotifTable from './Table'
import Body from './Body'
import Header from './Header'
import Footer from './Footer'
import Row from './Row'
import Cell from './Cell'

export { MotifTable as Table, Body, Header, Footer, Row, Cell, TableWrapper }
