/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: 'MuiGrid',
  description: '',
  displayName: 'Grid',
  methods: [],
  props: {
    alignContent: {
      type: {
        name: 'enum',
        value: [
          { value: "'stretch'", computed: false },
          { value: "'center'", computed: false },
          { value: "'flex-start'", computed: false },
          { value: "'flex-end'", computed: false },
          { value: "'space-between'", computed: false },
          { value: "'space-around'", computed: false },
        ],
      },
      required: false,
      description:
        "Defines the `align-content` style property.\r\nIt's applied for all screen sizes.",
      defaultValue: { value: "'stretch'", computed: false },
    },
    alignItems: {
      type: {
        name: 'enum',
        value: [
          { value: "'flex-start'", computed: false },
          { value: "'center'", computed: false },
          { value: "'flex-end'", computed: false },
          { value: "'stretch'", computed: false },
          { value: "'baseline'", computed: false },
        ],
      },
      required: false,
      description:
        "Defines the `align-items` style property.\r\nIt's applied for all screen sizes.",
      defaultValue: { value: "'stretch'", computed: false },
    },
    children: {
      type: { name: 'node' },
      required: false,
      description: 'The content of the component.',
    },
    classes: {
      type: { name: 'object' },
      required: true,
      description:
        'Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details.',
    },
    className: {
      type: { name: 'string' },
      required: false,
      description: '@ignore',
    },
    component: {
      type: { name: 'elementType' },
      required: false,
      description:
        'The component used for the root node.\r\nEither a string to use a HTML element or a component.',
      defaultValue: { value: "'div'", computed: false },
    },
    container: {
      type: { name: 'bool' },
      required: false,
      description:
        'If `true`, the component will have the flex *container* behavior.\r\nYou should be wrapping *items* with a *container*.',
      defaultValue: { value: 'false', computed: false },
    },
    direction: {
      type: {
        name: 'enum',
        value: [
          { value: "'row'", computed: false },
          { value: "'row-reverse'", computed: false },
          { value: "'column'", computed: false },
          { value: "'column-reverse'", computed: false },
        ],
      },
      required: false,
      description:
        'Defines the `flex-direction` style property.\r\nIt is applied for all screen sizes.',
      defaultValue: { value: "'row'", computed: false },
    },
    item: {
      type: { name: 'bool' },
      required: false,
      description:
        'If `true`, the component will have the flex *item* behavior.\r\nYou should be wrapping *items* with a *container*.',
      defaultValue: { value: 'false', computed: false },
    },
    justify: {
      type: {
        name: 'custom',
        raw: "deprecatedPropType(PropTypes.oneOf(['flex-start', 'center', 'flex-end', 'space-between', 'space-around', 'space-evenly']), 'Use `justifyContent` instead, the prop was renamed.')",
      },
      required: false,
      description:
        'Defines the `justify-content` style property.\r\nIt is applied for all screen sizes.\r\n@deprecated Use `justifyContent` instead, the prop was renamed',
    },
    justifyContent: {
      type: {
        name: 'enum',
        value: [
          { value: "'flex-start'", computed: false },
          { value: "'center'", computed: false },
          { value: "'flex-end'", computed: false },
          { value: "'space-between'", computed: false },
          { value: "'space-around'", computed: false },
          { value: "'space-evenly'", computed: false },
        ],
      },
      required: false,
      description:
        'Defines the `justify-content` style property.\r\nIt is applied for all screen sizes.',
      defaultValue: { value: "'flex-start'", computed: false },
    },
    lg: {
      type: {
        name: 'enum',
        value: [
          { value: 'false', computed: false },
          { value: "'auto'", computed: false },
          { value: 'true', computed: false },
          { value: '1', computed: false },
          { value: '2', computed: false },
          { value: '3', computed: false },
          { value: '4', computed: false },
          { value: '5', computed: false },
          { value: '6', computed: false },
          { value: '7', computed: false },
          { value: '8', computed: false },
          { value: '9', computed: false },
          { value: '10', computed: false },
          { value: '11', computed: false },
          { value: '12', computed: false },
        ],
      },
      required: false,
      description:
        "Defines the number of grids the component is going to use.\r\nIt's applied for the `lg` breakpoint and wider screens if not overridden.",
      defaultValue: { value: 'false', computed: false },
    },
    md: {
      type: {
        name: 'enum',
        value: [
          { value: 'false', computed: false },
          { value: "'auto'", computed: false },
          { value: 'true', computed: false },
          { value: '1', computed: false },
          { value: '2', computed: false },
          { value: '3', computed: false },
          { value: '4', computed: false },
          { value: '5', computed: false },
          { value: '6', computed: false },
          { value: '7', computed: false },
          { value: '8', computed: false },
          { value: '9', computed: false },
          { value: '10', computed: false },
          { value: '11', computed: false },
          { value: '12', computed: false },
        ],
      },
      required: false,
      description:
        "Defines the number of grids the component is going to use.\r\nIt's applied for the `md` breakpoint and wider screens if not overridden.",
      defaultValue: { value: 'false', computed: false },
    },
    sm: {
      type: {
        name: 'enum',
        value: [
          { value: 'false', computed: false },
          { value: "'auto'", computed: false },
          { value: 'true', computed: false },
          { value: '1', computed: false },
          { value: '2', computed: false },
          { value: '3', computed: false },
          { value: '4', computed: false },
          { value: '5', computed: false },
          { value: '6', computed: false },
          { value: '7', computed: false },
          { value: '8', computed: false },
          { value: '9', computed: false },
          { value: '10', computed: false },
          { value: '11', computed: false },
          { value: '12', computed: false },
        ],
      },
      required: false,
      description:
        "Defines the number of grids the component is going to use.\r\nIt's applied for the `sm` breakpoint and wider screens if not overridden.",
      defaultValue: { value: 'false', computed: false },
    },
    spacing: {
      type: {
        name: 'enum',
        value: [
          { value: '0', computed: false },
          { value: '1', computed: false },
          { value: '2', computed: false },
          { value: '3', computed: false },
          { value: '4', computed: false },
          { value: '5', computed: false },
          { value: '6', computed: false },
          { value: '7', computed: false },
          { value: '8', computed: false },
          { value: '9', computed: false },
          { value: '10', computed: false },
        ],
      },
      required: false,
      description:
        'Defines the space between the type `item` component.\r\nIt can only be used on a type `container` component.',
      defaultValue: { value: '0', computed: false },
    },
    wrap: {
      type: {
        name: 'enum',
        value: [
          { value: "'nowrap'", computed: false },
          { value: "'wrap'", computed: false },
          { value: "'wrap-reverse'", computed: false },
        ],
      },
      required: false,
      description:
        "Defines the `flex-wrap` style property.\r\nIt's applied for all screen sizes.",
      defaultValue: { value: "'wrap'", computed: false },
    },
    xl: {
      type: {
        name: 'enum',
        value: [
          { value: 'false', computed: false },
          { value: "'auto'", computed: false },
          { value: 'true', computed: false },
          { value: '1', computed: false },
          { value: '2', computed: false },
          { value: '3', computed: false },
          { value: '4', computed: false },
          { value: '5', computed: false },
          { value: '6', computed: false },
          { value: '7', computed: false },
          { value: '8', computed: false },
          { value: '9', computed: false },
          { value: '10', computed: false },
          { value: '11', computed: false },
          { value: '12', computed: false },
        ],
      },
      required: false,
      description:
        "Defines the number of grids the component is going to use.\r\nIt's applied for the `xl` breakpoint and wider screens.",
      defaultValue: { value: 'false', computed: false },
    },
    xs: {
      type: {
        name: 'enum',
        value: [
          { value: 'false', computed: false },
          { value: "'auto'", computed: false },
          { value: 'true', computed: false },
          { value: '1', computed: false },
          { value: '2', computed: false },
          { value: '3', computed: false },
          { value: '4', computed: false },
          { value: '5', computed: false },
          { value: '6', computed: false },
          { value: '7', computed: false },
          { value: '8', computed: false },
          { value: '9', computed: false },
          { value: '10', computed: false },
          { value: '11', computed: false },
          { value: '12', computed: false },
        ],
      },
      required: false,
      description:
        "Defines the number of grids the component is going to use.\r\nIt's applied for all the screen sizes with the lowest priority.",
      defaultValue: { value: 'false', computed: false },
    },
    zeroMinWidth: {
      type: { name: 'bool' },
      required: false,
      description:
        'If `true`, it sets `min-width: 0` on the item.\r\nRefer to the limitations section of the documentation to better understand the use case.',
      defaultValue: { value: 'false', computed: false },
    },
  },
  styles: {
    root: { description: 'Styles applied to the root element.' },
    container: {
      description: 'Styles applied to the root element if `container={true}`.',
    },
    item: {
      description: 'Styles applied to the root element if `item={true}`.',
    },
    zeroMinWidth: {
      description:
        'Styles applied to the root element if `zeroMinWidth={true}`.',
    },
    '': {
      description:
        'Styles applied to the root element if `justifyContent="space-evenly"`.',
    },
  },
}
export default generatedDocs
