import React from 'react'
import CircularProgress from '../../../core-components/CircularProgress'
import FileName from './FileName'
import FileStatusButton from './FileStatusButton'
import FileError from './FileError'
import { makeStyles } from '../../../utils'
import Check from '../../../icons/Check'

export default function FileStatus({
  file = null,
  successComponent: SuccessComponent = null,
  errorMessage,
  open,
}) {
  useStyles()

  const {
    motifId,
    isSuccess,
    isError,
    isRejected,
    name,
    size,
    progress = 0,
    removeFile,
    cancelUpload,
    retryUpload,
  } = file

  return (
    <div data-testid="UploaderFileStatus" className="UploaderFileStatus">
      {motifId && !isError && (
        <div className="UploaderFileStatus__file-data">
          <span>
            <FileName name={name} maxChar={28} />
            <span className="UploaderFileStatus__file-size">{size}</span>
          </span>
          <span>
            {isSuccess ? (
              SuccessComponent ? (
                SuccessComponent(file)
              ) : (
                <Check color="primary" />
              )
            ) : (
              <CircularProgress
                variant={progress ? 'determinate' : 'indeterminate'}
                value={progress}
              />
            )}
          </span>
        </div>
      )}
      <FileError file={file} customError={errorMessage} />
      <FileStatusButton
        isError={isError}
        isSuccess={isSuccess}
        cancel={cancelUpload}
        retry={retryUpload}
        remove={removeFile}
        isRejected={isRejected}
        open={open}
      />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.UploaderFileStatus': {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      ...theme.typography.body3,
      textAlign: 'center',

      '& > div': {
        flex: 1,
      },

      // .UploaderFileStatus__file-data
      '&__file-data': {
        color: theme.palette.common.black,
        maxWidth: '192px',
        whiteSpace: 'nowrap',
        display: 'grid',
        gap: '8px',
      },

      // .UploaderFileStatus__file-size
      '&__file-size': {
        textTransform: 'uppercase',
      },
    },
  },
}))
