import React from 'react'
import clsx from 'clsx'
import DialogTitle from '../../core-components/DialogTitle'
import { makeStyles, mergeClasses } from '../../utils'

export default function FullScreenDialogTitle({
  classes,
  className,
  ...props
}) {
  let defaultClasses = useStyles()
  return (
    <DialogTitle
      className={clsx('FullScreenDialogTitle-root', className)}
      classes={mergeClasses(defaultClasses, classes)}
      {...props}
    />
  )
}

let useStyles = makeStyles((theme) => ({
  // overrides Dialog fullScreens hardcoded title placement, to be removed in 2.0
  root: {
    '.MuiDialog-paperFullScreen > &': {
      padding: '24px 24px 12px 24px',
    },
  },
}))
