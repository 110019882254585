import React from 'react'
import { makeStyles } from '../../../utils'

const FileError = ({ file, customError, multiple }) => {
  const { name, statusDetail: errorDetail, isError, isRejected } = file
  const DEFAULT_ERROR = isRejected
    ? `Error uploading file ${name}: ${errorDetail ?? ''}`
    : `Upload failed: ${errorDetail}`

  if (!isError && !isRejected) {
    return null
  }

  const errorMsg = customError
    ? typeof customError === 'function'
      ? customError(name, errorDetail, file)
      : customError
    : DEFAULT_ERROR

  useStyles()

  return (
    <div
      data-testid="UploaderFileError"
      className={multiple ? 'UploaderFileError--multiple' : 'UploaderFileError'}
    >
      {errorMsg}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.UploaderFileError': {
      color: theme.palette.common.error,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '.UploaderFileError--multiple': {
      color: theme.palette.error.main,
      width: '100%',
      padding: '0 24px 0 24px',
      marginTop: '4px',
      ...theme.typography.body2,
    },
  },
}))

export default FileError
