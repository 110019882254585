import React, { useState } from 'react'
import Card from '@liveramp/motif/core/Card'
import NotificationContent from '@liveramp/motif/core/NotificationContent'
import { data } from './data'
import makeStyles from '@liveramp/motif/styles/makeStyles'

export default function MessageStates() {
  let classes = useStyles()
  let [currentData, setCurrentData] = useState(data)

  let notificationsLoading = (
    <NotificationContent
      onMarkReadClick={(notification) => setCurrentData([])}
      onSeeAllClick={() => {
        setCurrentData(data)
        alert('Clicked "SEE ALL" on the Notifications!')
      }}
      notifications={currentData}
      loading={true}
      height="320px"
    />
  )

  let notificationsEmpty = (
    <NotificationContent
      onMarkReadClick={(notification) => setCurrentData([])}
      onSeeAllClick={() => {
        setCurrentData(data)
        alert('Clicked "SEE ALL" on the Notifications!')
      }}
      notifications={[]}
      height="320px"
    />
  )

  let notificationsError = (
    <NotificationContent
      onMarkReadClick={(notification) => setCurrentData([])}
      onSeeAllClick={() => {
        setCurrentData(data)
        alert('Clicked "SEE ALL" on the Notifications!')
      }}
      notifications={currentData}
      loading={false}
      error={true}
      height="320px"
    />
  )

  return (
    /* Click SEE ALL to restore the Notifications for the demos */
    <div className={classes.grid}>
      <Card>{notificationsLoading}</Card>
      <Card>{notificationsEmpty}</Card>
      <Card>{notificationsError}</Card>
    </div>
  )
}

let useStyles = makeStyles((theme) => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 270px)',
    gap: '1rem',
    '& > .MuiCard-root': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
}))
