import React from 'react'
import { GridOverlay } from '../../core-components/DataGrid'
import LinearProgress from '../../core-components/LinearProgress'
import makeStyles from '../../styles/makeStyles'

export default function LoadingOverlay() {
  useStyles()

  return (
    <GridOverlay data-testid="RichDataTableLoadingOverlay">
      <div className="RichDataTableLoadingOverlay">
        <LinearProgress />
      </div>
    </GridOverlay>
  )
}

let useStyles = makeStyles((theme) => ({
  '@global': {
    '.RichDataTableLoadingOverlay': {
      position: 'absolute',
      width: '100%',
      // overlap the header bottom bar
      top: -4,
      zIndex: 1,
    },
  },
}))
