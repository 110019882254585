import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import { withStyles } from '../../utils'
import { BreadcrumbsStyle } from '../../utils/styleKeys'

const styles = (theme) => ({
  ...BreadcrumbsStyle,
  li: {
    '& *, & *:visited': {
      ...theme.typography.body3,
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.text.secondary,
      textDecoration: 'none',
    },
    '& *:hover': {
      cursor: 'pointer',
    },
    '&:last-child *': {
      color: theme.palette.secondary.dark,

      '&:hover': {
        cursor: 'auto',
      },
    },
    '&:hover:not(:last-child) *': {
      color: theme.palette.text.hoverLink,
      textDecoration: 'underline',
    },
  },
  separator: {
    ...theme.typography.body3,
    color: theme.palette.text.secondary,
  },
})

export default withStyles(styles, { name: 'MuiBreadcrumbs' })(Breadcrumbs)
