import React from 'react'

const WorkflowForecastingReport = (props) => (
  <svg width={80} height={80} viewBox=" 0 0 80 80" {...props}>
    <g fillRule="nonzero">
      <path d="M51.923 7.028h-34.88c-1.033.057-2.253.831-2.73 1.813a1 1 0 00-.101.437v58.18l.009.96.015.577.01.27.028.51.038.466c.115 1.194.349 1.985.795 2.673.748 1.152 2.069 1.558 3.62 1.558h44.972c1.798 0 2.985-.332 3.754-1.578.498-.808.692-1.877.744-3.437l.014-.699.002-45.648a1 1 0 00-.284-.698l-4.569-4.68-2.719-2.762-1.7-1.713-2.652-2.64-1.115-1.091-.766-.732-.518-.479-.339-.3-.3-.256-.181-.145a8.11 8.11 0 00-.085-.066l-.234-.171-.14-.093-.13-.077-.119-.062-.11-.048a1.273 1.273 0 00-.052-.02l-.098-.028a.886.886 0 00-.047-.01l-.09-.01-.042-.001zM17.1 9.026l34.551.001.118.095.232.197.284.252.52.476.758.718 1.16 1.124.808.796 1.21 1.202 1.828 1.834 3.615 3.669 4.027 4.125v45.22l-.006.362c-.03 1.386-.173 2.289-.456 2.747-.28.454-.901.628-2.05.628H18.726c-.937 0-1.668-.225-1.942-.648l-.052-.084c-.168-.293-.288-.686-.37-1.234l-.044-.349a12.587 12.587 0 01-.036-.388l-.028-.43-.021-.477-.018-.805-.004-.602-.001-57.888.036-.042c.232-.254.638-.487.854-.499z" />
      <path d="M57.215 33.331V67.49h1a1 1 0 001-1V33.33h-2zM53.665 33.331v34.142h-1a1 1 0 01-1-1V33.331a1 1 0 112 0z" />
      <path d="M58.215 65.49v2H52.91v-2zM59.215 33.331v1h-6.55v-2h5.55a1 1 0 011 1z" />
      <g>
        <path d="M45.92 44.832v22.782h1a1 1 0 001-1V44.832h-2zM42.37 45.165v22.438h-1a1 1 0 01-1-1V45.165a1 1 0 012 0z" />
        <path d="M46.92 65.99v1.624h-5.305v-1.625zM47.92 45.165v1h-6.55v-2h5.55a1 1 0 011 1z" />
      </g>
      <g>
        <path d="M34.625 55.223v12.39h1a1 1 0 001-1v-11.39h-2zM31.075 54.723v12.88h-1a1 1 0 01-1-1v-11.88a1 1 0 112 0z" />
        <path d="M35.625 65.99v1.624H30.32v-1.625zM36.625 54.723v1h-6.55v-2h5.55a1 1 0 011 1z" />
      </g>
      <path d="M52.882 26.012c-1.16-.123-2.213-1.156-2.355-2.308-.019-.149.003-.413.065-.794l.056-15.147 2.033 1.017-.056 15.146L68 23.844l-.167 2.086-14.951.082z" />
    </g>
  </svg>
)

export default WorkflowForecastingReport
