import React from 'react'
import clsx from 'clsx'
import changeCase from 'change-case'
import ErrorIcon from '@liveramp/motif/icons/ErrorOutline'
import WarningIcon from '@liveramp/motif/icons/WarningOutline'
import InfoIcon from '@liveramp/motif/icons/InfoOutline'
import LinkIcon from '@liveramp/motif/icons/Link'
import IconButton from '@liveramp/motif/core/IconButton'
import css from './Page.module.css'

export function Title({ children = '', ...props }) {
  return <h1 {...props}>{children}</h1>
}

export function Subtitle({ children = '', ...props }) {
  return (
    <h3 className={css.subtitle} {...props}>
      {children}
    </h3>
  )
}

export function Supporting(props) {
  return <p className={css.supporting} {...props} />
}

export function Usage({ children }) {
  return (
    <>
      <h4>Usage</h4>
      {children}
      <br />
    </>
  )
}

export function NoticePanel({ children = '', variant = 'info', ...props }) {
  let variantClass
  let icon

  if (variant === 'info') {
    variantClass = css.info
    icon = <InfoIcon />
  } else if (variant === 'danger') {
    variantClass = css.danger
    icon = <ErrorIcon />
  } else {
    variantClass = css.warning
    icon = <WarningIcon />
  }

  return (
    <div className={clsx(css.panel, variantClass)} {...props}>
      {icon}
      {children}
    </div>
  )
}

export function SectionTitle({ children = '', subtitle = false }) {
  let id = encodeURIComponent(changeCase.paramCase(children))
  let title = subtitle ? <h4>{children}</h4> : <h3>{children}</h3>

  return (
    <div className={css.sectionLink} id={id}>
      {title}
      <IconButton green href={`#${id}`} className={css.button}>
        <LinkIcon />
      </IconButton>
    </div>
  )
}
