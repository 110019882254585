/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: 'MuiCardActions',
  description: '',
  displayName: 'CardActions',
  methods: [],
  props: {
    children: {
      type: { name: 'node' },
      required: false,
      description: 'The content of the component.',
    },
    classes: {
      type: { name: 'object' },
      required: false,
      description:
        'Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details.',
    },
    className: {
      type: { name: 'string' },
      required: false,
      description: '@ignore',
    },
    disableSpacing: {
      type: { name: 'bool' },
      required: false,
      description: 'If `true`, the actions do not have additional margin.',
      defaultValue: { value: 'false', computed: false },
    },
  },
  styles: {
    root: { description: 'Styles applied to the root element.' },
    spacing: {
      description:
        'Styles applied to the root element if `disableSpacing={false}`.',
    },
  },
}
export default generatedDocs
