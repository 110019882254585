import isEqual from 'lodash.isequal'

export const handleGetOptionLabel = (option, getOptionLabel) => {
  if (typeof option === 'string') return option
  if (option?.getOptionLabel) return option.getOptionLabel(option)
  if (getOptionLabel) return getOptionLabel(option)
  return ''
}

export const handleOptionSelected = (a, b) => {
  let { getOptionLabel: getOptionLabelA, ...aObj } = a
  let { getOptionLabel: getOptionLabelB, ...bObj } = b

  return isEqual(aObj, bObj)
}

// The following functions were built to utilise MUI's useAutocomplete hook.
//
// The hook expects an array of flat objects, however, the objects do not have
// to be similar in definition. What we've done here is flatten the array and
// added a private property and the rest of the parent object to identify the
// original groups the options were in.
export const flattenOptions = (options) => {
  if (options) {
    return options.reduce((prev, curr) => {
      if (curr.options) {
        let flatSubOptions = curr.options.map((opt) => {
          let { options, ...currWithoutOptions } = curr
          let newOpt
          if (typeof opt === 'string') {
            newOpt = {
              _mfGroupId: curr.id,
              _mfGroup: currWithoutOptions,
              id: `${curr.id}-${opt}`,
              label: opt,
              getOptionLabel: (opt) => opt.label,
            }
          } else {
            newOpt = {
              ...opt,
              _mfGroupId: curr.id,
              _mfGroup: currWithoutOptions,
            }
          }

          return newOpt
        })

        return [...prev, ...flatSubOptions]
      }

      return [...prev, curr]
    }, [])
  }

  return options
}

export const regroupOptions = (groupedOptions, getOptionProps) =>
  groupedOptions.reduce((prev, curr, index) => {
    let optionProps = getOptionProps({ option: curr, index })

    if (curr._mfGroupId) {
      const groupIndex = prev.findIndex(
        (prevObject) => prevObject._mfGroupId === curr._mfGroupId,
      )

      if (groupIndex >= 0) {
        prev[groupIndex].options.push({
          option: curr,
          optionProps,
        })
      } else {
        prev.push({
          _mfGroupId: curr._mfGroupId,
          ...curr._mfGroup,
          options: [{ option: curr, optionProps }],
        })
      }
    } else {
      prev.push({ option: curr, optionProps })
    }

    return prev
  }, [])

export const restoreOriginalOptions = (options, selectedOptions) => {
  const restoredOptions = []
  selectedOptions.forEach((selectedOption) => {
    // If the selected option has suboptions
    if (selectedOption._mfGroupId) {
      const originalGroup = options.find(
        (opt) => selectedOption._mfGroupId === opt.id,
      )

      // If the group doesn't already exist in restoredOptions, add it but with
      // no options selected
      let restoredOptionGroupIndex = restoredOptions.findIndex(
        (opt) => opt.id === originalGroup.id,
      )
      if (restoredOptionGroupIndex < 0) {
        restoredOptions.push({ ...originalGroup, options: [] })
        restoredOptionGroupIndex = restoredOptions.length - 1
      }

      // Now find the singular selected suboption in the group and add its original
      // definition into restoredOptions
      const restoredSubOption = originalGroup.options.find(
        (opt) => opt === selectedOption.label || opt.id === selectedOption.id,
      )
      restoredOptions[restoredOptionGroupIndex].options.push(restoredSubOption)
    } else {
      restoredOptions.push(selectedOption)
    }
  })

  return restoredOptions
}
