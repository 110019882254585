/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: 'MuiCollapse',
  description:
    'The Collapse transition is used by the\r\n[Vertical Stepper](/components/steppers/#vertical-stepper) StepContent component.\r\nIt uses [react-transition-group](https://github.com/reactjs/react-transition-group) internally.',
  displayName: 'Collapse',
  methods: [],
  props: {
    children: {
      type: { name: 'node' },
      required: false,
      description: 'The content node to be collapsed.',
    },
    classes: {
      type: {
        name: 'custom',
        raw: "chainPropTypes(PropTypes.object, props => {\n  if (props.classes && props.classes.container) {\n    throw new Error(['Material-UI: the classes.container key is deprecated.', 'Use `classes.root` instead', 'The name of the pseudo-class was changed for consistency.']).join('\\n');\n  }\n\n  return null;\n})",
      },
      required: false,
      description:
        'Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details.',
    },
    className: {
      type: { name: 'string' },
      required: false,
      description: '@ignore',
    },
    collapsedHeight: {
      type: {
        name: 'custom',
        raw: "deprecatedPropType(PropTypes.oneOfType([PropTypes.number, PropTypes.string]), 'The prop was renamed to support the vertical orientation, use `collapsedSize` instead')",
      },
      required: false,
      description:
        'The height of the container when collapsed.\r\n@deprecated The prop was renamed to support the addition of horizontal orientation, use `collapsedSize` instead.',
    },
    collapsedSize: {
      type: { name: 'union', value: [{ name: 'number' }, { name: 'string' }] },
      required: false,
      description: 'The height of the container when collapsed.',
      defaultValue: { value: "'0px'", computed: false },
    },
    component: {
      type: { name: 'elementType' },
      required: false,
      description:
        'The component used for the root node.\r\nEither a string to use a HTML element or a component.',
      defaultValue: { value: "'div'", computed: false },
    },
    disableStrictModeCompat: {
      type: { name: 'bool' },
      required: false,
      description:
        "Enable this prop if you encounter 'Function components cannot be given refs',\r\nuse `unstable_createStrictModeTheme`,\r\nand can't forward the ref in the passed `Component`.",
      defaultValue: { value: 'false', computed: false },
    },
    in: {
      type: { name: 'bool' },
      required: false,
      description: 'If `true`, the component will transition in.',
    },
    onEnter: {
      type: { name: 'func' },
      required: false,
      description: '@ignore',
    },
    onEntered: {
      type: { name: 'func' },
      required: false,
      description: '@ignore',
    },
    onEntering: {
      type: { name: 'func' },
      required: false,
      description: '@ignore',
    },
    onExit: { type: { name: 'func' }, required: false, description: '@ignore' },
    onExited: {
      type: { name: 'func' },
      required: false,
      description: '@ignore',
    },
    onExiting: {
      type: { name: 'func' },
      required: false,
      description: '@ignore',
    },
    style: {
      type: { name: 'object' },
      required: false,
      description: '@ignore',
    },
    timeout: {
      type: {
        name: 'union',
        value: [
          { name: 'enum', value: [{ value: "'auto'", computed: false }] },
          { name: 'number' },
          {
            name: 'shape',
            value: {
              appear: { name: 'number', required: false },
              enter: { name: 'number', required: false },
              exit: { name: 'number', required: false },
            },
          },
        ],
      },
      required: false,
      description:
        "The duration for the transition, in milliseconds.\r\nYou may specify a single timeout for all transitions, or individually with an object.\r\n\r\nSet to 'auto' to automatically calculate transition time based on height.",
      defaultValue: { value: 'duration.standard', computed: true },
    },
  },
  styles: {
    root: { description: 'Styles applied to the root element.' },
    entered: {
      description:
        'Styles applied to the root element when the transition has entered.',
    },
    hidden: {
      description:
        'Styles applied to the root element when the transition has exited and `collapsedSize` != 0px.',
    },
    wrapper: { description: 'Styles applied to the outer wrapper element.' },
    wrapperInner: {
      description: 'Styles applied to the inner wrapper element.',
    },
  },
}
export default generatedDocs
