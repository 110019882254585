import React from 'react'

const Analyze = (props) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0703 17.0705C16.2851 16.8557 16.6333 16.8557 16.8481 17.0705L23.3886 23.6111C23.6034 23.8258 23.6034 24.1741 23.3886 24.3889C23.1738 24.6037 22.8256 24.6037 22.6108 24.3889L16.0703 17.8483C15.8555 17.6335 15.8555 17.2853 16.0703 17.0705Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.17587 2.54995C4.96433 2.54995 1.5502 5.96408 1.5502 10.1756C1.5502 14.3872 4.96433 17.8013 9.17587 17.8013C13.3874 17.8013 16.8015 14.3872 16.8015 10.1756C16.8015 5.96408 13.3874 2.54995 9.17587 2.54995ZM0.450195 10.1756C0.450195 5.35657 4.35681 1.44995 9.17587 1.44995C13.9949 1.44995 17.9015 5.35657 17.9015 10.1756C17.9015 14.9947 13.9949 18.9013 9.17587 18.9013C4.35681 18.9013 0.450195 14.9947 0.450195 10.1756Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0002 4.8689C12.304 4.8689 12.5502 5.11514 12.5502 5.4189V14.7838C12.5502 15.0875 12.304 15.3338 12.0002 15.3338C11.6964 15.3338 11.4502 15.0875 11.4502 14.7838V5.4189C11.4502 5.11514 11.6964 4.8689 12.0002 4.8689Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.62227 6.50403C9.92602 6.50403 10.1723 6.75027 10.1723 7.05403V14.7838C10.1723 15.0875 9.92602 15.3338 9.62227 15.3338C9.31851 15.3338 9.07227 15.0875 9.07227 14.7838V7.05403C9.07227 6.75027 9.31851 6.50403 9.62227 6.50403Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.24336 9.92297C7.54712 9.92297 7.79336 10.1692 7.79336 10.473V14.7838C7.79336 15.0875 7.54712 15.3338 7.24336 15.3338C6.9396 15.3338 6.69336 15.0875 6.69336 14.7838V10.473C6.69336 10.1692 6.9396 9.92297 7.24336 9.92297Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.1623 12.3013C5.46606 12.3013 5.7123 12.5475 5.7123 12.8513V14.7837C5.7123 15.0875 5.46606 15.3337 5.1623 15.3337C4.85855 15.3337 4.6123 15.0875 4.6123 14.7837V12.8513C4.6123 12.5475 4.85855 12.3013 5.1623 12.3013Z"
        fill="white"
      />
    </svg>
  )
}

export default Analyze
