// this export is icon files w/o the js extension; include icon names in this export if an icon should be rendered in the display page under the Action category

let icons = [
  'Activate',
  'Add',
  'AddCircle',
  'Archive',
  'Attachment',
  'Build',
  'CalendarToday',
  'Cancel',
  'Check',
  'CheckCircle',
  'CheckCircleOutline',
  'Clear',
  'Code',
  'CopyToClipboard',
  'CreateAudience',
  'CreateNewFolder',
  'CreateSegment',
  'Delete',
  'DeleteOutline',
  'Distribute',
  'Download',
  'Dropper',
  'Duplicate',
  'Edit',
  'Filter',
  'FoldLess',
  'FoldMore',
  'Grip',
  'History',
  'Insights',
  'JoinFull',
  'Layers',
  'Lightbulb',
  'LightbulbOutline',
  'Merge',
  'Overlap',
  'Palette',
  'Permission',
  'PermissionRevoke',
  'PlayArrow',
  'Pause',
  'Puzzle',
  'Reconnect',
  'Redo',
  'Refresh',
  'Remove',
  'RemoveCircle',
  'RemoveCircleOutline',
  'Save',
  'Search',
  'Send',
  'Share',
  'Stop',
  'TrendingDown',
  'TrendingUp',
  'Undo',
  'Update',
  'Upload',
  'View',
  'ViewModule',
  'WebAsset',
  'ZoomIn',
  'ZoomOut',
]

export default icons
