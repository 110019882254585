import React from 'react'
import createSvgIcon from './utils/createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M6.474 10.393a3.919 3.919 0 100-7.837 3.919 3.919 0 000 7.837zm0-1.11a2.808 2.808 0 110-5.616 2.808 2.808 0 010 5.615zM6.474 21.444a3.919 3.919 0 100-7.837 3.919 3.919 0 000 7.837zm0-1.11a2.808 2.808 0 110-5.616 2.808 2.808 0 010 5.615zM17.526 10.393a3.919 3.919 0 100-7.837 3.919 3.919 0 000 7.837zm0-1.11a2.808 2.808 0 110-5.616 2.808 2.808 0 010 5.615zM17.526 21.444a3.919 3.919 0 100-7.837 3.919 3.919 0 000 7.837zm0-1.11a2.808 2.808 0 110-5.616 2.808 2.808 0 010 5.615z" />
    <path d="M17.526 5.919H9.838V7.03h7.688zM18.081 17.526V9.838H16.97v7.688zM6.474 18.081h7.688V16.97H6.474zM5.919 6.474v7.688H7.03V6.474z" />
  </React.Fragment>,
  'ActivateMeasure',
)
