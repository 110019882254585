import React, { useState } from 'react'
import Stepper from '@liveramp/motif/core/Stepper'
import Step from '@liveramp/motif/core/Step'
import StepLabel from '@liveramp/motif/core/StepLabel'
import css from '../../../resources/Page.module.css'

export default function ErrorDemo() {
  const [activeStep] = useState(1)
  const steps = getSteps()

  return (
    <Stepper activeStep={activeStep} className={css.fullWidth}>
      {steps.map(({ label, labelProps = {} }) => (
        <Step key={label}>
          <StepLabel {...labelProps}>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}

function getSteps() {
  return [
    {
      label: 'Step One',
      labelProps: {
        error: true,
        optional: 'This step is completed but has an error',
      },
    },
    {
      label: 'Error Step!',
      labelProps: {
        error: true,
        optional: 'This step is active and has an error',
      },
    },
    {
      label: 'Step 3',
      labelProps: {
        variant: 'error',
        optional: 'This step uses variant to set the error',
      },
    },
  ]
}
