import React from 'react'

let FlagJP = (props) => (
  <svg
    width="24"
    height="18"
    viewBox="0 0 24 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.85.150002H2.14999C1.04542.150002.149994 1.04543.149994 2.15v13.7c0 1.1046.895426 2 1.999996 2H21.85c1.1046 0 2-.8954 2-2V2.15c0-1.10457-.8954-1.999998-2-1.999998z"
      fill="#fff"
      stroke="#919090"
      strokeWidth=".3"
    />
    <path
      d="M12 14.2857c2.8403 0 5.1429-2.3025 5.1429-5.14284C17.1429 6.30254 14.8403 4 12 4 9.15968 4 6.85715 6.30254 6.85715 9.14286c0 2.84034 2.30253 5.14284 5.14285 5.14284z"
      fill="#C81717"
    />
  </svg>
)

export default FlagJP
