import React, { Component } from 'react'
import ReactInspector from 'react-inspector'
import css from './Inspector.module.css'

export default class Inspector extends Component {
  constructor(props) {
    super(props)
    this.state = {
      startingSize: 0,
    }
    this.inspectorRef = React.createRef()
  }

  // Lifecycle methods are required to get the initial size of the inspector
  // component so that its minimum size will always be the starting size to
  // prevent crazy page movements.
  //
  // It also takes into account the inspector component may have to re-render
  // to show some expanded children
  componentDidMount() {
    this.setState({ startingSize: this.inspectorRef.current.clientHeight })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.startingSize === 0) {
      this.setState({ startingSize: this.inspectorRef.current.clientHeight })
    }
  }

  render() {
    return (
      <div
        ref={this.inspectorRef}
        className={css.inspector}
        style={{ minHeight: `${this.state.startingSize}px` }}
      >
        <ReactInspector theme={theme} {...this.props} />
      </div>
    )
  }
}

// https://github.com/storybookjs/react-inspector/tree/master/src/styles/themes
let theme = {
  BASE_FONT_FAMILY: 'Menlo, monospace',
  BASE_FONT_SIZE: '0.85rem',
  BASE_LINE_HEIGHT: 1.5,

  BASE_BACKGROUND_COLOR: '#333',
  BASE_COLOR: 'white',

  OBJECT_PREVIEW_ARRAY_MAX_PROPERTIES: 10,
  OBJECT_PREVIEW_OBJECT_MAX_PROPERTIES: 5,
  OBJECT_NAME_COLOR: '#ea80fc',
  OBJECT_VALUE_NULL_COLOR: '#bdbdbd',
  OBJECT_VALUE_UNDEFINED_COLOR: '#bdbdbd',
  OBJECT_VALUE_REGEXP_COLOR: '#f44336',
  OBJECT_VALUE_STRING_COLOR: '#f44336',
  OBJECT_VALUE_SYMBOL_COLOR: '#f44336',
  OBJECT_VALUE_NUMBER_COLOR: '#8c9eff',
  OBJECT_VALUE_BOOLEAN_COLOR: '#8c9eff',
  OBJECT_VALUE_FUNCTION_PREFIX_COLOR: '#82b1ff',

  HTML_TAG_COLOR: '#40c4ff',
  HTML_TAGNAME_COLOR: '#40c4ff',
  HTML_TAGNAME_TEXT_TRANSFORM: 'lowercase',
  HTML_ATTRIBUTE_NAME_COLOR: '#b0bec5',
  HTML_ATTRIBUTE_VALUE_COLOR: '#ffab40',
  HTML_COMMENT_COLOR: '#757575',
  HTML_DOCTYPE_COLOR: '#bdbdbd',

  ARROW_COLOR: '#757575',
  ARROW_MARGIN_RIGHT: 3,
  ARROW_FONT_SIZE: 12,
  ARROW_ANIMATION_DURATION: '0',

  TREENODE_FONT_FAMILY: 'Menlo, monospace',
  TREENODE_FONT_SIZE: '0.85rem',
  TREENODE_LINE_HEIGHT: 1.5,
  TREENODE_PADDING_LEFT: 12,

  TABLE_BORDER_COLOR: 'rgb(85, 85, 85)',
  TABLE_TH_BACKGROUND_COLOR: 'rgb(44, 44, 44)',
  TABLE_TH_HOVER_COLOR: 'rgb(48, 48, 48)',
  TABLE_SORT_ICON_COLOR: 'black', //'rgb(48, 57, 66)',
  TABLE_DATA_BACKGROUND_IMAGE:
    'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) 50%, rgba(51, 139, 255, 0.0980392) 50%, rgba(51, 139, 255, 0.0980392))',
  TABLE_DATA_BACKGROUND_SIZE: '128px 32px',
}
