import React from 'react'
import { Route, Switch } from 'react-router-dom'

import MotifMenu from './Menu'
import Header from './Header'
import css from './Navigation.module.css'

import {
  startingRoutes,
  styleRoutes,
  layoutsRoutes,
  coreDemoRoutes,
  composedDemoRoutes,
  rdtDemoRoutes,
} from './Routes'
import { apiRoutes } from './RoutesApis'
import Home from '../getting-started/motif'
import IconsPage from '../icons'
import CorePage from '../core-component-demo'
import ComposedPage from '../composed-component-demo'
import RichDataTablePage from '../composed-component-demo/rich-data-table'
import ErrorPage from './Error'
import SandboxPage from '../resources/Sandbox'

export default function Navigation() {
  return (
    <Switch>
      <Route path="/sandbox" component={SandboxPage} />
      {/* we don't want the Motif docs wrapper to exist in the sandbox */}
      {coreDemoRoutes.map(
        (r) =>
          r?.sandbox && (
            <Route
              key={`${r.link}-sandbox`}
              path={`/core/${r.link}/sandbox`}
              component={r.sandbox}
            />
          ),
      )}
      {composedDemoRoutes.map(
        (r) =>
          r?.sandbox && (
            <Route
              key={`${r.link}-sandbox`}
              path={`/composed/${r.link}/sandbox`}
              component={r.sandbox}
            />
          ),
      )}

      <>
        <MotifMenu />
        <div className={css.content}>
          <Header />
          <main>
            <Switch>
              <Route exact path="/" component={Home} />
              {startingRoutes.map((r) => (
                <Route
                  key={r.link}
                  exact
                  path={`/getting-started/${r.link}`}
                  component={r.component}
                />
              ))}
              {styleRoutes.map((r) => (
                <Route
                  key={r.link}
                  exact
                  path={`/style/${r.link}`}
                  component={r.component}
                />
              ))}
              {layoutsRoutes.map((r) => (
                <Route
                  key={r.link}
                  exact
                  path={`/layouts/${r.link}`}
                  component={r.component}
                />
              ))}
              <Route exact path="/core" component={CorePage} />
              {coreDemoRoutes.map((r) => (
                <Route
                  key={r.link}
                  exact
                  path={`/core/${r.link}`}
                  component={r.component}
                />
              ))}
              <Route exact path="/composed" component={ComposedPage} />
              {composedDemoRoutes.map((r) => (
                <Route
                  key={r.link}
                  exact
                  path={`/composed/${r.link}`}
                  component={r.component}
                />
              ))}
              <Route
                exact
                path="/rich-data-table"
                component={RichDataTablePage}
              />
              {rdtDemoRoutes.map((r) => (
                <Route
                  key={r.link}
                  exact
                  path={`/rich-data-table/${r.link}`}
                  component={r.component}
                />
              ))}
              {apiRoutes.map((r) => (
                <Route
                  key={r.link}
                  exact
                  path={`/api/${r.link}`}
                  component={r.component}
                />
              ))}
              <Route path="/icons" component={IconsPage} />
              <Route component={ErrorPage} />
            </Switch>
          </main>
        </div>
      </>
    </Switch>
  )
}
