import React, { useState } from 'react'
import Switch from '@liveramp/motif/core/Switch'
import FormGroup from '@liveramp/motif/core/FormGroup'
import FormControlLabel from '@liveramp/motif/core/FormControlLabel'
import css from '../../../resources/Page.module.css'

export default function SwitchTypeDemo() {
  let [checkedA, setCheckedA] = useState(true)

  return (
    <FormGroup className={css.grid}>
      <FormControlLabel
        control={
          <Switch
            checked={checkedA}
            onChange={() => setCheckedA(!checkedA)}
            value="checkedA"
          />
        }
        label="Default"
      />
      <FormControlLabel control={<Switch />} label="Uncontrolled" />
      <FormControlLabel
        disabled
        control={<Switch value="checkedC" />}
        label="Disabled Unchecked"
      />
      <FormControlLabel
        disabled
        control={<Switch checked value="checkedD" />}
        label="Disabled Checked"
      />
    </FormGroup>
  )
}
