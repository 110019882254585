import React from 'react'
import TreeView from '@liveramp/motif/core/TreeView'
import TreeItem from '@liveramp/motif/core/TreeItem'
import { CaretDown, CaretRight, Folder } from '@liveramp/motif/icons'

const ControlledDemo = () => {
  const [expanded, setExpanded] = React.useState(['1', '5', '6', '7', '11'])
  const [selected, setSelected] = React.useState([])

  const handleToggle = (event, nodeIds) => {
    console.log(`Expanded: ${nodeIds}`)
    setExpanded(nodeIds)
  }

  const handleSelect = (event, nodeIds) => {
    console.log(`Selected: ${nodeIds}`)
    setSelected(nodeIds)
  }

  const createWithChildren = (label) => (
    <>
      <Folder />
      <span>{label}</span>
    </>
  )

  return (
    <TreeView
      defaultCollapseIcon={<CaretDown />}
      defaultExpandIcon={<CaretRight />}
      expanded={expanded}
      selected={selected}
      onNodeToggle={handleToggle}
      onNodeSelect={handleSelect}
    >
      <TreeItem nodeId="1" label={createWithChildren('Coffee')}>
        <TreeItem nodeId="2" label="Espresso" />
        <TreeItem nodeId="3" label="Latte" />
        <TreeItem nodeId="4" label="Flat White" />
      </TreeItem>
      <TreeItem nodeId="5" label={createWithChildren('Boba')}>
        <TreeItem nodeId="6" label={createWithChildren('Milk')}>
          <TreeItem nodeId="7" label="Black" />
          <TreeItem nodeId="8" label="Taro" />
          <TreeItem nodeId="9" label="Oolong" />
          <TreeItem nodeId="10" label="Jasmine" />
        </TreeItem>
        <TreeItem nodeId="11" label={createWithChildren('Fruit')}>
          <TreeItem nodeId="12" label="Peach" />
          <TreeItem nodeId="13" label="Strawberry" />
          <TreeItem nodeId="14" label="Mango" />
        </TreeItem>
      </TreeItem>
    </TreeView>
  )
}

export default ControlledDemo
